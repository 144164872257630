const initialState = {
  subTasksByPeriod: []
}

const projectSubTasksByPeriodReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setSubTasksByPeriod':
      return {
        ...state,
        subTasksByPeriod: action.payload.subTasksByPeriod
      }
    default:
      return state;
  }
}

export default projectSubTasksByPeriodReducer;
