import React, {} from 'react';

import {Card, Row, Col, Button, } from 'react-bootstrap';

import DynamicTable from '../../../../Utils/DynamicTable/DynamicTable';

const ProjectDetailsSectionDynamicTable = ({field, sectionIdx, sectionFields, setSectionFields, removeField, }) => {

  const addTableRow = () => {
    let sectionFieldsCopy = [...sectionFields];
    const tableRowsValues = [...field.data];
    let rowsAdd = new Array(field.data[0].rowFields.length).fill('')
    tableRowsValues.push({
      rowFields: rowsAdd
    });
    sectionFieldsCopy[sectionIdx] = {...field, data: tableRowsValues};
    setSectionFields(sectionFieldsCopy);
  }

  const addTableHeader = () => {
    let sectionFieldsCopy = [...sectionFields];
    const tableRowsValues = [...field.data];
    for (var i = 0; i < tableRowsValues.length; i++) {
      tableRowsValues[i].rowFields.push('');
    }
    sectionFieldsCopy[sectionIdx] = {...field, data: tableRowsValues};
    setSectionFields(sectionFieldsCopy);
  }

  const removeTableHeader = (idx) => {
    let sectionFieldsCopy = [...sectionFields];
    const tableRowsValues = [...field.data];
    for (var i = 0; i < tableRowsValues.length; i++) {
      tableRowsValues[i].rowFields.splice(idx, 1);
    }
    sectionFieldsCopy[sectionIdx] = {...field, data: tableRowsValues};
    setSectionFields(sectionFieldsCopy);
  }

  const handleTableChanges = (idx, n, event) => {
    let sectionFieldsCopy = [...sectionFields];
    const tableRowsValues = [...field.data];
    tableRowsValues[idx].rowFields[n] = event.target.value;
    sectionFieldsCopy[sectionIdx] = {...field, data: tableRowsValues};
    setSectionFields(sectionFieldsCopy);
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs={10}>
            <Card.Title>
              {field.fieldName}
            </Card.Title>
          </Col>
          <Col xs={2}>
            <Button onClick={() => removeField(sectionIdx)}>
              X
            </Button>
          </Col>
        </Row>
        <Card.Text>
          <DynamicTable tableRows={field.data} addTableRow={addTableRow} addTableHeader={addTableHeader} removeTableHeader={removeTableHeader}
            handleTableChanges={handleTableChanges} />
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default ProjectDetailsSectionDynamicTable;
