import React, {useState, useEffect} from 'react';

import { useSelector } from 'react-redux';

import {UserPermissionsSelector, } from '../../../../redux/selectors';

import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, Typography, Box, } from '@material-ui/core';

import ProjectDetailsGroupTeamTable from './ProjectDetailsGroupTeamTable/ProjectDetailsGroupTeamTable';
// import ProjectDetailsModalEspecificResults from './ProjectDetailsModalEspecificResults';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  bar: {
    backgroundColor: '#002F6C',
    borderRight: `1px solid ${theme.palette.divider}`,
  }
}));

const ProjectDetailsModalGroupTeamTabs = ({project, groupTeams, }) => {

  const classes = useStyles();

  const {userPermissionsList} = useSelector(UserPermissionsSelector);

  const token = localStorage.getItem('token');
  const userData = JSON.parse(atob(token.split('.')[1]));
  const userRole = userData.isAdmin;
  // const userRole = false;
  const checkPermissions = userPermissionsList.find(permission => permission.slug === 'team.create');

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.bar}
          TabIndicatorProps={{style: {background:'#EC7700'}}}
          aria-label="simple tabs example"
          scrollButtons="on"
          variant="scrollable"
        >
          {
            groupTeams.map((groupTeam, idx) =>
              <Tab label={groupTeam.description} {...a11yProps(idx)} />
            )
          }
          {
            (userRole || checkPermissions) &&
            <Tab label="+" {...a11yProps(groupTeams.length)} />
          }
        </Tabs>
      </AppBar>
      {
        groupTeams.map((groupTeam, idx) =>
          <TabPanel value={value} index={idx}>
            <ProjectDetailsGroupTeamTable project={project} groupTeam={groupTeam} create={false}/>
          </TabPanel>
        )
      }
      <TabPanel value={value} index={groupTeams.length}>
        <ProjectDetailsGroupTeamTable project={project} create={true}/>
      </TabPanel>
    </div>
  );
}

export default ProjectDetailsModalGroupTeamTabs;
