import React, {useState, useEffect, } from 'react';

import { useSelector } from 'react-redux';

import {UserPermissionsSelector, } from '../../../../redux/selectors';

import {Table, TableContainer, Paper, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleNotifications, } from '../../../../redux/actions';

import FilesManagerTableHeader from './FilesManagerTableHeader';
import FilesManagerTableBody from './FilesManagerTableBody';
import FilesManagerFilters from './FilesManagerFilters/FilesManagerFilters';
import TablePaginator from '../../ItemsPaginator/TablePaginator';

import alert from '../../Alert/Alert';

const FilesManagerTable = ({title, files, deleteFile, showReasons, showFilters, showDeleteButton, activeColumn, reasonInTable,
  downloadPermission, deletePermission, }) => {

  const dispatch = useDispatch();

  const {userPermissionsList} = useSelector(UserPermissionsSelector);

  const token = localStorage.getItem('token');
  const userData = JSON.parse(atob(token.split('.')[1]));
  const userRole = userData.isAdmin;
  // const userRole = false;
  const checkDownloadPermission = userPermissionsList.find(permission => permission.slug === downloadPermission);
  const checkDeletePermission = userPermissionsList.find(permission => permission.slug === deletePermission);

  const [filesFiltered, setFilesFiltered] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [allFiles, setAllFiles] = useState(false);
  const [deleteReason, setDeleteReason] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    let filterFiles = [...files];
    // if (!allFiles) {
    //   filterFiles = filterFiles.filter(file => file.available);
    // }
    filterFiles = filterFiles.filter(file => (file.name?.toLowerCase().includes(searchText.toLowerCase())));
    setFilesFiltered(filterFiles);
  }, [files, searchText, allFiles]);

  const handleSearchText = (e) => setSearchText(e.target.value);

  const resetText = () => setSearchText('');

  const handleAllFiles = () => setAllFiles(allFiles => !allFiles);

  const handleDeleteReason = (e) => setDeleteReason(e.target.value);

  const handleDeleteFile = (fileId) => {
    const acceptFunction = () => {
      deleteFile(fileId, deleteReason);
      setDeleteReason('');
    };
    const alertData = {
      title: 'Seguro que desea eliminar el archivo?',
      text: 'No se podrá recuperar',
      acceptFunction: acceptFunction
    }
    alert(alertData);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filesFiltered - page * rowsPerPage);

  return (
    <TableContainer component={Paper}>
      <FilesManagerFilters searchText={searchText} handleSearchText={handleSearchText} title={title} allFiles={allFiles} handleAllFiles={handleAllFiles}
        resetText={resetText} showFilters={showFilters} activeColumn={activeColumn}
      />
      <Table>
        <FilesManagerTableHeader allFiles={allFiles} showReasons={showReasons} activeColumn={activeColumn}/>
        <FilesManagerTableBody
          files={rowsPerPage > 0 ? filesFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filesFiltered}
          handleDeleteFile={handleDeleteFile} emptyRows={emptyRows} allFiles={allFiles}
          deleteReason={deleteReason} handleDeleteReason={handleDeleteReason} showReasons={showReasons} showDeleteButton={showDeleteButton}
          activeColumn={activeColumn} reasonInTable={reasonInTable} canDownload={userRole || checkDownloadPermission}
          canDelete={userRole || checkDeletePermission}
        />
      </Table>
      {
        filesFiltered.length > 2 &&
        <TablePaginator rows={filesFiltered} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
      }
    </TableContainer>
  )
}

export default FilesManagerTable;
