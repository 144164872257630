const initialState={
  show: false,
  selectUserData: {
    teamIdx: 0,
    currentUsers: [],
    handleSelectUsers: () => {}
  }
}

const selectUserModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showSelectUserModal':
      return {
        ...state,
        show: true,
        selectUserData: action.payload.selectUserData
      }
    case 'closeSelectUserModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default selectUserModalReducer;
