import React from 'react';

import {TableCell, TableRow, FormControlLabel, Checkbox, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

import {getSubTaskType, } from '../../../../Utils/Functions/TaskFunctions';

const CapitulationSubTasksRow = ({subTask, edit, subTasksSelected, selectSubTask, }) => {

  const isChecked = subTasksSelected && subTasksSelected.findIndex(subTaskSelected => subTaskSelected.id === subTask.id) > -1;

  return (
    <TableRow key={subTask.description}>
      <TableCell component="th" scope="row">
        {subTask.description}
      </TableCell>
      <TableCell>{subTask.name}</TableCell>
      <TableCell>{subTask.subtaskType?.name || '-'}</TableCell>
      <TableCell>{subTask.amount}</TableCell>
      {
        edit &&
        <TableCell>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={() => selectSubTask(subTask)}
                name="inventoryable"
                color="primary"
              />
            }
          />
        </TableCell>
      }
    </TableRow>
  )
}

export default CapitulationSubTasksRow;
