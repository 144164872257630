import React, {useState, } from 'react';

import {Grid, Toolbar, Typography, Tooltip, IconButton, } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import TableSearch from './TableSearch';
import TableFilters from './TableFilters/TableFilters';

const FilesManagerFilters = ({title, searchText, handleSearchText, allFiles, handleAllFiles, resetText, showFilters, }) => {

  const [showSearch, setShowSearch] = useState(false);

  const handleShowSearch = () => {
    setShowSearch(showSearch => {
      resetText();
      return !showSearch
    });
  }

  return (
    <Toolbar>
      <Grid container>
        <Typography
          variant="h6"
        >
          {title}
        </Typography>
      </Grid>
      {
        showFilters &&
        <Grid container justify="flex-end">
          {/*<TableSearch searchText={searchText} handleSearchText={handleSearchText} handleShowSearch={handleShowSearch}/>*/}
          {
            showSearch ?
            <TableSearch searchText={searchText} handleSearchText={handleSearchText} handleShowSearch={handleShowSearch} resetText={resetText}/>
            :
            <Tooltip title={'Buscar'} disableFocusListener>
              <IconButton
                onClick={handleShowSearch}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
          }
          <TableFilters allFiles={allFiles} handleAllFiles={handleAllFiles}/>
        </Grid>
      }
    </Toolbar>
  )
}

export default FilesManagerFilters;
