import React from 'react';

import {Card, Col, Row, Container, ListGroup, } from 'react-bootstrap';

import { useDispatch, } from 'react-redux';
import { push } from 'connected-react-router';

const ProjectDevelopment = ({activePhase, projectCode, currentProject, }) => {

  const dispatch = useDispatch();

  const goDevelopmentDetails = () => dispatch(push(`/project/${projectCode}/activities`));

  return (
    <>
      <h5>
        Desarrollo del Proyecto
      </h5>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              Objetivos Específicos
            </Card.Header>
            <Card.Body>
              <Card.Text>
              <ListGroup>
                {
                  currentProject.specificObjectives.map(specificObjective =>
                    <ListGroup.Item>
                      {specificObjective}
                    </ListGroup.Item>
                  )
                }
                </ListGroup>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card onClick={goDevelopmentDetails}>
            <Card.Header>
              Actividades Actuales
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <ListGroup>
                  <ListGroup.Item variant="primary">
                    Diseño de curso de formación en temáticas de género dirigido a jefaturas de carrera y coordinadores de asignatura
                  </ListGroup.Item>
                  <ListGroup.Item action variant="danger">
                    Redacción de informes de nuevas líneas y su enlace con investigadores
                  </ListGroup.Item>
                  <ListGroup.Item action variant="warning">
                    Construcción de mapa de actores relevantes externos con los cuales relacionarse
                  </ListGroup.Item>
                </ListGroup>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              Resultados
            </Card.Header>
            <Card.Body>
              <Card.Text>
                No hay resultados por el momento
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ProjectDevelopment;
