import React from 'react';

import {TableBody, } from "@material-ui/core";

import ProjectDetailsResultsTableRowGeneral from './ProjectDetailsResultsTableRowGeneral';

const ProjectDetailsResultsTableBody = ({objectivesResults, maxIndicatorGoals, openIndicatorModal, }) => {

  return (
    <TableBody>
      {
        objectivesResults.map(objectiveResults =>
          <ProjectDetailsResultsTableRowGeneral objectiveResults={objectiveResults} maxIndicatorGoals={maxIndicatorGoals} openIndicatorModal={openIndicatorModal} />
        )
      }
    </TableBody>
  )
}

export default ProjectDetailsResultsTableBody;
