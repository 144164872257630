import React from 'react';

import {TableHead, TableRow, TableCell, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

const GoodsListHeader = ({showResponsible, handleShowResponsible, }) => {

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          Bien
        </TableCell>
        <TableCell>
          Monto
        </TableCell>
        <TableCell>
          Marca
        </TableCell>
        <TableCell>
          Modelo
        </TableCell>
        <TableCell>
          Proveedor
        </TableCell>
        <TableCell>
          Número de inventario
        </TableCell>
        <TableCell>
          Número de serie
        </TableCell>
        <TableCell>
          Ubicación
        </TableCell>
        <TableCell>
          Unidad requirente
        </TableCell>
        <TableCell>
          Período
        </TableCell>
        <TableCell>
          Responsable
          {
            showResponsible ?
            <FeatherIcon icon="arrow-left-circle" className="mr-1 iconButton" onClick={handleShowResponsible}/>
            :
            <FeatherIcon icon="arrow-right-circle" className="mr-1 iconButton" onClick={handleShowResponsible}/>
          }
        </TableCell>
        {
          showResponsible &&
          <>
            <TableCell>
              Correo responsable
            </TableCell>
            <TableCell>
              Teléfono responsable
            </TableCell>
          </>
        }
      </TableRow>
    </TableHead>
  )
}

export default GoodsListHeader;
