import React, {useState, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {TextField, Grid, FormControl, InputLabel, Select, MenuItem, Typography, Button, Card, CardContent, } from '@material-ui/core';

import InventoriableForm from '../../ProjectDetailsSubTaskModal/SubTaskTabs/InventoriableForm';

import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  textField: {
    width: '80%'
  },
  longTextField: {
    width: '90%'
  },
  blocks: {
    marginBottom: 20
  },
  title: {
    marginBottom: 10
  }
}));

const GoodForm = ({goodForm, goodFile, handleChange, handleFile, handleEditGood, }) => {

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(open => !open);

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <Card className={classes.blocks} >
          <CardContent>

            <Typography variant="h5" className={classes.title} >
              Datos del Bien
            </Typography>
            <Grid container>
              <Grid item xs={6} lg={6}>
                <Typography style={{marginLeft: 10}}>
                  Monto asociado: {goodForm.amount}
                </Typography>
              </Grid>
            </Grid>
            <InventoriableForm form={goodForm} handleChange={handleChange} />
            <Grid container>
              <Grid item xs={6} lg={6}>
                <TextField
                  name="codeNumber"
                  label="Código"
                  variant="outlined"
                  className={classes.textField}
                  value={goodForm.codeNumber}
                  onChange={handleChange}
                  type='number'
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  name="serialNumber"
                  label="Número de serie"
                  variant="outlined"
                  className={classes.textField}
                  value={goodForm.serialNumber}
                  onChange={handleChange}
                  type='number'
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  name="location"
                  label="Ubicación"
                  variant="outlined"
                  className={classes.longTextField}
                  value={goodForm.location}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.blocks} >
          <CardContent>
            <Typography variant="h5" className={classes.title} >
              Datos del Responsable
            </Typography>
              <TextField
                name="responsable"
                label="Responsable"
                variant="outlined"
                className={classes.textField}
                value={goodForm.responsable}
                onChange={handleChange}
              />
            <Grid container>
              <Grid item xs={6} lg={6}>
                <TextField
                  name="responsableEmail"
                  label="Correo del Responsable"
                  variant="outlined"
                  className={classes.textField}
                  value={goodForm.responsableEmail}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  name="responsableNumber"
                  label="Número del responsable"
                  variant="outlined"
                  className={classes.textField}
                  value={goodForm.responsableNumber}
                  onChange={handleChange}
                  type='number'
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
      <Grid container justify="flex-end">
        <PrimaryButton title={'Guardar cambios'} onClick={handleEditGood} />
      </Grid>
    </>
  )
}

export default GoodForm;
