import { createSelector } from 'reselect';

export const FinancingSourcesSelector = createSelector(
  state => state.financingSources,
  financingSources => financingSources.financingSources
);

export const ActivityStatusSelector = createSelector(
  state => state.activityStatus,
  activityStatus => activityStatus.activityStatus
);

export const AcademicUnitsSelector = createSelector(
  state => state.academicUnits,
  academicUnits => academicUnits.academicUnits
);

export const ExpenseTypesSelector = createSelector(
  state => state.expenseTypes,
  expenseTypes => expenseTypes.expenseTypes
);

export const SubTaskTypesSelector = createSelector(
  state => state.subTaskTypes,
  subTaskTypes => subTaskTypes.subTaskTypes
);

export const RolesSelector = createSelector(
  state => state.roles,
  roles => roles.roles
);

export const ProjectStatusListSelector = createSelector(
  state => state.projectStatusList,
  projectStatusList => projectStatusList.projectStatusList
);
