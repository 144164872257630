import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const getProjectStatusList = () => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/project/status/list`);
      dispatch(setProjectStatusList(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el listado de estados de proyecto'}
      ));
    }
  }
}

const setProjectStatusList = (projectStatusList) => ({
  type: 'setProjectStatusList',
  payload: {
    projectStatusList: projectStatusList
  }
});
