import React from 'react';

import {List, Divider, } from '@material-ui/core';

import ProjectDetailsMainObjective from './ProjectDetailsMainObjective';

const ProjectDetailsMainObjectives = ({project, generalPurposesData, }) => {
  return (
    <List style={{width: '100%'}}>
      <Divider />
      {
        generalPurposesData.generalPurposes.map(objective =>
          <>
            <ProjectDetailsMainObjective project={project} objective={generalPurposesData.entities?.generalPurposes[objective]} />
            <Divider />
          </>
        )
      }
    </List>
  )
}

export default ProjectDetailsMainObjectives;
