export const handleTaskModal = (show, type, taskData) => {
  if (show) {
    console.log(taskData);
    return dispatch => dispatch(showTaskModal(type, taskData));
  }
  else {
    return dispatch => dispatch(closeTaskModal());
  }
}

const showTaskModal = (type, taskData) => ({
  type: 'showTaskModal',
  payload: {
    type: type,
    taskData: taskData
  }
});

const closeTaskModal = () => ({
  type: 'closeTaskModal',
  payload: {}
});
