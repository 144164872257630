const initialState={
  show: false,
  type: 'create',
  taskData: {
    task: {
      subTasks: []
    },
    activityIdx: 0,
    handleTask: () => {}
  }
}

const projectTaskModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showTaskModal':
      return {
        ...state,
        show: true,
        type: action.payload.type,
        taskData: action.payload.taskData
      }
    case 'closeTaskModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default projectTaskModalReducer;
