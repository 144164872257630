import React, {useState, useEffect} from 'react';

import {Grid, Button, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleFilesManagerModal, handleBudgetSummaryModal, handleNotifications, } from '../../../../redux/actions';

import FilesManagerTable from '../../../Utils/FilesManager/FilesManagerTable/FilesManagerTable';
import BudgetTabs from './BudgetTabs/BudgetTabs';

import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';
import TertiaryButton from '../../../Utils/Buttons/TertiaryButton';

const ProjectDetailsBudget = ({project, projectBudget, }) => {

  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);

  useEffect(() => {
    console.log(files);
  }, [files]);

  const addFileToBudget = () => {
    const newFile = (file) => {
      console.log(file);
      setFiles(file);
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivo agregado correctamente'}
      ));
    }
    const data = {
      title: 'Agregar archivo a presupuesto',
      files: files,
      addFile: newFile
    }
    dispatch(handleFilesManagerModal(true, data, 'unique', true));
  }

  const deleteFile = (fileIdx) => {
    const filesCopy = [...files];
    filesCopy.splice(fileIdx, 1);
    setFiles(filesCopy)
  }

  const openBudgetSummaryModal = () => dispatch(handleBudgetSummaryModal(true));

  return (
    <>
      <BudgetTabs project={project} projectBudget={projectBudget}/>
      {/*<Grid container>
        <Grid item xs={2}>
          <TertiaryButton title={'Ver Resumen'} onClick={openBudgetSummaryModal} />
        </Grid>
      </Grid>
      <PrimaryButton title={'Agregar archivo'} onClick={addFileToBudget} />
      <FilesManagerTable title={'Archivos de presupuesto'} files={files} deleteFile={deleteFile} uploadType={'unique'}
        showReasons={true} showFilters={true} activeColumn={true}
      />*/}
    </>
  )
}

export default ProjectDetailsBudget;
