import React, {useState, useEffect, } from 'react';

import {TableBody, TableRow, TableCell, Button, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleSelectUserModal, } from '../../../../../redux/actions';

import CreateProjectAssignUsersTableRow from './CreateProjectAssignUsersTableRow';

const CreateProjectAssignUsersTableBody = ({currentAssignedUsers, handleChange, removeUser, assignUsers, roles, }) => {

  const dispatch = useDispatch();

  const openSelectUserModal = () => {
    const selectUserData = {
      currentUsers: currentAssignedUsers,
      handleSelectUser: assignUsers
    }
    dispatch(handleSelectUserModal(true, selectUserData));
  }

  return (
    <TableBody>
      {
        currentAssignedUsers.map((user, userIdx) =>
        <CreateProjectAssignUsersTableRow user={user} handleChange={handleChange} removeUser={removeUser} userIdx={userIdx}
          roles={roles}
        />
      )}
      <TableRow>
        <TableCell colSpan="3" className='centerText'>
          <Button onClick={openSelectUserModal}>Agregar Usuario</Button>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

export default CreateProjectAssignUsersTableBody;
