import React, {useState, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {TableCell, TableRow, IconButton, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {downloadFile, } from '../../../../redux/actions';
// import {downloadFileAxios} from '../../../../config/manageFileAxios';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import FeatherIcon from 'feather-icons-react';

import FilesManagerCollapseRow from './FilesManagerCollapseRow';
import FilesManagerDeleteRow from './FilesManagerDeleteRow';

const types = {
  0: 'Boleta',
  1: 'Resolución',
  2: 'Orden de compra'
};

const useStyles = makeStyles({
  tableCell: {
    padding: "0px 8px"
  },
  fileName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '300px'
  },
});

const FilesManagerTableRow = ({file, fileIdx, handleDeleteFile, deleteReason, handleDeleteReason, showReasons, showDeleteButton, activeColumn, reasonInTable,
  canDownload, canDelete, }) => {

  const dispatch = useDispatch();
  const classes = useStyles();

  const cutFileName = file.name.split('_');
  const [head, ...rest] = cutFileName;
  const fileName = rest.join('_') || file.name;

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDelete = () => setOpenDelete(openDelete => !openDelete);

  const deleteFileWithReason = () => {
    setOpenDelete(false);
    handleDeleteFile(file.id);
  };

  const download =  () => {
    try {
      dispatch(downloadFile(file.fileDownloadUri, fileName));
      // await downloadFileAxios(file.fileDownloadUri, file.fileName);
      // axios({
      //   url: file.fileDownloadUri, //your url
      //   method: 'GET',
      //   responseType: 'blob', // important
      // }).then((response) => {
      //    const url = window.URL.createObjectURL(new Blob([response.data]));
      //    const link = document.createElement('a');
      //    link.href = url;
      //    link.setAttribute('download', file.fileName); //or any other extension
      //    document.body.appendChild(link);
      //    link.click();
      // });
      // await axios.get(file.fileDownloadUri, {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/pdf',
      //     responseType: 'blob'
      //   },
      // })
      // .then((response) => response.blob())
      // .then((blob) => {
      //   // Create blob link to download
      //   const url = window.URL.createObjectURL(
      //     new Blob([blob]),
      //   );
      //   const link = document.createElement('a');
      //   link.href = url;
      //   link.setAttribute(
      //     'download',
      //     `FileName.pdf`,
      //   );
      //
      //   // Append to html link element page
      //   document.body.appendChild(link);
      //
      //   // Start download
      //   link.click();
      //
      //   // Clean up and remove the link
      //   link.parentNode.removeChild(link);
      // });
    } catch (e) {
      console.log(e);
      console.log('buuu');
    }
  }

  const handleDelete = () => handleDeleteFile(file.id);

  const recordDate = new Date(file.date);

  return (
    <>
      <TableRow
        // style={file.available ? {opacity: 1}: {opacity: 0.5}}
        key={file.id}
      >
        <TableCell
          className={classes.tableCell}
          style={{ width: 100 }}
        >
          <div className={classes.fileName}>
            {fileName}
          </div>
        </TableCell>
        <TableCell>
          {recordDate && `${recordDate.getDate()}/${recordDate.getMonth()+1}/${recordDate.getFullYear()}`}
        </TableCell>
        <TableCell>
          {file.createdByUserName}
        </TableCell>
        {
          activeColumn &&
          <TableCell>
            {
              file.available ?
              <FeatherIcon icon="check-circle" className="mr-1"/>
              :
              <FeatherIcon icon="x-circle" className="mr-1"/>
            }
          </TableCell>
        }
        <TableCell>
          {
            canDownload &&
            <FeatherIcon icon="download" className='iconButton' onClick={download}/>
          }
          {
            showDeleteButton && canDelete &&
            <FeatherIcon icon="trash" className='iconButton' onClick={reasonInTable ? handleOpenDelete : handleDelete}/>
          }
        </TableCell>
        {/*
          file.available ||
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        */}
      </TableRow>
      <FilesManagerDeleteRow openDelete={openDelete} deleteReason={deleteReason} handleDeleteReason={handleDeleteReason} handleOpenDelete={handleOpenDelete}
        deleteFileWithReason={deleteFileWithReason}
      />
      {/*
        showReasons && file.available ?
        <FilesManagerDeleteRow openDelete={openDelete} deleteReason={deleteReason} handleDeleteReason={handleDeleteReason} handleOpenDelete={handleOpenDelete}
          deleteFileWithReason={deleteFileWithReason}
        />
        :
        <FilesManagerCollapseRow file={file} open={open} />
      */}
    </>
  )
}

export default FilesManagerTableRow;
