import React from 'react';

import {TableBody, } from '@material-ui/core';

import HistoryRecordRow from './HistoryRecordRow';

const HistoryRecordBody = ({history, showDownloadButton, }) => {

  return (
    <TableBody>
      {
        history.map((record) =>
          <HistoryRecordRow record={record} showDownloadButton={showDownloadButton}
          />
        )
      }
    </TableBody>
  )
}

export default HistoryRecordBody;
