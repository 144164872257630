import React, {useState, useEffect, } from 'react';

import {TableRow, TableCell, } from "@material-ui/core";

import ProjectDetailsResultsTableRowSpecific from './ProjectDetailsResultsTableRowSpecific';

const ProjectDetailsResultsTableRowGeneral = ({objectiveResults, maxIndicatorGoals, openIndicatorModal, }) => {

  const [span, setSpan] = useState(0);

  useEffect(() => {
    if (objectiveResults.specificPurposes && objectiveResults.specificPurposes.length > 0) {
      const getIndicators = objectiveResults.specificPurposes.map(specificPurpose => specificPurpose?.indicators);
      const indicatorsLength = getIndicators.map(indicator => indicator.length);
      const indicatorsTotal = indicatorsLength.reduce((total, amount) => total + amount);
      const length = objectiveResults.specificPurposes.length + 1 + indicatorsTotal;
      setSpan(length);

    }
  }, [objectiveResults]);

  return (
    <>
      <TableRow>
        <TableCell rowSpan={span}>{objectiveResults.name}</TableCell>
      </TableRow>
      {
        objectiveResults.specificPurposes.map(specificPurpose =>
          <ProjectDetailsResultsTableRowSpecific objectiveResults={objectiveResults} specificPurpose={specificPurpose} maxIndicatorGoals={maxIndicatorGoals}
            openIndicatorModal={openIndicatorModal}
          />
        )
      }
    </>
  )
}

export default ProjectDetailsResultsTableRowGeneral;
