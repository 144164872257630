const initialState = {
  show: false,
}

const projectStateModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showStateModal':
      return {
        ...state,
        show: true,
      }
    case 'closeStateModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default projectStateModalReducer;
