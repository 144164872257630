import React from 'react';

import {TableBody, TableRow, TableCell, } from "@material-ui/core";

import ExtensionsTableRow from './ExtensionsTableRow';

const ExtensionsTableBody = ({project, extensions, }) => {

  return (
    <TableBody>
      <TableRow>
        <TableCell>{'Ejecución con prórroga'}</TableCell>
        <TableCell>{project.implamentationTime}</TableCell>
        <TableCell>{project.implementationTimeTwo}</TableCell>
        <TableCell>{project.validityTime}</TableCell>
        <TableCell>{project.validityTimeTwo}</TableCell>
      </TableRow>
      {
        project.implementationTimeThree &&
        <TableRow>
          <TableCell>{'Ejecución con prórroga'}</TableCell>
          <TableCell>{project.implementationTimeTwo}</TableCell>
          <TableCell>{project.implementationTimeThree}</TableCell>
          <TableCell>{project.validityTimeTwo}</TableCell>
          <TableCell>{project.validityTimeThree}</TableCell>
        </TableRow>
      }
    </TableBody>
  )
}

export default ExtensionsTableBody;
