import React from 'react';

import { useSelector } from 'react-redux';

import {UserSelector, } from '../../redux/selectors';

import AppNavBar from '../../components/Navbar/AppNavBar';

const AppNavBarContainer = () => {

  const userData = useSelector(UserSelector);
  const userInfo = userData.userInfo;

  return (
    <AppNavBar userData={userData} userInfo={userInfo} />
  )
}

export default AppNavBarContainer;
