import React, {useState, useEffect, } from 'react';

import clientAxios from '../../../../../config/axios';

import { useDispatch, } from 'react-redux';
import {setLoading, handleNotifications, } from '../../../../../redux/actions';

import HistoryRecordTable from '../../../../Utils/HistoryRecord/HistoryRecordTable/HistoryRecordTable';

const PaymentHistorial = ({paymentsProofForm, project, }) => {

  const dispatch = useDispatch();

  const [historial, setHistorial] = useState([]);

  useEffect(async() => {
    try {
      if (paymentsProofForm.id) {
        dispatch(setLoading(true));
        const {data} = await clientAxios().get(`api/rendition/payment-document/${paymentsProofForm.id}/records`);
        setHistorial(data);
        dispatch(setLoading(false));
      }
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el historial del comprobante'}
      ));
      dispatch(setLoading());
    }
  }, [paymentsProofForm]);

  return (
    <>
      <HistoryRecordTable title={'Historial'} historyData={historial} project={project} />
    </>
  )
}

export default PaymentHistorial;
