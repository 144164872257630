import React, {useState, useEffect} from 'react';

import {Button, Dialog, DialogTitle, DialogContent, DialogActions,  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { useDispatch, } from 'react-redux';
import {handleFilesManagerModal, } from '../../../redux/actions';

import FilesManager from './FilesManager';
import FilesSelector from './FilesSelector';
import TextField from '../TextFields/TextField';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'white'
  },
  text: {
    color: 'white',
    "&:hover": {
      color: 'white'
    }
  },
  textFields: {
    width: 500,
    marginTop: 30,
    marginBottom: 20,
    marginLeft: 0
  },
}));

const FilesManagerModal = ({filesManagerModal, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const {show, uploadType, unique, filesManagerData} = filesManagerModal;

  // const [reason, setReason] = useState('');
  //
  // const handleChange = (e) => setReason(e.target.value);

  const handleClose = () => {
    dispatch(handleFilesManagerModal());
  }

  const addFileToSection = (file, reason) => {
    filesManagerData.addFile(file, reason);
    dispatch(handleFilesManagerModal());
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={show}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="customized-dialog-title">
        {filesManagerData.title}
      </DialogTitle>
      <DialogContent>
        <FilesManager title={filesManagerData.title} files={filesManagerData.files} modal={true} addFiles={addFileToSection} uploadType={uploadType} edit={true}
          needReason={unique && filesManagerData.files.length > 0}
        />
        {/*
          unique && filesManagerData.files.length > 0 &&
          <TextField
            name="reason"
            label="Razón de edición"
            variant="outlined"
            value={reason}
            onChange={handleChange}
            multiline
            rows={2}
            classStyle={classes.textFields}
          />
        */}
      </DialogContent>
{/*      <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
          <Button onClick={addFileToSection}>
            Agregar Archivo
          </Button>
        </DialogActions>*/}
    </Dialog>
  );
}

export default FilesManagerModal;
