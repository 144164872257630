const initialState={
  show: false,
  budgetData: {
    projectId: -1
  },
  budgetSummary: []
}

const budgetSummaryModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showBudgetSummaryModal':
      return {
        ...state,
        show: true,
        budgetData: action.payload.budgetData
      }
    case 'closeBudgetSummaryModal':
      return {
        ...state,
        ...initialState
      }
    case 'setBudgetSummary':
      return {
        ...state,
        budgetSummary: action.payload.budgetSummary
      }
    default:
      return state;
  }
}

export default budgetSummaryModalReducer;
