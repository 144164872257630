export const handleGoodsModal = (show, goodsData) => {
  if (show) {
    return dispatch => dispatch(showGoodsModal(goodsData));
  }
  else {
    return dispatch => dispatch(closeGoodsModal());
  }
}

const showGoodsModal = (goodsData) => ({
  type: 'showGoodsModal',
  payload: {
    goodsData: goodsData
  }
});

const closeGoodsModal = () => ({
  type: 'closeGoodsModal',
  payload: {}
});
