const initialState={
  show: false,
  sectionData: {
    phaseIndex: 0,
    sectionIdx: 0,
    data: []
  }
}

const projectSectionModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showSectionModal':
      return {
        ...state,
        show: true,
        sectionData: action.payload.sectionData
      }
    case 'closeSectionModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default projectSectionModalReducer;
