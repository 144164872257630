import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {editGoodFile, } from '../../../../../redux/actions';

import FilesManager from '../../../../Utils/FilesManager/FilesManager';
import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';
import UploadButton from '../../../../Utils/Buttons/UploadButton';
import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles((theme) => ({
  files: {
    marginTop: 10,
    marginBottom: 20
  },
}));

const GoodsFile = ({project, goodForm, goodFile, handleFile, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [file, setFile] = useState({});
  const [newFile, setNewFile] = useState(null);

  const handleNewFile = (file) => setNewFile(file);

  const saveFile = () => {
    const goodData = {
      id: goodForm.id,
    }
    dispatch(editGoodFile(project.id, goodData, newFile));
  }

  return (
    <>
      <FilterComponent permission={'inventoryProperty.upload'}>
        <Grid container justify="flex-end">
        {
          edit ?
          <SecondaryButton title={'Cancelar'} onClick={() => setEdit(edit => !edit)} />
          :
          <PrimaryButton title={'Editar'} onClick={() => setEdit(edit => !edit)} />
        }
        </Grid>
      </FilterComponent>
      {
        edit &&
          <div className={classes.files}>
            <Grid container>
              <Grid item xs={9} md={9}>
                <UploadButton file={newFile} handleFile={handleNewFile} buttonName={'Archivo del bien'}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <PrimaryButton title={'Guardar archivo'} onClick={saveFile} />
              </Grid>
            </Grid>
          </div>
      }
      <FilesManager title={'Acta de Alta'} files={goodFile ? [goodFile] : []} edit={false}
         activeColumn={false} needReason={false} downloadPermission={'inventoryProperty.download'}
      />
    </>
  )
}

export default GoodsFile;
