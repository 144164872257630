import React from 'react';

import { addMonths, isValid, formatDistance, } from 'date-fns';

import {Grid, Paper, Typography, Button, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleGeneralModal, handleStateModal, } from '../../../redux/actions';

import {getProjectState, getProjectFinancingLine, getProjectFinancingSource, } from '../../Utils/Functions/ProjectHelperFunctions';

import FilterComponent from '../../Utils/FilterComponent/FilterComponent';

const ProjectDetailsResume = ({currentProject, setCurrentProject, }) => {

  const dispatch = useDispatch();

  const openGeneralModal = () => dispatch(handleGeneralModal(true));

  const openStateModal = () => dispatch(handleStateModal(true));

  const changePhase = () => {
    setCurrentProject(currentProject => ({...currentProject, stage: currentProject.stage + 1}))
  }

  const projectDate = new Date(currentProject.startDate);
  const validityTime = addMonths(projectDate, (currentProject.validityTime + (currentProject.validityTimeTwo || 0) + (currentProject.validityTimeThree || 0)));
  const implementationTime = addMonths(projectDate, (currentProject.implamentationTime) + (currentProject.implementationTimeTwo || 0) +
    (currentProject.implementationTimeThree || 0));

  return (
    <Grid container style={{marginBottom: 20}} alignItems="center">
      <Grid item xs={12} md={7} lg={7} style={{padding: '80px'}} >
        <Typography variant="h3">
          {currentProject.name}
        </Typography>
        <Typography variant="h6">
          {currentProject.projectCode}
        </Typography>
        <Typography>
          {currentProject.description}
        </Typography>
      </Grid>
      <Grid item xs={12} md={5} lg={5}>
        <FilterComponent permission={'project.generalData.update'}>
          <Button variant="contained" color="primary" onClick={openGeneralModal}>
            Editar Proyecto
          </Button>
        </FilterComponent>
        {/*<Button variant="contained" color="primary" onClick={openStateModal}>
          Cambiar estado del proyecto
        </Button>*/}
        <Paper style={{height: '100%', padding: 20}}>
          <Grid
            container
            direction="column"
          >
            <Grid item>
              <Grid container style={{marginBottom: 20}}>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant="h6">
                    Estado del Proyecto
                  </Typography>
                  <Typography variant="h7">
                    {currentProject.status?.name || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant="h6">
                    Fuente de Financiamiento
                  </Typography>
                  <Typography variant="h7">
                    {currentProject.financingSource?.name || '-'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container style={{marginBottom: 20}}>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant="h6">
                    Fecha de inicio
                  </Typography>
                  <Typography variant="h7">
                    {`${projectDate.getDate()}-${projectDate.getMonth()+1}-${projectDate.getFullYear()}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant="h6">
                    Fecha de término de ejecución
                  </Typography>
                  <Typography variant="h7">
                    {isValid(implementationTime) && `${implementationTime.getDate()}-${implementationTime.getMonth()+1}-${implementationTime.getFullYear()}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <Typography variant="h6">
                    Fecha de término de vigencia
                  </Typography>
                  <Typography variant="h7">
                    {isValid(validityTime) && `${validityTime.getDate()}-${validityTime.getMonth()+1}-${validityTime.getFullYear()}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ProjectDetailsResume;
