import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, {history} from './redux/store';

import Auth from './Auth/Auth';

import Loader from './components/Utils/Loader/Loader';
import Notification from './components/Utils/Notification/Notification';

import AuthRoute from './Auth/AuthRoute';

// import MainPage from './components/MainPage';
import NotFound from './components/NotFound/NotFound';
import ProjectsListContainer from './containers/ProjectsListContainer/ProjectsListContainer';
import UsersListContainer from './containers/UsersListContainer/UsersListContainer';
import ProjectDetailsContainer from './containers/ProjectDetailsContainer/ProjectDetailsContainer';
import ProjectContainer from './containers/ProjectContainer/ProjectContainer';
import ProjectPhasesEditContainer from './containers/ProjectPhasesEditContainer/ProjectPhasesEditContainer';
import ProjectDetailsSectionContainer from './containers/ProjectDetailsSectionContainer/ProjectDetailsSectionContainer';
import ProjectActivitiesContainer from './containers/ProjectActivitiesContainer/ProjectActivitiesContainer';
import CreateProjectContainer from './containers/CreateProjectContainer/CreateProjectContainer';
import PermissionsManagerContainer from './containers/PermissionsManagerContainer/PermissionsManagerContainer';

// import CreateProjectEditSection from './components/CreateProject/CreateProjectForm/CreateProjectEditSection/CreateProjectEditSection';
// import CreateProjectPhasesEdit from './components/ProjectDetailsProposal/ProjectDetailsPhases/ProjectDetailsPhasesEdit/ProjectDetailsPhasesEdit';
import ProjectDataTeam from './components/ProjectDetailsProposal/ProjectData/ProjectDataTeam/ProjectDataTeam';
// import ProjectDevelopmentActivityDetails from './components/ProjectDetailsProposal/ProjectDevelopment/ProjectDevelopmentDetails/ProjectDevelopmentDetails';
// import ProjectDetailsContainer from './containers/ProjectDetailsContainer/ProjectDetailsContainer';

import Layout from './layouts/Layout';


const AppRoutes = () =>
 <Provider store = {store}>
  <ConnectedRouter history={history}>
    <Auth>
      <Layout>
        <Switch>
          <Route exact path="/" component={ProjectsListContainer} />
          <AuthRoute exact path="/users" Component={UsersListContainer} />
          <AuthRoute exact path="/create/project" Component={CreateProjectContainer} />
          <Route exact path="/project/:projectCode/detailsP" component={ProjectDetailsContainer} />
          {/*<Route exact path="/project/create/section" component={CreateProjectEditSection} />*/}
          <Route exact path="/project/:projectCode/details" component={ProjectContainer} />
          <Route exact path="/project/:projectCode/details/teams" component={ProjectDataTeam} />
          <Route exact path="/project/:projectCode/details/phases/edit" component={ProjectPhasesEditContainer} />
          <Route exact path="/project/:projectCode/activities" component={ProjectActivitiesContainer} />
          <Route exact path="/project/:projectCode/details/:sectionName" component={ProjectDetailsSectionContainer} />
          <Route exact path="/project/:projectCode" component={ProjectDetailsContainer} />
          <AuthRoute exact path="/permissions" Component={PermissionsManagerContainer} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Auth>
    <Loader />
    <Notification />
  </ConnectedRouter>
 </Provider>;

export default AppRoutes;
