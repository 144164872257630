import clientAxios from '../../config/axios';

import { normalize, schema } from 'normalizr';


import {getProjects, } from './projectsActions';
import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const getProject = (projectCode) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/project/${projectCode}`);
      const currentAssignedUsers = data.projectRoles?.map(userRole => {
        const user = userRole.user;
        return {...user, role: userRole.rolePermission.id}
      });
      dispatch(setProject({...data, currentAssignedUsers: currentAssignedUsers}));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el proyecto'}
      ));
    }
  }
}

const setProject = (project) => ({
  type: 'setProject',
  payload: {
    project: project
  }
});

export const getGeneralPurposes = (projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/project/${projectId}/generalpurposes`);
      const generalPurposesData = {
        generalPurposes: data
      }
      const activity = new schema.Entity('activities', {

      },
      {
        processStrategy: (value, parent, key) => {
          return { ...value, milestoneId: parent.id };
        },
      });
      const milestone = new schema.Entity('milestones', {
        activities: [activity]
      },
      {
        processStrategy: (value, parent, key) => {
          return { ...value, specificPurposeId: parent.id };
        },
      });
      const specificPurpose = new schema.Entity('specificPurposes', {
        milestones: [milestone]
      },
      {
        processStrategy: (value, parent, key) => {
          return { ...value, generalPurposeId: parent.id };
        },
      });
      const generalPurpose = new schema.Entity('generalPurposes', {
        specificPurposes: [specificPurpose],
      });
      const normalizedData = normalize(generalPurposesData, { generalPurposes: [generalPurpose] });
      console.log(normalizedData);
      console.log(normalizedData.entities);
      dispatch(setPurposes(normalizedData));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener los objetivos'}
      ));
    }
  }
}

const setPurposes = (generalPurposesData) => ({
  type: 'setPurposes',
  payload: {
    entities: generalPurposesData.entities,
    generalPurposes: generalPurposesData.result.generalPurposes
  }
})

export const editProject = (projectId, projectData) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().put(`/api/project/edit/${projectId}/info`, projectData);
      dispatch(getProject(projectId));
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Se editaron correctamente los datos del proyecto'}
      ));
      dispatch(getProjects());
    } catch (e) {
      console.log(e);
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al actualizar el proyecto'}
      ));
    }
  }
}

export const editProjectObjectives = (projectId, objectivesData) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().put(`/api/project/edit/${projectId}/purposes`, objectivesData);
      dispatch(getProject(projectId));
      dispatch(getGeneralPurposes(projectId));
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Se editaron correctamente los objetivos del proyecto'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al actualizar el proyecto'}
      ));
    }
  }
}

export const editProjectUsers = (projectId, usersIds) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().put(`/api/project/edit/${projectId}/users`, usersIds);
      dispatch(getProject(projectId));
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Se editaron correctamente los usuarios asignados al proyecto'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al actualizar el proyecto'}
      ));
    }
  }
}

export const editProjectStatus = (projectId, statusData) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      console.log(statusData);
      const {data} = await clientAxios().put(`/api/project/edit/${projectId}/status`, statusData);
      dispatch(getProject(projectId));
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Se editó correctamente el estado del proyecto'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al actualizar el proyecto'}
      ));
    }
  }
}

// export const deleteProject = (projectId) => {
//   return async dispatch => {
//     try {
//       dispatch(setLoading(true));
//       // const {data} = await clientAxios().post(`/api/activity/add`, activity);
//       const {data} = await clientAxios().delete(`/api/project/delete/${projectId}`);
//       // const {activityData} = data;
//       dispatch(getSpecificPurposes(objectiveId));
//       dispatch(handleNotifications(true, {
//         status: 'success',
//         message: 'Actividad eliminada correctamente'}
//       ));
//     } catch (e) {
//       console.log(e);
//       dispatch(handleNotifications(true, {
//         status: 'error',
//         message: 'Hubo un error al eliminar la actividad'}
//       ));
//     }
//   }
// }
