import React from 'react';

import {Form, } from 'react-bootstrap';

const TextField = ({handleTextFieldChange, defaultValue, }) => {

  return (
    <Form>
      <Form.Group controlId="exampleForm.ControlTextarea1" onChange={handleTextFieldChange}>
        <Form.Label>Ingrese texto</Form.Label>
        <Form.Control defaultValue={defaultValue || ''} as="textarea" rows={3} />
      </Form.Group>
    </Form>
  )
}

export default TextField;
