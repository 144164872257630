import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Dialog, DialogContent, DialogActions, DialogTitle, Button, AppBar, Slide, Toolbar, IconButton, Typography, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useDispatch, } from 'react-redux';
import {handleGeneralModal, } from '../../../../redux/actions';

import CreateProjectFormHeader from '../../../CreateProject/CreateProjectForm/CreateProjectFormHeader/CreateProjectFormHeader';
import CreateProjectAssignUsers from '../../../CreateProject/CreateProjectForm/CreateProjectAssignUsers/CreateProjectAssignUsers';
import CreateProjectTabs from '../../../CreateProject/CreateProjectTabs/CreateProjectTabs';
// import InventoryForm from './InventoryForm';
import {getDateFormat, } from '../../../Utils/Functions/DateFunctions';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'white'
  },
  text: {
    color: 'white',
    "&:hover": {
      color: 'white'
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectDetailsGeneralModal = ({project, projectGeneralModal, userData, financingSources, roles, projectStatusList, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const {show, type, projectData} = projectGeneralModal;

  const [projectForm, setProjectForm] = useState({});
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    // const currentAssignedUsers = project.projectRoles.map(userRole => {
    //   const user = userRole.user;
    //   return {...user, role: userRole.rolePermission.id}
    // });
    // console.log(currentAssignedUsers);
    setProjectForm({...project, mainObjectives: [], financingSourceId: project.financingSource?.id});
  }, [project, show]);

  const handleClose = () => dispatch(handleGeneralModal());

  const handleChangeProjectForm = (e) => {
    setChanged(true);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setProjectForm(projectForm => ({...projectForm, [e.target.name]: value}));
  }

  const handleProjectForm = () => {
    const {handleProject} = projectData;
    handleProject(projectForm);
    dispatch(handleGeneralModal());
  }

  const handlePickDate = (date) => setProjectForm({...projectForm, startDate: date});

  return (
    <Dialog fullScreen open={show} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Editar proyecto
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Cerrar
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {/*<CreateProjectFormHeader projectForm={projectForm} handleProjectForm={handleChange}/>
        <CreateProjectAssignUsers projectForm={projectForm} setProjectForm={setProjectForm} />*/}
        <CreateProjectTabs project={project} projectForm={projectForm} handleChangeProjectForm={handleChangeProjectForm} handleProjectForm={handleProjectForm}
          setProjectForm={setProjectForm} financingSources={financingSources} roles={roles} projectStatusList={projectStatusList} handlePickDate={handlePickDate}
        />
      </DialogContent>
      <DialogActions>

      </DialogActions>
    </Dialog>
  )
}

export default ProjectDetailsGeneralModal;
