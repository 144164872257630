export const handlePeriodModal = (show, periodData, type) => {
  if (show) {
    return dispatch => dispatch(showPeriodModal(periodData, type));
  }
  else {
    return dispatch => dispatch(closePeriodModal());
  }
}

const showPeriodModal = (periodData, type) => ({
  type: 'showPeriodModal',
  payload: {
    periodData: periodData,
    type: type
  }
})

const closePeriodModal = () => ({
  type: 'closePeriodModal',
  payload: {}
})
