const initialState={
  show: false,
  historyData: {
    budgetRecords: []
  },
  getHistorial: () => {}
}

const historyRecordModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showHistoryRecordModal':
      return {
        ...state,
        show: true
      }
    case 'closeHistoryRecordModal':
      return {
        ...state,
        ...initialState
      }
    case 'setHistorialData':
      return {
        ...state,
        historyData: action.payload.data
      }
      break;
    default:
      return state;
  }
}

export default historyRecordModalReducer;
