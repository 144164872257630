import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {List, Divider, } from '@material-ui/core';

import PaymentsProofItem from './PaymentsProofItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
}));

const PaymentsProofList = ({paymentsProofs, subTasks, addPaymentsProof, periodSelect, estado, setEstado, }) => {

  const classes = useStyles();

  return (
    <List className={classes.root}>
      {
        paymentsProofs.map(paymentsProof =>
          <>
            <Divider />
              <PaymentsProofItem paymentsProof={paymentsProof} periodSelect={periodSelect}
                estado={estado} setEstado={setEstado}
              />
            <Divider />
          </>
        )
      }
    </List>
  )
}

export default PaymentsProofList;
