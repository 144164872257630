import React from 'react';

import { makeStyles, } from '@material-ui/core/styles';
import {CardActions, CardContent, Grid, TextField, InputAdornment, OutlinedInput, Button, } from '@material-ui/core';

import ProjectGoalDetailsShow from './ProjectGoalDetailsShow';
import ProjectGoalDetailsEdit from './ProjectGoalDetailsEdit';

import useForm from '../../../../FormValidator/formValidator';

import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles((theme) => ({
  buttonLeft: {
    marginLeft: 20
  },
  buttonRight: {
    marginRight: 20
  }
}));

const validationStateSchema = {
  name: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
};

const ProjectGoalDetails = ({setItemForm, edit, itemForm, handleItemEdit, handleNewMilestone, handleEditMilestone, handleDeleteMilestone, userRole,
  userPermissionsList, }) => {

  const classes = useStyles();

  const {disabled} = useForm(itemForm, validationStateSchema);

  const addFileToItem = (files) => {
    const itemFormCopy = {...itemForm};
    const itemFiles = [...itemForm.milestoneVerification || []];
    itemFiles.push(...files);
    itemFormCopy.milestoneVerification = itemFiles;
    setItemForm(itemFormCopy);
  }

  return (
    <>
      {
        ((edit && (userRole || userPermissionsList.find(permission => permission.slug === 'milestone.update'))) || itemForm.creating) ?
        <ProjectGoalDetailsEdit itemForm={itemForm} handleItemEdit={handleItemEdit} setItemForm={setItemForm}
          handleNewMilestone={handleNewMilestone} handleEditMilestone={handleEditMilestone}
        />
        :
        <ProjectGoalDetailsShow itemForm={itemForm} />
      }
      {
        edit &&
        <CardActions>
          <Grid container justify="start" className={classes.buttonLeft}>
            <FilterComponent permission={'milestone.delete'}>
              {
                itemForm.creating ||
                <SecondaryButton title={'Eliminar hito'} onClick={handleDeleteMilestone} />
              }
            </FilterComponent>
          </Grid>
          <Grid container justify="flex-end">
          {
            itemForm.creating ?
            <FilterComponent permission={'milestone.create'}>
              <PrimaryButton title={'Crear Hito'} onClick={handleNewMilestone} disabled={disabled}/>
            </FilterComponent>
            :
            <FilterComponent permission={'milestone.update'}>
              <PrimaryButton title={'Guardar'} onClick={handleEditMilestone} disabled={disabled}/>
            </FilterComponent>
          }
          </Grid>
        </CardActions>
      }
    </>
  )
}

export default ProjectGoalDetails;
