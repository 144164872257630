import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {ListItem, ListItemIcon, ListItemText, Checkbox, } from '@material-ui/core';

const PermissionItem = ({permission, handlePermissions, isChecked, }) => {

  return (
    <ListItem
      key={permission.id}
      role="listitem"
      button
      // onClick={() => handlePermissions(permission.id)}
    >
          <ListItemIcon>
          <Checkbox
            checked={isChecked}
            disabled
          />
          </ListItemIcon>

      <ListItemText primary={`${permission.name}`} />
    </ListItem>
  )
}

export default PermissionItem;
