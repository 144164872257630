import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {UserSelector, ProjectsSelector, } from '../../redux/selectors';

import ProjectActivities from '../../components/ProjectActivities/ProjectActivities';

const ProjectActivitiesContainer = () => {

  const projectsData = useSelector(ProjectsSelector);

  const userData = useSelector(UserSelector);
  const userInfo = userData.userInfo;

  return (
    <ProjectActivities projectsData={projectsData} />
  )

}

export default ProjectActivitiesContainer;
