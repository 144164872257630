import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const getUsers = () => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/auth/list/users`);
      dispatch(setUsers(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener los usuarios'}
      ));
      dispatch(setLoading());
    }
  }
}

export const editUser = (user) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().put(`/api/auth/user/${user.id}/edit`);
      dispatch(setUsers(getUsers));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al editar el usuario'}
      ));
      dispatch(setLoading());
    }
  }
}

const setUsers = (users) => ({
  type: 'setUsers',
  payload: {
    users: users
  }
});

export const handleUserActive = (user) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const userData = {
        active: !user.active,
        userId: user.id
      }
      const {data} = await clientAxios().put(`/api/auth/user/active`, userData);
      dispatch(getUsers());
      // dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Credenciales no coinciden'}
      ));
    }
  }
}
