import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {List, ListItem, ListItemText, ListItemSecondaryAction, CardHeader, Divider, Button, IconButton, Typography, Tooltip, } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '80%',
    height: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  divider: {
    width: '100%'
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    height: 50,
    fontSize: 20
  },
}));

const RolesList = ({roles, roleSelected, selectRole, }) => {

  const classes = useStyles();

  return (
    <>
      {/*<CardHeader
        className={classes.cardHeader}
        // subheader={`${numberOfChecked(items)}/${items.length} selected`}
        avatar={
          <Button>
          hola
          </Button>
        }
        title={'Roles'}
      />
      <Divider className={classes.divider}/>*/}
      <List className={classes.list} dense component="div" role="list">
        <ListItem className={classes.cardHeader} role={undefined} dense>
          <ListItemText
            primary={<Typography variant="h5" style={{ color: '#EC7700' }}>Roles</Typography>}
            // secondary="Experiments"
          />
          <ListItemSecondaryAction>
            <Tooltip title="Guardar rol">
              <IconButton edge="end" aria-label="comments">
                <SaveIcon style={{color: '#EA7600'}}/>
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider className={classes.divider}/>
        {
          roles.map((role, roleIdx) => {
            const selected = roleSelected.id === role.id;
            return (
              <ListItem key={role.id} selected={selected} role="listitem" button onClick={() => selectRole(role.id)}>
                <ListItemText primary={`${role.name}`} />
              </ListItem>
            )
          })
        }
        <ListItem />
      </List>
    </>
  )
}

export default RolesList;
