import {combineReducers} from 'redux';
import { connectRouter } from 'connected-react-router';

import userReducer from './userReducer';
import usersReducer from './usersReducer';

import projectReducer from './projectReducer';
import projectsReducer from './projectsReducer';
import createProjectReducer from './createProjectReducer';

import projectEspecificModalReducer from './projectEspecificModalReducer';
import projectGroupTeamModalReducer from './projectGroupTeamModalReducer';
import userFormModalReducer from './userFormModalReducer';
import projectSectionModalReducer from './projectSectionModalReducer';
import selectUserModalReducer from './selectUserModalReducer';
import projectDataEditModalReducer from './projectDataEditModalReducer';
import projectActivitiesModalReducer from './projectActivitiesModalReducer';
import projectSelectActivityModalReducer from './projectSelectActivityModalReducer';
import filesManagerModalReducer from './filesManagerModalReducer';
import projectTaskModalReducer from './projectTaskModalReducer';
import projectSubTaskModalReducer from './projectSubTaskModalReducer';
import projectPeriodModalReducer from './projectPeriodModalReducer';
import projectIndicatorModalReducer from './projectIndicatorModalReducer';
import projectInventoryModalReducer from './projectInventoryModalReducer';
import projectGeneralModalReducer from './projectGeneralModalReducer';
import budgetSummaryModalReducer from './budgetSummaryModalReducer';
import projectGoodsModalReducer from './projectGoodsModalReducer';
import historyRecordModalReducer from './historyRecordModalReducer';
import projectPaymentsModalReducer from './projectPaymentsModalReducer';
import projectBudgetReducer from './projectBudgetReducer';
import projectObjectivesResultsReducer from './projectObjectivesResultsReducer';
import projectSubTasksByPeriodReducer from './projectSubTasksByPeriodReducer';
import financingSourcesReducer from './financingSourcesReducer';
import activityStatusReducer from './activityStatusReducer';
import academicUnitsReducer from './academicUnitsReducer';
import expenseTypesReducer from './expenseTypesReducer';
import subTaskTypesReducer from './subTaskTypesReducer';
import rolesReducer from './rolesReducer';
import projectPeriodsReducer from './projectPeriodsReducer';
import projectGoodsReducer from './projectGoodsReducer';
import projectStateModalReducer from './projectStateModalReducer';
import userPermissionsReducer from './userPermissionsReducer';
import projectStatusListReducer from './projectStatusListReducer';

import loaderReducer from './loaderReducer';
import notificationsReducer from './notificationsReducer';

const createRootReducer = (history) => combineReducers({
  user: userReducer,
  users: usersReducer,
  project: projectReducer,
  projects: projectsReducer,
  userPermissions: userPermissionsReducer,
  projectEspecificModal: projectEspecificModalReducer,
  projectGroupTeamModal: projectGroupTeamModalReducer,
  userFormModal: userFormModalReducer,
  createProject: createProjectReducer,
  selectUserModal: selectUserModalReducer,
  sectionModal: projectSectionModalReducer,
  projectDataEditModal: projectDataEditModalReducer,
  projectActivitiesModal: projectActivitiesModalReducer,
  projectSelectActivityModal: projectSelectActivityModalReducer,
  filesManagerModal: filesManagerModalReducer,
  projectTaskModal: projectTaskModalReducer,
  projectSubTaskModal: projectSubTaskModalReducer,
  projectPeriodModal: projectPeriodModalReducer,
  projectIndicatorModal: projectIndicatorModalReducer,
  projectInventoryModal: projectInventoryModalReducer,
  projectGeneralModal: projectGeneralModalReducer,
  budgetSummaryModal: budgetSummaryModalReducer,
  projectGoodsModal: projectGoodsModalReducer,
  historyRecordModal: historyRecordModalReducer,
  projectPaymentsModal: projectPaymentsModalReducer,
  projectStateModal: projectStateModalReducer,
  projectBudget: projectBudgetReducer,
  projectObjectivesResults: projectObjectivesResultsReducer,
  projectSubTasksByPeriod: projectSubTasksByPeriodReducer,
  projectPeriods: projectPeriodsReducer,
  projectGoods: projectGoodsReducer,
  financingSources: financingSourcesReducer,
  activityStatus: activityStatusReducer,
  academicUnits: academicUnitsReducer,
  expenseTypes: expenseTypesReducer,
  subTaskTypes: subTaskTypesReducer,
  roles: rolesReducer,
  projectStatusList: projectStatusListReducer,
  loader: loaderReducer,
  notifications: notificationsReducer,
  router: connectRouter(history)
})

export default createRootReducer;
