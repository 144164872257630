import React, {useState, useEffect} from 'react';

import {useParams} from "react-router-dom";

import {Card, } from 'react-bootstrap';

import ProjectDetailsSectionTable from './ProjectDetailsSectionTable/ProjectDetailsSectionTable';
import ProjectDetailsSectionTextField from './ProjectDetailsSectionTextField';

const ProjectDetailsSection = ({projectsData, }) => {

  const {projectCode, sectionName} = useParams();
  const {projectSelected, projects} = projectsData;

  const [currentProject, setCurrentProject] = useState({});
  const [selectedSection, setSelectedSection] = useState({
    data: []
  });

  useEffect(() => {
    const projectFind = projects.find(project => project.mineducCode === projectCode);
    if (projectFind) {
      setCurrentProject(projectFind);
      const allCategories = [].concat(...projectFind.phases.map(phase => phase.categories));
      const allSections = [].concat(...allCategories.map(category => category.sections))
      const sectionSelected = allSections.find(section => section.sectionName && section.sectionName.replace(/\s+/g, '').toLowerCase() === sectionName);
      console.log(sectionSelected);
      setSelectedSection(sectionSelected);
    }
  }, [projects]);

  return (
    <>
      <h4>
        {selectedSection.sectionName}
      </h4>
      {
        selectedSection.data.map(field =>{
          if (field.fieldType === 'textField') {
            return (
              <ProjectDetailsSectionTextField field={field} />
            )
          }
          else if (field.fieldType === 'table') {
            return (
              <>
                <h5>
                  {field.fieldName}
                </h5>
                <ProjectDetailsSectionTable tableRows={field.data} />
              </>
            )
          }
          else if (field.fieldType === 'dynamicFields') {
            return (
              <Card.Body>
                aca va campos dinamicos
              </Card.Body>
            )
          }
          else if (field.fieldType === 'repository') {
            return (
              <Card.Body>
                <Card.Text>
                  Aca va listado de ultimos archivos subidos
                </Card.Text>
              </Card.Body>
            )
          }
          else {
            return (
              <>
                Sección vacía
              </>
            )
          }
        })
      }
    </>
  )
}

export default ProjectDetailsSection;
