import {useState, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, TextField, Button, Paper, Tooltip, IconButton, Popper, ClickAwayListener, } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

const useStyles = makeStyles((theme) => ({
  specificPurpose: {
    margin: theme.spacing(1),
    display: 'flex'
  },
  textFields: {
    width: '70%',
    marginRight: 10
  },
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 10000,
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.5)",
    zIndex: 1301,
    maxHeight: 200,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
}));

const DynamicFieldsSecondField = ({specificPurpose, generalPurposeIdx, specificPurposeIdx, handleChangeType, handleRemoveField, handleAddType, defaultValue, }) => {

  const classes = useStyles();
  const milestonesPerSpecificPurpose = specificPurpose.milestones?.length;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenInfo = (event) => {
    setAnchorEl(anchorEl => anchorEl ? null : event.currentTarget);
  };

  const handleCloseInfo = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper2' : undefined;

  return (
    <div className={classes.specificPurpose}>
      <TextField
        label="Objetivo específico"
        value={specificPurpose.name}
        className={classes.textFields}
        onChange={e => handleChangeType(generalPurposeIdx, specificPurposeIdx, e)}
        variant="outlined"
        size="small"
        inputProps={{ maxLength: 510 }}
      />
      <SecondaryButton title={'-'} onClick={() => handleRemoveField(generalPurposeIdx, specificPurposeIdx)} disabled={milestonesPerSpecificPurpose}/>
      <PrimaryButton title={'+'} onClick={() => handleAddType(generalPurposeIdx)} />
      {
        milestonesPerSpecificPurpose ?
        <ClickAwayListener onClickAway={handleCloseInfo}>
          <div>
            <Tooltip aria-describedby={id} title={'Información'} disableFocusListener onClick={handleOpenInfo}>
              <IconButton>
                <HelpIcon />
              </IconButton>
            </Tooltip>
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              className={classes.popper}
            >
              <Paper className={classes.paper}>
                El objetivo específico posee hitos, esto impide eliminarlo
              </Paper>
            </Popper>
          </div>
        </ClickAwayListener>
        :
        <></>
      }
    </div>
  )
}

export default DynamicFieldsSecondField;
