import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, FormControl, InputLabel, Select, MenuItem, Typography, Button, Paper, Checkbox, FormControlLabel, } from '@material-ui/core';

import {getSelectFormat, } from '../../../../Utils/Functions/DateFunctions';

import InventoriableForm from './InventoriableForm';

import PeriodSelect from '../../../../Utils/PeriodSelect/PeriodSelect';
import TextField from '../../../../Utils/TextFields/TextField';
import UploadButton from '../../../../Utils/Buttons/UploadButton';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  textFields: {
    width: '70%'
  },
  files: {
    marginTop: 10,
    marginBottom: 20
  },
  bigTextfield: {
    width: '90%',
    marginTop: 10
  }
}));

const SubTaskForm = ({periods, subTaskForm, setSubTaskForm, handleChange, handleAddFileSubtask, taskType, handlePeriod, addFileToSubTask, deleteFile, type,
  subTaskTypes, projectPeriods, }) => {

  const classes = useStyles();

  const inventoryForm = {
    model: subTaskForm.intentoryItems && subTaskForm.intentoryItems[0]?.model || '',
    brand: subTaskForm.intentoryItems && subTaskForm.intentoryItems[0]?.brand || '',
    supplier: subTaskForm.intentoryItems && subTaskForm.intentoryItems[0]?.supplier || '',
  }
  console.log(inventoryForm);

  return (
    <>
      <Grid container>
        <Grid item xs={6} lg={6}>
          <TextField
            name="description"
            label="Descripción"
            variant="outlined"
            value={subTaskForm.description}
            onChange={handleChange}
            classStyle={classes.textFields}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <FormControl id="subTaskType" variant="outlined" className={classes.formControl} >
            <InputLabel id="subTaskType">Tipo de subtarea</InputLabel>
            <Select
              name="subtaskTypeId"
              label="Tipo de subtarea"
              value={subTaskForm.subtaskTypeId}
              onChange={handleChange}
            >
              {
                subTaskTypes.map(subTaskType =>
                  <MenuItem value={subTaskType.id}>{subTaskType.name}</MenuItem>
                )
              }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} lg={6}>
          <TextField
            name="amount"
            label="Monto"
            variant="outlined"
            value={subTaskForm.amount}
            onChange={handleChange}
            type="number"
            classStyle={classes.textFields}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <PeriodSelect periods={projectPeriods} form={subTaskForm} setForm={setSubTaskForm} disabled={subTaskForm.rendido}/>
        </Grid>
      </Grid>
      <Grid container>
        {/*<Grid item xs={6} lg={6}>
          <Button
            variant="contained"
            component="label"
          >
            Seleccionar archivo
            <input hidden type="file" onChange={e => handleAddFileSubtask(e.target.files[0])}/>
          </Button>
          {
            subTaskForm.file &&
            <Typography variant="caption" display="block" gutterBottom>
              {subTaskForm.file.name}
            </Typography>
          }
          </Grid>*/}
        <Grid item xs={6} lg={6}>
          {
            subTaskForm.canBeInventoryable &&
            <Grid item xs={6} lg={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={subTaskForm.inventoryable}
                    onChange={handleChange}
                    name="inventoryable"
                    color="primary"
                  />
                }
                label="Inventariable"
              />
            </Grid>
          }
        </Grid>
      </Grid>
      {
        subTaskForm.canBeInventoryable && subTaskForm.inventoryable &&
        <InventoriableForm form={subTaskForm} handleChange={handleChange} />
      }
      {
        type ==='create' &&
        <div className={classes.files}>
          <UploadButton file={subTaskForm.file} handleFile={addFileToSubTask} buttonName={'Archivo de subtarea'}
          />
        </div>
      }
      {
        type !== "create" &&
        <TextField
          name="reason"
          label="Razones de edición"
          classStyle={classes.bigTextfield}
          onChange={handleChange}
          value={subTaskForm.reason}
          multiline
          rows={2}
          variant="outlined"
        />
      }
    </>
  )
}

export default SubTaskForm;
