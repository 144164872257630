import clientAxios from '../../config/axios';
import {uploadFileAxios, uploadSingleFileAxios, } from '../../config/manageFileAxios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';
import {handleGoodsModal, } from './projectGoodsModalActions';

export const getGoodsFile = (projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/project/${projectId}/inventory-test`);
      console.log(data);
      dispatch(setGoodsFile(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el archivo de la lista de bienes'}
      ));
      dispatch(setLoading());
    }
  }
}

const setGoodsFile = (goodsFile) => ({
  type: 'setGoodsFile',
  payload: {
    goodsFile: goodsFile
  }
});

export const getGoodsList = (projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/project/${projectId}/subtask/inventoryable`);
      dispatch(setGoodsList(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener la lista de bienes'}
      ));
      dispatch(setLoading());
    }
  }
}

const setGoodsList = (goodsList) => ({
  type: 'setGoodsList',
  payload: {
    goodsList: goodsList
  }
});

export const firstGoodsFile = (projectId, file) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const objectData = {
        userId: userData.userId,
        projectId: projectId
      }
      const {data} = await uploadSingleFileAxios(objectData, file, `/api/inventory/add`, 'post');
      dispatch(getGoodsFile(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivo agregado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al agregar el archivo de lista de bienes'}
      ));
      dispatch(setLoading());
    }
  }
}

export const saveGoodsFile = (projectId, file, reason) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const objectData = {
        userId: userData.userId,
        projectId: projectId,
      }
      const {data} = await uploadSingleFileAxios(objectData, file, `/api/inventory/edit`, 'put');
      dispatch(getGoodsFile(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivo agregado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el indicador'}
      ));
      dispatch(setLoading());
    }
  }
}

export const editGood = (projectId, good, file) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      let goodData = {
        ...good,
        userId: userData.userId,
      }
      delete goodData.file;
      const {data} = await uploadSingleFileAxios(goodData, null, `/api/inventory/inventoryable-item/`, 'put');
      dispatch(getGoodsList(projectId));
      dispatch(handleGoodsModal());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Bien modificado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al modificar el bien'}
      ));
      dispatch(setLoading());
    }
  }
}

export const editGoodFile = (projectId, goodData, file) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const goodD = {
        ...goodData,
        userId: userData.userId
      }
      const {data} = await uploadSingleFileAxios(goodD, file, `/api/inventory/inventoryable-item/file`, 'put');
      dispatch(getGoodsList(projectId));
      dispatch(handleGoodsModal());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivo de bien modificado correctamente'}
      ));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al agregar el archivo al bien'}
      ));
      dispatch(setLoading());
    }
  }
}
