const initialState = {
  activityStatus: []
}

const activityStatusReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setActivityStatus':
      return {
        ...state,
        activityStatus: action.payload.activityStatus
      }
    default:
      return state;
  }
}

export default activityStatusReducer;
