import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, Badge, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '30%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 10,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const PermissionsTabs = ({tabSelected, handleChangeTab, permissionsByTab, permissions, permissionsSelected, }) => {

  const permissionsSelectedInTab = permissions.filter(permission => permissionsSelected.includes(permission.id));
  const allSelected = permissionsSelectedInTab.length === permissions.length;

  return (
    <AppBar position="static">
      <Tabs value={tabSelected} onChange={handleChangeTab} scrollButtons="on" variant="scrollable" aria-label="simple tabs example">
        {
          permissionsByTab.map((tab, tabIdx) => {
            const permissionsSelectedInTab = tab.permissions.filter(permission => permissionsSelected.includes(permission.id));
            return (
              <StyledBadge badgeContent={`${permissionsSelectedInTab.length}/${tab.permissions.length}`} color="primary" onClick={e => handleChangeTab(e, tabIdx)}>
                <Tab label={tab.tab} {...a11yProps(tabIdx)} />
              </StyledBadge>
            )
          })
        }
      </Tabs>
    </AppBar>
  )
}

export default PermissionsTabs;
