import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const getSubTasksByPeriod = (periodId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/project/period/${periodId}/subtask`);
      console.log(data);
      dispatch(setSubTasksByPeriod(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener las subtareas del periodo'}
      ));
    }
  }
}

const setSubTasksByPeriod = (subTasksByPeriod) => ({
  type: 'setSubTasksByPeriod',
  payload: {
    subTasksByPeriod: subTasksByPeriod
  }
});
