export const handleSubTaskModal = (show, type, subTaskData) => {
  if (show) {
    return dispatch => dispatch(showSubTaskModal(type, subTaskData));
  }
  else {
    return dispatch => dispatch(closeSubTaskModal());
  }
}

const showSubTaskModal = (type, subTaskData) => ({
  type: 'showSubTaskModal',
  payload: {
    type: type,
    subTaskData: subTaskData
  }
})

const closeSubTaskModal = () => ({
  type: 'closeSubTaskModal',
  payload: {}
})
