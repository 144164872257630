import React, {useState, useEffect, } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Stepper, Step, StepLabel, Button, Typography, Grid, } from '@material-ui/core';

import CreateProjectFormHeader from '../CreateProjectForm/CreateProjectFormHeader/CreateProjectFormHeader';
import CreateProjectAssignUsers from '../CreateProjectForm/CreateProjectAssignUsers/CreateProjectAssignUsers';
import CreateProjectFormObjectives from '../CreateProjectForm/CreateProjectFormHeader/CreateProjectFormObjectives';

import useForm from '../../FormValidator/formValidator';

import PrimaryButton from '../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../Utils/Buttons/SecondaryButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    margin: '10px 30px 10px 30px'
  }
}));

const getSteps = () => {
  return ['Datos Generales', 'Objetivos del Proyecto', 'Asignar Usuarios'];
}

const validationStateSchema = {
  name: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  projectCode: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  financingSourceId: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  // tel_T: {
  //   validator: {
  //     regEx: /^[0-9]$/,
  //     error: 'Invalid last name format.',
  //   },
  // },
};

const CreateProjectStepper = ({projectForm, handleChangeProjectForm, setProjectForm, startDate, handlePickDate, createProject, financingSources,
  roles, }) => {

  const classes = useStyles();
  const steps = getSteps();

  const [activeStep, setActiveStep] = useState(0);
  const [disableObjectives, setDisableObjectives] = useState(true);

  useEffect(() => {
    const generalPurposes = [...projectForm.generalPurposes];
    const namesGP = generalPurposes.every(generalPurpose => generalPurpose.name);
    if (namesGP) {
      const specificPurposes = [].concat(...generalPurposes.map(purpose => purpose.specificPurposes));
      const namesSP = specificPurposes.every(specificPurpose => specificPurpose.name);
      setDisableObjectives(!namesSP);
    }
    else {
      setDisableObjectives(true);
    }
  }, [projectForm.generalPurposes]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const {disabled} = useForm(projectForm, validationStateSchema);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {
          {
            0:<>
                <CreateProjectFormHeader projectForm={projectForm} handleChangeProjectForm={handleChangeProjectForm} setProjectForm={setProjectForm}
                  startDate={startDate} handlePickDate={handlePickDate} handleNext={handleNext} financingSources={financingSources}
                />
                <Grid container justify="center">
                  <PrimaryButton title={'Siguiente'} onClick={handleNext} disabled={disabled} />
                </Grid>
              </>,
            1:
              <>
                <CreateProjectFormObjectives projectForm={projectForm} setProjectForm={setProjectForm} type={'create'}/>
                <Grid container justify="center">
                  <div className={classes.buttons}>
                    <SecondaryButton title={'Atrás'} onClick={handleBack} />
                  </div>
                  <div className={classes.buttons}>
                    <PrimaryButton title={'Siguiente'} onClick={handleNext} disabled={disableObjectives}/>
                  </div>
                </Grid>
              </>,
            2:<>
                <CreateProjectAssignUsers projectForm={projectForm} setProjectForm={setProjectForm} handleBack={handleBack} createProject={createProject}
                  roles={roles}
                />
                <Grid container justify="center">
                  <div className={classes.buttons}>
                    <SecondaryButton title={'Atrás'} onClick={handleBack} />
                  </div>
                  <div className={classes.buttons}>
                    <PrimaryButton title={'Crear Proyecto'} onClick={createProject} />
                  </div>
                </Grid>
              </>
          }[activeStep]
        }
      </div>
    </div>
  );
}

export default CreateProjectStepper;
