import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../Utils/TextFields/TextField';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 360,
    width: '100%'
  },
  divText: {
    marginTop: 10,
    marginBottom: 10
  }
}));

const ProjectsListTableFilters = ({nameFilter, handleNameFilter, }) => {

  const classes = useStyles();

  return (
    <div className={classes.divText}>
      <TextField
        label="Buscar proyecto"
        variant="outlined"
        value={nameFilter}
        onChange={handleNameFilter}
        classStyle={classes.formControl}
      />
    </div>
  )
}

export default ProjectsListTableFilters;
