import { createSelector } from 'reselect';

export const ProjectDetailsEspecificModalSelector = createSelector(
  state => state.projectEspecificModal,
  projectEspecificModal => projectEspecificModal
);

export const ProjectDetailsGroupTeamModalSelector = createSelector(
  state => state.projectGroupTeamModal,
  projectGroupTeamModal => projectGroupTeamModal
);

export const SectionModalSelector = createSelector(
  state => state.sectionModal,
  sectionModal => sectionModal
);

export const ActivitiesModalSelector = createSelector(
  state => state.projectActivitiesModal,
  projectActivitiesModal => projectActivitiesModal
);

export const SelectActivityModalSelector = createSelector(
  state => state.projectSelectActivityModal,
  projectSelectActivityModal => projectSelectActivityModal
);

export const FilesManagerModalSelector = createSelector(
  state => state.filesManagerModal,
  filesManagerModal => filesManagerModal
);

export const TaksModalSelector = createSelector(
  state => state.projectTaskModal,
  projectTaskModal => projectTaskModal
);

export const SubTaskModalSelector = createSelector(
  state => state.projectSubTaskModal,
  projectSubTaskModal => projectSubTaskModal
);

export const PeriodModalSelector = createSelector(
  state => state.projectPeriodModal,
  projectPeriodModal => projectPeriodModal
);

export const IndicatorModalSelector = createSelector(
  state => state.projectIndicatorModal,
  projectIndicatorModal => projectIndicatorModal
);

export const InventoryModalSelector = createSelector(
  state => state.projectInventoryModal,
  projectInventoryModal => projectInventoryModal
);

export const GeneralModalSelector = createSelector(
  state => state.projectGeneralModal,
  projectGeneralModal => projectGeneralModal
);

export const BudgetSummaryModalSelector = createSelector(
  state => state.budgetSummaryModal,
  budgetSummaryModal => budgetSummaryModal
);

export const GoodsModalSelector = createSelector(
  state => state.projectGoodsModal,
  projectGoodsModal => projectGoodsModal
);

export const HistoryRecordSelector = createSelector(
  state => state.historyRecordModal,
  historyRecordModal => historyRecordModal
);

export const PaymentsSelector = createSelector(
  state => state.projectPaymentsModal,
  projectPaymentsModal => projectPaymentsModal
);

export const StateModalSelector = createSelector(
  state => state.projectStateModal,
  projectStateModal => projectStateModal
);
