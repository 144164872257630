import React from 'react';

import {TableBody, TableRow, TableCell, Button, Grid, } from '@material-ui/core';

import ProjectDetailsGroupTeamTableRow from './ProjectDetailsGroupTeamTableRow';

const ProjectDetailsGroupTeamTableBody = ({edit, groupTeamForm, handleChange, newMember, deleteMember, }) => {

  return (
    <TableBody>
      {
        groupTeamForm.teamMembers.map((member, memberIdx) =>
          <ProjectDetailsGroupTeamTableRow edit={edit} member={member} handleChange={handleChange} deleteMember={deleteMember} memberIdx={memberIdx}/>
        )
      }
      {
        edit &&
        <TableCell>
          <Button onClick={newMember}>
            Agregar integrante
          </Button>
        </TableCell>
      }
    </TableBody>
  )
}

export default ProjectDetailsGroupTeamTableBody;
