const initialState={
  show: false,
  projectId: -1,
  groupTeams: []
}

const projectGroupTeamModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showGroupTeamModal':
      return {
        ...state,
        show: true,
        projectId: action.payload.projectId
      }
    case 'closeGroupTeamModal':
      return {
        ...state,
        ...initialState
      }
    case 'setGroupTeams':
      return {
        ...state,
        groupTeams: action.payload.groupTeams
      }
    default:
      return state;
  }
}

export default projectGroupTeamModalReducer;
