import React from 'react';

import { useSelector } from 'react-redux';

import {UserPermissionsSelector, } from '../../../../../../redux/selectors';

import {TableCell, TableRow, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

import {getSubTaskType, } from '../../../../../Utils/Functions/TaskFunctions';
import {getSelectFormat, } from '../../../../../Utils/Functions/DateFunctions';

import FilterComponent from '../../../../../Utils/FilterComponent/FilterComponent';

const ProjectDetailsSubtasksRow = ({subTask, openSubTaskModal, projectPeriods, }) => {

  const {userPermissionsList} = useSelector(UserPermissionsSelector);

  const token = localStorage.getItem('token');
  const userData = JSON.parse(atob(token.split('.')[1]));
  const userRole = userData.isAdmin;
  // const userRole = false;
  const checkPermissions = userPermissionsList.find(permission => permission.slug === 'subtask.show');

  const periodFind = projectPeriods.find(period => period.id === subTask.periodId);

  return (
    <TableRow
      onClick={() => (userRole || checkPermissions) ? openSubTaskModal(subTask) : {}}
      style={(userRole || checkPermissions) ? {cursor: 'pointer'} : {}}
    >
      <TableCell component="th" scope="row">
        {subTask.description}
      </TableCell>
      <TableCell>{subTask.name || '-'}</TableCell>
      <TableCell>{subTask.subtaskType?.name || '-'}</TableCell>
      <TableCell>{subTask.amount || '-'}</TableCell>
      <TableCell>
        {
          periodFind ? (periodFind.startDate &&
          getSelectFormat(periodFind.startDate, periodFind.endDate)) : '-'
        }
      </TableCell>
      <TableCell>{subTask.paymentDocument?.documentNumber}</TableCell>
      <TableCell>{subTask.updatedByUserName || subTask.createdByUserName}</TableCell>
      <TableCell>
        {
          subTask.paymentDocument ?
          <FeatherIcon icon="check-circle" className="mr-1"/>
          :
          <FeatherIcon icon="x-circle" className="mr-1"/>
        }
      </TableCell>
    </TableRow>
  )
}

export default ProjectDetailsSubtasksRow;
