import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Table, TableContainer, Paper, FormControl, InputLabel, Select, MenuItem, AppBar, Tabs, Tab, } from '@material-ui/core';

import PermissionsTabs from './PermissionsTabs';
import PermissionsList from './PermissionsList/PermissionsList';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '30%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const permissionsByTab = [
  {
    tab: 'Planificación',
    permissions: [
      {
        id: 1,
        permission: 'Ver pestaña planificación'
      },
      {
        id: 2,
        permission: 'Crear hitos'
      },
      {
        id: 3,
        permission: 'Editar hitos'
      },
      {
        id: 4,
        permission: 'Eliminar hitos'
      },
      {
        id: 5,
        permission: 'Crear actividades'
      },
      {
        id: 6,
        permission: 'Editar actividades'
      },
      {
        id: 7,
        permission: 'Eliminar actividades'
      },
    ]
  },
  {
    tab: 'Presupuesto',
    permissions: [
      {
        id: 8,
        permission: 'Ver pestaña presupuesto'
      },
      {
        id: 9,
        permission: 'Descargar archivo de presupuesto'
      },
      {
        id: 10,
        permission: 'Ver historial de presupuesto'
      },
      {
        id: 11,
        permission: 'Subir y modificar presupuesto'
      },
      {
        id: 12,
        permission: 'Editar resumen de presupuesto'
      },
    ]
  },
  {
    tab: 'Actividades',
    permissions: [
      {
        id: 13,
        permission: 'Crear tareas'
      },
      {
        id: 14,
        permission: 'Editar tareas'
      },
      {
        id: 15,
        permission: 'Eliminar tareas'
      },
      {
        id: 16,
        permission: 'Crear subtareas'
      },
      {
        id: 17,
        permission: 'Editar subtareas'
      },
      {
        id: 18,
        permission: 'Eliminar subtareas'
      },
    ]
  },
  {
    tab: 'Rendiciones',
    permissions: [

    ]
  },
  {
    tab: 'Inventarios',
    permissions: [

    ]
  },
  {
    tab: 'Antecedentes y documentación',
    permissions: [

    ]
  },
  {
    tab: 'Contexto normativo',
    permissions: [
    ]
  },
  {
    tab: 'Cierre del proyecto',
    permissions: [
    ]
  }
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Permissions = ({roleSelected, permissionsByTab, }) => {

  const classes = useStyles();

  const [permissions, setPermissions] = useState([]);
  const [tabSelected, setTabSelected] = useState(0);
  const [permissionsSelected, setPermissionsSelected] = useState([1, 11]);
  const [activePage, setActivePage] = useState(0);
  // const [pages, setPages] = useState([]);

  useEffect(() => {
    setPermissionsSelected(roleSelected.permissions.map(permission => permission.id));
  }, [roleSelected]);

  // useEffect(() => {
  //   const numberPages = permissions.length/10;
  //   let pagesItems = [];
  //   for (var i = 0; i < numberPages; i++) {
  //     pagesItems.push(i);
  //   }
  //   setPages(pagesItems);
  // }, [permissions]);

  useEffect(() => {
    setPermissions(permissionsByTab[tabSelected]?.permissions || []);
  }, [permissionsByTab, tabSelected]);

  const handleChange = (e) => setTabSelected(e.target.value);

  const handleChangeTab = (event, newValue) => {
    setTabSelected(newValue);
  };

  const handlePermissions = (roleId) => {
    let permissionsSelectedAux = [...permissionsSelected];
    const roleIndex = permissionsSelectedAux.findIndex(role => role === roleId);
    if (roleIndex > -1) {
      permissionsSelectedAux.splice(roleIndex, 1);
    }
    else {
      permissionsSelectedAux.push(roleId);
    }
    setPermissionsSelected(permissionsSelectedAux);
  }

  const selectAllTab = () => {
    let permissionsSelectedAux = [...permissionsSelected];
    const permissionsTab = permissionsByTab[tabSelected].permissions;
    const permissionsIds = permissionsTab.map(permission => permission.id);
    permissionsSelectedAux.push(...permissionsIds);
    setPermissionsSelected([...new Set(permissionsSelectedAux)]);
  }

  const cleanTab = () => {
    let permissionsSelectedAux = [...permissionsSelected];
    const permissionsTab = permissionsByTab[tabSelected].permissions;
    const permissionsIds = permissionsTab.map(permission => permission.id);
    setPermissionsSelected([...new Set(permissionsFiltered)]);
    const permissionsFiltered = permissionsSelectedAux.filter(permission => permissionsIds.indexOf(permission) === -1);
  }

  const handleToggleAll = (allSelected) => {
    if (allSelected) {
      cleanTab();
    }
    else {
      selectAllTab();
    }
  }
  console.log(permissions);

  return (
    <>
      <PermissionsTabs permissions={permissions} permissionsSelected={permissionsSelected} tabSelected={tabSelected} handleChangeTab={handleChangeTab}
        permissionsByTab={permissionsByTab}
      />
      <PermissionsList permissions={permissions} permissionsSelected={permissionsSelected} handlePermissions={handlePermissions}
        handleToggleAll={handleToggleAll}
      />
    </>
  )
}

export default Permissions;
