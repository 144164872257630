import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, Checkbox, Typography, FormControl, FormGroup, FormControlLabel, IconButton, } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: '10px 20px 10px 20px',
      fontFamily: 'Roboto',
    },
    title: {
      marginLeft: '-7px',
      marginRight: '24px',
      fontSize: '14px',
      color: theme.palette.text.secondary,
      textAlign: 'left',
      fontWeight: 500,
    },
    formGroup: {
      marginTop: '8px',
    },
    formControl: {},
    checkbox: {
      padding: '0px',
      width: '32px',
      height: '32px',
    },
    checkboxRoot: {},
    checked: {},
    label: {
      fontSize: '15px',
      marginLeft: '8px',
      color: theme.palette.text.primary,
    },
  }),
  { name: 'MUIDataTableViewCol' },
);


const Filters = ({allFiles, handleAllFiles, handleClose, }) => {

  const classes = useStyles();

  return (
    <FormControl component={'fieldset'} className={classes.root}>
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="caption" className={classes.title}>
            Filtros
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <IconButton size="small" onClick={handleClose}>
            <HighlightOffIcon/>
          </IconButton>
        </Grid>
      </Grid>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          classes={{
            root: classes.formControl,
            label: classes.label,
          }}
          control={
            <Checkbox
              color="primary"
              data-description="table-view-col"
              className={classes.checkbox}
              classes={{
                root: classes.checkboxRoot,
                checked: classes.checked,
              }}
              onChange={handleAllFiles}
              checked={allFiles}
              // value={column.name}
            />
          }
          label={'Todos los archivos'}
        />
      </FormGroup>
    </FormControl>
  )
}

export default Filters;
