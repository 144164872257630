import React from 'react';

import { makeStyles, } from '@material-ui/core/styles';
import {TableCell, TableRow, Collapse, Box, Typography, Table, TableHead, TableBody, FormControlLabel, Checkbox, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

const history = [
  { date: '2020-01-05', customerId: '11091700', amount: 3 },
  { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
];

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 50,
    fontSize: 28,
    fontWeight: 'bold'
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
  permissions: {
    marginLeft: '3%',
    fontSize: 10
  },
  permissionLabel: {
    size: 8
  }
}));

const PermissionsTableRowDetails = ({open, }) => {

  const classes = useStyles();

  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className={classes.permissions}>
            <FormControlLabel
              classes={classes.permissionLabel}
              control={<Checkbox
                // checked={gilad} onChange={handleChange}
                name="gilad" />}
              label="Crear usuarios"
            />
            <div />
            <FormControlLabel
              classes={classes.permissionLabel}
              control={<Checkbox
                // checked={gilad} onChange={handleChange}
                name="gilad" />}
              label="Editar usuarios"
            />
            <div />
            <FormControlLabel
              classes={classes.permissionLabel}
              control={<Checkbox
                // checked={gilad} onChange={handleChange}
                name="gilad" />}
              label="Eliminar usuarios"
            />
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default PermissionsTableRowDetails;
