import React, {} from 'react';

import {Table} from 'react-bootstrap';

import DynamicTableHeader from './DynamicTableHeader';
import DynamicTableBody from './DynamicTableBody';

const DynamicTable = ({tableRows, addTableRow, addTableHeader, removeTableHeader, handleTableChanges, }) => {

  return (
    <Table striped bordered hover>
      <DynamicTableHeader tableRows={tableRows} addTableHeader={addTableHeader} removeTableHeader={removeTableHeader} handleTableChanges={handleTableChanges}/>
      <DynamicTableBody tableRows={tableRows} addTableRow={addTableRow} handleTableChanges={handleTableChanges}/>
    </Table>
  )
}

export default DynamicTable;
