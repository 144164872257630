import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {TableRow, TableCell, TextField, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

import TextFieldColored from '../../../../Utils/TextFields/TextField';

const useStyles = makeStyles({
  textFieldAmount: {
    marginTop: -12,
    width: 120,
    maxHeight: 20
  },
  textFieldText: {
    marginTop: -12,
    maxHeight: 20
  },
  deleteButton: {
    paddingLeft: 8,
    paddingRight: 8,
    width: 24
  }
});

const BudgetSummaryRow = ({detail, detailIdx, fieldIdx, edit, handleChange, open, deleteSubcategory, }) => {

  const classes = useStyles();

  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.cell}>
        {
          edit ?
          <TextFieldColored
            name="name"
            size="small"
            label="Item"
            variant="outlined"
            value={detail.name}
            onChange={e => handleChange(e, fieldIdx, detailIdx)}
          />
          :
          <>
            {detail.name}
          </>
        }
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {
          edit ?
          <TextFieldColored
            name="amount"
            size="small"
            label="Monto"
            type="number"
            variant="outlined"
            value={detail.amount}
            onChange={e => handleChange(e, fieldIdx, detailIdx)}
          />
          :
          <>
            {detail.amount}
          </>
        }
      </TableCell>
      {
        edit &&
        <TableCell className={classes.deleteButton}>
          <FeatherIcon icon="x-circle" className='iconButton' onClick={() => deleteSubcategory(fieldIdx, detailIdx)}/>
        </TableCell>
      }
    </TableRow>
  )
}

export default BudgetSummaryRow;
