import React from 'react';

import {TableHead, TableRow, TableCell, Button, } from '@material-ui/core';

const IndicatorGoalsTableHeader = ({indicatorForm, type, deleteIndicator, edit, }) => {

  return (
    <TableHead>
      <TableRow>
        <TableCell>Línea Base</TableCell>
        {
          indicatorForm.indicatorGoal?.map((indicatorGoal, indicatorGoalIdx) =>
            <TableCell>
              {`Meta ${indicatorGoalIdx + 1}`}
              {
                edit &&
                <Button onClick={() => deleteIndicator(indicatorGoalIdx)}>
                  -
                </Button>
              }
            </TableCell>
          )
        }
        {
          edit &&
          <TableCell>Nueva Meta</TableCell>
        }
      </TableRow>
    </TableHead>
  )
}

export default IndicatorGoalsTableHeader;
