import React from 'react';

import {Container, Card, Form, } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';

import ProjectDetailsPhaseCategories from './ProjectDetailsPhaseCategories/ProjectDetailsPhaseCategories';
// import ProjectDetailsPhaseFilesManager from './ProjectDetailsPhaseFilesManager';
// import ProjectDetailsPhaseShowFiles from './ProjectDetailsPhaseShowFiles';

import '../ProjectDetailsPhasesCSS/ProjectDetailsPhases.css';

const phases = {
  0: 'Formulación de iniciativas',
  1: 'Adjudicación de iniciativas',
  2: 'Puesta en marcha',
  3: 'Monitoreo y seguimiento',
  4: 'Cierre y postcierre'
}

const ProjectDetailsPhase = ({phase, phaseIndex, openSectionModal, projectPhases, setProjectPhases, handleProjectPhasesChange, handleRemovePhase, currentProject, }) => {

  const addFileToPhase = (file, fileType) => {
    const phasesCopy = [...projectPhases];
    const phaseFiles = [...phase.files || []];
    const fileData = {
      file: file,
      fileType: fileType
    }
    phaseFiles.push(fileData);
    const phaseFindIndex = phasesCopy.findIndex(phaseC => phaseC.phaseNumber === phase.phaseNumber);
    phasesCopy[phaseFindIndex] = {...phase, files: phaseFiles};
    setProjectPhases(phasesCopy);
  }

  return (
    <Container>
      <Card>
        <Card.Header>
          {/*<Form.Group controlId="phaseName">
            <Form.Label>Fase {phaseIndex+1}</Form.Label>
            <Form.Control type="text" defaultValue={phase.phaseName} placeholder="Nombre de la fase del proyecto"
              onChange={e => handleProjectPhasesChange(phaseIndex, e)}/>
          </Form.Group>*/}
          {`Fase ${phase.phaseNumber}: ${phases[phase.phaseNumber-1]}`}
          {/*<FeatherIcon icon="x-circle" className="mr-1 cornerButtonClose" onClick={() => handleRemovePhase(phaseIndex)}/>*/}
        </Card.Header>
        {/*
          currentProject.state !== "finished" &&
          <ProjectDetailsPhaseFilesManager addFileToItem={addFileToPhase}/>
        }
        <ProjectDetailsPhaseShowFiles files={phase.files} />*/}
        {/*<ProjectDetailsPhaseCategories phase={phase} phaseIndex={phaseIndex} projectPhases={projectPhases} setProjectPhases={setProjectPhases}
          openSectionModal={openSectionModal} />}
        {/*<ProjectDetailsPhaseSections phase={phase} openSectionModal={openSectionModal} phaseIndex={phaseIndex} projectPhases={projectPhases}
          setProjectPhases={setProjectPhases}/>*/}
      </Card>
    </Container>
  )
}

export default ProjectDetailsPhase;
