import React, {useState, useEffect, } from 'react';

import HistoryRecordTable from '../../../../Utils/HistoryRecord/HistoryRecordTable/HistoryRecordTable';

const IndicatorHistorial = ({indicator, handleClose, project, }) => {

  const [historial, setHistorial] = useState([]);

  useEffect(() => {
    const allHistorial = indicator.indicatorVerication.map(file => file.verificationRecords);
    const getAllHistorial = [].concat(...allHistorial);
    setHistorial(getAllHistorial);
  }, []);

  return (
    <>
      <HistoryRecordTable title={'Historial'} historyData={historial} handleClose={handleClose} project={project} />
    </>
  )
}

export default IndicatorHistorial;
