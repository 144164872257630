import React from 'react';

import {Card, Row, Col, Button, } from 'react-bootstrap';

import Repository from '../../../../Utils/Repository/Repository';

const ProjectDetailsSectionRepository = ({field, sectionIdx, sectionFields, setSectionFields, removeField, }) => {

  const addFile = (event) => {
    const sectionFieldsCopy = [...sectionFields];
    const filesValues = [...sectionFieldsCopy[sectionIdx].data];
    filesValues.push(event.target.files[0]);
    sectionFieldsCopy[sectionIdx] = {...field, data: filesValues};
    setSectionFields(sectionFieldsCopy);
  }

  const removeFile = (fileIdx) => {
    let sectionFieldsCopy = [...sectionFields];
    const filesValues = [...field.data];
    filesValues.splice(fileIdx, 1);
    sectionFieldsCopy[sectionIdx] = {...field, data: filesValues};
    setSectionFields(sectionFieldsCopy);
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs={10}>
            <Card.Title>
              {field.fieldName}
            </Card.Title>
          </Col>
          <Col xs={2}>
            <Button onClick={() => removeField(sectionIdx)}>
              X
            </Button>
          </Col>
        </Row>
        <Card.Text>
          <Repository files={field.data} addFile={addFile} removeFile={removeFile} />
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default ProjectDetailsSectionRepository;
