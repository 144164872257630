import React, {useState, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, Typography, Box, Grid, } from '@material-ui/core';

import Indicator from './Indicator';
import IndicatorFiles from './IndicatorFiles';
import HistoryRecordTable from '../../../../Utils/HistoryRecord/HistoryRecordTable/HistoryRecordTable';
import IndicatorHistorial from './IndicatorHistorial';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterTabsComponent from '../../../../Utils/FilterComponent/FilterTabsComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  bar: {
    backgroundColor: '#002F6C',
    borderRight: `1px solid ${theme.palette.divider}`,
  }
}));

const historyData = {
  history: ['', '']
}

const IndicatorTabs = ({type, indicator, indicatorForm, setIndicatorForm, handleChange, files, show, handleClose, project, deleteFile, }) => {

  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [historial, setHistorial] = useState([]);



  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const makeHistorial = () => {

  }

  const tabs = [
    {
      permission: 'indicator.show',
      label: 'Datos del Indicador',
      component: <Indicator type={type} indicator={indicator} indicatorForm={indicatorForm} setIndicatorForm={setIndicatorForm} handleChange={handleChange}
        files={files} show={show} handleClose={handleClose} project={project}
      />
    },
    {
      permission: 'indicator.show',
      label: 'Medios de Verificación',
      component: <IndicatorFiles indicator={indicator} deleteFile={deleteFile} handleClose={handleClose} project={project}/>
    },
    {
      permission: 'indicatorVerificationMeans.history',
      label: 'Historial medios de Verificación',
      component: <>
        <IndicatorHistorial indicator={indicator} historyData={historyData} handleClose={handleClose} project={project} />
        <Grid container justify="flex-end">
          <SecondaryButton title={'Cerrar'} variant="secondary" onClick={handleClose} />
        </Grid>
      </>
    },
  ]

  return (
    <div className={classes.root}>
      <FilterTabsComponent value={value} handleChangeTab={handleChangeTab} tabs={tabs} />
      {/*<AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChangeTab}
          TabIndicatorProps={{style: {background:'white'}}}
          className={classes.bar}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab label="Datos del Indicador" {...a11yProps(0)} />
          <Tab label="Medios de Verificación" {...a11yProps(1)} />
          <Tab label="Historial medios de Verificación" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Indicator type={type} indicator={indicator} indicatorForm={indicatorForm} setIndicatorForm={setIndicatorForm} handleChange={handleChange}
          files={files} show={show} handleClose={handleClose} project={project}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <IndicatorFiles indicator={indicator} deleteFile={deleteFile} handleClose={handleClose} project={project}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <IndicatorHistorial indicator={indicator} historyData={historyData} handleClose={handleClose} project={project} />
        <Grid container justify="flex-end">
          <SecondaryButton title={'Cerrar'} variant="secondary" onClick={handleClose} />
        </Grid>
      </TabPanel>*/}
    </div>
  );
}

export default IndicatorTabs;
