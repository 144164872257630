import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Typography, Button, Grid, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '0.5px solid grey',
    marginBottom: '10px'
  },
  title: {
    fontSize: 18,
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
  blocks: {
    marginBottom: 20
  },
  title: {
    marginBottom: 10
  }
}));

const InventoryDetails = ({form, }) => {

  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Marca:`}
            </Typography>
            <Typography >
              {`${form.brand}`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Modelo:`}
            </Typography>
            <Typography >
              {`${form.model}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Proveedor:`}
            </Typography>
            <Typography >
              {`${form.supplier}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default InventoryDetails;
