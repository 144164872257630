import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Button, } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    textAlign: 'center'
  },
  buttons: {
    margin: 40
  },
  button: {
    width: 300,
    height: 70
  }
});

const NewClosingSelect = ({setSelectedOption, }) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>
        <Button variant="contained" color="primary" className={classes.button} onClick={() => setSelectedOption(1)}>
          Nueva Extensión
        </Button>
      </div>
      <div className={classes.buttons}>
        <Button variant="contained" color="primary" className={classes.button} onClick={() => setSelectedOption(2)}>
          Compromiso Presupuestario
        </Button>
      </div>
    </div>
  )
}

export default NewClosingSelect;
