export const handlePaymentsModal = (show, type, paymentData, handlePayment, handlePaymentSubTasks, handleDeletePayment) => {
  if (show) {
    return dispatch => dispatch(showPaymentsModal(type, paymentData, handlePayment, handlePaymentSubTasks, handleDeletePayment));
  }
  else {
    return dispatch => dispatch(closePaymentsModal());
  }
}

const showPaymentsModal = (type, paymentData, handlePayment, handlePaymentSubTasks, handleDeletePayment) => ({
  type: 'showPaymentsModal',
  payload: {
    type: type,
    paymentData: paymentData,
    handlePayment: handlePayment,
    handlePaymentSubTasks: handlePaymentSubTasks,
    handleDeletePayment: handleDeletePayment
  }
})

const closePaymentsModal = () => ({
  type: 'closePaymentsModal',
  payload: {}
})
