const initialState={
  show: false,
  goodsData: {
    good: {
      file: {}
    },
    handleGood: () => {}
  }
}

const projectGoodsModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showGoodsModal':
      return {
        ...state,
        show: true,
        goodsData: action.payload.goodsData
      }
    case 'closeGoodsModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default projectGoodsModalReducer;
