export const handleSectionModal = (show, sectionData) => {
  if (show) {
    return dispatch => dispatch(showSectionModal(sectionData));
  }
  else {
    return dispatch => dispatch(closeSectionModal());
  }
}

const showSectionModal = (sectionData) => ({
  type: 'showSectionModal',
  payload: {
    sectionData: sectionData
  }
})

const closeSectionModal = () => ({
  type: 'closeSectionModal',
  payload: {}
})
