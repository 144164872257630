const initialState = {
  expenseTypes: []
}

const expenseTypesReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setExpenseTypes':
      return {
        ...state,
        expenseTypes: action.payload.expenseTypes
      }
    default:
      return state;
  }
}

export default expenseTypesReducer;
