import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Table, TableContainer, Paper, FormControl, InputLabel, Select, MenuItem, AppBar, Tabs, Tab, } from '@material-ui/core';

import PermissionsTableHeader from './PermissionsTableHeader';
import PermissionsTableBody from './PermissionsTableBody';
import PermissionsTablePagination from './PermissionsTablePagination';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '30%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const permissionsData = [
  {
    id: 1,
    permission: 'Administrar Usuarios'
  },
  {
    id: 2,
    permission: 'Cambiar atribuciones a usuarios'
  },
  {
    id: 3,
    permission: 'Gestión en plataforma'
  },
  {
    id: 4,
    permission: 'Completar Información de Proyecto'
  },
  {
    id: 5,
    permission: 'Asignación de Proyectos '
  },
  {
    id: 6,
    permission: 'Asignación de Presupuesto'
  },
  {
    id: 7,
    permission: 'Subir archivos de respaldo'
  },
  {
    id: 8,
    permission: 'Verificar información Proyecto Asignado'
  },
  {
    id: 9,
    permission: 'Aprobar Informes'
  },
  {
    id: 10,
    permission: 'Editar información'
  },
  {
    id: 11,
    permission: 'Visualizar proyectos asignados'
  }
];

const permissionsByTab = [
  {
    tab: 'Planificación',
    permissions: [
      {
        id: 1,
        permission: 'Ver pestaña planificación'
      },
      {
        id: 2,
        permission: 'Crear hitos'
      },
      {
        id: 3,
        permission: 'Editar hitos'
      },
      {
        id: 4,
        permission: 'Eliminar hitos'
      },
      {
        id: 5,
        permission: 'Crear actividades'
      },
      {
        id: 6,
        permission: 'Editar actividades'
      },
      {
        id: 7,
        permission: 'Eliminar actividades'
      },
    ]
  },
  {
    tab: 'Presupuesto',
    permissions: [
      {
        id: 8,
        permission: 'Ver pestaña presupuesto'
      },
      {
        id: 9,
        permission: 'Descargar archivo de presupuesto'
      },
      {
        id: 10,
        permission: 'Ver historial de presupuesto'
      },
      {
        id: 11,
        permission: 'Subir y modificar presupuesto'
      },
      {
        id: 12,
        permission: 'Editar resumen de presupuesto'
      },
    ]
  },
  {
    tab: 'Actividades',
    permissions: [
      {
        id: 13,
        permission: 'Crear tareas'
      },
      {
        id: 14,
        permission: 'Editar tareas'
      },
      {
        id: 15,
        permission: 'Eliminar tareas'
      },
      {
        id: 16,
        permission: 'Crear subtareas'
      },
      {
        id: 17,
        permission: 'Editar subtareas'
      },
      {
        id: 18,
        permission: 'Eliminar subtareas'
      },
    ]
  }
];

const permisosRol = [
  {
    rol: 'admin',
    permisos: [1,2,3,4,5,6,7,8,9,10,11]
  },
  {
    rol: 'supervisor',
    permisos: [2, 5, 7, 8]
  },
  {
    rol: 'gestor',
    permisos: [2, 5, 7]
  },
  {
    rol: 'gestorFinanciero',
    permisos: [7, 8, 9]
  },
  {
    rol: 'coordinador',
    permisos: [10, 11]
  },
  {
    rol: 'observador',
    permisos: [11]
  }
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PermissionsTable = ({userForm, userPermissions, setUserPermissions, }) => {

  const classes = useStyles();

  const [permissions, setPermissions] = useState([]);
  const [tabSelected, setTabSelected] = useState(0);
  const [permissionsSelected, setPermissionsSelected] = useState([1, 11]);
  const [activePage, setActivePage] = useState(0);
  const [pages, setPages] = useState([]);

  // useEffect(() => {
  //   setPermissions(permissionsData);
  // }, [permissions]);

  useEffect(() => {
    const numberPages = permissions.length/10;
    let pagesItems = [];
    for (var i = 0; i < numberPages; i++) {
      pagesItems.push(i);
    }
    setPages(pagesItems);
  }, [permissions]);

  useEffect(() => {
    setPermissions(permissionsByTab[tabSelected].permissions);
  }, [tabSelected]);

  useEffect(() => {
    const roleSelected = permisosRol.find(permiso => permiso.rol === userForm?.role);
    if (roleSelected) {
      setUserPermissions(roleSelected.permisos);
    }
  }, []);

  const handleChange = (e) => setTabSelected(e.target.value);

  const handleChangeTab = (event, newValue) => {
    setTabSelected(newValue);
  };

  const selectPermission = (permissionID) => {
    let userPermissionsAux = [...userPermissions];
    const userPermissionIdx = userPermissionsAux.indexOf(permissionID);
    if (userPermissionIdx === -1) {
      userPermissionsAux.push(permissionID);
    }
    else {
      userPermissionsAux.splice(userPermissionIdx, 1);
    }
    setUserPermissions(userPermissionsAux)
  }

  const handlePermissions = (roleId) => {
    let permissionsSelectedAux = [...permissionsSelected];
    const roleIndex = permissionsSelectedAux.findIndex(role => role === roleId);
    if (roleIndex > -1) {
      permissionsSelectedAux.splice(roleIndex, 1);
    }
    else {
      permissionsSelectedAux.push(roleId);
    }
    setPermissionsSelected(permissionsSelectedAux);
  }

  return (
    <>
      <AppBar position="static">
        <Tabs value={tabSelected} onChange={handleChangeTab} aria-label="simple tabs example">
          {
            permissionsByTab.map((tab, tabIdx) =>
              <Tab label={tab.tab} {...a11yProps(tabIdx)} />
            )
          }
        </Tabs>
      </AppBar>
      <TableContainer component={Paper}>
        <Table>
          <PermissionsTableHeader />
          <PermissionsTableBody permissions={permissions.slice(activePage*10, (activePage+1)*10)} userPermissions={userPermissions}
            selectPermission={selectPermission} permissionsSelected={permissionsSelected} handlePermissions={handlePermissions}/>
        </Table>
        <PermissionsTablePagination pages={pages} activePage={activePage} setActivePage={setActivePage} />
      </TableContainer>

    </>
  )
}

export default PermissionsTable;
