import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tertiaryButton: {
    backgroundColor: '#002F6C',
    color: '#FFF',
    '&:hover':{
      backgroundColor: '#394049',
      boxShadow: 'none',
    },
    '&:disabled':{
      border: '1px solid #DAD7D7',
      backgroundColor: 'white',
      boxShadow: 'none',
      color: '#DAD7D7',
    }
  }
}));

const TertiaryButton = ({onClick, title, disabled, }) => {
  const classes = useStyles();

  return (
    <Button onClick={onClick} className={classes.tertiaryButton} disabled={disabled}>
      {title}
    </Button>
  )
}

export default TertiaryButton;
