import React from 'react';

import {Card, } from 'react-bootstrap';

const ProjectObjectiveDetailsShow = ({itemForm, }) => {

  return (
    <>
      <Card.Body>
        <Card.Title>{itemForm.name}</Card.Title>
      </Card.Body>
    </>
  )
}

export default ProjectObjectiveDetailsShow;
