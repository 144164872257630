import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Typography, Button, Accordion, AccordionSummary, AccordionDetails, Grid, } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useDispatch, } from 'react-redux';
import {handleNotifications, handleSubTaskModal, handlePaymentsModal, } from '../../../../../redux/actions';

import CapitulationSubTasksTable from '../CapitulationSubTasksTable/CapitulationSubTasksTable';
import PaymentsProofForm from './PaymentsProofForms/PaymentsProofForm';
import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '0.5px solid grey',
    marginBottom: '10px'
  },
  title: {
    fontSize: 18,
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
}));

const PaymentsProof = ({paymentsProofForm, setPaymentsProofForm, subTasks, addFile, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [reason, setReason] = useState('');
  const [edit, setEdit] = useState(false);

  const handleReason = (e) => setReason(e.target.value);

  const cancelEditPaymentsProof = () => setEdit(false);

  const handleEditPaymentsProof = () => {
    setEdit(false);
  }

  const editPayment = () => dispatch(handlePaymentsModal(true, 'edit', paymentsProofForm));

  return (
    <Accordion >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <Typography className={classes.heading}>
          Comprobante de Pago
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justify="flex-end">
          <PrimaryButton title={'Editar'} onClick={editPayment} />
        </Grid>
      </AccordionDetails>
      {
        edit ?
        <AccordionDetails style={{display: 'block'}}>
          <PaymentsProofForm subTasks={subTasks} paymentsProofForm={paymentsProofForm} setPaymentsProofForm={setPaymentsProofForm}
            cancelButton={cancelEditPaymentsProof} type={'edit'} reason={reason} handleReason={handleReason} addFile={addFile}
          />
        </AccordionDetails>
        :
        <>
          <AccordionDetails className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Archivo del comprobante:`}
            </Typography>
            <Typography >
              {`${paymentsProofForm.file?.name}`}
            </Typography>
          </AccordionDetails>
          <AccordionDetails>
            <Typography className={classes.miniTitle}>
              {`Monto del comprobante:`}
            </Typography>
            <Typography >
              {`${paymentsProofForm.paymentAmount}`}
            </Typography>
          </AccordionDetails>
          <AccordionDetails>
            <CapitulationSubTasksTable subTasks={paymentsProofForm.subTasks} type={'capitulation'}/>
          </AccordionDetails>
        </>
      }
    </Accordion>
  )
}

export default PaymentsProof;
