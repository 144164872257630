import React, {useState, useEffect} from 'react';

import {Button} from 'react-bootstrap';

import clientAxios from '../../config/axios';

import { useDispatch, } from 'react-redux';
import {handleUserFormModal, setLoading, getUsers, } from '../../redux/actions';


import UsersListTable from './UsersListTable/UsersListTable';
import UserFormModal from './UserFormModal/UserFormModal';

import './UsersListCSS/UsersList.css';

const UsersList = ({userFormModal, usersData, userData, }) => {

  const dispatch = useDispatch();
  const {accessToken, userInfo} = userData;

  const [users, setUsers] = useState([]);

  useEffect(async () => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    setUsers(usersData.usersList);
    // dispatch(setLoading(false));
  }, [usersData.usersList]);

  // const token = localStorage.getItem('token');
  // console.log(token.split('.'));
  // console.log(JSON.parse(atob(token.split('.')[0])));
  // console.log(JSON.parse(atob(token.split('.')[1])));
  // console.log(JSON.parse(atob(token.split('.')[2])));

  const openModalCreateUser = () => dispatch(handleUserFormModal(true, 'create', {}));

  return (
    <div className='usersListBody'>
      <h5 className='usersListHeader'>
        Lista de Usuarios
      </h5>
      {/*
      {
        userInfo.roles && userInfo.roles.includes('ROLE_ADMIN') &&
        <Button onClick={openModalCreateUser}>
          Agregar Usuario
        </Button>
      }
      */}
      <Button onClick={openModalCreateUser}>
        Agregar Usuario
      </Button>
      <UserFormModal userFormModal={userFormModal} />
      <UsersListTable users={users} userInfo={userInfo} />
    </div>
  )
}

export default UsersList;
