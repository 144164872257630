import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, } from '@material-ui/core';

import TextField from '../../../../Utils/TextFields/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  textField: {
    width: '80%'
  },
  longTextField: {
    width: '90%'
  }
}));

const GoodForm = ({form, handleChange, }) => {

  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container>
        <Grid item xs={6} lg={6}>
          <TextField
            name="brand"
            label="Marca"
            variant="outlined"
            classStyle={classes.textField}
            value={form.brand}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <TextField
            name="model"
            label="Modelo"
            variant="outlined"
            classStyle={classes.textField}
            value={form.model}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <TextField
            name="supplier"
            label="Proveedor"
            variant="outlined"
            classStyle={classes.longTextField}
            value={form.supplier}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default GoodForm;
