import React, {useState, useEffect} from 'react';

import { makeStyles, } from '@material-ui/core/styles';
import {Grid, FormControl, InputLabel, Select, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 500,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

const SelectActivity = ({objectives, selectedItems, setSelectedItems, type, }) => {

  const classes = useStyles();

  const [showItemsData, setShowItemsData] = useState([]);

  useEffect(() => {
    const objectivesData = objectives.map(objective => ({itemName: objective.mainObjective, itemID: objective.objectiveID}));
    const showItemsDataAux = [];
    showItemsDataAux.push(objectivesData);
    setShowItemsData(showItemsDataAux);
  }, [objectives]);

  const handleChangeMultiple = (e, n) => {
    // const newItem = (n === showItemsData.length);
    let selectedItemsCopy = [...selectedItems];

    selectedItemsCopy = selectedItemsCopy.slice(0, n);
    const showItemsDataCopy = showItemsData.slice(0, n+1);
    let findItem = [...objectives];
    for (var i = 0; i < n; i++) {
      // const indexItem = findItem.findIndex(item => item.itemName === selectedItems[i]);
      findItem = findItem[selectedItems[i]].items;
    }
    const newItem = findItem[e.target.value];
    if (newItem && newItem.items) {
      showItemsDataCopy.push(newItem.items);
      setShowItemsData(showItemsDataCopy);
    }

    selectedItemsCopy.push(e.target.value);
    setSelectedItems(selectedItemsCopy);
  };

  useEffect(() => {
    console.log(showItemsData);
  }, [showItemsData])

  return (
    <Grid container>
      {
        showItemsData.map((items, n) =>
          <>
            {
              (n > 3 && type === 'activity') ?
              <>
              </>
              :
              <Grid item lg={6} xs={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor="select-multiple-native">
                    {`Elegir ${n === 0 ? 'Objetivo general' : n === 1 ? 'Objetivo específico' : n === 2 ? 'hito' : n === 3 ? 'actividad' : 'tarea'}`}
                  </InputLabel>
                  <Select
                    native
                    value={selectedItems[n] || ''}
                    onChange={e => handleChangeMultiple(e, n)}

                  >
                    <option key={`select${n}`} value={''} disabled>
                      {'Elegir...'}
                    </option>
                    {items.map((item, itemIndex) => (
                      <option key={item.itemID} value={itemIndex}>
                        {item.itemName}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            }
          </>
        )
      }
    </Grid>
  )
}

export default SelectActivity;
