import React from 'react';

import { addMonths, isValid, formatDistance, } from 'date-fns';

import {TableCell, TableRow, FormControlLabel, Checkbox, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import { push } from 'connected-react-router';

import {getProjectState, getProjectFinancingLine, getProjectFinancingSource, } from '../../Utils/Functions/ProjectHelperFunctions';

const ProjectsListTableRow = ({project, userInfo, }) => {

  const dispatch = useDispatch();

  const goProjectDetails = () => {
    dispatch(push(`/project/${project.id}/detailsP`));
  }

  const projectDate = new Date(project.startDate);
  const validityTime = addMonths(projectDate, project.validityTime);
  const implementationTime = addMonths(projectDate, project.implamentationTime);
  let leftTime = null;
  let months = 0;
  if (implementationTime) {
    const today = new Date();
    months = (implementationTime.getFullYear() - today.getFullYear()) * 12;
    months -= today.getMonth();
    months += implementationTime.getMonth();
    leftTime = months <= 0 ? 0 : months;
  }

  return (
    <TableRow hover onClick={goProjectDetails}>
      <TableCell>{project.projectCode}</TableCell>
      <TableCell>{project.name}</TableCell>
      <TableCell>{project.financingSource?.name || project.financeSource}</TableCell>
      {
        userInfo.role === 'admin' &&
        <TableCell>{project.resolutionCode}</TableCell>
      }
      <TableCell>{`${projectDate.getDate()}-${projectDate.getMonth()+1}-${projectDate.getFullYear()}`}</TableCell>
      <TableCell>{isValid(implementationTime) &&
        `${implementationTime.getDate()}-${implementationTime.getMonth()+1}-${implementationTime.getFullYear()}`}</TableCell>
      <TableCell>{project.implamentationTime}</TableCell>
      <TableCell>{leftTime}</TableCell>
      {
        userInfo.role === 'admin' &&
        <>
          <TableCell>{isValid(validityTime) && `${validityTime.getDate()}-${validityTime.getMonth()+1}-${validityTime.getFullYear()}`}</TableCell>
          <TableCell>{project.usachCode}</TableCell>
        </>
      }
      <TableCell>{project.status?.name || project.status}</TableCell>
    </TableRow>
  )
}

export default ProjectsListTableRow;
