const initialState={
  show: false,
  type: 'create',
  userForm: {
    name: '',
    lastname: '',
    email: '',
    role: '',
    admin: 0
  }
}

const userFormModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showUserFormModal':
      return {
        ...state,
        show: true,
        type: action.payload.type,
        userForm: action.payload.type === 'edit' ? action.payload.userForm : state.userForm
      }
    case 'closeUserFormModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default userFormModalReducer;
