import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid, } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  notFounCont: {
    marginTop: '15%'
  }
}));

const NotFound = () => {

  const classes = useStyles();

  return (
    <div className={classes.notFounCont}>
      <Grid container justify="center">
        <Typography variant="h2">
          Página no encontrada
        </Typography>
      </Grid>
    </div>
  )
}

export default NotFound;
