import React, {useState, useEffect, } from 'react';

import {Dialog, DialogContent, DialogActions, DialogTitle, Button, Grid, Typography, FormControl, InputLabel, Select, MenuItem, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleIndicatorModal, getIndicatorData, setLoading, deleteIndicatorFile, } from '../../../../redux/actions';

import clientAxios from '../../../../config/axios';

import Indicator from './IndicatorTabs/Indicator';
import IndicatorTabs from './IndicatorTabs/IndicatorTabs';

import alert from '../../../Utils/Alert/Alert';

import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../Utils/Buttons/SecondaryButton';
import TertiaryButton from '../../../Utils/Buttons/TertiaryButton';

const ProjectDetailsIndicatorModal = ({project, generalPurposesData, projectIndicatorModal, userData, }) => {

  const dispatch = useDispatch();

  const {show, type, indicatorId, indicator} = projectIndicatorModal;
  const {userInfo, accessToken} = userData;

  const [indicatorForm, setIndicatorForm] = useState({
    indicatorName: '',
    calculationFormula: '',
    baseLine: '',
    indicatorGoal: [],
    files: [],
    availableFiles: []
  });
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (show && type !== 'create') {
      dispatch(getIndicatorData(indicatorId));
    }
  }, [show]);

  useEffect(() => {
    const availableFiles = indicator.files?.map(file => file.available);
    const goals = indicator.indicatorGoal?.sort((a, b) => a.year - b.year).map(goal => goal.goal);
    setIndicatorForm({...indicator, availableFiles: availableFiles, indicatorGoals: goals});
  }, [indicator]);

  const handleClose = () => dispatch(handleIndicatorModal());

  const handleChange = (e) => {
    setIndicatorForm(indicatorForm => ({...indicatorForm, [e.target.name]: e.target.value}));
  }

  const handleDelete = () => {
    const deleteItem = () => {
      dispatch(setLoading(true));
      dispatch(handleIndicatorModal());
      dispatch(setLoading(false));
    }
    const alertData = {
      title: `Seguro que desea eliminar este indicador?`,
      text: 'No se podrá recuperar',
      acceptFunction: deleteItem
    }
    alert(alertData);
  }

  const addFileToIndicator = (file, fileType) => {
    const indicatorFormCopy = {...indicatorForm};
    const indicatorFiles = [...indicatorForm.files || []];
    const fileData = {
      file: file,
      fileType: fileType,
      active: Math.random() < 0.5
    }
    indicatorFiles.push(fileData);
    indicatorFormCopy.files = indicatorFiles;
    setIndicatorForm(indicatorFormCopy);
  }

  const deleteFile = (fileId, reason) => {
    dispatch(deleteIndicatorFile(fileId, reason, 3, indicator.id));
  }

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h5" style={{marginTop: 16, marginRight: 20}}>
          {`Indicador de Objetivo`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {
          type === 'create' ?
          <Indicator type={type} generalPurposesData={generalPurposesData} indicator={indicator} indicatorForm={indicatorForm} setIndicatorForm={setIndicatorForm}
            handleChange={handleChange} files={files} show={show} project={project} handleClose={handleClose}
          />
          :
          <IndicatorTabs indicator={indicator} type={type} indicatorForm={indicatorForm} setIndicatorForm={setIndicatorForm} handleChange={handleChange}
            addFileToIndicator={addFileToIndicator} deleteFile={deleteFile} files={files} show={show} handleClose={handleClose} project={project}
          />
        }
      </DialogContent>
      {/*<DialogActions>
        <Grid container justify="flex-end">
          <SecondaryButton title={'Cerrar'} variant="secondary" onClick={handleClose} />
          {
            type === 'create' &&
            <PrimaryButton title={'Crear Indicador'} />
          }
        </Grid>
      </DialogActions>*/}
    </Dialog>
  )
}

export default ProjectDetailsIndicatorModal;
