import React, {useEffect} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import {getCurrentUser, } from '../redux/actions';

import LoginContainer from '../containers/LoginContainer/LoginContainer';
import Loader from '../components/Utils/Loader/Loader';

const UserSelector = createSelector(
  state => state.user,
  user => user
)

const Auth = ({children, }) => {

  const dispatch = useDispatch();

  const userData = useSelector(UserSelector);
  const userInfo = userData.userInfo;

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  if (localStorage.getItem('token')) {
    return (
      <>
        {children}
      </>
    )
  }
  else {
    return (
      <LoginContainer />
    )
  }

}

export default Auth;
