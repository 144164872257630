import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {TableCell, TableRow, TextField, Button, FormControl, MenuItem, Select, InputLabel, } from '@material-ui/core';
// import {Button, InputGroup, FormControl, Form, } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 250,
  },
  fields: {
    width: 250
  }
}));

const CreateProjectFormTeamTableRow = ({user, handleChange, removeUser, userIdx, roles, }) => {

  const classes = useStyles();

  return (
    <TableRow key={user.id}>
      {/*row.rowFields.map((field, n) =>
        <>
          {
            n === 3 ?
            <TableCell key={`field${n}`}>y
              <Form.Group>
                <Form.Control value={field} as="select" onChange={e => handleTableChanges(idx, n, e)}>
                  <option value='admin'>Administrador</option>
                  <option value='supervisor'>Supervisor de Proyecto</option>
                  <option value='gestor'>Gestor de Proyecto</option>
                  <option value='gestorFinanciero'>Gestor Financiero</option>
                  <option value='coordinador'>Coordinador de Proyecto</option>
                  <option value='observador'>Observador</option>
                </Form.Control>
              </Form.Group>
            </TableCell>
            :
            <TableCell key={`field${n}`}>
              <InputGroup>
                <FormControl
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  defaultValue={field}
                  onChange={e => handleTableChanges(idx, n, e)}
                />
              </InputGroup>
            </TableCell>
          }
        </>
      )*/}
      <TableCell>
        {user.name}
        {/*<TextField
          name="name"
          label="Nombre"
          variant="outlined"
          value={user.name}
          className={classes.fields}
          onChange={e => handleChange(e, userIdx)}
          disabled
        />*/}
      </TableCell>
      <TableCell>
        {user.rut}
        {/*<TextField
          name="rut"
          label="Rut"
          variant="outlined"
          value={user.rut}
          className={classes.fields}
          onChange={e => handleChange(e, userIdx)}
          disabled
        />*/}
      </TableCell>
      <TableCell>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">Rol</InputLabel>
          <Select
            name="role"
            value={user.role}
            onChange={e => handleChange(e, userIdx)}
            label="Age"
          >
            {
              roles.map(role =>
                <MenuItem value={role.id}>{role.name}</MenuItem>
              )
            }
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        {user.email}
        {/*<TextField
          name="email"
          label="Correo"
          variant="outlined"
          value={user.email}
          className={classes.fields}
          onChange={e => handleChange(e, userIdx)}
          disabled
        />*/}
      </TableCell>
      <TableCell>
        <Button
          onClick={() => removeUser(userIdx)}
        >
          -
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default CreateProjectFormTeamTableRow;
