import React from 'react';

import AppNavBarContainer from '../containers/AppNavBarContainer/AppNavBarContainer';
import Loader from '../components/Utils/Loader/Loader';
import Notification from '../components/Utils/Notification/Notification';

import './Layout.css';

const Layout = ({children, }) => {
  return(
    <>
      <AppNavBarContainer />

      <div className='pageBody'>
        {children}
      </div>
    </>
  )
}

export default Layout;
