import React, {useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, Typography, Box, Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {editProject, editProjectUsers, editProjectObjectives, } from '../../../redux/actions';

import CreateProjectFormHeader from '../CreateProjectForm/CreateProjectFormHeader/CreateProjectFormHeader';
import CreateProjectAssignUsers from '../CreateProjectForm/CreateProjectAssignUsers/CreateProjectAssignUsers';
import CreateProjectFormObjectives from '../CreateProjectForm/CreateProjectFormHeader/CreateProjectFormObjectives';
import ProjectState from './ProjectState/ProjectState';

import useForm from '../../FormValidator/formValidator';

import PrimaryButton from '../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../Utils/Buttons/SecondaryButton';
import FilterComponent from '../../Utils/FilterComponent/FilterComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const validationStateSchema = {
  name: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  projectCode: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  financingSourceId: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  // tel_T: {
  //   validator: {
  //     regEx: /^[0-9]$/,
  //     error: 'Invalid last name format.',
  //   },
  // },
};

const CreateProjectTabs = ({project, projectForm, handleChangeProjectForm, handleProjectForm, setProjectForm, handlePickDate, createProject,
  financingSources, roles, projectStatusList, }) => {
    console.log(project);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);
  const [disableObjectives, setDisableObjectives] = useState(true);

  useEffect(() => {
    const generalPurposes = [...projectForm.generalPurposes];
    const namesGP = generalPurposes.every(generalPurpose => generalPurpose.name);
    if (namesGP) {
      const specificPurposes = [].concat(...generalPurposes.map(purpose => purpose.specificPurposes));
      const namesSP = specificPurposes.every(specificPurpose => specificPurpose.name);
      setDisableObjectives(!namesSP);
    }
    else {
      setDisableObjectives(true);
    }
  }, [projectForm.generalPurposes]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const editInfo = () => {
    const newForm = {
      ...projectForm,
    }
    delete newForm.financingSource;
    dispatch(editProject(projectForm.id, newForm));
  }

  const editObjectives = () => {
    const purposes = [...projectForm.generalPurposes];
    const newGeneralPurposes = purposes.filter(purpose => purpose.new);
    const getEditedGP = purposes.filter(purpose => purpose.edit && !purpose.new);
    const editedGeneralPurposes = getEditedGP.map(generalPurpose => ({id: generalPurpose.id, name: generalPurpose.name}));
    const deletedGeneralPurposes = projectForm.deletedGeneralPurposes;
    const oldGeneralPurposes = purposes.filter(purpose => !purpose.new);
    const specificPurposesFOGP = [].concat(...oldGeneralPurposes.map(purpose => purpose.specificPurposes));
    const newSpecificPurposes = specificPurposesFOGP.filter(specificPurpose => specificPurpose.new);
    const getEditedSP = specificPurposesFOGP.filter(specificPurpose => !specificPurpose.new && specificPurpose.edit);
    const editedSpecificPurposes = getEditedSP.map(specificPurpose => ({id: specificPurpose.id, name: specificPurpose.name}));
    const deletedSpecificPurposes = projectForm.deletedSpecificPurposes;
    const data = {
      newGeneralPurposes: newGeneralPurposes,
      editedGeneralPurposes: editedGeneralPurposes,
      deletedGeneralPurposes: deletedGeneralPurposes || [],
      newSpecificPurposes: newSpecificPurposes,
      editedSpecificPurposes: editedSpecificPurposes,
      deletedSpecificPurposes: deletedSpecificPurposes || []
    }
    dispatch(editProjectObjectives(projectForm.id, data));
    setProjectForm({...projectForm, deletedGeneralPurposes: [], deletedSpecificPurposes: []});
    // console.log(newSpecificPurposes);
    // console.log(editedSpecificPurposes);
    // console.log(specificPurposesFOGP);
    // console.log(projectForm.generalPurposes);
  }

  const editUsers = () => {
    const prevUsers = [...project.currentAssignedUsers];
    const currentUsers = [...projectForm.currentAssignedUsers];

    // console.log(project.users);
    // const currentSubTasks = [...paymentSubTasks];
    const newUsers = currentUsers.filter(user => prevUsers.findIndex(prevUs => prevUs.id === user.id) < 0);
    const deletedUsers = prevUsers.filter(user => currentUsers.findIndex(currUs => currUs.id === user.id) < 0);
    const editedUsers = prevUsers.filter(user => currentUsers.findIndex(currUs => currUs.id === user.id) >= 0 && user.edited);
    console.log(newUsers);
    console.log(deletedUsers);
    console.log(editedUsers);
    const newUsersIds = newUsers.map(user => ({
      userId: user.id,
      rolePermissionId: user.role
    }));
    const deletedUsersIds = deletedUsers.map(user => ({
      userId: user.id,
      rolePermissionId: user.role
    }));
    const editedUsersIds = editedUsers.map(user => ({
      userId: user.id,
      rolePermissionId: user.role
    }));
    dispatch(editProjectUsers(projectForm.id, {
      newUsers: newUsersIds,
      editedUsers: editedUsersIds,
      deletedUsers: deletedUsersIds
    }));
  }

  const {disabled} = useForm(projectForm, validationStateSchema);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <FilterComponent permission={"project.generalData.update"}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="fullWidth"
          >
              <Tab label="Datos Generales" {...a11yProps(0)} />
              <Tab label="Estado del Proyecto" {...a11yProps(1)} />
              <Tab label="Objetivos del Proyecto" {...a11yProps(2)} />
              <Tab label="Usuarios Asignados" {...a11yProps(3)} />
          </Tabs>
        </FilterComponent>
      </AppBar>

      <TabPanel value={value} index={0}>
        <FilterComponent permission={"project.generalData.update"}>
          <CreateProjectFormHeader projectForm={projectForm} handleChangeProjectForm={handleChangeProjectForm} handleProjectForm={handleProjectForm}
            setProjectForm={setProjectForm} startDate={projectForm.startDate} handlePickDate={handlePickDate} financingSources={financingSources}
          />
          <Grid container justify="center">
            {/*<div className={classes.buttons}>
              <SecondaryButton title={'Borrar proyecto'}/>
            </div>*/}
            <div className={classes.buttons}>
              <PrimaryButton title={'Guardar cambios'} onClick={editInfo} disabled={disabled}/>
            </div>
          </Grid>
        </FilterComponent>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <FilterComponent permission={"project.state.update"}>
          <ProjectState project={projectForm} projectStatusList={projectStatusList} />
        </FilterComponent>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <FilterComponent permission={"project.objetives.update"}>
          <CreateProjectFormObjectives projectForm={projectForm} setProjectForm={setProjectForm} type={'edit'} />
          <Grid container justify="center">
            <div className={classes.buttons}>
              <PrimaryButton title={'Guardar cambios'} onClick={editObjectives} disabled={disableObjectives}/>
            </div>
          </Grid>
        </FilterComponent>
      </TabPanel>

      <TabPanel value={value} index={3}>
        <FilterComponent permission={"project.users.update"}>
          <CreateProjectAssignUsers projectForm={projectForm} setProjectForm={setProjectForm} roles={roles}/>
          <Grid container justify="center">
            <div className={classes.buttons}>
              <PrimaryButton title={'Guardar cambios'} onClick={editUsers}/>
            </div>
          </Grid>
        </FilterComponent>
      </TabPanel>
    </div>
  );
}

export default CreateProjectTabs;
