import React from 'react';

import { makeStyles, } from '@material-ui/core/styles';
import {Card, Table, } from 'react-bootstrap';
import {Grid, Typography, CardContent, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 50,
    fontSize: 28,
    fontWeight: 'bold'
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
}));

const ProjectGoalDetailsShow = ({itemForm, }) => {

  const classes = useStyles();

  return (
    <CardContent>
      <Typography variant="h5" className={classes.title}>
        {itemForm.name}
      </Typography>
      <div className={classes.dates}>
        <Typography className={classes.miniTitle}>
          {`Fecha de Cumplimiento:`}
        </Typography>
        <Typography >
          {`Mes ${itemForm.complianceDate}`}
        </Typography>
      </div>
    </CardContent>
  )
}

export default ProjectGoalDetailsShow;
