import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
// import {TextField, } from '@material-ui/core';

import TextField from '../../../Utils/TextFields/TextField';

const useStyles = makeStyles((theme) => ({
  textFields: {
    margin: '30px 15px 30px 15px',
    width: '90%'
  }
}));

const CreateProjectFormGeneral = ({projectForm, setProjectForm, handleChangeProjectForm, addSpecificObjective, handleSpecificObjectives, }) => {

  const classes = useStyles();

  return (
    <TextField
      name="description"
      label="Descripción del Proyecto"
      variant="outlined"
      multiline
      rows={3}
      value={projectForm.description}
      onChange={handleChangeProjectForm}
      classStyle={classes.textFields}
    />
  )
}

export default CreateProjectFormGeneral;
