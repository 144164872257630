import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, Button, ButtonGroup, TextField, Typography, } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import FilesManager from '../../../../../Utils/FilesManager/FilesManager';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  textFields: {
    width: '70%'
  },
  form: {
    marginTop: 30,
    marginBottom: 30
  },
  datesInfo: {
    marginBottom: 20
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
}));

const ExtensionForm = ({project, extensionForm, handleChange, edit, type, }) => {

  const classes = useStyles();

  const [validityTime, setValidityTime] = useState(new Date());
  const [implamentationTime, setImplamentationTime] = useState(new Date());

  useEffect(() => {
    const startDate = new Date(project.startDate);
    startDate.setMonth(parseInt(startDate.getMonth())+parseInt(extensionForm.validityTime));
    setValidityTime((!isNaN(startDate.getDate()) && startDate) || new Date());
  }, [extensionForm.validityTime, project.startDate]);

  useEffect(() => {
    const startDate = new Date(project.startDate);
    startDate.setMonth(parseInt(startDate.getMonth())+parseInt(extensionForm.implamentationTime));
    setImplamentationTime((!isNaN(startDate.getDate()) && startDate) || new Date());
  }, [extensionForm.implamentationTime, project.startDate]);

  return (
    <>
      <div className={classes.form}>
      {
        edit ?
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
              <Grid item xs={6}>
                <div className={classes.datesInfo}>
                  <Typography>
                    {`Anterior tiempo de ejecución: 36 meses`}
                  </Typography>
                  <Typography>
                    {`Anterior fecha de término de ejecución: 26/01/2024`}
                  </Typography>
                </div>
                <Grid container>
                  <Grid item xs={6}>
                    <TextField
                      name="implamentationTime"
                      label="Nuevo tiempo de Ejecución"
                      value={extensionForm.implamentationTime}
                      onChange={handleChange}
                      className={classes.textFields}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Nueva fecha de término de ejecución:
                    </Typography>
                    <Typography>
                      {`${implamentationTime.getDate()}/${implamentationTime.getMonth()+1}/${implamentationTime.getFullYear()}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.datesInfo}>
                  <Typography>
                    {`Anterior tiempo de vigencia: 36 meses`}
                  </Typography>
                  <Typography>
                    {`Anterior fecha de término de vigencia: 26/01/2024`}
                  </Typography>
                </div>
                <Grid container>
                  <Grid item xs={6}>
                    <TextField
                      name="validityTime"
                      label="Nuevo tiempo de Vigencia"
                      value={extensionForm.validityTime}
                      onChange={handleChange}
                      className={classes.textFields}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      Nueva fecha de término de vigencia:
                    </Typography>
                    <Typography>
                      {`${validityTime.getDate()}/${validityTime.getMonth()+1}/${validityTime.getFullYear()}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        :
        <Grid container direction="row" justify="space-around" alignItems="center">
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Fecha de término de ejecución:`}
            </Typography>
            <Typography >
              {`${extensionForm.implamentationTime || 1}`}
            </Typography>
          </div>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Fecha de término de vigencia:`}
            </Typography>
            <Typography>
              {`${extensionForm.validityTime || 3}`}
            </Typography>
          </div>
        </Grid>
      }
      </div>
    </>
  )
}

export default ExtensionForm;
