import React from 'react';

import {Dialog, DialogContent, DialogActions, DialogTitle, Button, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleHistoryRecordModal, } from '../../../redux/actions';

import HistoryRecordTable from './HistoryRecordTable/HistoryRecordTable';

const HistoryRecordModal = ({historyRecordModal, }) => {

  const dispatch = useDispatch();

  const {show, historyData} = historyRecordModal;

  const handleClose = () => dispatch(handleHistoryRecordModal());

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        Historial
      </DialogTitle>
      <DialogContent>
        <HistoryRecordTable historyData={historyData.budgetRecords || []} showDownloadButton={true}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default HistoryRecordModal;
