import React, {useState, } from 'react';

import clientAxios from '../../../../../config/axios';
import {uploadSingleFileAxios, uploadFileAxios, } from '../../../../../config/manageFileAxios';

import { makeStyles } from '@material-ui/core/styles';
import {Typography, Button, Accordion, AccordionSummary, AccordionDetails, Grid, Fab, Tooltip, Card, CardContent, } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useDispatch, } from 'react-redux';
import {handleNotifications, handleSubTaskModal, handleTaskModal, setLoading, } from '../../../../../redux/actions';

import ProjectDetailsSubtasksTable from './ProjectDetailsSubtasksTable/ProjectDetailsSubtasksTable';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '0.5px solid grey',
    marginBottom: '10px'
  },
  title: {
    fontSize: 18,
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
}));

const ProjectDetailsTasks = ({activityId, changed, setChanged, task, taskIdx, activityIdx, addTaskToActivity, addFilesToTask, addSubtaskToTask,
  projectPeriods, }) => {

  const dispatch = useDispatch();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const taskDate = new Date(task.date);
  // taskDate.setTime(taskDate.getTime() + 5*60*60*1000);

  const addFiles = (file, type) => {
    addFilesToTask(activityIdx, taskIdx, file, type);
    dispatch(handleNotifications(true, {
      status: 'success',
      message: 'Archivo agregado correctamente'}
    ));
  }

  const editTask = async (task) => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const taskData = {
        ...task,
        activityId: activityId,
        userId: userData.userId
      }
      const {data} = await clientAxios().put(`/api/task/${task.id}/edit`, taskData);
      setChanged(changed => !changed);
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Tarea editada correctamente'}
      ));
    } catch (e) {
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al editar la tarea'}
      ));
      dispatch(setLoading());
    }
  }

  const deleteTask = async () => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().delete(`/api/task/${task.id}`);
      dispatch(handleTaskModal());
      setChanged(changed => !changed);
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Tarea eliminada correctamente'}
      ));
    } catch (e) {
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al eliminar la tarea'}
      ));
      dispatch(setLoading());
    }
  }

  const openEditTask = () => {
    dispatch(handleTaskModal(true, 'edit', {
      activityIdx: activityIdx,
      task: {...task, expenseTypeId: task.expenseType?.id, academicUnitId: task.academicUnit?.id || 1, date: `${taskDate.getFullYear()}-${(taskDate.getMonth()+1) < 10 ? '0' + (taskDate.getMonth()+1) : taskDate.getMonth()+1}-${taskDate.getDate() < 10 ? '0' + (taskDate.getDate()) : taskDate.getDate()}`},
      handleTask: editTask,
      deleteTask: deleteTask
    }));
  }

  const addSubtask = async (subTask) => {
    // addSubtaskToTask(activityIdx, taskIdx, subTask);
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      console.log(subTask);
      let subTaskData = {
        ...subTask,
        userId: userData.userId,
        hasDocument: subTask.file?.name ? true : false,
      }
      if (subTask.period?.id >= 0) {
        subTaskData = {...subTaskData, periodId: subTask.period?.id};
      }
      const subTaskFile = subTaskData.file;
      delete subTaskData.file;
      delete subTaskData.files;
      delete subTaskData.period;
      delete subTaskData.canBeInventoryable;
      delete subTaskData.rendido;
      const {data} = await uploadSingleFileAxios(subTaskData, subTaskFile, `/api/task/subtask/add`, 'post');
      setChanged(changed => !changed);
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Subtarea editada correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al agregar la subtarea'}
      ));
      dispatch(setLoading());
    }
  }

  const addNewSubTask = () => {
    dispatch(handleSubTaskModal(true, 'create', {
      taskId: task.id,
      taskType: task.taskType,
      subTask: {
        description: '',
        period: 'none',
        subtaskTypeId: 1,
        file: {},
        files: [],
        amount: 0,
        inventoryable: false,
        canBeInventoryable: task.expenseType?.id === 1,
        taskId: task.id,
        period: {
          id: -1
        }
      },
      handleSubTask: addSubtask
    }));
  }

  const editSubtaskFile = async (subTaskData, file) => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const subTaskD = {
        ...subTaskData,
        userId: userData.userId
      }
      const {data} = await uploadSingleFileAxios(subTaskD, file, `/api/task/subtask/edit`, 'put');
      dispatch(handleSubTaskModal());
      setChanged(changed => !changed);
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivo de la subtarea editada correctamente'}
      ));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al agregar el archivo a la subtarea'}
      ));
      dispatch(setLoading());
    }
  }

  const editSubtask = async (subTask) => {
    // addSubtaskToTask(activityIdx, taskIdx, subTask);
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      let subTaskData = {
        ...subTask,
        userId: userData.userId,
        taskId: task.id
      }
      if (subTask.period?.id && subTask.period.id > -1) {
        subTaskData = {...subTaskData, periodId: subTask.period?.id};
      }
      // else {
      //   delete subTaskData.periodId;
      //   console.log(subTaskData);
      //   subTaskData = {...subTaskData, periodId: null};
      // }
      const {data} = await uploadSingleFileAxios(subTaskData, null, `/api/task/subtask/edit/info`, 'put');
      // const {data} = await clientAxios().put(`/api/task/subtask/edit/info`, subTaskData);
      dispatch(handleSubTaskModal());
      setChanged(changed => !changed);
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Subtarea editada correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al agregar la subtarea'}
      ));
      dispatch(setLoading());
    }
  }

  const deleteSubTask = async (subtaskId, reason) => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const dataRequest = {
        reason: reason,
        userId: userData.userId
      }
      const {data} = await clientAxios().delete(`/api/task/subtask/${subtaskId}/delete`, {data: dataRequest});
      // const {data} = await clientAxios().delete(`/api/milestone/deleteFile/${fileId}`, {data: dataRequest});
      dispatch(handleSubTaskModal());
      setChanged(changed => !changed);
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Subtarea eliminada correctamente'}
      ));
    } catch (e) {
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al eliminar la subtarea'}
      ));
      dispatch(setLoading());
    }
  }

  const openSubTaskModal = (subTask) => {
    const periodFind = projectPeriods.find(period => period.id === subTask.periodId);
    dispatch(handleSubTaskModal(true, 'edit', {
      taskIdx: taskIdx,
      taskType: task.taskType,
      subTask: {
        ...subTask,
        canBeInventoryable: task.expenseType?.id === 1,
        subtaskTypeId: subTask.subTaskType?.id || 1,
        taskId: task.id,
        period: periodFind ? periodFind : {id: -1}
      },
      handleSubTask: editSubtask,
      editSubtaskFile: editSubtaskFile,
      deleteSubTask: deleteSubTask
    }));
  }

  return (
    <Card style={{marginBottom: 20}}>
      <CardContent>
        <Grid container>
          <Grid item xs={10} lg={10}>
            <Typography gutterBottom variant="h5" component="h2">
              {task.name}
            </Typography>
          </Grid>
          <Grid item xs={2} lg={2}>
            <Grid container justify="flex-end">
              <FilterComponent permission={'task.update'}>
                <Tooltip title="Administrar Tarea">
                  <Fab size="small" onClick={openEditTask} color="primary" style={{backgroundColor: '#B1B1B1'}}>
                    <EditIcon />
                  </Fab>
                </Tooltip>
              </FilterComponent>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
      <Grid container className={classes.section}>
        <Grid item xs={4} lg={4}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Código Memo:`}
            </Typography>
            <Typography >
              {`${task.memoCode}`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={4} lg={4}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Código DDI:`}
            </Typography>
            <Typography >
              {`${task.ddiCode}`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={4} lg={4}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Fecha:`}
            </Typography>
            <Typography >
              {taskDate && `${taskDate.getDate()}/${taskDate.getMonth()+1}/${taskDate.getFullYear()}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.section}>
        <Grid item xs={4} lg={4}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Monto Máximo:`}
            </Typography>
            <Typography >
              {`${task.maxAmount || 0}`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={4} lg={4}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Nombre de Solicitante:`}
            </Typography>
            <Typography >
              {`${task.applicantName}`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={4} lg={4}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Tipo de Gasto:`}
            </Typography>
            <Typography >
              {`${task.expenseType?.name}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.section}>
        <Grid item xs={6} lg={6}>
        <div className={classes.dates}>
          <Typography className={classes.miniTitle}>
            {`Unidad académica:`}
          </Typography>
          <Typography >
            {`${task.academicUnit?.name}`}
          </Typography>
        </div>
        </Grid>
      </Grid>
      <Grid container className={classes.section}>
        <Grid item xs={6} lg={6}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Observaciones:`}
            </Typography>
            <Typography >
              {`${task.observation || '-'}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
        {/*<Grid container direction="row" justify="space-around" alignItems="center">
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Tipo de tarea:`}
            </Typography>
            <Typography >
              {`${task.taskType}`}
            </Typography>
          </div>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Unidad requirente:`}
            </Typography>
            <Typography >
              {`${task.academicTeam}`}
            </Typography>
          </div>
        </Grid>*/}
      </CardContent>
      <FilterComponent permission={'subtask.index'}>
        <Accordion >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <Typography>
              Subtareass
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{display: 'block'}}>
            <CardContent style={{paddingTop: 0, paddingBottom: 0}}>
              <FilterComponent permission={'subtask.create'}>
                <Grid container justify="flex-end">
                  <Tooltip title="Agregar Subtarea">
                    <Fab size="small" onClick={addNewSubTask} color="primary" style={{backgroundColor: '#EA7600'}}>
                      <AddIcon />
                    </Fab>
                  </Tooltip>

                  {/*<PeriodMenu openCreatePeriodModal={openCreatePeriodModal} openEditPeriodModal={openEditPeriodModal}/>*/}
                </Grid>
              </FilterComponent>
            </CardContent>
            <CardContent>
              <ProjectDetailsSubtasksTable subTasks={task.subTasks.sort((a,b) => a.id - b.id).filter(subTask => subTask.available) || []}
                openSubTaskModal={openSubTaskModal} projectPeriods={projectPeriods}
              />
            </CardContent>
          </AccordionDetails>
        </Accordion>
      </FilterComponent>
    </Card>
  )
}

export default ProjectDetailsTasks;
