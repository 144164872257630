import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {UserSelector, ProjectsSelector, } from '../../redux/selectors';

import ProjectsList from '../../components/ProjectsList/ProjectsList';

const ProjectsListContainer = () => {

  const userData = useSelector(UserSelector);
  const userInfo = userData.userInfo;

  const projectsData = useSelector(ProjectsSelector);

  return (
    <ProjectsList userData={userData} userInfo={userInfo} projects={projectsData.projects} />
  )
}

export default ProjectsListContainer;
