const initialState={
  loading: true,
  userInfo: {
    name: '',
    email: '',
    role: 'normal',
    username: '',
    roles: []
  },
  accessToken: ''
}

const userReducer = (state = initialState, action) => {
  switch(action.type){
    case 'loginUser':
      return {
        ...state,
        userInfo: {
          id: action.payload.id,
          name: action.payload.name,
          username: action.payload.username,
          email: action.payload.email,
          role: action.payload.role,
          roles: action.payload.roles
        },
        userStatus: 'logged',
        accessToken: action.payload.accessToken
      }
    case 'logoutUser':
      return {
        ...state,
        userInfo: initialState.userInfo,
        userStatus: 'logout'
      }
    case 'changeAdmin':
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          role: state.userInfo.role === 'admin' ? 'normal' : 'admin'
        }
      }
    default:
      return state;
  }
}

export default userReducer;
