export const handleProjectDataEditModal = (show, projectInfo) => {
  if (show) {
    return dispatch => dispatch(showDataEditModal(projectInfo))
  }
  else {
    return dispatch => dispatch(closeDataEditModal())
  }
}

const showDataEditModal = (projectInfo) => ({
  type: 'showDataEditModal',
  payload: {
    projectInfo: projectInfo
  }
})

const closeDataEditModal = () => ({
  type: 'closeDataEditModal',
  payload: {}
})
