import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import ProjectDetailProposal from '../../components/ProjectDetailsProposal/ProjectDetailProposal';

const ProjectsSelector = createSelector(
  state => state.projects,
  projects => projects
);

const SectionModalSelector = createSelector(
  state => state.sectionModal,
  sectionModal => sectionModal
);

const EditDataModalSelector = createSelector(
  state => state.projectDataEditModal,
  projectDataEditModal => projectDataEditModal
);

const ProjectContainer = () => {

  const projectsData = useSelector(ProjectsSelector);

  const sectionModalData = useSelector(SectionModalSelector);

  const editDataModal = useSelector(EditDataModalSelector);

  return (
    <ProjectDetailProposal projectsData={projectsData} sectionModalData={sectionModalData} editDataModal={editDataModal} />
  )

}

export default ProjectContainer;
