import React, {useState, useEffect} from 'react';

import clientAxios from '../../../../config/axios';
import {uploadFileAxios, } from '../../../../config/manageFileAxios';

import { useDispatch, } from 'react-redux';
import {handleFilesManagerModal, handleNotifications, setLoading, } from '../../../../redux/actions';

import FilesManagerTable from '../../../Utils/FilesManager/FilesManagerTable/FilesManagerTable';
import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';

import FilterComponent from '../../../Utils/FilterComponent/FilterComponent';

const ProjectDetailsDocumentation = ({project, active, userData, }) => {

  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const [changed, setChanged] = useState(false);

  useEffect(async () => {
    try {
      if (active && project.id) {
        dispatch(setLoading(true));
        const {data} = await clientAxios().get(`/api/project/${project.id}/backgroundDocuments/all`);
        const addData = data.map(file => ({...file, date: file.createdAt}));
        setFiles(addData);
        dispatch(setLoading());
      }
    } catch (e) {
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener los archivos'}
      ));
      dispatch(setLoading());
    }
  }, [project, active, changed]);

  const addFileToDocumentation = () => {
    const newFile = async (newFiles) => {
      try {
        dispatch(setLoading(true));
        const token = localStorage.getItem('token');
        const userData = JSON.parse(atob(token.split('.')[1]));
        const docData = {
          userid: userData.userId
        }
        const {data} = await uploadFileAxios(docData, newFiles, `/api/project/uploadBackgroundDocumentFiles/${project.id}`, 'post');
        setChanged(changed => !changed);
        dispatch(handleNotifications(true, {
          status: 'success',
          message: 'Archivo agregado correctamente'}
        ));
        dispatch(setLoading());
      } catch (e) {
        dispatch(handleNotifications(true, {
          status: 'error',
          message: 'Hubo un error al agregar el archivo'}
        ));
        dispatch(setLoading());
      }

    }
    const data = {
      title: 'Agregar archivo a antecedentes y documentación',
      files: files,
      addFile: newFile
    }
    dispatch(handleFilesManagerModal(true, data, 'multi'));
  }

  const deleteFile = async (fileId) => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().delete(`/api/project/backgroundDocument/${fileId}/delete`);
      setChanged(changed => !changed);
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivo eliminado correctamente'}
      ));
      dispatch(setLoading());
    } catch (e) {
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al eliminar el archivo'}
      ));
      dispatch(setLoading());
    }
  }

  return (
    <>
      <FilterComponent permission={'documentsAndBackground.upload'}>
        <PrimaryButton title={'Agregar archivos'} onClick={addFileToDocumentation} />
      </FilterComponent>
      <FilterComponent permission={'documentsAndBackground.index'}>
        <FilesManagerTable title={'Antecedentes y Documentación'} files={files} deleteFile={deleteFile} showDeleteButton={true}
          downloadPermission={'documentsAndBackground.download'} deletePermission={'documentsAndBackground.delete'}
        />
      </FilterComponent>
    </>
  )
}

export default ProjectDetailsDocumentation;
