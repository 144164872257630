import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {TextField, Grid, FormControl, InputLabel, Select, MenuItem, } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    margin: '1% 2% 1% 0%',
    display: 'flex'
  },
  select: {
    width: '300px'
  }
});

const ProjectDetailsActivitiesFilter = ({generalPurposesData, activitiesFilter, setActivitiesFilter, searchActivities, objectiveSelected, handleObjectiveSelected,
  allSpecificPurposes, milestones, handleMilestoneSelected, milestoneSelected, }) => {

  const classes = useStyles();

  const handleChangeActivitiesFilter = e => setActivitiesFilter(e.target.value);

  let specificPurposesIds = [];
  let milestonesIds = [];

  console.log(generalPurposesData.entities);

  if (generalPurposesData.entities && generalPurposesData.entities?.specificPurposes) {
    specificPurposesIds = Object.keys(generalPurposesData.entities?.specificPurposes);

    milestonesIds = objectiveSelected !== -1 ?
      generalPurposesData.entities?.specificPurposes[objectiveSelected].milestones :
      generalPurposesData.entities?.milestones ? Object.keys(generalPurposesData.entities?.milestones) : [];
  }

  return (
    <div className={classes.root}>
      <TextField id="outlined-basic" label="Buscar actividad" value={activitiesFilter} variant="outlined" onChange={handleChangeActivitiesFilter}/>
      <Grid container justify="flex-end" >
        <FormControl id="subTaskType" variant="outlined" className={classes.select}>
          <InputLabel id="subTaskType">Objetivo específico</InputLabel>
          <Select
            name="specificPurpose"
            label="Buscar por objetivo específico"
            value={objectiveSelected}
            onChange={handleObjectiveSelected}
          >
            <MenuItem value={-1}>Ver todos</MenuItem>
            {
              specificPurposesIds.map(specificPurposeId =>
                <MenuItem value={specificPurposeId}>{`${generalPurposesData.entities?.specificPurposes[specificPurposeId].name}`}</MenuItem>
              )
            }
          </Select>
        </FormControl>
        <FormControl id="subTaskType" variant="outlined" className={classes.select}>
          <InputLabel id="subTaskType">Hitos</InputLabel>
          <Select
            name="specificPurpose"
            label="Buscar por objetivo específico"
            value={milestoneSelected}
            onChange={handleMilestoneSelected}
          >
            <MenuItem value={-1}>Ver todos</MenuItem>
            {
              milestonesIds.map(milestoneId =>
                <MenuItem value={milestoneId}>{`${generalPurposesData.entities?.milestones[milestoneId].name}`}</MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Grid>
    </div>
  )
}

export default ProjectDetailsActivitiesFilter;
