import React, {useState, useEffect} from 'react';

import { uuid } from 'uuidv4';

import { makeStyles } from '@material-ui/core/styles';
import {Button, Grid, Typography, Card, CardActions, CardContent, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleInventoryModal, handleNotifications, handleFilesManagerModal, getGoodsFile, firstGoodsFile, saveGoodsFile, } from '../../../../../../redux/actions';

import ProjectDetailsInventoryTable from './ProjectDetailsInventoryTable/ProjectDetailsInventoryTable';
import FilesManagerTable from '../../../../../Utils/FilesManager/FilesManagerTable/FilesManagerTable';

import PeriodSelect from '../../../../../Utils/PeriodSelect/PeriodSelect';
import FilesManager from '../../../../../Utils/FilesManager/FilesManager';

import PrimaryButton from '../../../../../Utils/Buttons/PrimaryButton';
import TertiaryButton from '../../../../../Utils/Buttons/TertiaryButton';

import FilterComponent from '../../../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: '2% 3% 1% 3%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 12,
  },
});

const InventoryFiles = ({project, goodsFile, active, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (active && project.id) {
      dispatch(getGoodsFile(project.id));
    }
  }, [active, project.id]);

  const newFile = (file) => {
    dispatch(firstGoodsFile(project.id, file));
  }

  const editFile = (file, reason) => {
    dispatch(saveGoodsFile(project.id, file, reason));
  }

  const addFileToBudget = () => {
    const data = {
      title: 'Agregar archivo a inventario',
      files: goodsFile ? [goodsFile] : [],
      addFile: newFile
    }
    dispatch(handleFilesManagerModal(true, data, null, false));
  }

  const fileWithHistory = {...goodsFile, date: goodsFile?.updatedAt || goodsFile?.createdAt};

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <FilterComponent permission={'accumulatedInventory.upload'}>
            <Grid container justify="flex-start">
              <PrimaryButton title={'Agregar archivo'} onClick={addFileToBudget} />
            </Grid>
          </FilterComponent>
        </Grid>
      </Grid>
      <FilesManagerTable title={'Archivos de Inventario'} files={[fileWithHistory]} uploadType={'unique'}
        showReasons={true} showFilters={true} activeColumn={false} downloadPermission={'accumulatedInventory.download'}
      />
    </>
  )
}

export default InventoryFiles;
