import React, {useState, useEffect} from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import {Table, TableContainer, Paper, Button, } from '@material-ui/core';

import BudgetSummaryHeader from './BudgetSummaryHeader';
import BudgetSummaryTable from './BudgetSummaryTable';

import TertiaryButton from '../../../../Utils/Buttons/TertiaryButton';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    marginBottom: 10
  },
});



const BudgetSummaryTables = ({budgetSummary, setBudgetSummary, edit, deletedGreatAccounts, setDeletedGreatAccounts, deletedItems, setDeletedItems, }) => {

  const classes = useStyles();

  const [summary, setSummary] = useState({
    name: 'Total',
    total: 0
  });

  useEffect(() => {
    const budgetSummarySub = [].concat(...budgetSummary.map(field => field.items));
    const budgetSummaryTotal = Object.values(budgetSummarySub).reduce((t, {amount}) => t + (parseInt(amount) || 0), 0);
    setSummary(summary => ({...summary, total: budgetSummaryTotal}));
  }, [budgetSummary]);

  const handleChangeName = (e, fieldIdx) => {
    const budgetSummaryCopy = [...budgetSummary];
    budgetSummaryCopy[fieldIdx].name = e.target.value;
    budgetSummaryCopy[fieldIdx].edited = true;
    setBudgetSummary(budgetSummaryCopy);
  }

  const handleChange = (e, fieldIdx, detailIdx) => {
    const budgetSummaryCopy = [...budgetSummary];
    budgetSummaryCopy[fieldIdx].items[detailIdx][e.target.name] = e.target.value;
    budgetSummaryCopy[fieldIdx].items[detailIdx].edited = true;
    setBudgetSummary(budgetSummaryCopy);
  }

  const newCategory = () => {
    const budgetSummaryCopy = [...budgetSummary];
    budgetSummaryCopy.push({name: '', items: [], new: true});
    setBudgetSummary(budgetSummaryCopy);
  }

  const newSubcategory = (fieldIdx) => {
    const budgetSummaryCopy = [...budgetSummary];
    budgetSummaryCopy[fieldIdx].items.push({name: '', amount: 0, greatAccountId: budgetSummaryCopy[fieldIdx].id, new: true});
    setBudgetSummary(budgetSummaryCopy);
  }

  const deleteCategory = (fieldIdx) => {
    const budgetSummaryCopy = [...budgetSummary];
    const deletedCategory = budgetSummaryCopy.splice(fieldIdx, 1);
    const previousDeletedGreatAccounts = [...deletedGreatAccounts];
    previousDeletedGreatAccounts.push(...deletedCategory);
    const deletedGreatAccountsAux = previousDeletedGreatAccounts.filter(category => !category.new);
    setDeletedGreatAccounts(deletedGreatAccountsAux);
    setBudgetSummary(budgetSummaryCopy);
  }

  const deleteSubcategory = (fieldIdx, detailIdx) => {
    const budgetSummaryCopy = [...budgetSummary];
    const previousDeletedItems = [...deletedItems];
    const deletedItem = budgetSummaryCopy[fieldIdx].items.splice(detailIdx, 1);
    const deleteItemWID = {itemId: deletedItem[0].id, greatAccountId: budgetSummaryCopy[fieldIdx].id};
    previousDeletedItems.push(deleteItemWID);
    const deletedItemsAux = previousDeletedItems.filter(item => !item.new);
    setDeletedItems(deletedItemsAux);
    setBudgetSummary(budgetSummaryCopy);
  }

  return (
    <Paper style={{marginTop: 20}} elevation={3}>
      <TableContainer component={Paper}>
          {
            budgetSummary.map((field, fieldIdx) =>
              <BudgetSummaryTable field={field} fieldIdx={fieldIdx} edit={edit} handleChange={handleChange} newSubcategory={newSubcategory}
                deleteCategory={deleteCategory} deleteSubcategory={deleteSubcategory} handleChangeName={handleChangeName}
              />
            )
          }
          {
            edit &&
            <div style={{marginLeft: '40%', marginBottom: 30}}>
              <TertiaryButton variant="contained" color="secondary" onClick={newCategory} title={'Agregar Gran Cuenta'}/>
            </div>
          }
          <Table>
            <BudgetSummaryHeader field={summary} total={summary.total} show={false}/>
          </Table>
      </TableContainer>
    </Paper>
  )
}

export default BudgetSummaryTables;
