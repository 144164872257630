import React, {useState, useEffect, } from 'react';

import {Dialog, DialogContent, DialogActions, DialogTitle, Button, Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handlePaymentsModal, } from '../../../../redux/actions';

import PaymentsTabs from './PaymentsTabs/PaymentsTabs';
import PaymentsProofForm from '../../ProjectDetailsTabs/ProjectDetailsCapitulation/PaymentsProofs/PaymentsProofForms/PaymentsProofForm';

import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../Utils/FilterComponent/FilterComponent';

const ProjectDetailsPaymentsModal = ({project, projectPaymentsModal, subTasks, }) => {

  const dispatch = useDispatch();

  const {show, type, paymentData, handlePayment, handlePaymentSubTasks, handleDeletePayment} = projectPaymentsModal;

  const [paymentsProofForm, setPaymentsProofForm] = useState({
    file: {},
    subTasks: [],
    folioNumber: '0',
    registerNumber: '0',
    documentNumber: '0',
    amount: 0
  });
  const [reason, setReason] = useState('');
  const [changedFile, setChangedFile] = useState(false);
  const [accumulatedAmount, setAcummulatedAmount] = useState(0);

  useEffect(() => {
    if (show) {
      setPaymentsProofForm({...paymentData, file: type === 'create' ? {}
      :
      {
        date: paymentData.createdAt,
        name: paymentData.name,
        fileDownloadUri: paymentData.fileDownloadUri,
        filePath: paymentData.filePath,
        createdByUserName: paymentData.updatedByUserName || paymentData.createdByUserName
      }
      });
    }
  }, [show]);

  useEffect(() => {
    const fileName = paymentsProofForm.file?.name;
    if (fileName) {
      const fistDivision = fileName.split('_');
      if (fistDivision[2]) {
        const secondDivision = fistDivision[2].split('.');
        if (secondDivision[0]) {
          const thirdDivision = secondDivision[0].split('-');
          if (thirdDivision[1]) {
            const number = fistDivision[0];
            const registerNumber = thirdDivision[0];
            const folioNumber = thirdDivision[1];
            setPaymentsProofForm(paymentsProofForm => ({...paymentsProofForm, folioNumber: folioNumber, documentNumber: number, registerNumber: registerNumber}));
            setChangedFile(true);
          }
        }
      }
    }
  }, [paymentsProofForm.file]);

  useEffect(() => {
    if (paymentsProofForm.subTasks?.length > 0) {
      const subTasksAmounts = paymentsProofForm.subTasks.map(subTask => subTask.amount);
      const amount = subTasksAmounts.reduce((a, b) => parseInt(a) + parseInt(b));
      setAcummulatedAmount(amount);
    }
    else {
      setAcummulatedAmount(0);
    }
  }, [paymentsProofForm.subTasks]);

  const selectSubTask = (subTask) => {
    const paymentSubTasks = [...paymentsProofForm.subTasks];
    const findIndex = paymentSubTasks.findIndex(subTaskSelected => subTaskSelected.id === subTask.id);
    if (findIndex === -1) {
      paymentSubTasks.push(subTask);
    }
    else {
      paymentSubTasks.splice(findIndex, 1);
    }
    setPaymentsProofForm(paymentsProofForm => ({...paymentsProofForm, subTasks: paymentSubTasks}))
  }

  const handleChange = (e) => {
    setPaymentsProofForm(paymentsProofForm => ({...paymentsProofForm, [e.target.name]: e.target.value}));
  }

  const handleClose = () => dispatch(handlePaymentsModal());

  const addFile = (file) => setPaymentsProofForm(paymentsProofForm => ({...paymentsProofForm, file: file ? file : paymentsProofForm.file}));

  const handleReason = (e) => setReason(e.target.value);

  const handlePaymentForm = () => {
    handlePayment({...paymentsProofForm, reason: reason}, changedFile);
    setReason('');
    dispatch(handlePaymentsModal());
  }

  return (
    <>

    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'lg'}
      fullWidth={true}
    >
      <DialogTitle>
        {
          type === 'create' ?
          'Crear comprobante de Pago'
          :
          'Editar comprobante de pago'
        }
      </DialogTitle>
      <DialogContent>
        {
          type === 'create' ?
          <FilterComponent permission={'proofOfPayment.create'}>
            <PaymentsProofForm subTasks={subTasks} type={type} paymentsProofForm={paymentsProofForm} handleChange={handleChange} selectSubTask={selectSubTask}
              accumulatedAmount={accumulatedAmount} reason={reason} addFile={addFile}
            />
          </FilterComponent>
          :
          <FilterComponent permission={'proofOfPayment.show'}>
            <PaymentsTabs project={project} subTasks={subTasks} type={type} paymentsProofForm={paymentsProofForm} setPaymentsProofForm={setPaymentsProofForm}
              reason={reason} setReason={setReason} handleReason={handleReason} addFile={addFile} handlePaymentForm={handlePaymentForm} selectSubTask={selectSubTask}
              accumulatedAmount={accumulatedAmount} handleChange={handleChange} selectSubTask={selectSubTask} paymentData={paymentData}
              handlePaymentSubTasks={handlePaymentSubTasks} handleDeletePayment={handleDeletePayment}
            />
          </FilterComponent>
        }
      </DialogContent>
      <DialogActions>
        <SecondaryButton title={'Cerrar'} onClick={handleClose} />
          {
            type === 'create' &&
            <FilterComponent permission={'proofOfPayment.create'}>
              <PrimaryButton title={'Crear comprobante'} onClick={handlePaymentForm}/>
            </FilterComponent>
          }
      </DialogActions>
    </Dialog>
    </>
  )
}

export default ProjectDetailsPaymentsModal;
