import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, FormControl, InputLabel, Select, MenuItem, Typography, Button, FormControlLabel, Checkbox, } from '@material-ui/core';

import PeriodSelect from '../../../Utils/PeriodSelect/PeriodSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  buttons: {
    margin: '20px 200px 50px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const InventoryForm = ({inventoryForm, setInventoryForm, handleChange, handleAddFileInventory, periods, }) => {

  const classes = useStyles();

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container>
          <Grid item xs={inventoryForm.type === 'accumulated' ? 5 : 6} lg={inventoryForm.type === 'accumulated' ? 5 : 6}>
            <Button
              variant="contained"
              component="label"
            >
              Seleccionar archivo
              <input hidden type="file" onChange={e => handleAddFileInventory(e.target.files[0])}/>
            </Button>
            {
              inventoryForm.file &&
              <Typography variant="caption" display="block" gutterBottom>
                {inventoryForm.file.name}
              </Typography>
            }
          </Grid>
          <Grid item xs={inventoryForm.type === 'accumulated' ? 5 : 6} lg={inventoryForm.type === 'accumulated' ? 5 : 6}>
            <PeriodSelect periods={periods} form={inventoryForm} setForm={setInventoryForm} />
          </Grid>
          {
            inventoryForm.type === 'accumulated' &&
            <Grid item xs={2} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inventoryForm.current}
                    onChange={handleChange}
                    name="current"
                    color="primary"
                  />
                }
                label="Activo"
              />
            </Grid>
          }
        </Grid>
        {/*<SubTasks taskForm={taskForm} setTaskForm={setTaskForm} />*/}
      </form>
    </>
  )
}

export default InventoryForm;
