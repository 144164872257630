import React from 'react';

import {TableBody, } from '@material-ui/core';

import ProjectsListTableRow from './ProjectsListTableRow';

const ProjectsListTableBody = ({projects, userInfo, }) => {

  return (
    <TableBody>
      {
        Array.isArray(projects) && projects.map(project =>
          <ProjectsListTableRow key={project.mineducCode} project={project} userInfo={userInfo} />
        )
      }
    </TableBody>
  )
}

export default ProjectsListTableBody;
