const initialState={
  show: false,
  projectInfo: {
    projectCode: '',
    objective: -1
  }
}

const projectEspecificModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showEspecificModal':
      return {
        ...state,
        show: true,
        projectInfo: action.payload.projectInfo
      }
    case 'closeEspecificModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default projectEspecificModalReducer;
