import React, {useState, useEffect} from 'react';

import {Modal, Button, } from 'react-bootstrap';

import { useDispatch, } from 'react-redux';
import {handleProjectDataEditModal, } from '../../../redux/actions';

import ProjectDataEditForm from './ProjectDataEditForm';

const ProjectDataEditModal = ({editDataModal, editProject, }) => {

  const dispatch = useDispatch();

  const projectInfo = editDataModal.projectInfo;

  const [projectForm, setProjectForm] = useState({
    specificObjectives: []
  });

  useEffect(() => {
    setProjectForm(projectInfo);
  }, [projectInfo]);

  const handleClose = () => dispatch(handleProjectDataEditModal());

  return (
    <Modal
      show={editDataModal.show}
      onHide={handleClose}
      dialogClassName="projectEspecificModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Datos del Proyecto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProjectDataEditForm projectForm={projectForm} setProjectForm={setProjectForm}/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => editProject(projectForm)}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProjectDataEditModal;
