import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid, Card, CardContent, Button, IconButton, ClickAwayListener, Tooltip, Popper, Paper, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

import {getSubTaskType, } from '../../../../Utils/Functions/TaskFunctions';
import {getSelectFormat, } from '../../../../Utils/Functions/DateFunctions';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  buttons: {
    margin: '20px 200px 50px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  subTaskTitle: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  info: {
    fontSize: 20,
    marginTop: 10,
    fontWeight: 'bold'
  },
  cards: {
    margin: 5
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
  section: {
    margin: 20
  },
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 10000,
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.5)",
    zIndex: 1301,
    maxHeight: 200,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  }
}));

const SubTaskShow = ({subTaskForm, }) => {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenInfo = (event) => {
    setAnchorEl(anchorEl => anchorEl ? null : event.currentTarget);
  };

  const handleCloseInfo = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <Card>
        <CardContent>
          <Grid container className={classes.section}>
            <Grid item xs={8}>
              <Typography className={classes.subTaskTitle}>
                {`${subTaskForm.description}`}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                Rendido:
              </Typography>
              <Typography>
                Inventariable:
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                {
                  subTaskForm.paymentDocument?.id > 0 ?
                  <>
                    <ClickAwayListener onClickAway={handleCloseInfo}>
                      <div>
                        <Tooltip aria-describedby={id} title={'Comprobante'} disableFocusListener onClick={handleOpenInfo}>
                          <FeatherIcon icon="check-circle" className="mr-1"/>
                        </Tooltip>
                        <Popper
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          className={classes.popper}
                        >
                          <Paper className={classes.paper}>
                            {`Comprobante de pago asociado: ${subTaskForm.paymentDocument?.documentNumber}-${subTaskForm.paymentDocument?.registerNumber}`}
                          </Paper>
                        </Popper>
                      </div>
                    </ClickAwayListener>
                  </>
                  :
                  <FeatherIcon icon="x-circle" className="mr-1"/>
                }
              </Typography>
              <Typography>
                {
                  subTaskForm.inventoryable ?
                  <FeatherIcon icon="check-circle" className="mr-1"/>
                  :
                  <FeatherIcon icon="x-circle" className="mr-1"/>
                }
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.section}>
            <Grid item xs={6} lg={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Tipo de subtarea:`}
                </Typography>
                <Typography >
                  {`${subTaskForm.subtaskType?.name}`}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} lg={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Monto asociado`}
                </Typography>
                <Typography >
                  {`${subTaskForm.amount || '-'}`}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.section}>
            <Grid item xs={6} lg={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Período:`}
                </Typography>
                <Typography >
                  {`${subTaskForm.period?.id >= 0 ? getSelectFormat(subTaskForm.period?.startDate, subTaskForm.period?.endDate) : '-'}`}
                </Typography>
              </div>
            </Grid>
          </Grid>
          {/*<div className={classes.section}>
            <Typography className={classes.info}>
              {`Archivo`}
            </Typography>
            <Grid container>
              <Grid item xs={8}>
                <Typography>
                  {`${subTaskForm.file.name}`}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button>
                  Descargar
                </Button>
              </Grid>
            </Grid>
          </div>*/}
        </CardContent>
      </Card>
    </>
  )
}

export default SubTaskShow;
