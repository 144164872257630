import React from 'react';

import {TableBody, TableRow, TableCell, } from '@material-ui/core';

import FilesManagerTableRow from './FilesManagerTableRow';

const FilesManagerTableBody = ({files, handleDeleteFile, deleteReason, handleDeleteReason, emptyRows, showReasons, showDeleteButton, activeColumn,
  allFile, reasonInTable, canDownload, canDelete, }) => {

  return (
    <TableBody>
      {
        files.map((file, fileIdx) =>
          <FilesManagerTableRow file={file} fileIdx={fileIdx} handleDeleteFile={handleDeleteFile} deleteReason={deleteReason} reasonInTable={reasonInTable}
            handleDeleteReason={handleDeleteReason} showReasons={showReasons} showDeleteButton={showDeleteButton} activeColumn={activeColumn}
            canDownload={canDownload} canDelete={canDelete}
          />
        )
      }
    </TableBody>
  )
}

export default FilesManagerTableBody;
