import React, {useState, useEffect} from 'react';

import {Dialog, DialogContent, DialogActions, DialogTitle, Button, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleSelectUserModal, } from '../../../redux/actions';

import UsersListTable from '../../UsersList/UsersListTable/UsersListTable';

const SelectUsersModal = ({selectUserModal, usersData, }) => {

  const dispatch = useDispatch();
  const {usersList} = usersData;
  const {show, selectUserData} = selectUserModal;

  const [usersSelected, setUsersSelected] = useState([]);

  useEffect(() => {
    const usersInTeamEmails = selectUserData.currentUsers.map(user => user.email);
    const usersInTeam = usersList.filter(user => usersInTeamEmails.includes(user.email));
    setUsersSelected(usersInTeam);
  }, [usersList, selectUserData])

  const handleSelectUser = () => {
    selectUserData.handleSelectUser(usersSelected, selectUserData.teamIdx);
    // addUserToTeam(usersSelected, selectUserData.teamIdx);
  }

  const selectUser = (user) => {
    const findIndex = usersSelected.findIndex(userSelected => userSelected.email === user.email);
    if (findIndex === -1) {
      setUsersSelected([...usersSelected, {...user, role: 1}]);
    }
    else {
      const usersSelectedCopy = [...usersSelected];
      usersSelectedCopy.splice(findIndex, 1);
      setUsersSelected(usersSelectedCopy);
    }
  };

  const handleClose = () => {
    dispatch(handleSelectUserModal(false));
  }

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        Lista de usuarios
      </DialogTitle>
      <DialogContent>
        <UsersListTable users={usersList} usersSelected={usersSelected} selectUser={selectUser} />
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleSelectUser}>
          Agregar Usuario
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectUsersModal;
