import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {TableCell, TableRow, TextField, Button, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 250,
  },
  fields: {
    width: 200
  }
}));

const ProjectDetailsGroupTeamTableRow = ({edit, member, handleChange, deleteMember, memberIdx, }) => {

  const classes = useStyles();

  return (
    <TableRow>
      <TableCell>
        {
          edit ?
          <TextField
            name="name"
            label="Nombre"
            variant="outlined"
            value={member.name}
            className={classes.fields}
            onChange={e => handleChange(e, memberIdx)}
          />
          :
          <>
            {member.name}
          </>
        }
      </TableCell>
      <TableCell>
        {
          edit ?
          <TextField
            name="rut"
            label="Rut"
            variant="outlined"
            value={member.rut}
            className={classes.fields}
            onChange={e => handleChange(e, memberIdx)}
          />
          :
          <>
            {member.rut}
          </>
        }
      </TableCell>
      <TableCell>
        {
          edit ?
          <TextField
            name="institutionPosition"
            label="Cargo"
            variant="outlined"
            value={member.institutionPosition}
            className={classes.fields}
            onChange={e => handleChange(e, memberIdx)}
          />
          :
          <>
            {member.institutionPosition}
          </>
        }
      </TableCell>
      <TableCell>
        {
          edit ?
          <TextField
            name="projectPosition"
            label="Cargo en el Proyecto"
            variant="outlined"
            value={member.projectPosition}
            className={classes.fields}
            onChange={e => handleChange(e, memberIdx)}
          />
          :
          <>
            {member.projectPosition}
          </>
        }
      </TableCell>
      <TableCell>
        {
          edit ?
          <TextField
            name="hour"
            label="Horas Asignadas"
            variant="outlined"
            value={member.hour}
            className={classes.fields}
            onChange={e => handleChange(e, memberIdx)}
          />
          :
          <>
            {member.hour}
          </>
        }
      </TableCell>
      <TableCell>
        {
          edit ?
          <TextField
            name="email"
            label="Correo"
            variant="outlined"
            value={member.email}
            className={classes.fields}
            onChange={e => handleChange(e, memberIdx)}
          />
          :
          <>
            {member.email}
          </>
        }
      </TableCell>
      {
        edit &&
        <TableCell>
          <Button onClick={() => deleteMember(memberIdx)}>
            -
          </Button>
        </TableCell>
      }
    </TableRow>
  )
}

export default ProjectDetailsGroupTeamTableRow;
