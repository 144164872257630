import React, {useState, useEffect, } from 'react';

import { max, subDays, } from 'date-fns';
import { uuid } from 'uuidv4';

import { makeStyles } from '@material-ui/core/styles';
import {Dialog, DialogContent, DialogActions, DialogTitle, Button, Grid, ClickAwayListener, Tooltip, Popper, Paper, IconButton, } from '@material-ui/core';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';

import { useDispatch, } from 'react-redux';
import {handlePeriodModal, handleNotifications, } from '../../../../redux/actions';

import {getDaysArray, } from '../../../Utils/Functions/DateFunctions';
import alert from '../../../Utils/Alert/Alert';

import PeriodForm from './PeriodForm';

import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../Utils/Buttons/SecondaryButton';

import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 10000,
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.5)",
    zIndex: 1301,
    maxHeight: 200,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  }
}));

const ProjectDetailsPeriodModal = ({projectPeriodModal, projectPeriods, userData, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const {show, type, periodData} = projectPeriodModal;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [excludeDates, setExcludeDates] = useState([]);
  const [highlightDates, setHighlightDates] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    let {periods, periodSelected} = periodData;
    const periodsCopy = [...projectPeriods];
    if (periodData.periodSelected.id) {
      const periosSelectindex = periodsCopy.findIndex(period => period.id === periodData.periodSelected.id);
      periodsCopy.splice(periosSelectindex, 1);
    }
    setStartDate(periodData.periodSelected.startDate);
    setEndDate(periodData.periodSelected.endDate);
    let excludeDatesPeriod = [];
    let highlight = [];
    for (var i = 0; i < periodsCopy.length; i++) {
      let invalidDates = getDaysArray(new Date(periodsCopy[i].startDate), new Date(periodsCopy[i].endDate));
      excludeDatesPeriod = [...excludeDatesPeriod, ...invalidDates];
      let highlightName = `react-datepicker__day--highlighted-custom-${i%5}`;
      highlight = [...highlight, {
        [highlightName]: invalidDates
      }];
    }
    setExcludeDates(excludeDatesPeriod);
    setHighlightDates(highlight);
  }, [periodData.periods]);

  const onChange = dates => {
    const [start, end] = dates;
    const selectedDates = getDaysArray(start, end);
    const found = selectedDates.some(selectedDate => excludeDates.findIndex(excludeDate =>
      excludeDate.getFullYear() === selectedDate.getFullYear() && excludeDate.getMonth() === selectedDate.getMonth() &&
      excludeDate.getDate() === selectedDate.getDate()) >= 0);
    if (found) {
      dispatch(handleNotifications(true, {
        status: 'warning',
        message: 'No se puede seleccionar la fecha, hay fechas ya tomadas en el rango'}
      ));
      setStartDate(null);
    }
    else {
      setStartDate(start);
      setEndDate(end);
    }
  };

  const handlePickDate = (date, type) => {
    let selectedDates = [];
    if (type === 'startDate') {
      selectedDates = getDaysArray(date, endDate || date);
    }
    else {
      selectedDates = getDaysArray(startDate || date, date);
    }
    const found = selectedDates.some(selectedDate => excludeDates.findIndex(excludeDate =>
      excludeDate.getFullYear() === selectedDate.getFullYear() && excludeDate.getMonth() === selectedDate.getMonth() &&
      excludeDate.getDate() === selectedDate.getDate()) >= 0);
    if (found) {
      dispatch(handleNotifications(true, {
        status: 'warning',
        message: 'No se puede seleccionar la fecha, hay fechas ya tomadas en el rango'}
      ));
    }
    else {
      if (type === 'startDate') {
        setStartDate(date);
      }
      else {
        setEndDate(date);
      }
    }
  };

  const handleClose = () => dispatch(handlePeriodModal());

  const deletePeriod = () => {
    if (!periodData.hasPayments) {
      periodData.deletePeriod();
    }
    else {
      const alertData = {
        title: `Este período posee comprobantes de pago`,
        text: 'No se puede eliminar',
      }
      alert(alertData)
    }
  }

  const handleDelete = () => {
    const alertData = {
      title: `Seguro que desea eliminar este indicador?`,
      text: 'No se podrá recuperar',
      acceptFunction: deletePeriod
    }
    alert(alertData);
  }

  const handleNewPeriod = () => {
    // console.log(periodData.addNewPeriod);
    if (endDate) {
      const {handlePeriod} = periodData;
      handlePeriod({
        id: uuid(),
        startDate: startDate,
        endDate: endDate,
        inventory: []
      });
      setStartDate(null);
      setEndDate(null);
      dispatch(handlePeriodModal());
    }
    else {
      setStartDate(null);
      setEndDate(null);
      dispatch(handleNotifications(true, {
        status: 'warning',
        message: 'Seleccione fecha de inicio y fin para el periodo'}
      ));
    }
  }

  const handleOpenInfo = (event) => {
    setAnchorEl(anchorEl => anchorEl ? null : event.currentTarget);
  };

  const handleCloseInfo = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'sm'}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        {type === 'create' ? 'Crear periodo' : 'Editar Periodo'}
      </DialogTitle>
      <DialogContent style={{textAlign: 'center'}}>
        <PeriodForm type={type} startDate={startDate} endDate={endDate} onChange={onChange} excludeDates={excludeDates} highlightDates={highlightDates}
          handlePickDate={handlePickDate}
        />
      </DialogContent>
      <DialogActions>
        {
          type !== 'create' &&
          <Grid container justify="flex-start">
            <SecondaryButton title={'Eliminar Período'} variant="secondary" onClick={handleDelete} disabled={periodData.hasPayments}/>
            {
              periodData.hasPayments &&
              <ClickAwayListener onClickAway={handleCloseInfo}>
                <div>
                  <Tooltip aria-describedby={id} title={'Qué ocurre?'} disableFocusListener onClick={handleOpenInfo}>
                    <IconButton>
                      <NotListedLocationIcon/>
                    </IconButton>
                  </Tooltip>
                  <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    className={classes.popper}
                  >
                    <Paper className={classes.paper}>
                      {`Este período no se puede eliminar porque tiene comprobantes de pago asociados`}
                    </Paper>
                  </Popper>
                </div>
              </ClickAwayListener>
            }
          </Grid>
        }
        <Grid container justify="flex-end">
          <SecondaryButton title={'Cerrar'} variant="secondary" onClick={handleClose} />
          {
            type === 'create' &&
            <PrimaryButton title={'Crear Periodo'} variant="primary" onClick={handleNewPeriod} />
          }
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ProjectDetailsPeriodModal;
