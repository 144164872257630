import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, Button, ButtonGroup, TextField, Typography, } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ExtensionForm from '../ClosingForms/ExtensionForm/ExtensionForm';

import FilesManager from '../../../../Utils/FilesManager/FilesManager';
import alert from '../../../../Utils/Alert/Alert';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  textFields: {
    width: '70%'
  },
  form: {
    marginTop: 30,
    marginBottom: 30
  },
  datesInfo: {
    marginBottom: 20
  }
}));

const NewClosingExtension = ({project, }) => {

  const classes = useStyles();

  const [extensionForm, setExtensionForm] = useState({
    validityTime: 0,
    implamentationTime: 0,
    files: []
  });

  const handleChange = (e) => setExtensionForm(extensionForm => ({...extensionForm, [e.target.name]: e.target.value}));

  const handleNewExtension = () => {
    alert({
      title: 'Segur@ que desea crear una nueva extensión?',
      text: 'Esto modificará los campos señalados y el estado del proyecto'
    });
  }

  return (
    <>
      <Typography variant="h5">
        Nueva Extensión
      </Typography>
      <ExtensionForm project={project} extensionForm={extensionForm} handleChange={handleChange} edit={true} />
      <Grid container justify="flex-end">
        <Button onClick={handleNewExtension}>
          Crear Nueva Extensión
        </Button>
      </Grid>
    </>
  )
}

export default NewClosingExtension;
