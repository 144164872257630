import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const getFinancingSources = () => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/financingsource/all`);
      dispatch(setFinancingSources(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener las fuentes de financiamiento'}
      ));
    }
  }
}

const setFinancingSources = (financingSources) => ({
  type: 'setFinancingSources',
  payload: {
    financingSources: financingSources
  }
});
