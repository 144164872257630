import React, {useState, useEffect, } from 'react';

import { uuid } from 'uuidv4';

import {Dialog, DialogContent, DialogActions, DialogTitle, Button, Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleBudgetSummaryModal, handleNotifications, getBudgetSummary, } from '../../../../redux/actions';

import BudgetSummaryTables from './BudgetSummaryTable/BudgetSummaryTables';

import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../Utils/Buttons/SecondaryButton';
import TertiaryButton from '../../../Utils/Buttons/TertiaryButton';

const ProjectBudgetSummaryModal = ({project, budgetSummaryModal, userData, }) => {

  const dispatch = useDispatch();

  const {show, budgetSummary} = budgetSummaryModal;

  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (show) {
      dispatch(getBudgetSummary());
    }
  }, [show]);

  const handleClose = () => dispatch(handleBudgetSummaryModal());

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={6}>
            Resumen del Presupuesto
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="flex-end">
              <PrimaryButton title={edit ? 'Cancelar' : 'Editar'} onClick={() => edit ? setEdit(false) : setEdit(true)} />
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <BudgetSummaryTables budgetSummaryData={budgetSummary} edit={edit} />
      </DialogContent>
      <DialogActions>
        <SecondaryButton title={'Cerrar'} onClick={handleClose} />
        {
          edit &&
          <PrimaryButton title={'Guardar cambios'} />
        }
      </DialogActions>
    </Dialog>
  )
}

export default ProjectBudgetSummaryModal;
