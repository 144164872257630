import React, {useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, Typography, Box, Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
// import {editProject, editProjectUsers, editProjectObjectives, } from '../../../redux/actions';

// import CreateProjectFormHeader from '../CreateProjectForm/CreateProjectFormHeader/CreateProjectFormHeader';
// import CreateProjectAssignUsers from '../CreateProjectForm/CreateProjectAssignUsers/CreateProjectAssignUsers';
// import CreateProjectFormObjectives from '../CreateProjectForm/CreateProjectFormHeader/CreateProjectFormObjectives';
import SubTask from './SubTask';
import SubTaskFile from './SubTaskFile';
import SubTaskHistorial from './SubTaskHistorial'

// import useForm from '../../FormValidator/formValidator';

import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterTabsComponent from '../../../../Utils/FilterComponent/FilterTabsComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const validationStateSchema = {
  name: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  projectCode: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  financingSourceId: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
};

const SubTaskTabs = ({subTaskData, handleSubTaskData, periods, subTaskForm, setSubTaskForm, handleChange, handleAddFileSubtask, taskType, handlePeriod,
  addFileToSubTask, deleteFile, type, subTaskTypes, disabled, handleDeleteSubTask, projectPeriods, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      permission: 'subtask.show',
      label: 'Datos de la Subtarea',
      component: <SubTask subTaskData={subTaskData} subTaskForm={subTaskForm} setSubTaskForm={setSubTaskForm} handleChange={handleChange} addFileToSubTask={addFileToSubTask}
        periods={periods} taskType={taskType} handlePeriod={handlePeriod} type={type} subTaskTypes={subTaskTypes} disabled={disabled}
        handleDeleteSubTask={handleDeleteSubTask} projectPeriods={projectPeriods}
      />
    },
    {
      permission: 'subtask.show',
      label: 'Archivo de la Subtarea',
      component: <SubTaskFile subTaskData={subTaskData} />
    },
    {
      permission: 'subtask.history',
      label: 'Historial de la Subtarea',
      component: <SubTaskHistorial subTaskForm={subTaskForm} />
    },
  ]

  return (
    <div className={classes.root}>
      <FilterTabsComponent value={value} handleChangeTab={handleChangeTab} tabs={tabs} />
      {/*<AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChangeTab}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab label="Datos de la Subtarea" {...a11yProps(0)} />
          <Tab label="Archivo de la Subtarea" {...a11yProps(1)} />
          {
            taskType === 'purchase' &&
            <Tab label="Datos del bien" {...a11yProps(2)} />
          }
          <Tab label="Historial de la Subtarea" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <SubTask subTaskData={subTaskData} subTaskForm={subTaskForm} setSubTaskForm={setSubTaskForm} handleChange={handleChange} addFileToSubTask={addFileToSubTask}
          periods={periods} taskType={taskType} handlePeriod={handlePeriod} type={type} subTaskTypes={subTaskTypes} disabled={disabled}
          handleDeleteSubTask={handleDeleteSubTask} projectPeriods={projectPeriods}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SubTaskFile subTaskData={subTaskData} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SubTaskHistorial subTaskForm={subTaskForm} />
      </TabPanel>
      <TabPanel value={value} index={3}>
      </TabPanel>*/}
    </div>
  );
}

export default SubTaskTabs;
