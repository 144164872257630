import React from 'react';

import {TableHead, TableRow, TableCell, } from '@material-ui/core';

import {Form, } from 'react-bootstrap';

const UsersListTableHeader = ({selectUser, }) => {
  return (
    <TableHead>
      <TableRow>
        {
          selectUser &&
          <TableCell>
            <Form.Check
              type='checkbox'
              id={`default-checkbox`}
            />
          </TableCell>
        }
        {/*<TableCell>Código</TableCell>*/}
        <TableCell>Nombres</TableCell>
        <TableCell>Apellidos</TableCell>
        <TableCell>Correo</TableCell>
        <TableCell>Rol</TableCell>
        {
          !selectUser &&
          <TableCell>Usuario activo</TableCell>
        }
        {/*<TableCell>Grupo de Trabajo</TableCell>
        <TableCell>Cargo</TableCell>
        <TableCell>Tipo de usuario </TableCell>*/}
      </TableRow>
    </TableHead>
  )
}

export default UsersListTableHeader;
