import React from 'react';

import { makeStyles, } from '@material-ui/core/styles';
import {CardContent, Typography, TextField, Grid, Button, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
  }
}));

const ProjectObjectiveDetailsEdit = ({itemForm, handleItemEdit, handleNewObjective, handleEditObjective, }) => {

  const classes = useStyles();

  return (
    <CardContent>
      <TextField
        name="name"
        className={classes.textField}
        value={itemForm.name}
        onChange={handleItemEdit}
        placeholder="Nombre del Objetivo"
        label="Nombre del Objetivo"
        variant="outlined"
      />
      <Grid container justify="flex-end">
        {
          itemForm.creating ?
          <Button onClick={handleNewObjective}>
            Crear Objetivo
          </Button>
          :
          <Button onClick={handleEditObjective}>
            Editar Objetivo
          </Button>
        }
      </Grid>
    </CardContent>
  )
}

export default ProjectObjectiveDetailsEdit;
