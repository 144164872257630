import React from 'react';

import {TableBody, TableRow, TableCell, } from '@material-ui/core';

import CapitulationSubTasksRow from './CapitulationSubTasksRow';

const CapitulationSubTasksBody = ({subTasks, subTasksSelected, selectSubTask, accumulatedAmount, edit, }) => {

  const filterSubTasks = edit ? subTasks : subTasks.filter(subTask => subTasksSelected.findIndex(subTaskSelected => subTaskSelected.id === subTask.id) > -1);

  return (
    <TableBody>
      {filterSubTasks.map((subTask) => (
        <CapitulationSubTasksRow subTask={subTask} edit={edit} subTasksSelected={subTasksSelected} selectSubTask={selectSubTask}/>
      ))}
      <TableRow>
        <TableCell />
        <TableCell />
        {
          edit &&
          <TableCell />
        }
        <TableCell >Subtotal</TableCell>
        <TableCell >{accumulatedAmount}</TableCell>
      </TableRow>
    </TableBody>
  )
}

export default CapitulationSubTasksBody;
