import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

const budgetSummaryData = [
  {
    title: 'Gasto Adquirible',
    data: [
      {
        title: 'Bienes',
        value: 33000000
      },
      {
        title: 'Servicios de consultoría',
        value: 0
      },
      {
        title: 'Obras',
        value: 0
      }
    ]
  },
  {
    title: 'Recursos humanos',
    data: [
      {
        title: 'Recursos humanos',
        value: 293116147
      },
    ]
  },
  {
    title: 'Gastos académicos',
    data: [
      {
        title: 'Gastos académicos',
        value: 5750000
      },
    ]
  },
  {
    title: 'Gastos de administración',
    data: [
      {
        title: 'Gastos de administración',
        value: 27390000
      },
    ]
  }
];


export const handleBudgetSummaryModal = (show, budgetData) => {
  if (show) {
    return dispatch => dispatch(showBudgetSummaryModal(budgetData));
  }
  else {
    return dispatch => dispatch(closeBudgetSummaryModal());
  }
}

const showBudgetSummaryModal = (budgetData) => ({
  type: 'showBudgetSummaryModal',
  payload: {
    budgetData: budgetData
  }
});

const closeBudgetSummaryModal = () => ({
  type: 'closeBudgetSummaryModal',
  payload: {}
});

export const getBudgetSummary = (projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      // const {data} = await clientAxios().get(`/api/generalpurpose/${projectId}`);
      // const {specificPurposes} = data;
      dispatch(setBudgetSummary(budgetSummaryData));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al cargar los equipos de trabajo'}
      ));
    }
  }
}

const setBudgetSummary = (budgetSummary) => ({
  type: 'setBudgetSummary',
  payload: {
    budgetSummary: budgetSummary
  }
});
