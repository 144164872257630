import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const getProjects = () => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      if (userData.isAdmin) {
        const {data} = await clientAxios().get(`/api/project/all`);
        // const {data} = await clientAxios().get(`/api/project/all/user/${userData.userId}`);
        dispatch(setProjects(data));
        dispatch(setLoading());
      }
      else {
        const {data} = await clientAxios().get(`/api/project/all/user/${userData.userId}`);
        dispatch(setProjects(data));
        dispatch(setLoading());
      }
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener los proyectos'}
      ));
    }
  }
}

export const handleAddProject = (project) => {
  return dispatch => dispatch(addProject(project));
}

const addProject = (project) => ({
  type: 'addProject',
  payload: {
    project: project
  }
});

export const handleSelectProject = (project) => {
  return dispatch => dispatch(selectProject(project));
}

export const setProjects = (projects) => ({
  type: 'setProjects',
  payload: {
    projects: projects
  }
})

const selectProject = (project) => ({
  type: 'selectProject',
  payload: {
    project: project
  }
})

export const handleEditProject = (projects) => {
  return dispatch => dispatch(editProject(projects));
}

const editProject = (projects) => ({
  type: 'editProject',
  payload: {
    projects: projects
  }
});
