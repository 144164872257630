import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const getAcademicUnits = () => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/task/academicunits`);
      dispatch(setAcademicUnits(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener los estados de la actividad'}
      ));
    }
  }
}

const setAcademicUnits = (academicUnits) => ({
  type: 'setAcademicUnits',
  payload: {
    academicUnits: academicUnits
  }
});
