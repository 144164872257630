const initialState = {
  academicUnits: []
}

const academicUnitsReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setAcademicUnits':
      return {
        ...state,
        academicUnits: action.payload.academicUnits
      }
    default:
      return state;
  }
}

export default academicUnitsReducer;
