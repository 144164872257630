import React from 'react';

import { useSelector } from 'react-redux';

import {UserSelector, RolesSelector, } from '../../redux/selectors';

import PermissionsManager from '../../components/PermissionsManager/PermissionsManager';

const PermissionsManagerContainer = () => {

  const userData = useSelector(UserSelector);
  const userInfo = userData.userInfo;

  const roles = useSelector(RolesSelector);

  return (
    <PermissionsManager userData={userData} userInfo={userInfo} rolesData={roles} />
  )
}

export default PermissionsManagerContainer;
