import React from 'react';

import {Row, Col, Card, } from 'react-bootstrap';

import ProjectDetailsResume from '../../ProjectDetailsProposal/ProjectDetailsResume/ProjectDetailsResume';

const ProjectDetailsDescription = ({project, setProject, }) => {

  return (
    <ProjectDetailsResume currentProject={project} setCurrentProject={setProject} />
  )
}

export default ProjectDetailsDescription;
