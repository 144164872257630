import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const handleHistoryRecordModal = (show, route) => {
  if (show) {
    return async dispatch => {
      try {
        dispatch(setLoading(true));
        const {data} = await clientAxios().get(route);
        dispatch(setHistorialData(data));
        dispatch(setLoading());
        dispatch(showHistoryRecordModal(true));
      } catch (e) {
        console.log(e);
        dispatch(setLoading());
        dispatch(handleNotifications(true, {
          status: 'error',
          message: 'Hubo un error al obtener el historial'}
        ));
      }
    }
  }
  else {
    return dispatch => dispatch(closeHistoryRecordModal());
  }
}

const setHistorialData = (data) => ({
  type: 'setHistorialData',
  payload: {
    data: data
  }
});

const showHistoryRecordModal = () => ({
  type: 'showHistoryRecordModal',
  payload: {}
});

const closeHistoryRecordModal = () => ({
  type: 'closeHistoryRecordModal',
  payload: {}
});
