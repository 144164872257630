export const handleGeneralModal = (show, projectInfo) => {
  if (show) {
    return dispatch => dispatch(showGeneralModal(projectInfo));
  }
  else {
    return dispatch => dispatch(closeGeneralModal());
  }
}

const showGeneralModal = (projectInfo) => ({
  type: 'showGeneralModal',
  payload: {
    projectInfo: projectInfo
  }
})

const closeGeneralModal = () => ({
  type: 'closeGeneralModal',
  payload: {}
})
