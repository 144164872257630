import React, {useState, useEffect, } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {Typography, Grid, FormControl, InputLabel, Select, MenuItem, } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  fields: {
    width: '98%',
    marginBottom: 20
  },
  details: {
    width: '95%'
  },
  selects: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '95%',
    marginBottom: 10
  }
}));

const IndicatorPurposeSelect = ({project, generalPurposesData, specificPurposeSelected, setSpecificPurposeSelected, handleSpecificPurpose, }) => {

  const classes = useStyles();

  const [generalPurposeSelected, setGeneralPurposeSelected] = useState(-1);
  const [specificPurposes, setSpecificPurposes] = useState([]);

  useEffect(() => {
    if (generalPurposeSelected !== -1) {
      const specificPurposesSelected = generalPurposesData.entities.generalPurposes[generalPurposeSelected].specificPurposes;
      // const specificPurposesSelected = project.generalPurposes.find(generalPurpose => generalPurpose.id === generalPurposeSelected).specificPurposes;
      setSpecificPurposes(specificPurposesSelected);
      setSpecificPurposeSelected(-1);
    }
  }, [generalPurposeSelected]);

  const handleChange = e => setGeneralPurposeSelected(e.target.value);

  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={6}>
        <FormControl variant="outlined" className={classes.selects}>
          <InputLabel>Objetivo General</InputLabel>
          <Select
            variant="outlined"
            label="Objetivo General"
            value={generalPurposeSelected}
            onChange={handleChange}
          >
            <MenuItem disabled value={-1}>Seleccione objetivo general</MenuItem>
            {
              generalPurposesData?.generalPurposes?.map(generalPurposeId =>
                <MenuItem value={generalPurposeId}>{generalPurposesData.entities.generalPurposes[generalPurposeId].name}</MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <FormControl variant="outlined" className={classes.selects} disabled={generalPurposeSelected === -1}>
          <InputLabel>Objetivo Específico</InputLabel>
          <Select
            value={specificPurposeSelected}
            onChange={handleSpecificPurpose}
            label="Objetivo Específico"
          >
            <MenuItem disabled value={-1}>Seleccione objetivo específico</MenuItem>
            {
              specificPurposes.map(specificPurposeId =>
                <MenuItem value={specificPurposeId}>{generalPurposesData.entities.specificPurposes[specificPurposeId].name}</MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default IndicatorPurposeSelect;
