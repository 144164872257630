import React from 'react';

import {Backdrop, CircularProgress} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 1000000,
    color: '#fff',
  },
}));

const LoaderSelector = createSelector(
  state => state.loader,
  loader => loader
)

const Loader = () => {

  const classes = useStyles();
  const loadingData = useSelector(LoaderSelector);

  const {loading} = loadingData;

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default Loader;
