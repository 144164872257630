import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import ProjectDetailsPhasesEdit from '../../components/ProjectDetailsPhases/ProjectDetailsPhasesEdit/ProjectDetailsPhasesEdit';

const ProjectsSelector = createSelector(
  state => state.projects,
  projects => projects
);

const SectionModalSelector = createSelector(
  state => state.sectionModal,
  sectionModal => sectionModal
);

const ProjectPhasesEditContainer = () => {

  const projectsData = useSelector(ProjectsSelector);

  const sectionModalData = useSelector(SectionModalSelector);

  return (
    <ProjectDetailsPhasesEdit projectsData={projectsData} sectionModalData={sectionModalData}/>
  )

}

export default ProjectPhasesEditContainer;
