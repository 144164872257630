import React, {useState, useEffect, } from 'react';

import {Dialog, DialogContent, DialogActions, DialogTitle, Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleGoodsModal, editGood, } from '../../../../redux/actions';

import GoodsTabs from './GoodsTabs/GoodsTabs';
import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../Utils/Buttons/SecondaryButton';

const ProjectDetailsGoodsModal = ({project, projectGoodsModal, userData, }) => {

  const dispatch = useDispatch();

  const {show, goodsData} = projectGoodsModal;

  const [goodForm, setGoodForm] = useState({});
  const [goodFile, setGoodFile] = useState(null);
  const [edit, setEdit] = useState(false);
  const [changed, setChanged] = useState(false);
  const [wasEdited, setWasEdited] = useState(false);

  useEffect(() => {
    setWasEdited(false);
  }, [show]);

  useEffect(() => {
    setGoodForm({
      id: goodsData?.good?.intentoryItems && goodsData?.good?.intentoryItems[0]?.id,
      amount: goodsData?.good?.amount || 0,
      codeNumber: goodsData?.good?.intentoryItems && goodsData?.good?.intentoryItems[0]?.codeNumber || 0,
      serialNumber: goodsData?.good?.intentoryItems && goodsData?.good?.intentoryItems[0]?.serialNumber || 0,
      location: goodsData?.good?.intentoryItems && goodsData?.good?.intentoryItems[0]?.location || '',
      responsable: goodsData?.good?.intentoryItems && goodsData?.good?.intentoryItems[0]?.responsable || '',
      responsableEmail: goodsData?.good?.intentoryItems && goodsData?.good?.intentoryItems[0]?.responsableEmail || '',
      responsableNumber: goodsData?.good?.intentoryItems && goodsData?.good?.intentoryItems[0]?.responsableNumber || 0,
      model: goodsData?.good?.intentoryItems && goodsData?.good?.intentoryItems[0]?.model || '',
      brand: goodsData?.good?.intentoryItems && goodsData?.good?.intentoryItems[0]?.brand || '',
      supplier: goodsData?.good?.intentoryItems && goodsData?.good?.intentoryItems[0]?.model || '',
      contact: goodsData?.good?.intentoryItems && goodsData?.good?.intentoryItems[0]?.contact || 'algo',
    });
    setGoodFile((goodsData.good?.intentoryItems && goodsData.good?.intentoryItems[0] && goodsData.good?.intentoryItems[0]?.name) ? {
      date: goodsData.good?.intentoryItems[0].createdAt,
      name: goodsData.good?.intentoryItems[0].name,
      fileDownloadUri: goodsData.good?.intentoryItems[0].fileDownloadUri,
      filePath: goodsData.good?.intentoryItems[0].filePath,
      createdByUserName: goodsData.good?.intentoryItems[0].updatedByUserName || goodsData.good?.intentoryItems[0].createdByUserName
    } :
      null
    )
  }, [goodsData]);

  const handleChange = (e) => {
    setGoodForm(goodForm => ({...goodForm, [e.target.name]: e.target.value}));
  }

  const handleFile = (file) => {
    setWasEdited(true);
    setGoodFile(file);
  }

  const handleClose = () => dispatch(handleGoodsModal());

  const handleEditGood = () => {
    dispatch(editGood(project.id, goodForm, wasEdited ? goodFile : null));
  }

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        {goodForm.description}
      </DialogTitle>
      <DialogContent>
        {/*<InventoryForm inventoryForm={inventoryForm} setInventoryForm={setInventoryForm} handleChange={handleChange} periods={project.periods}
          handleAddFileInventory={handleAddFileInventory}
        />*/}
        <GoodsTabs project={project} goodForm={goodForm} goodFile={goodFile} handleChange={handleChange} handleEditGood={handleEditGood} handleFile={handleFile}/>
      </DialogContent>
      <DialogActions>
        <Grid container justify="flex-end">
          <SecondaryButton title={'Cerrar'} onClick={handleClose} />
          {/*<PrimaryButton title={'Guardar cambios'} onClick={handleEditGood} />*/}
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ProjectDetailsGoodsModal;
