import React, {useState, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, Toolbar, Typography, Tooltip, IconButton, FormControl, InputLabel, MenuItem, Select, } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import {getSelectFormat, } from '../../../../../Utils/Functions/DateFunctions';

import TableSearch from '../../../../../Utils/FilesManager/FilesManagerTable/FilesManagerFilters/TableSearch';
import GoodsPopper from './GoodsPopper/GoodsPopper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    width: 350
  }
}));

const GoodsFilter = ({projectPeriods, academicUnits, searchText, handleSearchText, resetText, filters, handleChangeFilters, }) => {

  const classes = useStyles();

  const [showSearch, setShowSearch] = useState(false);

  const handleShowSearch = () => {
    setShowSearch(showSearch => {
      resetText();
      return !showSearch
    });
  }

  return (
    <Toolbar>
      <Grid container>
        <Typography
          variant="h6"
        >
          Bienes
        </Typography>
      </Grid>
      <Grid container justify="flex-end">
        {/*<TableSearch searchText={searchText} handleSearchText={handleSearchText} handleShowSearch={handleShowSearch}/>*/}
        {
          showSearch ?
          <TableSearch searchText={searchText} handleSearchText={handleSearchText} handleShowSearch={handleShowSearch} resetText={resetText}/>
          :
          <Tooltip title={'Buscar'} disableFocusListener>
            <IconButton
              onClick={handleShowSearch}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        }
      </Grid>
      <FormControl id="subTaskType" variant="outlined" className={classes.formControl}>
        <InputLabel id="subTaskType">Unidad requirente</InputLabel>
        <Select
          name="unitSelected"
          label="Unidad Requirente"
          value={filters.unitSelected}
          onChange={handleChangeFilters}
        >
          <MenuItem value={-1}>Ninguno</MenuItem>
          {
            academicUnits.map(unit =>
              <MenuItem key={unit.id} value={unit.id}>{unit.name}</MenuItem>
            )
          }
        </Select>
      </FormControl>
      <FormControl id="subTaskType" variant="outlined" className={classes.formControl}>
        <InputLabel id="subTaskType">Período</InputLabel>
        <Select
          name="periodSelected"
          label="Unidad Requirente"
          value={filters.periodSelected}
          onChange={handleChangeFilters}
        >
          <MenuItem value={-1}>Ninguno</MenuItem>
          {
            projectPeriods && projectPeriods.map(period => {
              const startDate = new Date(period.startDate);
              const endDate = new Date(period.endDate);
              const startDateFormat = getSelectFormat(startDate, endDate);
              return (
                <MenuItem key={period.id} value={period.id}>{startDateFormat}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
      <GoodsPopper filters={filters} handleChangeFilters={handleChangeFilters} />
    </Toolbar>
  )
}

export default GoodsFilter;
