const initialState={
  show: false,
  type: 'create',
  inventoryData: {
    inventory: {
      file: {},
      period: '',
      type: 'accumulated',
      current: false
    },
    periods: [],
    handleInventory: () => {}
  },
}

const projectInventoryModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showInventoryModal':
      return {
        ...state,
        show: true,
        type: action.payload.type,
        inventoryData: action.payload.inventoryData
      }
    case 'closeInventoryModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default projectInventoryModalReducer;
