import React, {useState, } from 'react';

import {TableCell, TableRow, IconButton, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

import { useDispatch, } from 'react-redux';
import {downloadFile, } from '../../../../redux/actions';

const actions = {
  'CREATE': 'Creado',
  'CREATED': 'Creado',
  'DELETE': 'Eliminado',
  'UPDATED': 'Actualizado',
}

const HistoryRecordRow = ({record, showDownloadButton, }) => {

  const dispatch = useDispatch();

  const recordDate = new Date(record.createdAt);

  const download =  () => {
    try {
      dispatch(downloadFile(record.fileDownloadUri, record.name));
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <TableRow >
        <TableCell>
          {recordDate && `${recordDate.getDate()}/${recordDate.getMonth()+1}/${recordDate.getFullYear()}
          ${recordDate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})}`}
        </TableCell>
        <TableCell>
          {record.createdByUserName || 'Juanito Perez'}
        </TableCell>
        <TableCell>
          {record.reason || '-'}
        </TableCell>
        <TableCell>
          {actions[record.action]}
        </TableCell>
        <TableCell>
          {record.name}
        </TableCell>
        {
          showDownloadButton &&
          <TableCell>
            <FeatherIcon icon="download" className='iconButton' onClick={download}/>
          </TableCell>
        }
      </TableRow>
    </>
  )
}

export default HistoryRecordRow;
