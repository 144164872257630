import React, {useState, useEffect} from 'react';

import clientAxios from '../../config/axios';

import { useDispatch, } from 'react-redux';
import { push } from 'connected-react-router';
import {handleAddProject, setLoading, handleNotifications, getProjects, } from '../../redux/actions';

import CreateProjectForm from './CreateProjectForm/CreateProjectForm';
import CreateProjectStepper from './CreateProjectStepper/CreateProjectStepper';
import SelectUsersModalContainer from '../../containers/GeneralModalsContainers/SelectUsersModalContainer/SelectUsersModalContainer';

import FilterComponent from '../Utils/FilterComponent/FilterComponent';

import './CreateProjectCSS/CreateProject.css';

const CreateProject = ({selectUserModal, usersData, userData, financingSources, edit, roles, }) => {

  const dispatch = useDispatch();

  const {usersList} = usersData;

  const [projectForm, setProjectForm] = useState({
    projectCode: '',
    name: '',
    startDate: new Date(),
    iniciativeType: '',
    validityTime: 0,
    resolutionCode: '',
    usachCode: '',
    generalPurposes: [
      {
        name: null,
        specificPurposes: [{ name: null }]
      }
    ],
    financingSourceId: 1,
    implamentationTime: 0,
    currentAssignedUsers: []
  });
  const [startDate, setStartDate] = useState(new Date());
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const todayDate = new Date();
    const todayDateFormat = `${todayDate.getFullYear()}-${todayDate.getMonth()+1}-${todayDate.getDate()}`;
    setProjectForm(projectForm => ({...projectForm, startDate: todayDateFormat}));
    dispatch(setLoading(false));
  }, []);

  const createProject = async () => {
    try {
      // dispatch(setLoading(true));
      const newProject = {
        ...projectForm,
        teams: teams,
        state: 'creating',
        periods: [],
        generalPurposesRequest: projectForm.generalPurposes,
        startDate: new Date(startDate),
        projectRoles: projectForm.currentAssignedUsers.map(user => ({
          userId: user.id,
          rolePermissionId: user.role
        })),
        statusId: 1
      }
      // newProject.generalPurposesRequest = newProject.mainObjectives;
      // for (var i = 0; i < newProject.generalPurposesRequest.length; i++) {
      //   // for (var j = 0; j < newProject.generalPurposesRequest.sectionFields.length; i++) {
      //   //   array[i]
      //   // }
      //   for (var j = 0; j < newProject.generalPurposesRequest[i].secondField.length; j++) {
      //     delete Object.assign(newProject.generalPurposesRequest[i].secondField[j], {['name']: newProject.generalPurposesRequest[i].secondField[j]['value'] })['value'];
      //   }
      //   delete Object.assign(newProject.generalPurposesRequest[i], {['name']: newProject.generalPurposesRequest[i]['firstField'] })['firstField'];
      //   delete Object.assign(newProject.generalPurposesRequest[i], {['specificPurposes']: newProject.generalPurposesRequest[i]['secondField'] })['secondField'];
      // }
      // console.log(newProject);
      // console.log(newProject.currentAssignedUsers);

      const {data} = await clientAxios().post(`/api/project/add`, newProject);
      // const purposeData = {...projectForm.mainObjectives[0]};
      // const purpose = {
      //   name: purposeData.firstField,
      //   projectId: data.id
      // }
      // const purposeResponse = await clientAxios().post(`/api/generalpurpose/add`, purpose);
      dispatch(getProjects());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Proyecto creado correctamente'}
      ));
      dispatch(push('/'));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Ocurrió un error al crear el proyecto'}
      ));
      dispatch(setLoading(false));
    }
  }

  const handleChangeProjectForm = (e) => setProjectForm({...projectForm, [e.target.name]: e.target.value});

  const checkProjectForm = () => {
    if (projectForm.projectCode && projectForm.name ) {

    }
  }

  const handlePickDate = (date) => setStartDate(date);

  return (
    <>
      {/*<CreateProjectSelectUserModal selectUserModal={selectUserModal} usersList={usersList} />*/}
      <FilterComponent>
        <SelectUsersModalContainer />
        <CreateProjectStepper projectForm={projectForm} setProjectForm={setProjectForm} handleChangeProjectForm={handleChangeProjectForm} teams={teams}
          setTeams={setTeams} createProject={createProject} selectUserModal={selectUserModal} usersList={usersList} startDate={startDate}
          handlePickDate={handlePickDate} financingSources={financingSources} roles={roles}
        />
      </FilterComponent>
    </>
  )
}

export default CreateProject;
