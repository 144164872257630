export const handleStateModal = (show) => {
  if (show) {
    return dispatch => dispatch(showStateModal());
  }
  else {
    return dispatch => dispatch(closeStateModal());
  }
}

const showStateModal = (sectionData) => ({
  type: 'showStateModal',
  payload: {}
})

const closeStateModal = () => ({
  type: 'closeStateModal',
  payload: {}
})
