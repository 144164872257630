import React, {useState, useEffect} from 'react';

import { uuid } from 'uuidv4';

import { makeStyles } from '@material-ui/core/styles';
import {Button, Grid, Typography, Card, CardActions, CardContent, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleInventoryModal, handleNotifications, } from '../../../../redux/actions';

import InventoryTabs from './InventoryTabs/InventoryTabs';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: '2% 3% 1% 3%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 12,
  },
});

const ProjectDetailsInventory = ({project, academicUnits, projectPeriods, active, projectGoods, userData, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <InventoryTabs project={project} active={active} projectGoods={projectGoods} projectPeriods={projectPeriods}
        academicUnits={academicUnits}
      />
    </>
  )
}

export default ProjectDetailsInventory;
