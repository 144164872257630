import React from 'react';

import { makeStyles, } from '@material-ui/core/styles';
import {CardActions, CardContent, Grid, InputAdornment, OutlinedInput, Button, } from '@material-ui/core';
import FeatherIcon from 'feather-icons-react';

import ProjectDetailsPhaseShowFiles from '../../../../ProjectDetailsPhases/ProjectDetailsPhasesEdit/ProjectDetailsPhaseShowFiles';
import TextField from '../../../../Utils/TextFields/TextField';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '90%',
    margin: 10
  }
}));

const ProjectGoalDetailsEdit = ({itemSelected, setItemForm, itemForm, handleItemEdit, handleNewMilestone, handleEditMilestone, }) => {

  const classes = useStyles();

  return (
    <>
      <CardContent>
        <TextField
          name="name"
          classStyle={classes.textField}
          value={itemForm.name}
          onChange={handleItemEdit}
          placeholder="Nombre del hito"
          label="Nombre del hito"
          variant="outlined"
        />
        <TextField
          name="complianceDate"
          classStyle={classes.textField}
          value={itemForm.complianceDate}
          onChange={handleItemEdit}
          placeholder="Fecha de Cumplimiento"
          label="Fecha de Cumplimiento"
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">{`Mes   `}</InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </CardContent>
    </>
  )
}

export default ProjectGoalDetailsEdit;
