import React, {useState, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {FormControl, InputLabel, Select, MenuItem, Grid, Button, Paper, } from '@material-ui/core';

import {downloadFileAxios, uploadFileAxios} from '../../../config/manageFileAxios';

import FilesManagerTable from './FilesManagerTable/FilesManagerTable';
import FilesSelector from './FilesSelector';
import UploadButton from '../Buttons/UploadButton';
import TertiaryButton from '../Buttons/TertiaryButton';
import TextField from '../TextFields/TextField';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  primaryButton: {
    backgroundColor: '#EC7700',
    color: '#FFF',
    '&:hover':{
      backgroundColor: '#DE7C00',
      boxShadow: 'none',
    }
  },
  textField: {
    marginBottom: 10
  }
}));


const FilesManager = ({title, files, addFiles, deleteFile, modal, edit, showReasons, showFilters, showDeleteButton, activeColumn, uploadType, formAndUnique,
  needReason, reasonInTable, downloadPermission, deletePermission, }) => {

  const classes = useStyles();

  const [file, setFile] = useState({});
  const [reason, setReason] = useState('');
  const [option, setOption] = useState(0);

  const handleChange = (e) => setReason(e.target.value);

  const handleAddFile = (file) => {
    addFiles(file, reason);
    setFile({});
    setReason('');
  }

  const handleSetFile = (e) => {
    if (e.target && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <Paper elevation={3} style={{padding: 20}}>
      {
        edit &&
        (
          uploadType === 'multi' ?
            <FilesSelector handleAddFiles={addFiles} modal={modal}/>
            :
            <>
              <Grid container>
                <Grid item className={classes.textField} xs={12} md={needReason ? 5 : 7} lg={5}>
                  <UploadButton file={file} buttonName={'Seleccionar archivo'} handleFile={formAndUnique ? handleAddFile : setFile} formAndUnique={true}/>
                </Grid>
                {
                  needReason &&
                  <Grid item xs={12} md={4} lg={4}>
                    <TextField
                      name="reason"
                      label="Razones de edición"
                      classStyle={classes.textField}
                      onChange={handleChange}
                      value={reason}
                      multiline
                      rows={2}
                      variant="outlined"
                    />
                  </Grid>
                }
                {
                  formAndUnique ||
                  <Grid item xs={modal ? 0 : 12} md={modal ? 0 : 3} lg={modal ? 0 : 3}>
                    <TertiaryButton title={'Agregar archivo'} onClick={() => handleAddFile(file)} disabled={!(file && file.name) || (needReason && !reason)} />
                  </Grid>
                }
              </Grid>
            </>
          )
      }
      {
        !modal &&
        <FilesManagerTable title={title} files={files} deleteFile={deleteFile} showReasons={showReasons} showFilters={showFilters}
          showDeleteButton={showDeleteButton} activeColumn={activeColumn} reasonInTable={reasonInTable}
          downloadPermission={downloadPermission} deletePermission={deletePermission}
        />
      }
    </Paper>
  )
}

export default FilesManager;
