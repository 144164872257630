import React, {useState, useEffect, } from 'react';

import {Grid, Card, } from '@material-ui/core';

import PermissionsTable from './PermissionsTable/PermissionsTable';
import RolesList from './RolesList/RolesList';
import Permissions from './Permissions/Permissions';

// const rolesData = [
//   {
//     id: 1,
//     roleName: 'Gestor financiero',
//     permissions: [2, 5, 7, 8],
//   },
//   {
//     id: 2,
//     roleName: 'Observador',
//     permissions: ['planificación.indicator.create', 5, 7],
//   },
//   {
//     id: 3,
//     roleName: 'Gestor proyecto',
//     permissions: [7, 8, 9],
//   },
//   {
//     id: 4,
//     roleName: 'Supervisor proyecto',
//     permissions: [10, 11],
//   },
//   {
//     id: 5,
//     roleName: 'Coordinador proyecto',
//     permissions: [11],
//   },
// ];


const PermissionsManager = ({rolesData, }) => {

  const [roles, setRoles] = useState([]);
  const [roleSelected, setRoleSelected] = useState({
    permissions: []
  });
  const [permissionsByTab, setPermissionsByTab] = useState([]);
  console.log(rolesData);

  useEffect(() => {
    const getAllPermissions = [].concat(...rolesData.map(roles => roles.permissions));
    console.log(getAllPermissions);
    const permissionsFiltered = getAllPermissions.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
    console.log(permissionsFiltered);
    let group = permissionsFiltered.reduce((r, a) => {
      r[a.grupo] = [...r[a.grupo] || [], a];
      return r;
    }, {});
    const groupKeys = Object.keys(group);
    // console.log(groupKeys);
    const groupPermissions = groupKeys.map(key => ({
      tab: key,
      permissions: group[key]
    }));
    // const groupPermissions = group(getAllPermissions, permission => permission.grupo);
    setRoles(rolesData);
    setPermissionsByTab(groupPermissions);
  }, [rolesData]);

  useEffect(() => {
    if (roles.length > 0) {
      setRoleSelected(roles[0]);
    }
  }, [roles]);

  const selectRole = (roleId) => {
    const findRole = roles.find(role => role.id === roleId);
    setRoleSelected(findRole);
  };

  return (
    <Card>
      <Grid container>
        <Grid item xs={2}>
          <RolesList roles={roles} roleSelected={roleSelected} selectRole={selectRole} />
        </Grid>
        <Grid item xs={10}>
          <Permissions permissionsByTab={permissionsByTab} roleSelected={roleSelected} />
        </Grid>
      </Grid>
    </Card>
  )
}

export default PermissionsManager;
