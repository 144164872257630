import React, {useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Card, CardContent, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {editProjectStatus, } from '../../../../redux/actions';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import ExtensionsTable from './ExtensionsTable/ExtensionsTable';

import {getShowDateFormat, } from '../../../Utils/Functions/DateFunctions';

import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../Utils/Buttons/SecondaryButton';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2% 10% 2% 10%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  textFields: {
    width: '70%'
  },
  form: {
    marginTop: 30,
    marginBottom: 30
  },
  datesInfo: {
    marginBottom: 20
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
  cards: {
    margin: 20
  }
}));

const extensions = [
  {
    id: 1,
    type: 'Ejecución con prorroga',
    prevValidityTime: 12,
    validityTime: 14,
    prevImplementationTime: 12,
    implamentationTime: 14
  },
  {
    id: 1,
    type: 'Ejecución con compromiso',
    prevValidityTime: 14,
    validityTime: 15,
    prevImplementationTime: 14,
    implamentationTime: 15
  },
]

const ProjectState = ({project, projectStatusList, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const existsFirstExtension = project.implementationTimeTwo;
  const existsSecondExtension = project.implementationTimeThree;

  const [projectValidityDate, setProjectValidityDate] = useState(new Date());
  const [projectImplementationDate, setProjectImplementationDate] = useState(new Date());
  const [projectValidityDate2, setProjectValidityDate2] = useState(new Date());
  const [projectImplementationDate2, setProjectImplementationDate2] = useState(new Date());
  const [projectValidityDate3, setProjectValidityDate3] = useState(new Date());
  const [projectImplementationDate3, setProjectImplementationDate3] = useState(new Date());

  const [projectState, setProjectState] = useState(1);

  const [validityDate2, setValidityDate2] = useState(0);
  const [implamentationDate2, setImplamentationDate2] = useState(0);

  const [validityDate3, setValidityDate3] = useState(0);
  const [implamentationDate3, setImplamentationDate3] = useState(0);

  const [validityTime2, setValidityTime2] = useState(new Date());
  const [implamentationTime2, setImplamentationTime2] = useState(new Date());

  const [validityTime3, setValidityTime3] = useState(new Date());
  const [implamentationTime3, setImplamentationTime3] = useState(new Date());

  const [secondExtension, setSecondExtension] = useState(false);

  useEffect(() => {
    setProjectState(project?.status?.id || 1);
    setValidityDate2(project.validityTimeTwo || 0);
    setImplamentationDate2(project.implementationTimeTwo || 0);
    setValidityDate3(project.validityTimeThree || 0);
    setImplamentationDate3(project.implementationTimeThree || 0);

    const valDate = new Date(project.startDate);
    valDate.setMonth(parseInt(valDate.getMonth())+parseInt(project.validityTime));
    setProjectValidityDate(valDate);
    const impDate = new Date(project.startDate);
    impDate.setMonth(parseInt(impDate.getMonth())+parseInt(project.implamentationTime));
    setProjectImplementationDate(impDate);

    if ( existsFirstExtension) {
      const valDate2 = new Date(project.startDate);
      valDate2.setMonth(parseInt(valDate2.getMonth())+parseInt(project.validityTime + project.validityTimeTwo));
      setProjectValidityDate2(valDate2);
      const impDate2 = new Date(project.startDate);
      impDate2.setMonth(parseInt(impDate2.getMonth())+parseInt(project.implamentationTime + project.implementationTimeTwo));
      setProjectImplementationDate2(impDate2);
    }

    if (existsSecondExtension) {
      const valDate3 = new Date(project.startDate);
      valDate3.setMonth(parseInt(valDate3.getMonth())+parseInt(project.validityTime + project.validityTimeTwo + project.validityTimeThree));
      setProjectValidityDate3(valDate3);
      const impDate3 = new Date(project.startDate);
      impDate3.setMonth(parseInt(impDate3.getMonth())+parseInt(project.implamentationTime + project.implementationTimeTwo + project.implementationTimeThree));
      setProjectImplementationDate3(impDate3);
    }

  }, [project]);

  useEffect(() => {
    const startDate = new Date(project.startDate);
    startDate.setMonth(parseInt(startDate.getMonth())+parseInt(validityDate2));
    setValidityTime2((!isNaN(startDate.getDate()) && startDate) || new Date(project.startDate));
  }, [validityDate2, project.startDate]);

  useEffect(() => {
    const startDate = new Date(project.startDate);
    startDate.setMonth(parseInt(startDate.getMonth())+parseInt(implamentationDate2));
    setImplamentationTime2((!isNaN(startDate.getDate()) && startDate) || new Date(project.startDate));
  }, [implamentationDate2, project.startDate]);

  const handleDates = e => e.target.name === 'implamentationDate' ? setImplamentationDate2(e.target.value) : setValidityDate2(e.target.value);

  useEffect(() => {
    const startDate = new Date(project.startDate);
    startDate.setMonth(parseInt(startDate.getMonth())+parseInt(validityDate3));
    setValidityTime3((!isNaN(startDate.getDate()) && startDate) || new Date(project.startDate));
  }, [validityDate3, project.startDate]);

  useEffect(() => {
    const startDate = new Date(project.startDate);
    startDate.setMonth(parseInt(startDate.getMonth())+parseInt(implamentationDate3));
    setImplamentationTime3((!isNaN(startDate.getDate()) && startDate) || new Date(project.startDate));
  }, [implamentationDate3, project.startDate]);

  const handleDates2 = e => e.target.name === 'implamentationDate' ? setImplamentationDate3(e.target.value) : setValidityDate3(e.target.value);

  const handleChange = (e) => setProjectState(e.target.value);

  const changeProjectState = () => {
    const data = {
      statusId: projectState,
      validityTimeTwo: validityDate2 || null,
      implementationTimeTwo: implamentationDate2 || null,
      validityTimeThree: validityDate3 || null,
      implementationTimeThree: validityDate3 || null
    }
    dispatch(editProjectStatus(project.id, data));
  }

  const handleSecondExtension = () => setSecondExtension(secondExtension => !secondExtension);


  const disabled = () => {
    if (projectState !== 3) {
      return false;
    }
    else {
      const isFirstExtension = project.implementationTimeTwo ? false : true;
      const isSecondExtension = project.implementationTimeThree ? false : true;
      if (isFirstExtension) {
        return !(validityDate2 && implamentationDate2);
      }
      else {
        if (secondExtension) {
          return !(validityDate3 && implamentationDate3);
        }
        else {
          return true;
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.cards}>
        <CardContent>
        <Grid item xs={6} lg={6}>
          <FormControl id="subTaskType" variant="outlined" className={classes.formControl} >
            <InputLabel id="subTaskType">Estado del proyecto</InputLabel>
            <Select
              name="projectState"
              label="Estado del proyecto"
              value={projectState}
              onChange={handleChange}
            >
              {
                projectStatusList.map(status =>
                  <MenuItem value={status.id}>{status.name}</MenuItem>
                )
              }
            </Select>
          </FormControl>
        </Grid>
        {
          projectState === 3 &&
          <>
            <Card className={classes.cards}>
              <CardContent>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container>
                    <Grid item xs={6}>
                      <div className={classes.datesInfo}>
                        <Typography>
                          {`Anterior tiempo de ejecución: ${project.implamentationTime} meses`}
                        </Typography>
                        <Typography>
                          {`Anterior fecha de término de ejecución: ${getShowDateFormat(projectImplementationDate)}`}
                        </Typography>
                      </div>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            name="implamentationDate"
                            label="Nuevo tiempo de Ejecución"
                            value={implamentationDate2}
                            onChange={handleDates}
                            className={classes.textFields}
                            type="number"
                            disabled={existsFirstExtension ? true : false}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>
                            Nueva fecha de término de ejecución:
                          </Typography>
                          <Typography>
                            {
                              existsFirstExtension ?
                              <>
                              {`${projectImplementationDate2.getDate()}/${projectImplementationDate2.getMonth()+1}/${projectImplementationDate2.getFullYear()}`}
                              </>
                              :
                              <>
                              {`${implamentationTime2.getDate()}/${implamentationTime2.getMonth()+1}/${implamentationTime2.getFullYear()}`}
                              </>
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.datesInfo}>
                        <Typography>
                          {`Anterior tiempo de vigencia: ${project.validityTime} meses`}
                        </Typography>
                        <Typography>
                          {`Anterior fecha de término de vigencia: ${getShowDateFormat(projectValidityDate)}`}
                        </Typography>
                      </div>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            name="validityDate"
                            label="Nuevo tiempo de Vigencia"
                            value={validityDate2}
                            onChange={handleDates}
                            className={classes.textFields}
                            type="number"
                            disabled={existsFirstExtension ? true : false}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>
                            Nueva fecha de término de vigencia:
                          </Typography>
                          <Typography>
                            {
                              existsFirstExtension ?
                              <>
                              {`${projectValidityDate2.getDate()}/${projectValidityDate2.getMonth()+1}/${projectValidityDate2.getFullYear()}`}
                              </>
                              :
                              <>
                              {`${validityTime2.getDate()}/${validityTime2.getMonth()+1}/${validityTime2.getFullYear()}`}
                              </>
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </CardContent>
            </Card>
            {
              existsFirstExtension &&
              <Card className={classes.cards}>
                <CardContent>
                  {
                    (!existsSecondExtension && !secondExtension) &&
                    <PrimaryButton title={'Agregar segunda extension'} onClick={handleSecondExtension} />
                  }
                  {
                    (existsSecondExtension || secondExtension) &&
                    <>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                          <Grid item xs={6}>
                            <div className={classes.datesInfo}>
                              <Typography>
                                {`Anterior tiempo de ejecución: ${project.implementationTimeThree} meses`}
                              </Typography>
                              <Typography>
                                {`Anterior fecha de término de ejecución: ${getShowDateFormat(projectImplementationDate2)}`}
                              </Typography>
                            </div>
                            <Grid container>
                              <Grid item xs={6}>
                                <TextField
                                  name="implamentationDate"
                                  label="Nuevo tiempo de Ejecución"
                                  value={implamentationDate3}
                                  onChange={handleDates2}
                                  className={classes.textFields}
                                  type="number"
                                  disabled={existsSecondExtension ? true : false}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography>
                                  Nueva fecha de término de ejecución:
                                </Typography>
                                <Typography>
                                  {
                                    existsFirstExtension ?
                                    <>
                                    {`${projectImplementationDate3.getDate()}/${projectImplementationDate3.getMonth()+1}/${projectImplementationDate3.getFullYear()}`}
                                    </>
                                    :
                                    <>
                                    {`${implamentationTime3.getDate()}/${implamentationTime3.getMonth()+1}/${implamentationTime3.getFullYear()}`}
                                    </>
                                  }
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <div className={classes.datesInfo}>
                              <Typography>
                                {`Anterior tiempo de vigencia: ${project.validityTimeTwo} meses`}
                              </Typography>
                              <Typography>
                                {`Anterior fecha de término de vigencia: ${getShowDateFormat(projectValidityDate2)}`}
                              </Typography>
                            </div>
                            <Grid container>
                              <Grid item xs={6}>
                                <TextField
                                  name="validityDate"
                                  label="Nuevo tiempo de Vigencia"
                                  value={validityDate3}
                                  onChange={handleDates2}
                                  className={classes.textFields}
                                  type="number"
                                  disabled={existsSecondExtension ? true : false}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography>
                                  Nueva fecha de término de vigencia:
                                </Typography>
                                <Typography>
                                  {
                                    existsFirstExtension ?
                                    <>
                                    {`${projectValidityDate3.getDate()}/${projectValidityDate3.getMonth()+1}/${projectValidityDate3.getFullYear()}`}
                                    </>
                                    :
                                    <>
                                    {`${validityTime3.getDate()}/${validityTime3.getMonth()+1}/${validityTime3.getFullYear()}`}
                                    </>
                                  }
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </MuiPickersUtilsProvider>
                      {
                        existsSecondExtension ?
                        <></>
                        :
                        <SecondaryButton title={'Cancelar'} onClick={handleSecondExtension} />
                      }
                    </>
                  }
                </CardContent>
              </Card>
            }
          </>
        }
        <Grid container justify="flex-end">
          <PrimaryButton title={'Cambiar estado del proyecto'} disabled={disabled()} onClick={changeProjectState} />
        </Grid>
        </CardContent>
      </Card>
      {
        existsFirstExtension &&
        <Card className={classes.cards}>
          <CardContent>
            <Typography variant="h5">
              Extensiones realizadas
            </Typography>
            <ExtensionsTable extensions={extensions} project={project} />
          </CardContent>
        </Card>
      }
    </div>
  )
}

export default ProjectState;
