const initialState = {
  roles: []
}

const rolesReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setRoles':
      return {
        ...state,
        roles: action.payload.roles
      }
    default:
      return state;
  }
}

export default rolesReducer;
