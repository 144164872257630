import React, {useState, useEffect, } from 'react';

import {Dialog, DialogContent, DialogActions, DialogTitle, Button, Grid, Typography, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleSubTaskModal, } from '../../../../redux/actions';

import {getDateFormat, } from '../../../Utils/Functions/DateFunctions';

import alert from '../../../Utils/Alert/Alert';

import useForm from '../../../FormValidator/formValidator';

import SubTaskForm from './SubTaskTabs/SubTaskForm';
// import SubTask from './SubTaskTabs/SubTask';
// import SubTaskShow from './SubTaskShow';
import SubTaskTabs from './SubTaskTabs/SubTaskTabs';
import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../Utils/FilterComponent/FilterComponent';

const validationStateSchema = {
  description: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  subtaskTypeId: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  }
  // tel_T: {
  //   validator: {
  //     regEx: /^[0-9]$/,
  //     error: 'Invalid last name format.',
  //   },
  // },
};


const ProjectDetailsSubTaskModal = ({project, projectSubTaskModal, userData, subTaskTypes, projectPeriods, }) => {

  const dispatch = useDispatch();

  const {show, type, subTaskData} = projectSubTaskModal;

  const [subTaskForm, setSubTaskForm] = useState({
    description: '',
    period: '',
    subtaskTypeId: 1,
    file: {},
    files: [],
    amount: 0,
    inventoryable: false,
    reason: '',
    period: {
      id: -1
    }
  });
  const [changed, setChanged] = useState(false);

  const handleClose = () => dispatch(handleSubTaskModal());

  useEffect(() => {
    console.log(subTaskData.subTask);
    setSubTaskForm({
      ...subTaskData.subTask,
      model: subTaskData.subTask?.intentoryItems && subTaskData.subTask?.intentoryItems[0]?.model || '',
      brand: subTaskData.subTask?.intentoryItems && subTaskData.subTask?.intentoryItems[0]?.brand || '',
      supplier: subTaskData.subTask?.intentoryItems && subTaskData.subTask?.intentoryItems[0]?.supplier || '',
    });
  }, [show]);

  const handleSubTaskData = () => {
    const {handleSubTask} = subTaskData;
    // addNewTask(activityIdx, taskForm);
    handleSubTask({...subTaskForm, rendido: Math.random() < 0.5});
    dispatch(handleSubTaskModal());
  }

  const handleChange = (e) => {
    setChanged(true);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setSubTaskForm(subTaskForm => ({...subTaskForm, [e.target.name]: value}));
  }

  const handlePeriod = (e) => {
    const {value} = e.target;
    const findPeriod = project.periods.find(period => period.id === value);
    if (findPeriod) {
      setSubTaskForm(subTaskForm => ({...subTaskForm, period: findPeriod, periodId: findPeriod.id}));
    }
    else {
      setSubTaskForm(subTaskForm => ({...subTaskForm, period: {
        id: -1,
        startDate: null,
        endDate: null
      }, periodId: -1}));
    }
  }

  const addFileToSubTask = (file) => {
    setSubTaskForm(subTaskFormCopy => ({...subTaskFormCopy, file: file}));
  }

  const deleteFile = (fileIdx) => {
    const subTaskFormCopy = {...subTaskForm};
    const subTaskFiles = [...subTaskFormCopy.files];
    subTaskFiles.splice(fileIdx, 1);
    subTaskFormCopy.files = subTaskFiles;
    setSubTaskForm(subTaskFormCopy);
  }

  const handleAddFileSubtask = (file) => {

  }

  const handleSave = () => {
    if (changed) {
      console.log(subTaskForm.reasonsOfChange);
      if (subTaskForm.reasonsOfChange) {

      }
      else {
        const alertData = {
          title: `Debe agregar una razón del cambio de la subtarea`,
          text: 'Es necesario para poder editar la subtarea'
        }
        alert(alertData);
      }
    }
    else {
      dispatch(handleSubTaskModal());
    }
  }

  const handleDeleteSubTask = () => {
    const {deleteSubTask} = subTaskData;
    const handleDelete = () => deleteSubTask(subTaskForm.id, subTaskForm.reason);
    const alertData = {
      title: `Seguro que desea eliminar la subtarea?`,
      text: 'No se podrá recuperar',
      acceptFunction: handleDelete
    }
    alert(alertData);
  }

  const {disabled} = useForm(subTaskForm, validationStateSchema);

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        {
          type === 'create' ?
          <>
            Crear subtarea
          </>
          :
          <div style={{display: 'flex'}}>
            <Typography variant="h5">
              Subtarea
            </Typography>
          </div>
        }
      </DialogTitle>
      <DialogContent>
        {
          type === 'create' ?
          <FilterComponent permission={'subtask.create'}>
            <SubTaskForm subTaskForm={subTaskForm} setSubTaskForm={setSubTaskForm} handleChange={handleChange} addFileToSubTask={addFileToSubTask}
              periods={project.periods} taskType={subTaskData.taskType} handlePeriod={handlePeriod} type={type} subTaskTypes={subTaskTypes}
              projectPeriods={projectPeriods}
            />
          </FilterComponent>
          :
          <FilterComponent permission={'subtask.show'}>
            <SubTaskTabs subTaskData={subTaskData} handleSubTaskData={handleSubTaskData} subTaskForm={subTaskForm} setSubTaskForm={setSubTaskForm}
              handleChange={handleChange} addFileToSubTask={addFileToSubTask} periods={project.periods} taskType={subTaskData.taskType} handlePeriod={handlePeriod}
              type={type} subTaskTypes={subTaskTypes} disabled={disabled} handleDeleteSubTask={handleDeleteSubTask} projectPeriods={projectPeriods}
            />
          </FilterComponent>
        }
      </DialogContent>
      <DialogActions>
        <Grid container justify="flex-end">
          <SecondaryButton title={'Cerrar'} variant="secondary" onClick={handleClose} />
          <FilterComponent permission={'subtask.create'}>
            {
              type === 'create' &&
              <PrimaryButton title={'Crear tarea'} onClick={handleSubTaskData} disabled={disabled || !subTaskForm.file?.name}/>
            }
          </FilterComponent>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ProjectDetailsSubTaskModal;
