import React from 'react';

import {TableHead, TableRow, TableCell, } from '@material-ui/core';

const FilesManagerTableHeader = ({allFiles, showReasons, activeColumn, }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Archivo</TableCell>
        {/*<TableCell>Tipo de archivo</TableCell>*/}
        <TableCell>Fecha</TableCell>
        <TableCell>Subido por</TableCell>
        {
          activeColumn &&
          <TableCell>Activo</TableCell>
        }
        <TableCell>Accion</TableCell>
        {
          allFiles && showReasons &&
          <TableCell>Ver razones de edición</TableCell>
        }
      </TableRow>
    </TableHead>
  )
}

export default FilesManagerTableHeader;
