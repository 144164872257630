import clientAxios from '../../config/axios';
import {uploadSingleFileAxios, } from '../../config/manageFileAxios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';
import {handlePaymentsModal, } from './projectPaymentsModalActions';

export const savePayment = (periodId, paymentsProofForm, file) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const objectData = {
        ...paymentsProofForm,
        amount: paymentsProofForm.amount || 0,
        userId: userData.userId,
        periodId: periodId
      }
      console.log(objectData);
      console.log(file);
      const {data} = await uploadSingleFileAxios(objectData, file, `/api/rendition/payment-document/add`, 'post');
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Comprobante creado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al crear el comprobante'}
      ));
    }
  }
}

export const editPayment = (paymentsProofForm, file) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const paymentsProofData = {
        ...paymentsProofForm,
        userId: userData.userId
      }
      console.log(paymentsProofData);
      console.log(file);
      const {data} = await uploadSingleFileAxios(paymentsProofData, file, `/api/rendition/payment-document/${paymentsProofForm.id}`, 'put');
      dispatch(handlePaymentsModal());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivo de la subtarea editada correctamente'}
      ));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al agregar el archivo a la subtarea'}
      ));
      dispatch(setLoading());
    }
  }
}

export const editPaymentSubTasks = (paymentsProofId, payment) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const paymentData = {
        ...payment,
        amount: payment.amount || 0,
        userId: userData.userId
      }
      console.log(paymentData);
      console.log(paymentsProofId);
      const {data} = await uploadSingleFileAxios(paymentData, null, `/api/rendition/payment-document/${paymentsProofId}/subtasks`, 'put');
      dispatch(handlePaymentsModal());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivo de la subtarea editada correctamente'}
      ));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al agregar el archivo a la subtarea'}
      ));
      dispatch(setLoading());
    }
  }
}

export const handleDeletePayment = (paymentsProofId, reason) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const deleteData = {
        userId: userData.userId,
        reason: reason
      }
      var formData = new FormData();
      formData.append('userId', userData.userId);
      formData.append('reason', reason);
      const {data} = await clientAxios().delete(`/api/rendition/payment-document/${paymentsProofId}`, {data: formData}, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
      // const {data} = await clientAxios().delete(`/api/rendition/payment-document/${paymentsProofId}`, {data: deleteData});
      dispatch(handlePaymentsModal());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivo de la subtarea editada correctamente'}
      ));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al agregar el archivo a la subtarea'}
      ));
      dispatch(setLoading());
    }
  }
}
