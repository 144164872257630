import React, {useState, useEffect, } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {Paper, Table, Grid, Button, } from "@material-ui/core";

import { useDispatch, } from 'react-redux';
import {handleIndicatorModal, getObjectiveResults, } from '../../../../../redux/actions';
import clientAxios from '../../../../../config/axios';

import ExtensionsTableHeader from './ExtensionsTableHeader';
import ExtensionsTableBody from './ExtensionsTableBody';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    overflowX: "auto",
    marginTop: 10
  },
  table: {
    minWidth: 700
  }
}));

const ExtensionsTable = ({project, extensions, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <ExtensionsTableHeader />
        <ExtensionsTableBody project={project} extensions={extensions} />
      </Table>
    </Paper>
  )
}

export default ExtensionsTable;
