import React, {useState, useEffect} from 'react';

import {useParams} from "react-router-dom";

import clientAxios from '../../config/axios';

import { uuid } from 'uuidv4';

import ProjectDetailsDescription from './ProjectDetailsDescription/ProjectDetailsDescription';
import ProjectDetailsTabs from './ProjectDetailsTabs/ProjectDetailsTabs';
import ProjectDetailsModalEspecific from './ProjectDetailsModals/ProjectDetailsModalEspecific/ProjectDetailsModalEspecific';
import ProjectDetailsModalGroupTeam from './ProjectDetailsModals/ProjectDetailsModalGroupTeam/ProjectDetailsModalGroupTeam';
import ProjectActivitiesModal from './ProjectDetailsModals/ProjectActivitiesModal/ProjectActivitiesModal';
import ProjectSelectActivityModal from './ProjectDetailsModals/ProjectSelectActivityModal/ProjectSelectActivityModal';
import ProjectDetailsTaskModal from './ProjectDetailsModals/ProjectDetailsTaskModal/ProjectDetailsTaskModal';
import ProjectDetailsSubTaskModal from './ProjectDetailsModals/ProjectDetailsSubTaskModal/ProjectDetailsSubTaskModal';
import ProjectDetailsPeriodModal from './ProjectDetailsModals/ProjectDetailsPeriodModal/ProjectDetailsPeriodModal';
import ProjectDetailsIndicatorModal from './ProjectDetailsModals/ProjectDetailsIndicatorModal/ProjectDetailsIndicatorModal';
import ProjectDetailsInventoryModal from './ProjectDetailsModals/ProjectDetailsInventoryModal/ProjectDetailsInventoryModal';
import ProjectDetailsGeneralModal from './ProjectDetailsModals/ProjectDetailsGeneralModal/ProjectDetailsGeneralModal';
import ProjectBudgetSummaryModal from './ProjectDetailsModals/ProjectBudgetSummaryModal/ProjectBudgetSummaryModal';
import ProjectDetailsGoodsModal from './ProjectDetailsModals/ProjectDetailsGoodsModal/ProjectDetailsGoodsModal';
import ProjectDetailsPaymentsModal from './ProjectDetailsModals/ProjectDetailsPaymentsModal/ProjectDetailsPaymentsModal';
import ProjectStateModal from './ProjectDetailsModals/ProjectStateModal/ProjectStateModal';
import FilesManagerModal from '../Utils/FilesManager/FilesManagerModal';
import HistoryRecordModal from '../Utils/HistoryRecord/HistoryRecordModal';
import SelectUsersModalContainer from '../../containers/GeneralModalsContainers/SelectUsersModalContainer/SelectUsersModalContainer';

import FilterComponent from '../Utils/FilterComponent/FilterComponent';

import { useDispatch, } from 'react-redux';
import {setLoading, handleProjectActivitiesModal, getProject, getGeneralPurposes, getProjectPeriods, setUserPermissions, } from '../../redux/actions';

import projectDevelopmentData from '../ProjectActivities/ProjectDevelopmentData';

import './ProjectDetailsCSS/ProjectDetails.css';

const ProjectDetails = ({projectsData, projectData, projectEspecificModal, projectGroupTeamModal, projectActivitiesModal, projectSelectActivityModal,
  filesManagerModal, projectTaskModal, projectSubTaskModal, projectPeriodModal, projectIndicatorModal, projectInventoryModal, projectGeneralModal, projectGoodsModal,
  budgetSummaryModal, historyRecordModal, projectPaymentsModal, userData, projectObjectivesResults, projectBudget, financingSources, activityStatus,
  academicUnits, expenseTypes, subTaskTypes, projectPeriods, projectSubTasksByPeriod, projectGoods, projectStateModal, roles, projectStatusList, }) => {

  const dispatch = useDispatch();
  const {projectCode} = useParams();
  const {projects} = projectsData;

  const [project, setProject] = useState({});
  const [projectObjectives, setProjectObjectives] = useState([]);
  const [projectActivities, setProjectActivities] = useState([]);
  const [allSpecificPurposes, setAllSpecificPurposes] = useState([]);
  const [allMilestones, setAllMilestones] = useState([]);

  useEffect(() => {
    return () => {
      dispatch(handleProjectActivitiesModal());
    }
  }, []);

  useEffect(async() => {
    dispatch(getProject(projectCode));
    dispatch(getGeneralPurposes(projectCode));
    dispatch(getProjectPeriods(projectCode));
    // try {
    //   // dispatch(setLoading(true));
    //   // dispatch(setLoading(false));
    //   const {data} = await clientAxios().get(`/api/project/${projectCode}`);
    //   setProject({...data, periods: [], state: Math.floor(Math.random() * 4)});
    //   // dispatch(setLoading(false));
    // } catch (e) {
    //   console.log(e);
    //   setProject({periods: [], state: Math.floor(Math.random() * 4)});
    //   dispatch(setLoading(false));
    // }
  }, [projectCode]);

  useEffect(() => {
    const findCurrentProject = projects.find(projectL => projectL.id == projectCode);
    const getUserPermissions = findCurrentProject?.projectRole?.permissions || [];
    dispatch(setUserPermissions(getUserPermissions));
  }, [projectCode, projects])

  // useEffect(() => {
  //   const projectDevelopmentDataCopy = [...projectDevelopmentData];
  //   let activities = [];
  //   let specificPurposes = [];
  //   let milestones = [];
  //   for (var i = 0; i < projectDevelopmentDataCopy.length; i++) {
  //     projectDevelopmentDataCopy[i].id = uuid();
  //     projectDevelopmentDataCopy[i].itemName = projectDevelopmentDataCopy[i].mainObjective;
  //     projectDevelopmentDataCopy[i].items = projectDevelopmentDataCopy[i].specificObjectives;
  //     for (var d = 0; d < projectDevelopmentDataCopy[i].specificObjectives.length; d++) {
  //       projectDevelopmentDataCopy[i].specificObjectives[d].id = uuid();
  //       projectDevelopmentDataCopy[i].specificObjectives[d].itemName = projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveDescription;
  //       projectDevelopmentDataCopy[i].specificObjectives[d].items = projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals;
  //       projectDevelopmentDataCopy[i].specificObjectives[d].identifier = `${i+1}-${d+1}`;
  //       specificPurposes.push(projectDevelopmentDataCopy[i].specificObjectives[d]);
  //       for (var j = 0; j < projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals.length; j++) {
  //         projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].id = uuid();
  //         projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].itemName = projectDevelopmentDataCopy[i].
  //           specificObjectives[d].specificObjectiveGoals[j].goalDescription;
  //         projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].items = projectDevelopmentDataCopy[i].
  //           specificObjectives[d].specificObjectiveGoals[j].activities;
  //         projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].identifier = `${i+1}-${d+1}.${j+1}`;
  //         projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].specificPurposeId = projectDevelopmentDataCopy[i].
  //           specificObjectives[d].id;
  //         milestones.push(projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j]);
  //         for (var k = 0; k < projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].activities.length; k++) {
  //           projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].activities[k].id = uuid();
  //           projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].activities[k].specificPurposeId = projectDevelopmentDataCopy[i].
  //             specificObjectives[d].id;
  //           projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].activities[k].milestoneId = projectDevelopmentDataCopy[i].
  //             specificObjectives[d].specificObjectiveGoals[j].id;
  //           projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].activities[k].identifier = `${i+1}-${d+1}-${j+1}-${k+1}`;
  //           projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].activities[k].itemName = projectDevelopmentDataCopy[i].
  //             specificObjectives[d].specificObjectiveGoals[j].activities[k].activity;
  //           projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].activities[k].tasks = [];
  //           projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].activities[k].files = [];
  //           activities.push(projectDevelopmentDataCopy[i].specificObjectives[d].specificObjectiveGoals[j].activities[k]);
  //         }
  //       }
  //     }
  //   }
  //   setProjectObjectives(projectDevelopmentDataCopy);
  //   setProjectActivities(activities);
  //   setAllSpecificPurposes(specificPurposes);
  //   setAllMilestones(milestones);
  // }, [projectDevelopmentData]);

  return (
    <div className='projectDetailsBody'>
      <ProjectDetailsDescription project={projectData.project} setProject={setProject} userData={userData}/>

      <ProjectDetailsTabs project={projectData.project} generalPurposesData={projectData.generalPurposesData} setProject={setProject}
        projectObjectives={projectObjectives} setProjectObjectives={setProjectObjectives} projectActivities={projectActivities}
        setProjectActivities={setProjectActivities} userData={userData} allSpecificPurposes={allSpecificPurposes} allMilestones={allMilestones}
        projectObjectivesResults={projectObjectivesResults} projectBudget={projectBudget} projectPeriods={projectPeriods} projectGoods={projectGoods}
        academicUnits={academicUnits}
      />

      <ProjectDetailsGeneralModal project={projectData.project} projectGeneralModal={projectGeneralModal} financingSources={financingSources}
        roles={roles} projectStatusList={projectStatusList}
      />

      <FilterComponent permission={'indicator.show'}>
        <ProjectDetailsIndicatorModal userData={userData} project={projectData.project} generalPurposesData={projectData.generalPurposesData}
          projectIndicatorModal={projectIndicatorModal}
        />
      </FilterComponent>

      {/*<ProjectDetailsModalEspecific projectEspecificModal={projectEspecificModal} userData={userData} />*/}

      <FilterComponent permission={'team.index'}>
        <ProjectDetailsModalGroupTeam project={projectData.project} projectGroupTeamModal={projectGroupTeamModal} userData={userData} />
      </FilterComponent>

      <ProjectActivitiesModal project={projectData.project} generalPurposesData={projectData.generalPurposesData} projectActivitiesModal={projectActivitiesModal}
        projectObjectives={projectObjectives} userData={userData} activityStatus={activityStatus}
      />

      <ProjectDetailsTaskModal projectTaskModal={projectTaskModal} userData={userData} academicUnits={academicUnits} expenseTypes={expenseTypes} />

      <ProjectDetailsSubTaskModal project={projectData.project} projectSubTaskModal={projectSubTaskModal} userData={userData} subTaskTypes={subTaskTypes}
        projectPeriods={projectPeriods}
      />

      <ProjectDetailsPeriodModal projectPeriodModal={projectPeriodModal} projectPeriods={projectPeriods} userData={userData} />

      <ProjectDetailsInventoryModal project={projectData.project} projectInventoryModal={projectInventoryModal} userData={userData} />

      <ProjectBudgetSummaryModal project={projectData.project} budgetSummaryModal={budgetSummaryModal} />

      <ProjectDetailsGoodsModal project={projectData.project} projectGoodsModal={projectGoodsModal} />

      <ProjectDetailsPaymentsModal projectPaymentsModal={projectPaymentsModal} subTasks={projectSubTasksByPeriod.subTasksByPeriod}/>

      <ProjectStateModal project={projectData.project} projectStateModal={projectStateModal} />

      <HistoryRecordModal historyRecordModal={historyRecordModal} />

      <FilesManagerModal filesManagerModal={filesManagerModal} userData={userData} />

      <SelectUsersModalContainer />
    </div>
  )
}

export default ProjectDetails;
