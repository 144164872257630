const initialState={
  show: false,
  objectiveData: {
    objective: {
      name: '',
      specificPurposes: []
    },
    project: {
      name: ''
    }
  },
  specificPurposesData: {
    specificPurposes: [],
    error: false
  }
}

const projectActivitiesModal = (state = initialState, action) => {
  switch(action.type){
    case 'showActivitiesModal':
      return {
        ...state,
        show: true,
        objectiveData: action.payload.objectiveData
      }
    case 'closeActivitiesModal':
      return {
        ...state,
        ...initialState
      }
    case 'setEspecificPurposes':
      return {
        ...state,
        specificPurposesData: {
          specificPurposes: action.payload.specificPurposes,
          error: false
        }
      }
    case 'failedGetSpecificPurposes':
      return {
        ...state,
        specificPurposesData: {
          specificPurposes: [],
          error: true
        }
      }
    default:
      return state;
  }
}

export default projectActivitiesModal;
