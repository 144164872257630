import React from 'react';

import {Table, } from 'react-bootstrap';

import ProjectDetailsSectionTableHeader from './ProjectDetailsSectionTableHeader';
import ProjectDetailsSectionTableBody from './ProjectDetailsSectionTableBody';

const ProjectDetailsSectionTable = ({tableRows, }) => {

  const [, ...bodyRows] = tableRows;

  return (
    <Table>
      <ProjectDetailsSectionTableHeader headerRows={tableRows[0]}/>
      <ProjectDetailsSectionTableBody bodyRows={bodyRows}/>
    </Table>
  )
}

export default ProjectDetailsSectionTable;
