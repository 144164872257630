import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, Button, } from '@material-ui/core';

import TextField from '../../../Utils/TextFields/TextField';

const useStyles = makeStyles((theme) => ({
  textFields: {
    margin: '30px 15px 30px 15px',
    width: '85%'
  },
  bigTextfield: {
    margin: '30px 15px 30px 15px',
    width: '95%'
  }
}));

const CreateProjectFormName = ({projectForm, handleChangeProjectForm, }) => {

  const classes = useStyles();

  return (
    <>
      <Grid item xs={6} md={6} lg={12}>
        <TextField
          name="name"
          label="Nombre del Proyecto"
          variant="outlined"
          value={projectForm.name}
          onChange={handleChangeProjectForm}
          classStyle={classes.bigTextfield}
        />
      </Grid>

      <Grid item xs={6} md={6} lg={4}>
        <TextField
          name="projectCode"
          label="Código del Proyecto"
          variant="outlined"
          value={projectForm.projectCode}
          onChange={handleChangeProjectForm}
          classStyle={classes.textFields}
        />
      </Grid>

      <Grid item xs={6} md={6} lg={4}>
        <TextField
          name="resolutionCode"
          label="Número de resolución interna"
          variant="outlined"
          value={projectForm.resolutionCode}
          onChange={handleChangeProjectForm}
          classStyle={classes.textFields}
        />
      </Grid>

      <Grid item xs={6} md={6} lg={4}>
        <TextField
          name="usachCode"
          label="Código interno USACH"
          variant="outlined"
          value={projectForm.usachCode}
          onChange={handleChangeProjectForm}
          classStyle={classes.textFields}
        />
      </Grid>
    </>
  )
}

export default CreateProjectFormName;
