import React from 'react';

import { useSelector } from 'react-redux';

import {UserSelector, UsersSelector, SelectUserModalSelector, FinancingSourcesSelector, RolesSelector, } from '../../redux/selectors';

import CreateProject from '../../components/CreateProject/CreateProject';

const CreateProjectContainer = () => {

  const selectUserModal = useSelector(SelectUserModalSelector);

  const usersData = useSelector(UsersSelector);

  const userData = useSelector(UserSelector);

  const financingSources = useSelector(FinancingSourcesSelector);

  const roles = useSelector(RolesSelector);

  return (
    <CreateProject selectUserModal={selectUserModal} usersData={usersData} userData={userData} financingSources={financingSources}
      roles={roles}
    />
  )

}

export default CreateProjectContainer;
