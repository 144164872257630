import React from 'react';

import {TableHead, TableRow, TableCell, } from '@material-ui/core';

const ProjectDetailsGroupTeamTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Nombre</TableCell>
        <TableCell>RUT</TableCell>
        <TableCell>Cargo en la Institución</TableCell>
        <TableCell>Cargo en el proyecto</TableCell>
        <TableCell>Horas/mes asignadas</TableCell>
        <TableCell>Email</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default ProjectDetailsGroupTeamTableHeader;
