import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, Checkbox, Typography, FormControl, FormGroup, FormControlLabel, IconButton, InputLabel, Select, MenuItem, } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles(
  theme => ({
    root: {
      padding: '10px 20px 10px 20px',
      fontFamily: 'Roboto',
    },
    title: {
      marginLeft: '-7px',
      marginRight: '24px',
      fontSize: '14px',
      color: theme.palette.text.secondary,
      textAlign: 'left',
      fontWeight: 500,
    },
    formControl: {
      zIndex: 10000
    },
    checkbox: {
      padding: '0px',
      width: '32px',
      height: '32px',
    },
    checkboxRoot: {},
    checked: {},
    label: {
      fontSize: '15px',
      marginLeft: '8px',
      color: theme.palette.text.primary,
    },
  }),
  { name: 'MUIDataTableViewCol' },
);


const Filters = ({filters, handleChangeFilters, handleClose, }) => {

  const classes = useStyles();

  return (
    <FormControl component={'fieldset'} className={classes.root}>
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="caption" className={classes.title}>
            Filtros
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <IconButton size="small" onClick={handleClose}>
            <HighlightOffIcon/>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} >
          <FormControl id="subTaskType" variant="outlined" >
            <InputLabel id="subTaskType">Unidad requirente</InputLabel>
            <Select
              name="unit"
              label="Unidad Requirente"
              value={filters.unitSelected}
              onChange={handleChangeFilters}
            >
              <MenuItem className={classes.formControl} value={-1}>Ninguno</MenuItem>
              <MenuItem value={0}>Unidad uno</MenuItem>
              <MenuItem value={1}>Unidad dos</MenuItem>
              <MenuItem value={2}>Unidad tres</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} >
          <FormControl id="subTaskType" variant="outlined" >
            <InputLabel id="subTaskType">Período</InputLabel>
            <Select
              name="unit"
              label="Unidad Requirente"
              value={filters.periodSelected}
              onChange={handleChangeFilters}
            >
              <MenuItem value={-1}>Ninguno</MenuItem>
              <MenuItem value={0}>Periodo 1</MenuItem>
              <MenuItem value={1}>Periodo 3</MenuItem>
              <MenuItem value={2}>Periodo 2</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </FormControl>
  )
}

export default Filters;
