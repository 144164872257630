import React, {useState, useEffect} from 'react';

import clientAxios from '../../../config/axios';

import {Dialog, DialogContent, DialogActions, DialogTitle, Button, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleUserFormModal, handleNotifications, setLoading, getUsers, } from '../../../redux/actions';

import UserForm from './UserForm';
import PrimaryButton from '../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../Utils/Buttons/SecondaryButton';

const UserFormModal = ({userFormModal, }) => {

  const dispatch = useDispatch();
  const {show, type, } = userFormModal;

  const [userForm, setUserForm] = useState({
    name: '',
    email: '',
    lastname: '',
    role: 0,
    admin: 0
  });
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    setUserForm({...userFormModal.userForm, role: userFormModal.userForm?.role?.length-1 > -1 || 0});
  }, [userFormModal]);

  const handleClose = () => dispatch(handleUserFormModal());

  const handleChangeUserForm = (e) => {
    setUserForm({...userForm, [e.target.name]: e.target.value});
  }

  const createUser = async () => {
    if (userForm.name !== '' && userForm.email !== '' && userForm.lastname !== '') {
      const userFormCopy = {...userForm};
      if (userFormCopy.role === 0) {
        userFormCopy.role = ['user'];
      }
      else {
        userFormCopy.role = ['admin', 'user'];
      }
      dispatch(setLoading(true));
      const {data} = await clientAxios().post(`/api/auth/signup`, userFormCopy);
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Usuario creado correctamente'}
      ));
      dispatch(getUsers());
      dispatch(handleUserFormModal());
    }
    else {
      dispatch(handleNotifications(true, {
        status: 'warning',
        message: 'Complete todos los campos'}
      ));
    }
  }

  const handleEditUser = async () => {
    if (userForm.name !== '' && userForm.email !== '' && userForm.lastname !== '') {
      const userFormCopy = {...userForm};
      if (userFormCopy.role === 0) {
        userFormCopy.role = ['user'];
        userFormCopy.isAdmin = false;
      }
      else {
        userFormCopy.role = ['admin', 'user'];
        userFormCopy.isAdmin = true;
      }
      dispatch(setLoading(true));
      const {data} = await clientAxios().put(`/api/auth/user/${userForm.id}/edit`, userFormCopy);
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Usuario creado correctamente'}
      ));
      dispatch(getUsers());
      dispatch(handleUserFormModal());
    }
    else {
      dispatch(handleNotifications(true, {
        status: 'warning',
        message: 'Complete todos los campos'}
      ));
      dispatch(setLoading());
    }
  }

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'sm'}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        {
          type === 'create' ? 'Crear Usuario' : 'Editar Usuario'
        }
      </DialogTitle>
      <DialogContent>
        {/*<InventoryForm inventoryForm={inventoryForm} setInventoryForm={setInventoryForm} handleChange={handleChange} periods={project.periods}
          handleAddFileInventory={handleAddFileInventory}
        />*/}
        <UserForm createUser={createUser} userForm={userForm} handleChangeUserForm={handleChangeUserForm}/>
        {/*<PermissionsTable userForm={userForm} userPermissions={userPermissions} setUserPermissions={setUserPermissions} />*/}
      </DialogContent>
      <DialogActions>
        <SecondaryButton title={'Cerrar'} variant="secondary" onClick={handleClose} />
        {
          type === 'create' ?
          <PrimaryButton title={'Crear usuario'} variant="primary" onClick={createUser} />
          :
          <PrimaryButton title={'Guardar'} variant="primary" onClick={handleEditUser} />
        }
      </DialogActions>
    </Dialog>
  )
}

export default UserFormModal;
