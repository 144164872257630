const initialState = {
  usersList: []
}

const usersReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setUsers':
      return {
        ...state,
        usersList: action.payload.users
      }
    default:
      return state;
  }
}

export default usersReducer;
