import React, {useState, useEffect} from 'react';

import {useParams} from "react-router-dom";

import {Col, Container, Row, Button, } from 'react-bootstrap';

import FeatherIcon from 'feather-icons-react';

import { useDispatch, } from 'react-redux';
import { push } from 'connected-react-router';
import {handleProjectDataEditModal, handleEditProject, setLoading, } from '../../redux/actions';

import ProjectData from './ProjectData/ProjectData';
import ProjectDevelopment from './ProjectDevelopment/ProjectDevelopment';
import ProjectCategory from './ProjectCategories/ProjectCategory';
import ProjectDetailsResume from './ProjectDetailsResume/ProjectDetailsResume';
import ProjectPhasesEdit from '../ProjectDetailsPhases/ProjectDetailsPhasesEdit/ProjectDetailsPhasesEdit';
import ProjectDataEditModal from './ProjectDataEditModal/ProjectDataEditModal';

import './ProjectDetailProposal.css'

const ProjectDetailProposal = ({projectsData, sectionModalData, editDataModal, }) => {

  const dispatch = useDispatch();

  const {projectCode} = useParams();
  const {projects} = projectsData;

  const [currentProject, setCurrentProject] = useState({
    specificObjectives: []
  });
  const [activePhase, setActivePhase] = useState(1);
  const [projectPhases, setProjectPhases] = useState(5);

  useEffect(() => {
    const projectFind = projects.find(project => project.mineducCode === projectCode);
    if (projectFind) {
      setCurrentProject(projectFind);
      if (projectFind.phases) {
        setProjectPhases(projectFind.phases.length);
      }
    }
    dispatch(setLoading(false));
  }, [projects, projectCode]);

  useEffect(() => {
    if (currentProject) {
      const projectsCopy = [...projects];
      const projectIndex = projectsCopy.findIndex(projectC => projectC.mineducCode === currentProject.mineducCode);
      projectsCopy[projectIndex] = currentProject;
      dispatch(handleEditProject(projectsCopy));
    }
  }, [currentProject]);

  const changePhaseLeft = () => setActivePhase(activePhase-1);

  const changePhaseRight = () => setActivePhase(activePhase+1);

  const goEditPhases = () => dispatch(push(`/project/${projectCode}/details/phases/edit`));

  const goSectionDetails = (sectionName) => dispatch(push(`/project/${projectCode}/details/${sectionName}`));

  const openEditModal = () => dispatch(handleProjectDataEditModal(true, currentProject));

  const finishProject = () => {
    setCurrentProject(currentProject => ({...currentProject, state: "finished"}));
  }

  const editProject = (projectForm) => {
    setCurrentProject(projectForm);
    dispatch(handleProjectDataEditModal());
  }

  return (
    <>
      <ProjectDataEditModal editDataModal={editDataModal} editProject={editProject}/>
        <ProjectDetailsResume currentProject={currentProject} setCurrentProject={setCurrentProject} />
        {
          currentProject.currentPhase < 3 &&
          <Button onClick={openEditModal} className="float-right">
            Modificar datos
          </Button>
        }
        {
          currentProject.currentPhase === 3 && currentProject.state !== "finished" &&
          <>
            <Button onClick={finishProject} className="float-right">
              Finalizar projecto
            </Button>
            <Button onClick={openEditModal} className="float-right" style={{marginRight: 20}}>
              Extender proyecto
            </Button>
          </>
        }
        <ProjectData currentProject={currentProject} projectCode={projectCode} />
      {/*
        !currentProject.phases ?
        <Container>
          <div className="centerText">
            <Button onClick={goEditPhases}>
              Agregar Fases al Proyecto
            </Button>
          </div>
        </Container>
        :
        <Row>
          <Col xs={2} className="centerText">
            {
              activePhase > 1 &&
              <FeatherIcon icon="arrow-left" className="mr-1 phasesArrows" onClick={changePhaseLeft}/>
            }
          </Col>
          <Col xs={8}>
            <h4>
              {currentProject.phases[activePhase-1].phaseName}
            </h4>
            <div>
              <Button onClick={goEditPhases}>
                Agregar Fases al Proyecto
              </Button>
            </div>
            {
              currentProject.phases[activePhase-1] && currentProject.phases[activePhase-1].categories.map(category =>
                <ProjectCategory category={category} goSectionDetails={goSectionDetails}/>
              )
            }
          </Col>
          <Col xs={2} className="centerText">
            {
              activePhase < projectPhases &&
              <FeatherIcon icon="arrow-right" className="mr-1 phasesArrows" onClick={changePhaseRight}/>
            }
          </Col>
        </Row>
      */}
      <ProjectDevelopment currentProject={currentProject} projectCode={projectCode}/>
      {/*<ProjectPhasesEdit projectsData={projectsData} sectionModalData={sectionModalData}/>*/}
      <ProjectPhasesEdit currentProject={currentProject} sectionModalData={sectionModalData}/>
    </>
  )
}

export default ProjectDetailProposal;
