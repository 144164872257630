import React from 'react';

import {Table, TableContainer, Paper, } from '@material-ui/core';

import CreateProjectAssignUsersTableHeader from './CreateProjectAssignUsersTableHeader';
import CreateProjectAssignUsersTableBody from './CreateProjectAssignUsersTableBody';

const CreateProjectAssignUsersTable = ({currentAssignedUsers, handleChange, removeUser, assignUsers, roles, }) => {

  return (
    <TableContainer component={Paper}>
      <Table>
        <CreateProjectAssignUsersTableHeader />
        <CreateProjectAssignUsersTableBody currentAssignedUsers={currentAssignedUsers} handleChange={handleChange} removeUser={removeUser}
          assignUsers={assignUsers} roles={roles}
        />
      </Table>
    </TableContainer>
  )
}

export default CreateProjectAssignUsersTable;
