import React from 'react';

const ProjectDetailsSectionTableRow = ({row, }) => {

  return (
    <tr>
      {
        row.rowFields.map(field=>
          <td>{field}</td>
        )
      }
    </tr>
  )
}

export default ProjectDetailsSectionTableRow;
