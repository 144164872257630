import React, {useState, useEffect, } from 'react';

import { uuid } from 'uuidv4';

import clientAxios from '../../../../config/axios';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, Button, Typography, FormControl, InputLabel, Select, MenuItem, IconButton, Tooltip, Fab, Paper, } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import { max, subDays, } from 'date-fns';

import { useDispatch, } from 'react-redux';
import {handlePeriodModal, handlePaymentsModal, setProjectPeriod, setLoading, handleNotifications, savePayment,
  getSubTasksByPeriod, deleteProjectPeriod, } from '../../../../redux/actions';

import {getSelectFormat, } from '../../../Utils/Functions/DateFunctions';

import PeriodMenu from './PeriodMenu';
import PaymentsProofs from './PaymentsProofs/PaymentsProofs';
import PaymentsProofList from './PaymentsProofs/PaymentsProofList/PaymentsProofList';

import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';

import FilterComponent from '../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  buttons: {
    margin: '20px 200px 50px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const ProjectDetailsCapitulation = ({project, setProject, projectActivities, userData, projectPeriods, active, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [periodSelect, setPeriodSelect] = useState({
    id: -1
  });
  const [estado, setEstado] = useState(false);
  const [subTasksPeriod, setSubTasksPeriod] = useState([]);
  const [paymentsProofs, setPaymentsProofs] = useState([]);

  useEffect(() => {
    if (projectPeriods && projectPeriods.length > 0) {
      setPeriodSelect(projectPeriods[0]);
    }
  }, [projectPeriods]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setLoading(true));
        const {data} = await clientAxios().get(`/api/rendition/period/${periodSelect.id}/payment-documents`);
        console.log(data);
        setPaymentsProofs(data);
        dispatch(setLoading());
      } catch (e) {
        dispatch(handleNotifications(true, {
          status: 'error',
          message: 'Hubo un error al obtener los comprobantes de pago'}
        ));
        dispatch(setLoading());
      }
    }
    if (active && periodSelect.id > 0) {
      fetchData();
      dispatch(getSubTasksByPeriod(periodSelect.id));
    }
  }, [periodSelect, estado]);

  useEffect(() => {
    const projectTasksArray = projectActivities.map(activity => activity.tasks);
    const projectTasks = [].concat(...projectTasksArray);
    const projectSubTasksArray = projectTasks.map(task => task.subTasks);
    const projectSubTasks = [].concat(...projectSubTasksArray);
    setSubTasksPeriod(projectSubTasks);
  }, [projectActivities]);

  const addNewPeriod = (periodForm) => {
    dispatch(setProjectPeriod(project.id, periodForm));
  }

  const openCreatePeriodModal = () => {
    dispatch(handlePeriodModal(true, {
      periods: project.periods,
      handlePeriod: addNewPeriod
    }, 'create'));
  }

  const deletePeriod = async () => {
    const deleteP = async (id) => dispatch(deleteProjectPeriod(project.id, id));
    await deleteP(periodSelect.id);
    dispatch(handlePeriodModal());
  }

  const openEditPeriodModal = () => {
    dispatch(handlePeriodModal(true, {
      periods: project.periods,
      handlePeriod: () => {},
      periodSelected: {...periodSelect, startDate: new Date(periodSelect.startDate), endDate: new Date(periodSelect.endDate)},
      hasPayments: paymentsProofs.length > 0,
      deletePeriod: deletePeriod
    }, 'edit'));
  }

  const selectPeriod = (e) => {
    const {value} = e.target;
    const findPeriod = projectPeriods.find(period => period.id === value);
    if (findPeriod) {
      setPeriodSelect(findPeriod);
    }
  }

  const addPaymentsProof = async (paymentsProofForm) => {
    const setNewPayment = async (paymentsProofFormCopy, file) => dispatch(savePayment(periodSelect.id, paymentsProofFormCopy, file));
    const paymentsProofFormCopy = {...paymentsProofForm, subTasksId: paymentsProofForm.subTasks.map(subTask => subTask.id)};
    const file = paymentsProofForm.file;
    delete paymentsProofFormCopy.file;
    delete paymentsProofFormCopy.subTasks;
    await setNewPayment(paymentsProofFormCopy, file);
    setEstado(estado => !estado);
  }

  const handlePayments = () => dispatch(handlePaymentsModal(true, 'create', {file: {}, subTasks: [], documentNumber: '0', folioNumber: '0', registerNumber: '0'},
    addPaymentsProof));

  return (
    <>
      <Typography variant="h4">
        Rendiciones
      </Typography>
      {/*<Grid container justify="flex-end">
        <Button onClick={openPeriodModal}>
          Nuevo Periodo
        </Button>
      </Grid>*/}
      <Grid container>
        <Grid item xs={6}>
          <FilterComponent permission={'period.index'}>
            <FormControl id="period" variant="outlined" className={classes.formControl}>
              <InputLabel>Período</InputLabel>
              <Select
                name="period"
                value={periodSelect.id}
                onChange={selectPeriod}
              >
                {
                  projectPeriods.map(period => {
                    const startDate = new Date(period.startDate);
                    const endDate = new Date(period.endDate);
                    const startDateFormat = getSelectFormat(startDate, endDate);
                    return (
                      <MenuItem value={period.id}>{startDateFormat}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </FilterComponent>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="flex-end">
            <FilterComponent permission={'period.create'}>
              <Tooltip title="Agregar período">
                <Fab size="small" onClick={openCreatePeriodModal} color="primary" style={{backgroundColor: '#EA7600'}}>
                  <AddIcon />
                </Fab>
              </Tooltip>
            </FilterComponent>
            <FilterComponent permission={'period.delete'}>
              {
                periodSelect.id &&
                <Tooltip title="Administrar período">
                  <Fab size="small" onClick={openEditPeriodModal} color="primary" style={{backgroundColor: '#B1B1B1'}}>
                    <EditIcon />
                  </Fab>
                </Tooltip>
              }
            </FilterComponent>
            {/*<PeriodMenu openCreatePeriodModal={openCreatePeriodModal} openEditPeriodModal={openEditPeriodModal}/>*/}
          </Grid>
        </Grid>
      </Grid>
      {
        periodSelect &&
        <FilterComponent permission={'proofOfPayment.index'}>
          <Paper>
            <PaymentsProofList paymentsProofs={paymentsProofs} subTasks={subTasksPeriod} addPaymentsProof={addPaymentsProof} periodSelect={periodSelect}
              estado={estado} setEstado={setEstado}
            />
          </Paper>
        </FilterComponent>
      }
      <FilterComponent permission={'proofOfPayment.create'}>
        {
          periodSelect.id >= 0 &&
          <div style={{marginTop: 20}}>
            <PrimaryButton title={'Nuevo Comprobante de Pago'} onClick={handlePayments} />
          </div>
        }
      </FilterComponent>
    </>
  )
}

export default ProjectDetailsCapitulation;
