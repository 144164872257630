import React from 'react';

import {Card, Row, Col, Button, } from 'react-bootstrap';

import TextField from '../../../../Utils/TextField/TextField';

const ProjectDetailsSectionTextField = ({field, sectionIdx, sectionFields, setSectionFields, removeField, }) => {

  const handleTextFieldChange = (e) => {
    let sectionsCopy = [...sectionFields];
    sectionsCopy[sectionIdx].data = e.target.value;
    setSectionFields(sectionsCopy);
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs={10}>
            <Card.Title>
              {field.fieldName}
            </Card.Title>
          </Col>
          <Col xs={2}>
            <Button onClick={() => removeField(sectionIdx)}>
              X
            </Button>
          </Col>
        </Row>
        <Card.Text>
          <TextField handleTextFieldChange={handleTextFieldChange} defaultValue={field.data}/>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default ProjectDetailsSectionTextField;
