import React, {useState, useEffect, } from 'react';
import Pagination from '@material-ui/lab/Pagination';

const ProjectDetailsActivitiesPaginator = ({itemsFiltered, setItemsFiltered, page, setPage, }) => {

  const [pagesNumber, setPagesNumber] = useState(0);

  useEffect(() => {
    const newPagesNumber = Math.ceil(itemsFiltered.length / 5);
    setPagesNumber(newPagesNumber);
    if (newPagesNumber < page) {
      setPage(1);
    }
  }, [itemsFiltered]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
      <Pagination count={pagesNumber} page={page} onChange={handleChange} />
  );
}

export default ProjectDetailsActivitiesPaginator;
