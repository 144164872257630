const initialState = {
  financingSources: []
}

const financingSourcesReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setFinancingSources':
      return {
        ...state,
        financingSources: action.payload.financingSources
      }
    default:
      return state;
  }
}

export default financingSourcesReducer;
