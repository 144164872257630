import React from 'react';

import { withStyles, makeStyles, } from '@material-ui/core/styles';
import {TableCell, TableHead, TableRow, } from '@material-ui/core';
import { green, purple } from '@material-ui/core/colors';

import FeatherIcon from 'feather-icons-react';

import TextField from '../../../../Utils/TextFields/TextField';
import TextFieldColoredSecondary from '../../../../Utils/TextFields/TextFieldSecondary';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#002F6C',
    color: '#FFFFFF',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  deleteButton: {
    paddingLeft: 8,
    paddingRight: 8,
    width: 24,
    maxWidth: 24
  },
  tile: {
    fontSize: 30
  }
});

const BudgetSummaryHeader = ({field, total, show, handleOpen, open, edit, handleChangeName, fieldIdx, deleteCategory, }) => {

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {
          edit &&
          <TableCell className={classes.deleteButton}>
            <FeatherIcon icon="x-circle" className='iconButton' onClick={() => deleteCategory(fieldIdx)}/>
          </TableCell>
        }
        <TableCell>
          {
            edit ?
            <TextField
              name="name"
              size="small"
              label="Gran Cuenta"
              variant="outlined"
              value={field.name}
              onChange={e => handleChangeName(e, fieldIdx)}
            />
            :
            <div className={classes.name}>
              {field.name}
            </div>
          }
        </TableCell>
        <TableCell align="right">{total}</TableCell>
        {
          show &&
          <TableCell align="right" style={{width: 10, paddingLeft: 8, paddingRight: 8}}>
            {
              open ?
              <FeatherIcon icon="arrow-up-circle" className='iconButton' onClick={handleOpen}/>
              :
              <FeatherIcon icon="arrow-down-circle" className='iconButton' onClick={handleOpen}/>

            }
          </TableCell>
        }
      </TableRow>
    </TableHead>
  )
}

export default BudgetSummaryHeader;
