import React, {useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, Button, FormControl, InputLabel, Select, MenuItem, Typography, } from '@material-ui/core';

import CreateProjectFormName from './CreateProjectFormName';
import CreateProjectFormDates from './CreateProjectFormDates';
import CreateProjectFormGeneral from './CreateProjectFormGeneral';
import CreateProjectFormObjectives from './CreateProjectFormObjectives';
import PrimaryButton from '../../../Utils/Buttons/PrimaryButton'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '30px 15px 30px 15px',
    minWidth: 360,
    width: '95%'
  },
  textFields: {
    width: '70%'
  },
  note: {
    marginLeft: 20,
    fontSize: 12,
    opacity: 0.5
  },
  form: {
    margin: '2% 15% 2% 15%'
  }
}));

const CreateProjectFormHeader = ({projectForm, handleChangeProjectForm, setProjectForm, startDate, handlePickDate, handleNext, financingSources, }) => {

  const classes = useStyles();

  return (
      <div className={classes.form}>

        <Grid container>

          <CreateProjectFormName projectForm={projectForm} handleChangeProjectForm={handleChangeProjectForm} />

          <Grid item xs={12}>
            <FormControl name="financingSource" variant="outlined" className={classes.formControl}>
              <InputLabel >Fuente de Financiamiento</InputLabel>
              <Select
                name="financingSourceId"
                label="Fuente de Financiamiento"
                value={projectForm.financingSourceId}
                onChange={handleChangeProjectForm}
              >
                {
                  financingSources.map(financingSource =>
                    <MenuItem value={financingSource.id}>{financingSource.name}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
            <Typography className={classes.note}>
              {projectForm.financingLine === '0' ? 'Asignación de tipo concursable' : 'Asignación directa'}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item lg={12}>
            <CreateProjectFormDates projectForm={projectForm} handleChangeProjectForm={handleChangeProjectForm} startDate={startDate}
              handlePickDate={handlePickDate}
            />
            <CreateProjectFormGeneral projectForm={projectForm} handleChangeProjectForm={handleChangeProjectForm} />
          </Grid>
          {/*<Grid item lg={6}>
            <CreateProjectFormObjectives projectForm={projectForm} setProjectForm={setProjectForm} />
          </Grid>*/}
        </Grid>

      </div>
  )
}

export default CreateProjectFormHeader;
