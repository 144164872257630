import React from 'react';

import { makeStyles, } from '@material-ui/core/styles';
import {Grid, Typography, CardContent, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 50,
    fontSize: 28,
    fontWeight: 'bold'
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
}));

const ProjectActivityDetailsShow = ({itemForm, }) => {

  const classes = useStyles();

  return (
    <CardContent>

      <Grid container>
        <Grid item lg={9}>
          <Typography variant="h5" className={classes.title}>
            {itemForm.name}
          </Typography>
        </Grid>
        <Grid item lg={3}>
          <Grid container justify="flex-end">
            <Typography className={classes.miniTitle}>
              {`Estado:`}
            </Typography>
            <Typography>
              {itemForm?.activityStatus?.name || '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="space-around" alignItems="center">
        <div className={classes.dates}>
          <Typography className={classes.miniTitle}>
            {`Fecha de Inicio:`}
          </Typography>
          <Typography >
            {`Mes ${itemForm.startDate || 1}`}
          </Typography>
        </div>
        <div className={classes.dates}>
          <Typography className={classes.miniTitle}>
            {`Fecha de Término:`}
          </Typography>
          <Typography>
            {`Mes ${itemForm.endDate || 3}`}
          </Typography>
        </div>
      </Grid>

    </CardContent>
  )
}

export default ProjectActivityDetailsShow;
