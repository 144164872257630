import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Button, IconButton, } from '@material-ui/core';

import PublishIcon from '@material-ui/icons/Publish';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
  buttonFile: {
    backgroundColor: 'transparent',
    borderColor: '#777 !important',
    color: '#777',
    textAlign: 'left',
    height: 50,
  },
  selectedFile: {
    backgroundColor: 'lightblue',
    color: 'black'
  },
  uploadText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '250px'
  },
});

const UploadButton = ({file, buttonName, handleFile, formAndUnique, }) => {

  const classes = useStyles();

  const clearFile = () => handleFile(null);

  return (
    <div style={{display: 'inline'}}>
      <Button
        variant="contained"
        component="label"
        className={`${classes.buttonFile} ${(file && file.name) && classes.selectedFile}`}
      >
        {
          (file && file.name) ?
          <InsertDriveFileIcon />
          :
          <PublishIcon />
        }
        <div className={classes.uploadText}>
          {(file && file.name) ? file.name : buttonName}
        </div>
        <input hidden type="file" onChange={e => handleFile(e.target.files[0])}/>
      </Button>
      {
        formAndUnique ||
        <IconButton className={classes.clearIcon} onClick={clearFile}>
          <ClearIcon />
        </IconButton>
      }
    </div>
  )
}

export default UploadButton;
