import {useState, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, TextField, Button, Paper, Tooltip, IconButton, Popper, ClickAwayListener, } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import DynamicFieldsSecondField from './DynamicFieldsSecondField';

import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

const useStyles = makeStyles((theme) => ({
  generalPurpose: {
    margin: theme.spacing(1),
    display: 'flex'
  },
  textFields: {
    width: '70%',
  },
  container: {
    margin: 15
  },
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 10000,
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.5)",
    zIndex: 1301,
    maxHeight: 200,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
}));

const DynamicFieldsFirstField = ({generalPurpose, generalPurposeIdx, handleChange, handleChangeType, handleAddType, handleRemove, handleRemoveField, type, }) => {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  // const newObjectives = generalPurpose.specificPurposes.filter(specificPurpose => specificPurpose.new);
  // const check = newObjectives.length > 0 && type === 'edit';
  const milestonesPerGeneralPurpose = [].concat(...(generalPurpose.specificPurposes?.filter(specificPurpose => specificPurpose.milestones)
    .map(specificPurpose => specificPurpose.milestones) || []));

  const handleOpenInfo = (event) => {
    setAnchorEl(anchorEl => anchorEl ? null : event.currentTarget);
  };

  const handleCloseInfo = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper1' : undefined;

  return (
    <Paper style={{padding: 10, margin: 10}}>
      <Grid container className={classes.container}>
        <Grid item xs={12} md={6} lg={6}>
          <div className={classes.generalPurpose}>
            <TextField
              label="Objetivo principal"
              // disabled={check}
              value={generalPurpose.name}
              className={classes.textFields}
              onChange={e => handleChange(generalPurposeIdx, e)}
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 510 }}
            />
            <div style={{display: 'flex'}}>
              <SecondaryButton title={'-'} onClick={() => handleRemove(generalPurposeIdx)} disabled={milestonesPerGeneralPurpose.length}/>
              {
                milestonesPerGeneralPurpose.length > 0 &&
                <ClickAwayListener onClickAway={handleCloseInfo}>
                  <div>
                    <Tooltip aria-describedby={id} title={'Información'} disableFocusListener onClick={handleOpenInfo}>
                      <IconButton>
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                    <Popper
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      className={classes.popper}
                    >
                      <Paper className={classes.paper}>
                        El objetivo general posee hitos, esto impide eliminarlo
                      </Paper>
                    </Popper>
                  </div>
                </ClickAwayListener>
              }
              </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
        {
          generalPurpose.specificPurposes.length > 0 ?
          generalPurpose.specificPurposes.map((specificPurpose, specificPurposeIdx) =>
            <DynamicFieldsSecondField specificPurpose={specificPurpose} generalPurposeIdx={generalPurposeIdx} specificPurposeIdx={specificPurposeIdx}
              handleChangeType={handleChangeType} handleRemoveField={handleRemoveField} handleAddType={handleAddType} />
          )
          :
          <div className={classes.generalPurpose}>
            <PrimaryButton title={'+'} variant="contained" onClick={() => handleAddType(generalPurposeIdx)} />
          </div>
        }
        </Grid>
      </Grid>
    </Paper>
  )
}

export default DynamicFieldsFirstField;
