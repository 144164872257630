import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {UserSelector, ProjectsSelector, ProjectDetailsEspecificModalSelector, ProjectDetailsGroupTeamModalSelector, SectionModalSelector,
  ActivitiesModalSelector, SelectActivityModalSelector, FilesManagerModalSelector, TaksModalSelector, SubTaskModalSelector, PeriodModalSelector,
  IndicatorModalSelector, InventoryModalSelector, GeneralModalSelector, BudgetSummaryModalSelector, GoodsModalSelector,
  HistoryRecordSelector, ProjectSelector, ProjectObjectivesResultsSelector, ProjectBudgetSelector, PaymentsSelector, FinancingSourcesSelector,
  ActivityStatusSelector, AcademicUnitsSelector, ExpenseTypesSelector, SubTaskTypesSelector, ProjectPeriodsSelector,
  ProjectSubTasksByPeriodSelector, ProjectGoodsSelector, StateModalSelector, RolesSelector, ProjectStatusListSelector, } from '../../redux/selectors';

import ProjectDetails from '../../components/ProjectDetails/ProjectDetails';

const ProjectDetailsContainer = () => {

  const projectsData = useSelector(ProjectsSelector);

  const projectEspecificModal = useSelector(ProjectDetailsEspecificModalSelector);

  const projectGroupTeamModal = useSelector(ProjectDetailsGroupTeamModalSelector);

  const sectionModalData = useSelector(SectionModalSelector);

  const projectActivitiesModal = useSelector(ActivitiesModalSelector);

  const projectSelectActivityModal = useSelector(SelectActivityModalSelector);

  const projectTaskModal = useSelector(TaksModalSelector);

  const filesManagerModal = useSelector(FilesManagerModalSelector);

  const projectSubTaskModal = useSelector(SubTaskModalSelector);

  const projectPeriodModal = useSelector(PeriodModalSelector);

  const projectIndicatorModal = useSelector(IndicatorModalSelector);

  const projectInventoryModal = useSelector(InventoryModalSelector);

  const projectGeneralModal = useSelector(GeneralModalSelector);

  const projectGoodsModal = useSelector(GoodsModalSelector);

  const budgetSummaryModal = useSelector(BudgetSummaryModalSelector);

  const historyRecordModal = useSelector(HistoryRecordSelector);

  const projectPaymentsModal = useSelector(PaymentsSelector);

  const projectStateModal = useSelector(StateModalSelector);

  const projectObjectivesResults = useSelector(ProjectObjectivesResultsSelector);

  const projectBudget = useSelector(ProjectBudgetSelector);

  const projectPeriods = useSelector(ProjectPeriodsSelector);

  const projectSubTasksByPeriod = useSelector(ProjectSubTasksByPeriodSelector);

  const projectGoods = useSelector(ProjectGoodsSelector);

  const userData = useSelector(UserSelector);

  const financingSources = useSelector(FinancingSourcesSelector);

  const activityStatus = useSelector(ActivityStatusSelector);

  const academicUnits = useSelector(AcademicUnitsSelector);

  const expenseTypes = useSelector(ExpenseTypesSelector);

  const subTaskTypes = useSelector(SubTaskTypesSelector);

  const roles = useSelector(RolesSelector);

  const projectStatusList = useSelector(ProjectStatusListSelector);

  const project = useSelector(ProjectSelector);

  return (
    <ProjectDetails projectsData={projectsData} projectData={project} projectEspecificModal={projectEspecificModal} projectGroupTeamModal={projectGroupTeamModal}
      sectionModalData={sectionModalData} projectActivitiesModal={projectActivitiesModal} projectSelectActivityModal={projectSelectActivityModal}
      filesManagerModal={filesManagerModal} projectTaskModal={projectTaskModal} projectSubTaskModal={projectSubTaskModal} projectPeriodModal={projectPeriodModal}
      projectIndicatorModal={projectIndicatorModal} projectInventoryModal={projectInventoryModal} projectGeneralModal={projectGeneralModal}
      projectGoodsModal={projectGoodsModal} budgetSummaryModal={budgetSummaryModal} userData={userData} historyRecordModal={historyRecordModal}
      projectObjectivesResults={projectObjectivesResults} projectBudget={projectBudget} projectPaymentsModal={projectPaymentsModal} financingSources={financingSources}
      activityStatus={activityStatus} academicUnits={academicUnits} expenseTypes={expenseTypes} subTaskTypes={subTaskTypes} projectPeriods={projectPeriods}
      projectSubTasksByPeriod={projectSubTasksByPeriod} projectGoods={projectGoods} projectStateModal={projectStateModal} roles={roles}
      projectStatusList={projectStatusList}
    />
  )
}

export default ProjectDetailsContainer;
