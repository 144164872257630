import React from 'react';

import {Card, CardContent, Typography, } from '@material-ui/core';

import ProjectActivityDetails from './ProjectActivityDetails/ProjectActivityDetails';
import GoalTabs from './ProjectGoalDetails/GoalTabs/GoalTabs';
// import ProjectGoalDetails from './ProjectGoalDetails/ProjectGoalDetails';
import ProjectObjectiveDetails from './ProjectObjectiveDetails/ProjectObjectiveDetails';

import { useDispatch, } from 'react-redux';
import {setLoading, handleNotifications, getSpecificPurposes, createSpecificPurpose, createMilestone, createActivity, editActivity,
  editMilestone, deleteActivity, deleteMilestone, saveGoalFile, deleteGoalFile, } from '../../../redux/actions';

import {getItemType, } from '../../Utils/Functions/ProjectHelperFunctions';
import alert from '../../Utils/Alert/Alert';

const ProjectActivitiesItems = ({project, itemSelected, edit, setEdit, itemForm, setItemForm, objective, activityStatus, userRole, userPermissionsList, }) => {

  const dispatch = useDispatch();

  const handleItemEdit = (e) => {
    setItemForm({...itemForm, [e.target.name]: e.target.value});
  }

  const handleNewActivity = () => {
    const data = {
      ...itemForm,
    }
    dispatch(createActivity(data, project?.id));
  }

  const handleNewMilestone = () => {
    const data = {
      ...itemForm,
    }
    dispatch(createMilestone(data, project?.id));
  }

  const handleNewObjective = () => {
    dispatch(createSpecificPurpose(itemForm, project?.id));
  }

  const handleEditActivity = () => {
    dispatch(editActivity(itemForm, project?.id));
  }

  const handleEditMilestone = () => {
    const filterFiles = itemForm.milestoneVerification.filter(file => itemSelected.milestoneVerification.indexOf(file) === -1);
    dispatch(editMilestone(itemForm, project?.id));
  }

  const addGoalFiles = (files) => {
    dispatch(saveGoalFile(itemForm.id, files, project?.id));
  }

  const deleteFile = (fileId, reason) => {
    dispatch(deleteGoalFile(fileId, reason, project?.id));
  }

  const handleDeleteActivity = () => {
    const deleteAct = () => dispatch(deleteActivity(itemForm, project?.id));
    const alertData = {
      title: `Seguro que desea eliminar esta actividad?`,
      text: 'No se podrá recuperar',
      acceptFunction: deleteAct
    }
    alert(alertData);
  }

  const handleDeleteMilestone = () => {
    const deleteMile = () => dispatch(deleteMilestone(itemForm, project?.id));
    const alertData = {
      title: `Seguro que desea eliminar este hito?`,
      text: 'No se podrá recuperar',
      acceptFunction: deleteMile
    }
    alert(alertData);
  }

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {
            itemForm.type === 'new' ? 'Nuevo Objetivo' : getItemType(itemForm.type)
          }
        </Typography>
      </CardContent>
      {
        {
          'goal': <GoalTabs edit={edit} itemForm={itemForm} setItemForm={setItemForm} handleItemEdit={handleItemEdit}
            handleEditMilestone={handleEditMilestone} handleNewMilestone={handleNewMilestone}
            handleDeleteMilestone={handleDeleteMilestone} addGoalFiles={addGoalFiles} deleteFile={deleteFile}
            userRole={userRole} userPermissionsList={userPermissionsList}
          />,
          'objective': <ProjectObjectiveDetails edit={edit} itemForm={itemForm} handleItemEdit={handleItemEdit}
            handleNewObjective={handleNewObjective}
          />,
          'activity': <ProjectActivityDetails setItemForm={setItemForm} edit={edit} itemForm={itemForm} handleItemEdit={handleItemEdit}
            handleNewActivity={handleNewActivity} handleEditActivity={handleEditActivity}
            handleDeleteActivity={handleDeleteActivity} activityStatus={activityStatus}
            userRole={userRole} userPermissionsList={userPermissionsList}
          />,
          'new': <ProjectObjectiveDetails edit={edit} itemForm={itemForm} handleItemEdit={handleItemEdit}
            handleNewObjective={handleNewObjective}
          />,
        }[itemForm.type]
      }
    </Card>
  )
}

export default ProjectActivitiesItems;
