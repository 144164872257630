import React, {useState, useEffect, } from 'react';

import {Dialog, DialogContent, DialogActions, DialogTitle, Button, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleProjectGroupTeamModal, getGroupTeams, } from '../../../../redux/actions';

import ProjectDetailsModalGroupTeamTabs from './ProjectDetailsModalGroupTeamTabs';
import SecondaryButton from '../../../Utils/Buttons/SecondaryButton';

const ProjectDetailsModalGroupTeam = ({project, projectGroupTeamModal, }) => {

  const dispatch = useDispatch();

  const {show, projectId, groupTeams} = projectGroupTeamModal;

  useEffect(() => {
    if (show) {
      dispatch(getGroupTeams(projectId));
    }
  }, [show]);

  const handleClose = () => dispatch(handleProjectGroupTeamModal());

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'xg'}
      fullWidth={true}
    >
      <DialogTitle>
        Equipo de Trabajo
      </DialogTitle>
      <DialogContent>
        <ProjectDetailsModalGroupTeamTabs project={project} groupTeams={groupTeams} />
      </DialogContent>
      <DialogActions>
        <SecondaryButton title={'Cerrar'} onClick={handleClose} />
        {/*<Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>*/}
      </DialogActions>
    </Dialog>
  )
}

export default ProjectDetailsModalGroupTeam;
