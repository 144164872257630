const initialState = {
  subTaskTypes: []
}

const subTaskTypesReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setSubTaskTypes':
      return {
        ...state,
        subTaskTypes: action.payload.subTaskTypes
      }
    default:
      return state;
  }
}

export default subTaskTypesReducer;
