import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {FormControl, InputLabel, Select, MenuItem, } from '@material-ui/core';

import {getSelectFormat, } from '../Functions/DateFunctions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  textFields: {
    width: '70%'
  }
}));

const PeriodSelect = ({periods, form, setForm, disabled, }) => {

  const classes = useStyles();

  const handlePeriod = (e) => {
    const {value} = e.target;
    const findPeriod = periods.find(period => period.id === value);
    if (findPeriod) {
      setForm(form => ({...form, period: findPeriod}));
    }
    else {
      setForm(form => ({...form, period: {
        id: -1,
        startDate: null,
        endDate: null
      }}));
    }
  }

  return (
    <FormControl id="period" variant="outlined" className={classes.formControl} disabled={disabled}>
      <InputLabel id="period">Período</InputLabel>
      <Select
        name="period"
        label="Período"
        value={form.period.id}
        onChange={handlePeriod}
        variant="outlined"
      >
        <MenuItem value={-1}>-</MenuItem>
        {
          periods && periods.map(period => {
            const startDate = new Date(period.startDate);
            const endDate = new Date(period.endDate);
            const startDateFormat = getSelectFormat(startDate, endDate);
            return (
              <MenuItem key={period.id} value={period.id}>{startDateFormat}</MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  )
}

export default PeriodSelect;
