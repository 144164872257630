import React, {useState, useEffect} from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import {Table, TableContainer, Paper, Collapse, } from '@material-ui/core';

import BudgetSummaryHeader from './BudgetSummaryHeader';
import BudgetSummaryBody from './BudgetSummaryBody';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    marginBottom: 10
  },
});


const BudgetSummaryTable = ({field, fieldIdx, edit, handleChange, newSubcategory, deleteCategory, deleteSubcategory, handleChangeName, }) => {

  let i = 0;
  
  const classes = useStyles();
  const total = Object.values(field.items).reduce((t, {amount}) => t + (parseInt(amount) || 0), 0);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (field.new && i === 0) {
      setOpen(true);
      i = i+1;
    }
  }, [field]);

  const handleOpen = () => setOpen(open => !open);

  return (
    <>
      <Table className={classes.table} aria-label="customized table">
        <BudgetSummaryHeader field={field} total={total} show={true} handleOpen={handleOpen} open={open} edit={edit} fieldIdx={fieldIdx}
          handleChangeName={handleChangeName} deleteCategory={deleteCategory}
        />
      </Table>
      <Collapse in={open}>
        <Table  className={classes.table} aria-label="customized table">
          <BudgetSummaryBody items={field.items} fieldIdx={fieldIdx} edit={edit} handleChange={handleChange} newSubcategory={newSubcategory}
            deleteCategory={deleteCategory} deleteSubcategory={deleteSubcategory}
          />
        </Table>
      </Collapse>
    </>
  )
}

export default BudgetSummaryTable;
