import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Typography, Button, Accordion, AccordionSummary, AccordionDetails, Grid, Tooltip, Fab, } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

import { useDispatch, } from 'react-redux';
import {handleNotifications, handleSubTaskModal, setLoading, handleFilesManagerModal, } from '../../../../../redux/actions';

import ExtensionForm from '../ClosingForms/ExtensionForm/ExtensionForm';

import alert from '../../../../Utils/Alert/Alert';

import FilesManager from '../../../../Utils/FilesManager/FilesManager';
import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
// import ProjectDetailsSubtasksTable from './ProjectDetailsSubtasksTable/ProjectDetailsSubtasksTable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '0.5px solid grey',
    marginBottom: '10px'
  },
  title: {
    fontSize: 18,
  },
}));

const ProjectDetailsProlongation = ({project, prolongation, prolongationIdx, }) => {

  const dispatch = useDispatch();
  const classes = useStyles();

  const [edit, setEdit] = useState(false);
  const [extensionForm, setExtensionForm] = useState({
    validityTime: 0,
    implamentationTime: 0,
    files: []
  });

  useEffect(() => {
    setExtensionForm({...prolongation, validityTime: 12, implamentationTime: 12})
  }, [prolongation]);

  const handleChange = (e) => setExtensionForm(extensionForm => ({...extensionForm, [e.target.name]: e.target.value}));

  const handleFile = (file) => {
    setExtensionForm(extensionForm => ({...extensionForm, files: [...extensionForm.files, file]}));
  }

  const addFile = () => {
    const data = {
      title: 'Seleccionar archivo de comprobante de pago',
      files: extensionForm.files,
      addFile: handleFile
    }
    dispatch(handleFilesManagerModal(true, data, 'unique', false));
  }

  // const addFiles = (file, type) => {
  //   addFilesToTask(activityIdx, taskIdx, file, type);
  //   dispatch(handleNotifications(true, {
  //     status: 'success',
  //     message: 'Archivo agregado correctamente'}
  //   ));
  // }
  //
  // const addNewSubTask = () => {
  //   const addSubtask = (subTask) => {
  //     addSubtaskToTask(activityIdx, taskIdx, subTask);
  //   }
  //   dispatch(handleSubTaskModal(true, 'create', {
  //     taskIdx: taskIdx,
  //     taskType: task.taskType,
  //     subTaskForm: {
  //       description: '',
  //       period: 'none',
  //       subTaskType: 'purchaseOrder',
  //       file: {},
  //       amount: 0,
  //       inventoryable: false
  //     },
  //     handleSubTask: addSubtask
  //   }));
  // }
  //
  // const openSubTaskModal = (subTask) => {
  //   const editSubtask = (subTask) => {
  //     // addSubtaskToTask(activityIdx, taskIdx, subTask);
  //   }
  //   dispatch(handleSubTaskModal(true, 'edit', {
  //     taskIdx: taskIdx,
  //     taskType: task.taskType,
  //     subTaskForm: {
  //       ...subTask
  //     },
  //     handleSubTask: editSubtask
  //   }));
  // }

  const handleEdit = () => setEdit(edit => !edit);

  const handleDelete = () => {
    const deleteItem = () => {
      dispatch(setLoading(true));
      setEdit(false);
      dispatch(setLoading(false));
    }
    const alertData = {
      title: `Seguro que desea eliminar esta prolongación?`,
      text: 'No se podrá recuperar',
      acceptFunction: deleteItem
    }
    alert(alertData);
  }

  return (
    <Accordion >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <Typography className={classes.heading}>{`Prórroga 15 de marzo 2021`}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{display: 'block'}}>
        {
          edit ?
          <Grid container justify="flex-end">
            <Tooltip title="Cancelar">
              <Fab size="small" color="primary" onClick={handleEdit} style={{backgroundColor: '#B1B1B1'}}>
                <CancelIcon />
              </Fab>
            </Tooltip>
          </Grid>
          :
          <Grid container justify="flex-end">
            <Tooltip title="Editar prolongación">
              <Fab size="small" color="primary" onClick={handleEdit} style={{backgroundColor: '#B1B1B1'}}>
                <EditIcon />
              </Fab>
            </Tooltip>
          </Grid>
        }
        {
          prolongation.type === 'extension' &&
          <ExtensionForm project={project} extensionForm={extensionForm} handleChange={handleChange} edit={edit} type={'edit'}/>
        }
        {
          edit &&
          <PrimaryButton title={'Agregar archivo'} onClick={addFile} />
        }
        <div style={{marginTop: 20, marginBottom: 20}}>
          <FilesManager title={`Archivos de ${prolongation.type === 'extension' ? 'extensión' : 'prórroga'}`} files={extensionForm.files}
            edit={false} deleteButton={true}
          />
        </div>
        {
          edit &&
          <div style={{display: 'flex'}}>
            <Grid justify="flex-start">
              <Tooltip title="Eliminar prolongación">
                <Fab size="small" color="primary" onClick={handleDelete} style={{backgroundColor: '#B1B1B1'}}>
                  <DeleteForeverIcon />
                </Fab>
              </Tooltip>
            </Grid>
            <Grid container justify="flex-end">
              <Tooltip title="Guardar cambios">
                <Fab size="small" color="primary" onClick={handleDelete} style={{backgroundColor: '#EA7600'}}>
                  <SaveIcon />
                </Fab>
              </Tooltip>
            </Grid>
          </div>
        }
      </AccordionDetails>
    </Accordion>
  )
}

export default ProjectDetailsProlongation;
