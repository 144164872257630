import React, {useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, Typography, Box, } from '@material-ui/core';

import InventoryFiles from './InventoryFiles/InventoryFiles';
import GoodsList from './GoodsList/GoodsList';

import FilterTabsComponent from '../../../../Utils/FilterComponent/FilterTabsComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const InventoryTabs = ({project, projectPeriods, academicUnits, projectGoods, }) => {

  const classes = useStyles();
  const {goodsFile, goodsList} = projectGoods;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      permission: 'accumulatedInventory.index',
      label: 'Inventario Acumulado',
      component: <InventoryFiles project={project} goodsFile={goodsFile} />
    },
    {
      permission: 'inventoryProperty.index',
      label: 'Lista de Bienes',
      component: <GoodsList project={project} projectPeriods={projectPeriods} goodsList={goodsList}
        academicUnits={academicUnits}
      />
    },
  ]

  return (
    <div className={classes.root}>
      <FilterTabsComponent value={value} handleChangeTab={handleChange} tabs={tabs} />
      {/*<AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab label="Inventario Acumulado" {...a11yProps(0)} />
          <Tab label="Lista de Bienes" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <InventoryFiles project={project} goodsFile={goodsFile} active={value === 0} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GoodsList project={project} projectPeriods={projectPeriods} goodsList={goodsList}
          academicUnits={academicUnits} active={value === 1}
        />
      </TabPanel>*/}
    </div>
  );
}

export default InventoryTabs;
