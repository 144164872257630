import axios from 'axios';
import clientAxios from '../../config/axios';
import {downloadFileAxios} from '../../config/manageFileAxios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const downloadFile = (url, fileName) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      await downloadFileAxios(url, fileName);
      dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al descargar el archivo'}
      ));
      dispatch(setLoading(false));
    }
  }
}
