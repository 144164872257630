import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import ProjectDetailsSection from '../../components/ProjectDetailsSection/ProjectDetailsSection';

const ProjectsSelector = createSelector(
  state => state.projects,
  projects => projects
);

const ProjectDetailsSectionContainer = () => {

  const projectsData = useSelector(ProjectsSelector);

  return (
    <ProjectDetailsSection projectsData={projectsData} />
  )
}

export default ProjectDetailsSectionContainer;
