import React, {useState, useEffect} from 'react';

import {Form, Row, Col, } from 'react-bootstrap';

import CreateProjectFormName from '../../CreateProject/CreateProjectForm/CreateProjectFormHeader/CreateProjectFormName';
import CreateProjectFormDates from '../../CreateProject/CreateProjectForm/CreateProjectFormHeader/CreateProjectFormDates';
// import CreateProjectFormObjectives from '../../CreateProject/CreateProjectForm/CreateProjectFormGeneral/CreateProjectFormObjectives';

const ProjectDataEditForm = ({projectForm, setProjectForm, }) => {

  const handleChangeProjectForm = (e) => {
    setProjectForm({...projectForm, [e.target.id]: e.target.value});
  }

  return(
    <Form>
      {
        projectForm.currentPhase !== 3 &&
        <Row>
          <CreateProjectFormName projectForm={projectForm} handleChangeProjectForm={handleChangeProjectForm} />
        </Row>
      }
      <CreateProjectFormDates projectForm={projectForm} handleChangeProjectForm={handleChangeProjectForm} />

      {/*
        projectForm.currentPhase !== 3 &&
          <CreateProjectFormObjectives projectForm={projectForm} setProjectForm={setProjectForm} />
      */}

    </Form>
  )
}

export default ProjectDataEditForm;
