import React from 'react';

import {TableHead, TableRow, TableCell, } from '@material-ui/core';

const HistoryRecordHeader = ({showDownloadButton, }) => {

  return (
    <TableHead>
      <TableRow>
        <TableCell>Fecha</TableCell>
        <TableCell>Usuario</TableCell>
        <TableCell>Motivo</TableCell>
        <TableCell>Acción</TableCell>
        <TableCell>Archivo</TableCell>
        {
          showDownloadButton &&
          <TableCell>Acciones</TableCell>
        }
      </TableRow>
    </TableHead>
  )
}

export default HistoryRecordHeader;
