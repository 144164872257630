const initialState={
  budgetFile: {},
  budgetResume: []
}

const projectBudgetReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setBudgetFile':
      return {
        ...state,
        budgetFile: action.payload.budgetFile
      }
    case 'setBudgetResume':
      return {
        ...state,
        budgetResume: action.payload.budgetResume
      }
      break;
    default:
      return state;
  }
}

export default projectBudgetReducer;
