import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Dialog, DialogContent, DialogActions, DialogTitle, Button, Grid, Typography, IconButton, Tooltip, Popper, ClickAwayListener, Paper, } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

import { useDispatch, } from 'react-redux';
import {handleTaskModal, setLoading, } from '../../../../redux/actions';

import TaskForm from './TaskForms/TaskForm';
import TaskShow from './TaskShow';
import {getDateFormat, } from '../../../Utils/Functions/DateFunctions';

import alert from '../../../Utils/Alert/Alert';

import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 10000,
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.5)",
    zIndex: 1301,
    maxHeight: 200,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  }
}));


const ProjectDetailsTaskModal = ({projectTaskModal, userData, expenseTypes, academicUnits, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const {show, type, taskData} = projectTaskModal;

  const [taskForm, setTaskForm] = useState({
    name: '',
    memoCode: '',
    date: new Date(),
    ddiCode: '',
    academicUnitId: 0,
    maxAmount: 0,
    applicantName: '',
    taskTypeId: '',
    observation: '',
    subTasks: []
  });
  const [edit, setEdit] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setEdit(type === 'create');
  }, [show]);

  useEffect(() => {
    setTaskForm(taskData.task);
  }, [taskData]);

  const handleClose = () => dispatch(handleTaskModal());

  const handleTasks = () => {
    const {handleTask, activityId} = taskData;
    handleTask(taskForm);
    dispatch(handleTaskModal());
  }

  const handleDelete = () => {
    const {deleteTask} = taskData;
    const alertData = {
      title: `Seguro que desea eliminar esta tarea?`,
      text: 'No se podrá recuperar',
      acceptFunction: deleteTask
    }
    alert(alertData);
  }

  const handleEditButton = () => setEdit(edit => !edit);

  const handleOpenInfo = (event) => {
    setAnchorEl(anchorEl => anchorEl ? null : event.currentTarget);
  };

  const handleCloseInfo = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        {
          type === 'create' ?
          <>
            Crear tarea
          </>
          :
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="h5">
                Tarea
              </Typography>
            </Grid>
            {/*
              !edit &&
              <Grid item xs={2}>
                <div style={{display: 'flex'}}>
                <Button onClick={handleEditButton} disabled={taskForm.subTasks.length > 0}>
                  Editar
                </Button>

                </div>
              </Grid>
            */}
          </Grid>
        }
      </DialogTitle>
      <DialogContent>
        <FilterComponent permission={type === 'create' ? 'task.create' : 'task.update'}>
          <TaskForm taskForm={taskForm} setTaskForm={setTaskForm} academicUnits={academicUnits} expenseTypes={expenseTypes} />
        </FilterComponent>
        {/*
          edit ?
          :
          <TaskShow taskForm={taskForm} />
        */}
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid container justify="flex-start">
            <FilterComponent permission={'task.create'}>
              {
                type !== 'create' &&
                <div style={{marginLeft: 18, marginBottom: -40}}>
                  <FilterComponent permission={'task.delete'}>
                    <SecondaryButton title={'Eliminar'} variant="secondary" onClick={handleDelete}
                      disabled={taskForm.subTasks?.filter(subTask => subTask.available)?.length > 0}
                    />
                  </FilterComponent>
                </div>
              }
            </FilterComponent>
          </Grid>
          <Grid container justify="flex-end">
            <SecondaryButton title={'Cerrar'} onClick={handleClose} />
            {
              type === 'create' ?
              <FilterComponent permission={'task.create'}>
                <PrimaryButton title={'Crear tarea'} onClick={handleTasks} />
              </FilterComponent>
              :
              <>
                <FilterComponent permission={'task.update'}>
                  <PrimaryButton  title={'Editar tarea'} onClick={handleTasks} disabled={taskForm.subTasks?.filter(subTask => subTask.available)?.length > 0}/>
                </FilterComponent>
                {
                  taskForm?.subTasks?.length > 0 &&
                  <ClickAwayListener onClickAway={handleCloseInfo}>
                    <div>
                      <Tooltip aria-describedby={id} title={'Información'} disableFocusListener onClick={handleOpenInfo}>
                        <IconButton>
                          <HelpIcon />
                        </IconButton>
                      </Tooltip>
                      <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        className={classes.popper}
                      >
                        <Paper className={classes.paper}>
                          La tarea posee subtareas asociadas, esto impide que se pueda editar o eliminar
                        </Paper>
                      </Popper>
                    </div>
                  </ClickAwayListener>
                }
              </>
            }
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ProjectDetailsTaskModal;
