import React from 'react';

// import { useSelector } from 'react-redux';
// import { createSelector } from 'reselect';

import Login from '../../components/Auth/Login';

const LoginContainer = () => {

  return (
    <Login/>
  )
}

export default LoginContainer;
