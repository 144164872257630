export const handleInventoryModal = (show, type, inventoryData) => {
  if (show) {
    return dispatch => dispatch(showInventoryModal(type, inventoryData));
  }
  else {
    return dispatch => dispatch(closeInventoryModal());
  }
}

const showInventoryModal = (type, inventoryData) => ({
  type: 'showInventoryModal',
  payload: {
    type: type,
    inventoryData: inventoryData
  }
})

const closeInventoryModal = () => ({
  type: 'closeInventoryModal',
  payload: {}
})
