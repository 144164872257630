import React from 'react';

import {TableCell, TableRow, FormControlLabel, Checkbox, TextField, Button, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

// import {getSubTaskType, } from '../../../../Utils/Functions/TaskFunctions';

const IndicatorGoalsTableRow = ({indicatorForm, type, handleChange, edit, handleIndicatorGoals, newIndicator, }) => {

  return (
    <TableRow>
      <TableCell>
        {
          edit ?
          <TextField
            name="baseLine"
            label="Línea Base"
            value={indicatorForm.baseLine}
            onChange={handleChange}
          />
          :
          <>
            {indicatorForm.baseLine}
          </>
        }
      </TableCell>
      {
        indicatorForm.indicatorGoal?.map((indicatorGoal, indicatorGoalIdx) =>
          <TableCell>
            {
              edit ?
              <TextField
                label={`Meta Año ${indicatorGoalIdx + 1}`}
                value={indicatorGoal.goal}
                onChange={e => handleIndicatorGoals(e, indicatorGoalIdx)}
              />
              :
              <>
                {indicatorGoal.goal}
              </>
            }
          </TableCell>
        )
      }
      {
        edit &&
        <TableCell>
          <Button onClick={newIndicator}>
            +
          </Button>
        </TableCell>
      }
    </TableRow>
  )
}

export default IndicatorGoalsTableRow;
