import React, {useState, useEffect, } from 'react';

import clientAxios from '../../../../config/axios';

import { makeStyles } from '@material-ui/core/styles';
import {Card, CardContent, CardActions, Typography, Button, TextField, Divider, Accordion, Collapse, Breadcrumbs, Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleTaskModal, handleNotifications, setLoading, } from '../../../../redux/actions';

import {getDateFormat, } from '../../../Utils/Functions/DateFunctions';
import ProjectDetailsTasks from './ProjectDetailsTasks/ProjectDetailsTasksView';

import FilterComponent from '../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    border: '1px solid grey',
    marginBottom: 20
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 20,
  },
  pos: {
    marginBottom: 12,
  },
  title: {
    fontSize: 14,
  },
  divisor: {
    marginLeft: 5,
    marginRight: 5
  },
  treePoints: {
    cursor: 'pointer',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
});

const ProjectDetailsActivity = ({activityId, generalPurposesData, activity, activityIdx, addTaskToActivity, addFilesToTask, deleteFileOfTask,
  addSubtaskToTask, projectPeriods, }) => {

  const dispatch = useDispatch();
  const classes = useStyles();

  const [tasks, setTasks] = useState([]);
  const [addTask, setAddTask] = useState(false);
  const [newTask, setNewTask] = useState('');
  const [show, setShow] = useState(false);
  const [changed, setChanged] = useState(false);
  const [showed, setShowed] = useState(1);

  useEffect(async () => {
    if (show) {
      try {
        dispatch(setLoading(true));
        const {data} = await clientAxios().get(`/api/activity/${activity.id}`);
        console.log(data);
        // setTasks(data);
        setTasks(data.tasks || []);
        dispatch(setLoading());
      } catch (e) {
        dispatch(handleNotifications(true, {
          status: 'error',
          message: 'Hubo un error al obtener las tareas'}
        ));
        dispatch(setLoading());
      }
    }
  }, [activity, show, changed]);

  const addNewTask = async (newTask) => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const taskData = {
        ...newTask,
        activityId: activity.id,
        userId: userData.userId,
      }
      const {data} = await clientAxios().post(`/api/task/add`, taskData);
      setChanged(changed => !changed);
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Tarea agregada correctamente'}
      ));
    } catch (e) {
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al agregar la tarea'}
      ));
      dispatch(setLoading());
    }
  }

  const openTaskModal = () => {
    let today = new Date()
    dispatch(handleTaskModal(true, 'create', {
      activityId: activity.id,
      task: {
        name: '',
        memoCode: '',
        date: `${today.getFullYear()}-${(today.getMonth()+1) < 10 ? '0' + (today.getMonth()+1) : today.getMonth()+1}-${today.getDate() < 10 ? '0' + (today.getDate()) : today.getDate()}`,
        ddiCode: '',
        academicUnitId: '',
        maxBudget: 0,
        applicantName: '',
        expenseTypeId: '',
        observations: '',
      },
      handleTask: addNewTask
    }));
  }

  const showMore = () => {
    if (showed === 3) {
      setShowed(1);
    }
    else {
      setShowed(showed => showed + 1);
    }
  }
  console.log(activity);

  return (
    <Card className={classes.root}>
      <CardContent>
        <div style={{display: 'flex'}}>
          <Typography className={classes.treePoints} color="textSecondary" onClick={showMore} gutterBottom>
          {
            showed < 3 ?
              '...' : '->'
          }
          </Typography>
          {
            showed > 2 &&
            <>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {generalPurposesData.entities?.generalPurposes[generalPurposesData.entities?.specificPurposes[generalPurposesData.entities?.milestones[generalPurposesData.entities?.activities[activity.id].milestoneId].specificPurposeId].generalPurposeId].name}
              </Typography>
            </>
          }
          {
            showed > 1 &&
            <>
              <Typography className={classes.divisor} color="textSecondary" gutterBottom>
                /
              </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {generalPurposesData.entities?.specificPurposes[generalPurposesData.entities?.milestones[generalPurposesData.entities?.activities[activity.id].milestoneId].specificPurposeId].name}
              </Typography>
            </>
          }
          <Typography className={classes.divisor} color="textSecondary" gutterBottom>
            /
          </Typography>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {generalPurposesData.entities?.milestones[generalPurposesData.entities?.activities[activity.id].milestoneId].name}
          </Typography>
        </div>
        <Grid container>
          <Grid item xs={12} md={9} lg={10}>
            <Typography variant="h5" gutterBottom>
              {`${activity.identifier || ''} ${activity.name}`}
            </Typography>
          </Grid>
          <Grid item xs={2} md={3} lg={2}>
            <div className={classes.dates}>
              <Typography className={classes.miniTitle}>
                {`Estado:`}
              </Typography>
              <Typography >
                {`${activity.activityStatus?.name}`}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <FilterComponent permission={'task.index'}>
          <Collapse in={show}>
            <Typography>
              Lista de Tareas
            </Typography>
            {
              tasks?.sort((a, b) => (a.id - b.id)).map((task, taskIdx) =>
                <ProjectDetailsTasks key={task.taskId} activityId={activity.id} changed={changed} setChanged={setChanged} task={task} taskIdx={taskIdx}
                  activityIdx={activityIdx} addTaskToActivity={addTaskToActivity} addFilesToTask={addFilesToTask} deleteFileOfTask={deleteFileOfTask}
                  addSubtaskToTask={addSubtaskToTask} projectPeriods={projectPeriods}
                />
              )
            }
            {
              addTask &&
              <>
                <TextField id="outlined-basic" label="Nueva tarea" variant="outlined" value={newTask} onChange={e => setNewTask(e.target.value)} />
                <Button onClick={e => addNewTask(e)}>
                  Agregar tarea
                </Button>
                <Button onClick={() => setAddTask(false)}>
                  Cancelar
                </Button>
              </>
            }
          </Collapse>
        </FilterComponent>
      </CardContent>
      <FilterComponent permission={'task.index'}>
        <Divider />
        <CardActions>
          <Button onClick={() => setShow(show => !show)}>
            {show ? 'Ocultar tareas' : 'Ver tareas'}
          </Button>
          <FilterComponent permission={'task.create'}>
            {
              !addTask && show && !(activity.activityStatus?.id === 4) &&
              <Button onClick={openTaskModal}>
                Nueva tarea
              </Button>
            }
          </FilterComponent>
        </CardActions>
      </FilterComponent>
    </Card>
  )
}

export default ProjectDetailsActivity;
