import React, {useState, useEffect, useRef, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Popper, Tooltip, IconButton, Paper, ClickAwayListener, } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';

import Filters from './Filters';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  popper: {
    zIndex: 10000,
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.5)",
    zIndex: 1301,
    maxHeight: 200,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  }
}));

const TableFilters = ({allFiles, handleAllFiles, }) => {

  const classes = useStyles();
  const wrapperref = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl => anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Tooltip aria-describedby={id} title={'Filtrar'} disableFocusListener onClick={handleClick}>
            <IconButton
            // data-testid={viewColumns + '-iconButton'}
            // aria-label={viewColumns}
            // classes={{ root: this.getActiveIcon(classes, 'viewcolumns') }}
            // disabled={options.viewColumns === 'disabled'}
            // onClick={this.setActiveIcon.bind(null, 'viewcolumns')}
            >
              <FilterIcon />
            </IconButton>
          </Tooltip>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            className={classes.popper}
          >
            <Paper className={classes.paper}>
              <Filters allFiles={allFiles} handleAllFiles={handleAllFiles} handleClose={handleClose}/>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  )
}

export default TableFilters;
