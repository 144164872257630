import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, FormControl, InputLabel, Select, MenuItem, Typography, Button, } from '@material-ui/core';

import SubTasks from './SubTaskForms/SubTasks';

import TextField from '../../../../Utils/TextFields/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  buttons: {
    margin: '20px 200px 50px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  textFields: {
    minWidth: 360
  }
}));

const TaskForm = ({taskForm, setTaskForm, expenseTypes, academicUnits, }) => {

  const classes = useStyles();

  const handleChange = (e) => setTaskForm(taskForm => ({...taskForm, [e.target.name]: e.target.value}));

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container>
          <Grid item xs={12} lg={12}>
            <TextField
              name="name"
              label="Nombre de la tarea"
              variant="outlined"
              value={taskForm.name}
              onChange={handleChange}
              classStyle={classes.textFields}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} lg={6}>
            <TextField
              name="memoCode"
              label="Código Memo"
              variant="outlined"
              value={taskForm.memoCode}
              onChange={handleChange}
              classStyle={classes.textFields}
            />
          </Grid>
          <Grid item xs={6} lg={6}>
            <TextField
              name="date"
              label="Fecha"
              variant="outlined"
              value={taskForm.date}
              onChange={handleChange}
              classStyle={classes.textFields}
              type='date'
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} lg={6}>
            <TextField
              name="ddiCode"
              label="Código DDI"
              variant="outlined"
              value={taskForm.ddiCode}
              onChange={handleChange}
              classStyle={classes.textFields}
            />
          </Grid>
          <Grid item xs={6} lg={6}>
            <FormControl id="academicUnitId"
              variant="outlined" className={classes.formControl}>
              <InputLabel id="academicUnitId">Unidad Académica</InputLabel>
              <Select
                name="academicUnitId"
                value={taskForm.academicUnitId}
                onChange={handleChange}
              >
                {
                  academicUnits.map(academicUnit =>
                    <MenuItem value={academicUnit.id}>{academicUnit.name}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} lg={6}>
            <TextField
              name="maxAmount"
              label="Monto Máximo"
              variant="outlined"
              value={taskForm.maxAmount}
              onChange={handleChange}
              classStyle={classes.textFields}
              type='number'
            />
          </Grid>
          <Grid item xs={6} lg={6}>
            <TextField
              name="applicantName"
              label="Nombre de Solicitante"
              variant="outlined"
              value={taskForm.applicantName}
              onChange={handleChange}
              classStyle={classes.textFields}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} lg={6}>
            <FormControl id="expenseTypeId"
              variant="outlined" className={classes.formControl}>
              <InputLabel>Tipo de Gasto</InputLabel>
              <Select
                name="expenseTypeId"
                value={taskForm.expenseTypeId}
                onChange={handleChange}
              >
                {
                  expenseTypes.map(expenseType =>
                    <MenuItem value={expenseType.id}>{expenseType.name}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} lg={6}>
            <TextField
              name="observation"
              label="Observaciones"
              variant="outlined"
              multiline
              rows={2}
              value={taskForm.observation}
              onChange={handleChange}
              classStyle={classes.textFields}
            />
          </Grid>
        </Grid>
        {/*<SubTasks taskForm={taskForm} setTaskForm={setTaskForm} />*/}
      </form>
    </>
  )
}

export default TaskForm;
