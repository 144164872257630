import React from 'react';

import ProjectActivityDetailsShow from './ProjectActivityDetailsShow';
import ProjectActivityDetailsEdit from './ProjectActivityDetailsEdit';

const ProjectActivityDetails = ({edit, itemForm, setItemForm, handleItemEdit, saveItemDetails, handleNewActivity, handleEditActivity, handleDeleteActivity,
  activityStatus, userRole, userPermissionsList, }) => {

  return (
    <>
      {
        ((edit && (userRole || userPermissionsList.find(permission => permission.slug === 'activity.update'))) || itemForm.creating) ?
        <ProjectActivityDetailsEdit itemForm={itemForm} setItemForm={setItemForm} handleItemEdit={handleItemEdit} saveItemDetails={saveItemDetails}
          handleNewActivity={handleNewActivity} handleEditActivity={handleEditActivity} handleDeleteActivity={handleDeleteActivity} activityStatus={activityStatus}
        />
        :
        <ProjectActivityDetailsShow itemForm={itemForm} />
      }
    </>
  )
}

export default ProjectActivityDetails;
