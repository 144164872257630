import React, {useState, } from 'react';

import {IconButton, Menu, MenuItem, Divider, } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const ProjectActivitiesMenu = ({itemSelected, anchorEl, setAnchorEl, open, handleOpenMenu, handleCloseMenu, edit, saveItems, deleteItem, editItems, }) => {

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={edit ? saveItems : editItems}>
          {`${edit ? 'Terminar edición' : 'Editar'}`}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ProjectActivitiesMenu;
