export const handleFilesManagerModal = (show, filesManagerData, uploadType, unique) => {
  if (show) {
    return dispatch => dispatch(showFilesManagerModal(filesManagerData, uploadType, unique));
  }
  else {
    return dispatch => dispatch(closeFilesManagerModal());
  }
}

const showFilesManagerModal = (filesManagerData, uploadType, unique) => ({
  type: 'showFilesManagerModal',
  payload: {
    filesManagerData: filesManagerData,
    uploadType: uploadType,
    unique: unique
  }
})

const closeFilesManagerModal = () => ({
  type: 'closeFilesManagerModal',
  payload: {}
})
