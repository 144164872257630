const initialState = {
  objectivesResults: []
}

const projectObjectivesResultsReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setObjectivesResults':
      return {
        ...state,
        objectivesResults: action.payload.objectivesResults
      }
    default:
      return state;
  }
}

export default projectObjectivesResultsReducer;
