import React, {useState, useEffect} from 'react';

import {useParams} from "react-router-dom";

import { uuid } from 'uuidv4';

import {Form, Button, Container, InputGroup, } from 'react-bootstrap';

import { useDispatch, } from 'react-redux';
import { push } from 'connected-react-router';
import {handleEditProject, handleSectionModal, setLoading, } from '../../../redux/actions';

import ProjectDetailsSectionModal from '../ProjectDetailsSectionModal/ProjectDetailsSectionModal';
import ProjectDetailsPhase from './ProjectDetailsPhase';

const ProjectPhasesEdit = ({currentProject, sectionModalData, }) => {

  console.log(currentProject);

  const dispatch = useDispatch();
  const {projectCode} = useParams();
  // const {projects} = projectsData;

  const [project, setProject] = useState({});
  // const [projectPhases, setProjectPhases] = useState([
  //   {
  //     phaseName: '',
  //     categories: [{
  //       categoryName: '',
  //       categoryId: uuid(),
  //       sections: []
  //     }]
  //   }
  // ]);
  const [projectPhases, setProjectPhases] = useState([]);
  const [projectNumberPhases, setProjectNumberPhases] = useState([]);
  const [activePhase, setActivePhase] = useState({});

  // useEffect(() => {
  //   setActivePhase(project.financingLine);
  // }, [project]);

  useEffect(() => {
    // const projectFind = projects.find(project => project.mineducCode === projectCode);
    setProject(currentProject);
    // setProjectPhases((projectFind && projectFind.phases) || [
    //   {
    //     phaseName: '',
    //     categories: [{
    //       categoryName: '',
    //       categoryId: uuid(),
    //       sections: []
    //     }]
    //   }
    // ]);
    dispatch(setLoading(false));
  }, [currentProject, projectCode]);

  useEffect(() => {
    if (project.currentPhase) {
      let projectPhasesNumber = [...projectNumberPhases];
      let phases = [...projectPhases];
      for (var i = projectPhases.length; i < project.currentPhase; i++) {
        projectPhasesNumber.push(i+1);
        phases.push({
          phaseNumber: i+1,
          files: [],
        })
      };
      console.log(phases);
      setProjectPhases(phases);
      setProjectNumberPhases(projectPhasesNumber);

    }
  }, [project]);

  useEffect(() => {
    const projectCurrentPhase = projectPhases.find(phase => project.currentPhase === phase.phaseNumber);
    setActivePhase(projectCurrentPhase);
  }, [project, projectPhases]);

  const changePhase = (i) => {
    const findPhase = projectPhases.find(phase => phase.phaseNumber === i);
    setActivePhase(findPhase);
  }

  const handleAddPhase = () => {
    const allProjectPhases = [...projectPhases];
    allProjectPhases.push({ phaseName: '', categories: [{categoryName: '', sections: []}]});
    setProjectPhases(allProjectPhases);
  };

  const handleRemovePhase = index => {
    const allProjectPhases = [...projectPhases];
    allProjectPhases.splice(index, 1);
    setProjectPhases(allProjectPhases);
  };

  const handleProjectPhasesChange = (index, event) => {
    const allProjectPhases = [...projectPhases];
    allProjectPhases[index].phaseName = event.target.value;
    setProjectPhases(allProjectPhases);
  };

  const openSectionModal = (phaseIndex, categoryIdx, sectionIdx) => {
    const sectionData = {
      phaseIndex: phaseIndex,
      categoryIdx: categoryIdx,
      sectionIdx: sectionIdx,
      ...projectPhases[phaseIndex].categories[categoryIdx].sections[sectionIdx]
    }
    dispatch(handleSectionModal(true, sectionData));
  }

  // const savePhases = () => {
  //   const projectFind = projects.find(project => project.mineducCode === projectCode);
  //   projectFind.phases = projectPhases;
  //   const projectsCopy = [...projects];
  //   projectsCopy[projectsCopy.indexOf(projectFind)] = projectFind;
  //   dispatch(handleEditProject(projectsCopy));
  //   dispatch(push(`/project/${projectCode}/details`));
  // }

  return (
    <>
      <ProjectDetailsSectionModal sectionModalData={sectionModalData} projectPhases={projectPhases} setProjectPhases={setProjectPhases}/>
      <Form>
        <Container className='phasesEditHeader'>
          <InputGroup className="mb-3 phasesAdd">
            <InputGroup.Prepend>
              {
                activePhase && projectNumberPhases.map((phase, i) => {
                  if (parseInt(project.financingLine) === 1 && i === 0) {
                    return (
                      <>
                      </>
                    )
                  }
                  else {
                    console.log(activePhase);
                    const isActive = activePhase.phaseNumber-1 === i;
                    return (
                      <Button key={i} variant={`outline-secondary ${isActive ? 'active' : ''}`} className="buttonAddPhase" onClick={() => changePhase(i+1)}>
                      {`Fase ${i+1}`}
                      </Button>
                    )
                  }
                })
              }
            </InputGroup.Prepend>
          </InputGroup>
        </Container>
        {
          activePhase && projectPhases.length > 0 &&
          <ProjectDetailsPhase key={activePhase} currentProject={currentProject} phase={activePhase} openSectionModal={openSectionModal} phaseIndex={activePhase}
            projectPhases={projectPhases} setProjectPhases={setProjectPhases} handleProjectPhasesChange={handleProjectPhasesChange}
            handleRemovePhase={handleRemovePhase}/>
        }
      </Form>
      {/*<Button className='float-right' onClick={savePhases}>
        Guardar
      </Button>*/}
    </>
  )
}

export default ProjectPhasesEdit;
