import React, {useState, useEffect} from 'react';

import {Button, Dialog, DialogTitle, DialogContent, DialogActions, AppBar,  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { useDispatch, } from 'react-redux';
import {handleSelectActivityModal, } from '../../../../redux/actions';

import SelectActivity from './SelectActivity';

import projectDevelopmentData from '../../../ProjectActivities/ProjectDevelopmentData';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'white'
  },
  text: {
    color: 'white',
    "&:hover": {
      color: 'white'
    }
  }
}));

const ProjectSelectActivityModal = ({projectSelectActivityModal, projectObjectives, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const {show, projectData, selectActivityFunction, type} = projectSelectActivityModal;

  const [objectives, setObjectives] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    setObjectives(projectObjectives);
    // console.log(projectDevelopmentDataCopy);
    // setItemSelected({...projectDevelopmentDataCopy[0], type: 'objective'});
    // setObjectives(projectDevelopmentDataCopy);
  }, [projectObjectives]);

  const handleClose = () => {
    setSelectedItems([]);
    dispatch(handleSelectActivityModal());
  }

  const selectItem = () => {
    selectActivityFunction(selectedItems);
    setSelectedItems([]);
    dispatch(handleSelectActivityModal());
  }

  return (
    <Dialog
      fullWidth
      maxWidth={'lg'}
      open={show}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onClose={() => handleClose()}>
        Modal title
      </DialogTitle>
      <DialogContent>
        <SelectActivity objectives={objectives} selectedItems={selectedItems} setSelectedItems={setSelectedItems} type={type}/>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
          <Button onClick={selectItem} color="primary" disabled={type === 'activity' ? (selectedItems.length < 4) : (selectedItems.length < 5)}>
            {type === 'activity' ? 'Seleccionar actividad' : 'Seleccionar tarea'}
          </Button>
        </DialogActions>
    </Dialog>
  );
}

export default ProjectSelectActivityModal;
