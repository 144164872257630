import React, {useState, useEffect, } from 'react';

import {Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {saveGoalFile, } from '../../../../../redux/actions';

import FilesManager from '../../../../Utils/FilesManager/FilesManager';
import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const GoalFiles = ({itemForm, addGoalFiles, deleteFile, }) => {

  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);

  const activeFiles = itemForm.milestoneVerification.filter(file => file.available);
  const filesWithHistory = activeFiles.map(file => {
    const historial = file.milestoneVerificationRecords;
    const historialCreate = historial.find(record => record.action === "CREATE");
    file.date = historialCreate?.createdAt;
    file.userId = historialCreate?.createdByUserId;
    file.createdByUserName = historialCreate?.createdByUserName;
    return file
  });
  console.log(activeFiles);

  return (
    <>
      <Grid container justify="flex-end">
        <FilterComponent permission={'milestoneVerificationMeans.upload'}>
          {
            edit ?
            <SecondaryButton title={'Cancelar'} onClick={() => setEdit(edit => !edit)} />
            :
            <PrimaryButton title={'Editar'} onClick={() => setEdit(edit => !edit)} />
          }
        </FilterComponent>
      </Grid>
      <FilesManager title={'Medios de Verificación de Hito'} deleteFile={deleteFile} files={activeFiles || []} addFiles={addGoalFiles} edit={edit} uploadType={'multi'}
        showReasons={true} showFilters={true} showDeleteButton={true} activeColumn={false} reasonInTable={true}
        downloadPermission={'milestoneVerificationMeans.download'} deletePermission={'milestoneVerificationMeans.delete'}
      />
    </>
  )
}

export default GoalFiles;
