import React, {useState, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, Typography, Box, } from '@material-ui/core';

import BudgetFiles from './BudgetFiles';
import BudgetSummary from './BudgetSummary';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';
import FilterTabsComponent from '../../../../Utils/FilterComponent/FilterTabsComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  bar: {
    backgroundColor: '#002F6C',
    borderRight: `1px solid ${theme.palette.divider}`,
  }
}));

const BudgetTabs = ({project, projectBudget, }) => {

  const classes = useStyles();
  const {budgetFile, budgetResume} = projectBudget;

  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      permission: 'budgetTab.budgetSummary.index',
      label: 'Archivos de Presupuesto',
      component: <BudgetFiles project={project} budgetFile={budgetFile} />
    },
    {
      permission: 'budgetTab.budgetFile.index',
      label: 'Resumen de Presupuesto',
      component: <BudgetSummary project={project} budgetResume={budgetResume}
      />
    },
  ]

  return (
    <div className={classes.root}>
      {/*<FilterComponent permission={'budgetTab.show'}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChangeTab}
            TabIndicatorProps={{style: {background:'white'}}}
            className={classes.bar}
            aria-label="simple tabs example"
            variant="fullWidth"
          >
            <Tab label="Archivos de Presupuesto" {...a11yProps(0)} />
            <FilterComponent permission={'budgetTab.budgetSummary.index'}>
            <Tab label="Resumen de Presupuesto" {...a11yProps(1)} />
            </FilterComponent>
          </Tabs>
        </AppBar>
      </FilterComponent>

      <FilterComponent permission={'budgetTab.budgetFile.index'}>
        <TabPanel value={value} index={0}>
          <BudgetFiles project={project} budgetFile={budgetFile} active={value === 0} />
        </TabPanel>
      </FilterComponent>

      <FilterComponent permission={'budgetTab.budgetSummary.index'}>
        <TabPanel value={value} index={1}>
          <BudgetSummary project={project} budgetResume={budgetResume} active={value === 1}
          />
        </TabPanel>
      </FilterComponent>*/}
      <FilterTabsComponent value={value} handleChangeTab={handleChangeTab} tabs={tabs} />
    </div>
  );
}

export default BudgetTabs;
