import React from 'react';

import ProjectDetailsSectionTableRow from './ProjectDetailsSectionTableRow';

const ProjectDetailsSectionTableBody = ({bodyRows, }) => {

  return (
    <tbody>
      {
        bodyRows.map(row =>
          <ProjectDetailsSectionTableRow row={row} />
        )
      }
    </tbody>
  )
}

export default ProjectDetailsSectionTableBody;
