import React, {useState, } from 'react';

// import {Accordion, Card} from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import {Typography, } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import { useDispatch, } from 'react-redux';
import {handleProjectGroupTeamModal, } from '../../../../redux/actions';

// import ProjectDetailsGeneral from './ProjectDetailsGeneral';
import ProjectDetailsMainObjectives from './ProjectDetailsMainObjectives/ProjectDetailsMainObjectives';
import ProjectDetailsEspecific from './ProjectDetailsEspecific';
// const ProjectDetailsResultsTable = lazy(() => import('./ProjectDetailsResultsTable/ProjectDetailsResultsTable'));
import ProjectDetailsResultsTable from './ProjectDetailsResultsTable/ProjectDetailsResultsTable';

import FilterComponent from '../../../Utils/FilterComponent/FilterComponent';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

const ProjectDetailsPlanification = ({project, generalPurposesData, active, projectObjectivesResults, }) => {

  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // useEffect(() => {
  //
  // }, [active]);

  const showGroupTeam = () => dispatch(handleProjectGroupTeamModal(true, project?.id));

  return (
    <>
      <FilterComponent permission={'generalObjective.index'}>
        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary>
              <Typography>Objetivos Generales</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ProjectDetailsMainObjectives project={project} generalPurposesData={generalPurposesData} />
            </AccordionDetails>
        </Accordion>
      </FilterComponent>

      <FilterComponent permission={'indicator.index'}>
        <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary>
            <Typography>Indicadores de Resultados Comprometidos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProjectDetailsResultsTable project={project} projectObjectivesResults={projectObjectivesResults} active={active} active2={expanded === 'panel2'}/>
          </AccordionDetails>
        </Accordion>
      </FilterComponent>

      <FilterComponent permission={'team.index'}>
        <Accordion square onClick={showGroupTeam} onChange={handleChange('panel3')}>
          <AccordionSummary>
            <Typography>Equipo de Trabajo</Typography>
          </AccordionSummary>
        </Accordion>
      </FilterComponent>
      {/*
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            Objetivos Específicos
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <ProjectDetailsEspecific />
          </Accordion.Collapse>
        </Card>
      */}
    </>
  )
}

export default ProjectDetailsPlanification;
