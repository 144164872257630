import React, {useState, useEffect} from 'react';

import {Button, Dialog, DialogTitle, DialogContent, DialogActions, AppBar,  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, } from 'react-redux';
import {handleStateModal, } from '../../../../redux/actions';

import StateSelector from './StateSelector';
import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../Utils/Buttons/SecondaryButton';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'white'
  },
  text: {
    color: 'white',
    "&:hover": {
      color: 'white'
    }
  }
}));

const states = [
  {
    id: 0,
    name: 'Formulación y Adjudicación'
  },
  {
    id: 2,
    name: 'Ejecución'
  },
  {
    id: 3,
    name: 'Ejecución con Prorroga'
  },
  {
    id: 4,
    name: 'Ejecución con Compromiso Presupuestario'
  },
  {
    id: 5,
    name: 'Ejecución con Contraparte'
  },
  {
    id: 6,
    name: 'Cerrado'
  },
]

const ProjectStateModal = ({project, projectStateModal, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const {show} = projectStateModal;

  const [projectState, setProjectState] = useState(null);

  useEffect(() => {
    setProjectState(project.stage);
  }, [project]);

  const handleChange = e => setProjectState(e.target.value);

  const handleClose = () => dispatch(handleStateModal());

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={show}
      onClose={handleClose}
    >
      <DialogTitle id="customized-dialog-title">
        Cambiar estado del proyecto
      </DialogTitle>
      <DialogContent>
        <StateSelector states={states} projectState={projectState} handleChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <SecondaryButton title={'Cerrar'} onClick={handleClose} />
      </DialogActions>
    </Dialog>
  );
}

export default ProjectStateModal;
