import React, {useState, } from 'react';

import {Grid, Toolbar, Typography, Tooltip, IconButton, } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import TableSearch from '../../../../Utils/FilesManager/FilesManagerTable/FilesManagerFilters/TableSearch';

const CapitulationSubTasksFilters = ({searchText, handleSearchText, resetText, }) => {

  const [showSearch, setShowSearch] = useState(false);

  const handleShowSearch = () => {
    setShowSearch(showSearch => {
      resetText();
      return !showSearch
    });
  }

  return (
    <Toolbar>
      <Grid container>
        <Typography
          variant="h6"
        >
          Subtareas
        </Typography>
      </Grid>
      <Grid container justify="flex-end">
        {/*<TableSearch searchText={searchText} handleSearchText={handleSearchText} handleShowSearch={handleShowSearch}/>*/}
        {
          showSearch ?
          <TableSearch searchText={searchText} handleSearchText={handleSearchText} handleShowSearch={handleShowSearch} resetText={resetText}/>
          :
          <Tooltip title={'Buscar'} disableFocusListener>
            <IconButton
              onClick={handleShowSearch}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        }
      </Grid>
    </Toolbar>
  )
}

export default CapitulationSubTasksFilters;
