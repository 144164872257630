import React, {useState, useEffect} from 'react';

import {Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {getBudgetResume, saveBudgetResume, } from '../../../../../redux/actions';

import BudgetSummaryTables from '../../../ProjectDetailsModals/ProjectBudgetSummaryModal/BudgetSummaryTable/BudgetSummaryTables';
import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const BudgetSummary = ({project, budgetResume, active, }) => {

  const dispatch = useDispatch();

  const [budgetSummary, setBudgetSummary] = useState([]);
  const [deletedGreatAccounts, setDeletedGreatAccounts] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [edit, setEdit] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (active && project.id) {
      dispatch(getBudgetResume(project.id));
    }
  }, [active, project.id]);

  useEffect(() => {
    setBudgetSummary(budgetResume);
  }, [budgetResume, edit]);

  useEffect(() => {
    const categories = [...budgetSummary];
    const checkGreatAccounts = categories.every(category => category.name);
    if (checkGreatAccounts) {
      const items = [].concat(...categories.map(category => category.items));
      const checkItems = items.every(item => item.name);
      setDisabled(!checkItems);
    }
    else {
      setDisabled(true);
    }
  }, [budgetSummary]);

  const handleEdit = () => setEdit(edit => !edit);

  const handleSaveSummary = () => {
    const categories = [...budgetSummary];
    const newGreatAccounts = categories.filter(category => category.new);
    const getEditedGreatAccounts = categories.filter(category => category.edited && !category.new);
    const editedGreatAccounts = getEditedGreatAccounts.map(category => ({id: category.id, name: category.name}));
    const oldCategories = categories.filter(category => !category.new);
    const itemsFromOC = [].concat(...oldCategories.map(category => category.items));
    const newItems = itemsFromOC.filter(item => item.new);
    const getEditedItems = itemsFromOC.filter(item => !item.new && item.edited);
    const editedItems = getEditedItems.map(item => ({itemId: item.id, name: item.name, amount: item.amount}));
    const data = {
      projectId: project.id,
      newGreatAccounts: newGreatAccounts,
      editedGreatAccounts: editedGreatAccounts,
      deletedGreatAccounts: deletedGreatAccounts || [],
      newItems: newItems,
      editedItems: editedItems,
      deletedItems: deletedItems || []
    }
    dispatch(saveBudgetResume(project.id, data));
    setDeletedItems([]);
    setDeletedGreatAccounts([]);
    setEdit(false);
  }

  return (
    <>
      <FilterComponent permission={'budgetTab.budgetSummary.update'}>
        <Grid container justify="flex-end">
          {
            edit ?
            <SecondaryButton title={'Cancelar'} onClick={handleEdit} />
            :
            <PrimaryButton title={'Editar presupuesto'} onClick={handleEdit} />
          }
        </Grid>
      </FilterComponent>

      <BudgetSummaryTables budgetSummary={budgetSummary} setBudgetSummary={setBudgetSummary} edit={edit} deletedGreatAccounts={deletedGreatAccounts}
        setDeletedGreatAccounts={setDeletedGreatAccounts} deletedItems={deletedItems} setDeletedItems={setDeletedItems}
      />
      {
        edit &&
        <Grid container justify="center" style={{marginTop: 20}}>
          <PrimaryButton title={'Guardar'} onClick={handleSaveSummary} disabled={disabled}/>
        </Grid>
      }
    </>
  )
}

export default BudgetSummary;
