import React, {useState, useEffect, useRef} from 'react';

import { createMuiTheme, withStyles, makeStyles, ThemeProvider, } from '@material-ui/core/styles';
import {ListItem, ListItemText, TextField, Grid, } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import FeatherIcon from 'feather-icons-react';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
  }
}));

const ColorListItem = withStyles((theme) => ({
  root: {
    border: '1px solid #D6D9D8',
    height: 60,
    '&:hover': {
      backgroundColor: grey[300],
    },
  },
}))(ListItem);

const ProjectActivitiesListActivity = ({activity, activityIdx, goalIdx, handleItemSelected, handleEditingField, editingField, edit,
  useOutsideAlerter, itemEdit, handleItemEdit, handleDeleteItem, changed, setChanged, }) => {

  const classes = useStyles();
  const textRef = useRef(null);
  const wrapperref = useRef(null);

  useOutsideAlerter(wrapperref, handleEditingField, changed, setChanged);

  const activitySelected = editingField.length === 2 && editingField[0] === goalIdx && editingField[1] === activityIdx;

  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    if (activitySelected) {
      textRef.current.focus();
    }
  }, [activitySelected]);

  const handleShowButtons = (state) => edit && !activitySelected && setShowButtons(state);

  const startEditField = (...indexes) => {
    setShowButtons(false);
    handleEditingField(...indexes);
  }

  return (
    <>
      <ColorListItem button onClick={() => handleItemSelected(activity.id, activityIdx, "activity")}
        // onDoubleClick={() => edit ? startEditField(goalIdx, activityIdx) : {}}
        onMouseEnter={() => handleShowButtons(true)}
        onMouseLeave={() => handleShowButtons(false)}>
        {
          activitySelected ?
          <div ref={wrapperref}>
            <TextField
              className={classes.textField}
              inputRef={textRef}
              value={itemEdit}
              onChange={handleItemEdit}
              placeholder="Actividad"
              variant="outlined"
            />
          </div>
          :
          <Grid container>
            <Grid item xs={11}>
              {`Actividad ${activityIdx+1}: ${activity.name}`}
            </Grid>
            <Grid item xs={1}>
            {/*
              showButtons &&
              <FeatherIcon icon="x" className="mr-1 iconButton" onClick={e => handleDeleteItem(e, 'activity', goalIdx, activityIdx)}/>
            */}
            </Grid>
          </Grid>
        }
      </ColorListItem>
    </>
  )
}

export default ProjectActivitiesListActivity;
