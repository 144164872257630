import React, {useEffect} from 'react';

import {TableHead, TableRow, TableCell, } from "@material-ui/core";

const ExtensionsTableHeader = () => {

  return (
    <TableHead>
      <TableRow>
        <TableCell>Tipo de extension</TableCell>
        <TableCell>Anterior tiempo de ejecución</TableCell>
        <TableCell>Nuevo tiempo de ejecución</TableCell>
        <TableCell>Anterior tiempo de vigencia</TableCell>
        <TableCell>Nuevo tiempo de vigencia</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default ExtensionsTableHeader;
