import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const getSubTaskTypes = () => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/task/subtask/types`);
      dispatch(setSubTaskTypes(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener los estados de la actividad'}
      ));
    }
  }
}

const setSubTaskTypes = (subTaskTypes) => ({
  type: 'setSubTaskTypes',
  payload: {
    subTaskTypes: subTaskTypes
  }
});
