export const getDateFormat = (currentDate) => {
  const currentDateMonth = currentDate.getMonth()+1;
  const currentDateFormat = `${currentDate.getFullYear()}-${currentDateMonth.length > 1 ? currentDateMonth : ('0' + currentDateMonth)}-${currentDate.getDate()}`;
  return currentDateFormat;
}

export const getShowDateFormat = (currentDate) => {
  const currentDateMonth = currentDate.getMonth()+1;
  const currentDateFormat = `${currentDate.getDate()}/${currentDateMonth.toString().length > 1 ? currentDateMonth : ('0' + currentDateMonth)}/${currentDate.getFullYear()}`;
  return currentDateFormat;
}

export const getDaysArray = function(s,e) {for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};

const months = {
  0: 'Enero',
  1: 'Febrero',
  2: 'Marzo',
  3: 'Abril',
  4: 'Mayo',
  5: 'Junio',
  6: 'Julio',
  7: 'Agosto',
  8: 'Septiembre',
  9: 'Octubre',
  10: 'Noviembre',
  11: 'Diciembre'
}

export const getSelectFormat = (startDate, endDate) => {
  const newStartDate = new Date(startDate);
  const newEndDate = new Date(endDate);
  const starDateMonth = months[newStartDate.getMonth()];
  const endDateMonth = months[newEndDate.getMonth()];
  const selectDateFormat = `${newStartDate.getDate()}/${starDateMonth}/${newStartDate.getFullYear()} - ${newEndDate.getDate()}/${endDateMonth}/${newEndDate.getFullYear()}`;
  return selectDateFormat;
}
