import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {Table, TableContainer, Paper, } from '@material-ui/core';

import IndicatorGoalsTableHeader from './IndicatorGoalsTableHeader';
import IndicatorGoalsTableBody from './IndicatorGoalsTableBody';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const IndicatorGoalsTable = ({indicatorForm, setIndicatorForm, handleChange, type, edit, }) => {

  const classes = useStyles();

  const handleIndicatorGoals = (e, indicatorGoalIdx) => {
    const indicatorFormCopy = {...indicatorForm};
    indicatorFormCopy.indicatorGoal[indicatorGoalIdx].goal = e.target.value;
    setIndicatorForm(indicatorFormCopy);
  }

  const newIndicator = () => {
    const indicatorFormCopy = {...indicatorForm};
    indicatorFormCopy.indicatorGoal.push({
      goal: '',
      year: indicatorFormCopy.indicatorGoal.length + 1
    });
    setIndicatorForm(indicatorFormCopy);
  }

  const deleteIndicator = (idx) => {
    const indicatorFormCopy = {...indicatorForm};
    indicatorFormCopy.indicatorGoal.splice(idx, 1);
    setIndicatorForm(indicatorFormCopy);
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <IndicatorGoalsTableHeader indicatorForm={indicatorForm} type={type} edit={edit} deleteIndicator={deleteIndicator}/>
        <IndicatorGoalsTableBody indicatorForm={indicatorForm} setIndicatorForm={setIndicatorForm} handleChange={handleChange} type={type} edit={edit}
          handleIndicatorGoals={handleIndicatorGoals} newIndicator={newIndicator}
        />
      </Table>
    </TableContainer>
  )
}

export default IndicatorGoalsTable;
