const initialState={
  userPermissionsList: [
    {
      id: 1,
      slug: 'budgetTab.budgetFile.index'
    },
    {
      id: 1,
      slug: 'budgetTab.budgetSummary.index'
    },
    {
      id: 1,
      slug: 'planningTab.show'
    },
    {
      id: 1,
      slug: 'generalObjective.index'
    },
    {
      id: 1,
      slug: 'generalobjective.show'
    },
    {
      id: 1,
      slug: 'milestone.show'
    },
    {
      id: 1,
      // slug: 'milestone.create'
    },
    {
      id: 1,
      // slug: 'milestone.update'
    },
    {
      id: 1,
      // slug: 'milestone.delete'
    },
    {
      id: 1,
      slug: 'activity.show'
    },
    {
      id: 1,
      slug: 'activity.create'
    },
    {
      id: 1,
      // slug: 'activity.update'
    },
    {
      id: 1,
      // slug: 'activity.delete'
    },
    {
      id: 4,
      slug: 'milestoneVerificationMeans.download'
    },
    {
      id: 1,
      slug: 'milestoneVerificationMeans.history'
    },
    {
      id: 1,
      slug: 'milestoneVerificationMeans.upload'
    },
    {
      id: 1,
      slug: 'milestoneVerificationMeans.delete'
    },
    {
      id: 5,
      slug: 'indicator.index'
    },
    {
      id: 1,
      slug: 'indicator.show'
    },
    {
      id: 1,
      slug: 'indicator.create'
    },
    {
      id: 1,
      // slug: 'indicator.update'
    },
    {
      id: 1,
      // slug: 'indicator.delete'
    },
    {
      id: 1,
      slug: 'team.index'
    },
    {
      id: 1,
      slug: 'team.create'
    },
    {
      id: 1,
      slug: 'team.update'
    },
    {
      id: 1,
      slug: 'team.delete'
    },
    {
      id: 3,
      slug: 'task.index'
    },
    {
      id: 4,
      slug: 'task.show'
    },
    {
      id: 3,
      slug: 'activity.index'
    },
    {
      id: 3,
      slug: 'subtask.index'
    },
    {
      id: 3,
      slug: 'subtask.create'
    },
    {
      id: 3,
      slug: 'subtask.show'
    },
    {
      id: 3,
      slug: 'subtask.history'
    },
    {
      id: 3,
      slug: 'proofOfPayment.index'
    },
    {
      id: 3,
      slug: 'proofOfPayment.show'
    },
    {
      id: 3,
      slug: 'proofOfPayment.create'
    },
    {
      id: 3,
      slug: 'proofOfPayment.update'
    },
    {
      id: 3,
      slug: 'inventoryProperty.index'
    },
    {
      id: 3,
      slug: 'accumulatedInventory.index'
    },
    {
      id: 3,
      slug: 'documentsAndBackgroundTab.show'
    },
    {
      id: 3,
      slug: 'documentsAndBackground.index'
    },
    {
      id: 3,
      slug: 'documentsAndBackground.download'
    },
    {
      id: 3,
      slug: 'documentsAndBackground.upload'
    },
    {
      id: 3,
      slug: 'documentsAndBackground.delete'
    },
    {
      id: 3,
      slug: 'inventoryProperty.download'
    }
  ],
}

const userPermissionsReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setUserPermissions':
      return {
        ...state,
        userPermissionsList: action.payload.userPermissionsList,
      }
    case 'clearUserPermissions':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default userPermissionsReducer;
