import React from 'react';

import {TableBody, } from '@material-ui/core';

import ProjectDetailsSubtasksRow from './ProjectDetailsSubtasksRow';

const ProjectDetailsSubtasksBody = ({subTasks, openSubTaskModal, projectPeriods, }) => {

  return (
    <TableBody>
      {subTasks.map((subTask) => (
        <ProjectDetailsSubtasksRow key={subTask.id} subTask={subTask} openSubTaskModal={openSubTaskModal} projectPeriods={projectPeriods} />
      ))}
    </TableBody>
  )
}

export default ProjectDetailsSubtasksBody;
