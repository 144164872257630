import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, Typography, Box, } from '@material-ui/core';

import { useSelector } from 'react-redux';

import {UserPermissionsSelector, } from '../../../redux/selectors';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  bar: {
    backgroundColor: '#002F6C',
    borderRight: `1px solid ${theme.palette.divider}`,
  }
}));

const ComponentTab = ({children, value, i, }) => {
  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    return React.cloneElement(child, { active: value === i });
  });

  return <div>{childrenWithProps}</div>
}

const FilterComponent = ({value, handleChangeTab, tabs, }) => {

  const classes = useStyles();

  const {userPermissionsList} = useSelector(UserPermissionsSelector);

  const token = localStorage.getItem('token');
  const userData = JSON.parse(atob(token.split('.')[1]));
  const userRole = userData.isAdmin;
  // const userRole = false;

  const getAuthotizedTabs = userRole ? tabs : tabs.filter(tab => userPermissionsList.find(permission => permission.slug === tab.permission));

  return (
    <>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChangeTab}
          TabIndicatorProps={{style: {background:'white'}}}
          className={classes.bar}
          aria-label="simple tabs example"
          variant="fullWidth"
          scrollButtons="on"
          variant="scrollable"
        >
          {
            getAuthotizedTabs.map((tab, i) =>
              <Tab label={tab.label} {...a11yProps(i)} />
            )
          }
        </Tabs>
      </AppBar>
      {
        getAuthotizedTabs.map((tab, i) =>
          <TabPanel value={value} index={i}>
            <ComponentTab value={value} i={i}>
              {tab.component}
            </ComponentTab>
          </TabPanel>
        )
      }
    </>
  )
}

export default FilterComponent;
