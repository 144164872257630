import React, {useEffect, } from 'react';

import clientAxios from '../../config/axios';

import {Navbar, Nav, } from 'react-bootstrap';

import { useDispatch, } from 'react-redux';
// import { createSelector } from 'reselect';
import {handleSetAdmin, manageUser, setLoading, setProjects, handleNotifications, getProjects, getUsers, getActivityStatus,
  getFinancingSources, getAcademicUnits, getExpenseTypes, getSubTaskTypes, getRoles, getProjectStatusList, } from '../../redux/actions';

import { push } from 'connected-react-router';

const AppNavBar = ({userInfo, }) => {

  const dispatch = useDispatch();

  useEffect(async () => {
    try {
      // dispatch(setLoading(true));
      dispatch(getProjects());
      dispatch(getUsers());
      dispatch(getActivityStatus());
      dispatch(getFinancingSources());
      dispatch(getAcademicUnits());
      dispatch(getExpenseTypes());
      dispatch(getSubTaskTypes());
      dispatch(getRoles());
      dispatch(getProjectStatusList());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Ocurrió un error al obtener la lista de proyectos'}
      ));
      dispatch(setLoading(false));
    }
  }, []);

  const goHome = () => dispatch(push('/'));

  const goUsers = () => dispatch(push('/users'));

  const goPermissions = () => dispatch(push('/permissions'));

  const setAdmin = () => dispatch(handleSetAdmin());

  const logout = () => {
    dispatch(manageUser());
    dispatch(push('/'));
  };
  const token = localStorage.getItem('token');
  const userData = JSON.parse(atob(token.split('.')[1]));
  const otro = JSON.parse(atob(token.split('.')[0]));

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand onClick={goHome}>Repositorio UCI</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link onClick={goHome}>Inicio</Nav.Link>
          {
            userData.isAdmin &&
            <Nav.Link onClick={goUsers}>Usuarios</Nav.Link>
          }
          {
            userData.isAdmin &&
            <Nav.Link onClick={goPermissions}>Permisos</Nav.Link>
          }
          {/*<Nav.Link onClick={setAdmin}>
            {
              userInfo.role === 'admin' ? 'Desactivar admin' : 'Activar admin'
            }
          </Nav.Link>*/}
        </Nav>
        <Nav>
          <Nav.Link onClick={logout}>Cerrar Sesión</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default AppNavBar;
