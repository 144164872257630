const initialState={
  project: {
    periods: [],
    startDate: '2021-01-26'
  },
  generalPurposesData: {
    entities: {},
    generalPurposes: []
  }
}

const projectReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setProject':
      return {
        ...state,
        project: action.payload.project,
      }
    case 'setPurposes':
      return {
        ...state,
        generalPurposesData: action.payload,
      }
    default:
      return state;
  }
}

export default projectReducer;
