const initialState={
  show: false,
  unique: false,
  filesManagerData: {
    addFile: () => {},
    title: '',
    files: '',
    addFiles: () => {}
  }
}

const filesManagerModal = (state = initialState, action) => {
  switch(action.type){
    case 'showFilesManagerModal':
      return {
        ...state,
        show: true,
        unique: action.payload.unique,
        uploadType: action.payload.uploadType,
        filesManagerData: action.payload.filesManagerData
      }
    case 'closeFilesManagerModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default filesManagerModal;
