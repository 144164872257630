import React, {useState, useEffect, } from 'react';

import {Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {saveIndicatorFile, } from '../../../../../redux/actions';

import FilesManager from '../../../../Utils/FilesManager/FilesManager';
import HistoryRecordAccordion from '../../../../Utils/HistoryRecord/HistoryRecordAccordion';
import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const IndicatorFiles = ({indicator, handleClose, deleteFile, }) => {

  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [availableFiles, setAvailableFiles] = useState([]);

  useEffect(() => {
    const availableFilesA = indicator.indicatorVerication.filter(file => file.available);
    setAvailableFiles(availableFilesA);
  }, [indicator]);

  const addFiles = (files) => {
    dispatch(saveIndicatorFile(4, indicator.id, files));
  }
  const activeFiles = indicator.indicatorVerication.filter(file => file.available);
  const filesWithHistory = activeFiles.map(file => {
    const historial = file.verificationRecords;
    const historialCreate = historial.find(record => record.action === "CREATE");
    file.date = historialCreate.createdAt;
    file.userId = historialCreate.createdByUserId;
    file.createdByUserName = historialCreate.createdByUserName;
    return file
  });

  return (
    <>
      <FilterComponent permission={'indicatorVerificationMeans.upload'}>
        <Grid container justify="flex-end">
          {
            edit ?
            <SecondaryButton title={'Cancelar'} onClick={() => setEdit(edit => !edit)} />
            :
            <PrimaryButton title={'Editar'} onClick={() => setEdit(edit => !edit)} />
          }
        </Grid>
      </FilterComponent>
      <FilesManager title={'Medios de Verificación'} files={filesWithHistory} uploadType={'multi'}
        // addFiles={addFileToIndicator}
        deleteFile={deleteFile}
        addFiles={addFiles}
        edit={edit} reasonInTable={true}
        showReasons={true} showFilters={true} showDeleteButton={true} activeColumn={false}
        downloadPermission={'indicatorVerificationMeans.download'}
        deletePermission={'indicatorVerificationMeans.delete'}
      />
      <Grid container justify="flex-end">
        <SecondaryButton title={'Cerrar'} variant="secondary" onClick={handleClose} />
      </Grid>
    </>
  )
}

export default IndicatorFiles;
