import React from 'react';

import {TableBody, TableRow, TableCell, Button, } from '@material-ui/core';

import BudgetSummaryRow from './BudgetSummaryRow';

import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

const BudgetSummaryBody = ({items, fieldIdx, edit, handleChange, newSubcategory, open, deleteSubcategory, }) => {

  return (
    <TableBody>
      {items.map((detail, detailIdx) =>
        <BudgetSummaryRow detail={detail} detailIdx={detailIdx} fieldIdx={fieldIdx} edit={edit} handleChange={handleChange} open={open}
          deleteSubcategory={deleteSubcategory}
        />
      )}
      {
        edit &&
        <TableRow>
          <TableCell>
            <SecondaryButton title={'Nuevo item'} onClick={() => newSubcategory(fieldIdx)} />
          </TableCell>
        </TableRow>
      }
    </TableBody>
  )
}

export default BudgetSummaryBody;
