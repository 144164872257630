import React, {useState, useEffect, } from 'react';

import clientAxios from '../../../../../config/axios';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {saveGoalFile, setLoading, handleNotifications, editPaymentSubTasks, } from '../../../../../redux/actions';

import CapitulationSubTasksTable from '../../../ProjectDetailsTabs/ProjectDetailsCapitulation/CapitulationSubTasksTable/CapitulationSubTasksTable';

import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';
import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import TextField from '../../../../Utils/TextFields/TextField';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles({
  textField: {
    marginTop: 20,
    marginBottom: 10,
    width: '20%',
    minWidth: 300
  },
  reasonField: {
    marginTop: 20,
    width: '98%'
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
});


const PaymentSubTasks = ({subTasks, paymentsProofForm, subTasksSelected, handleChange, paymentData, handlePaymentSubTasks, setPaymentsProofForm,
  active, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [reason, setReason] = useState('');
  const [paymentSubTasks, setPaymentSubTasks] = useState([]);
  const [accumulatedAmount, setAcummulatedAmount] = useState(0);
  const [allSubTasks, setAllSubTasks] = useState([]);
  const [prevSubTasks, setPrevSubTasks] = useState([]);

  useEffect(() => {
    const getPaymentSubTasks = async () => {
      try {
        dispatch(setLoading(true));
        const {data} = await clientAxios().get(`/api/rendition/payment-document/${paymentsProofForm.id}/subtasks`);
        setPaymentSubTasks(data);
        setPrevSubTasks(data);
        const paymentsS = [...subTasks, ...data];
        console.log(paymentsS);
        const orderPayments = paymentsS.sort((a, b) => a.id - b.id);
        setAllSubTasks(orderPayments);
        dispatch(setLoading());
      } catch (e) {
        dispatch(handleNotifications(true, {
          status: 'error',
          message: 'Hubo un error al obtener los comprobantes de pago'}
        ));
        dispatch(setLoading());
      }
    }
    if (active) {
      getPaymentSubTasks();
    }
  }, [active]);

  useEffect(() => {
    if (paymentSubTasks.length > 0) {
      const subTasksAmounts = paymentSubTasks.map(subTask => subTask.amount);
      const amount = subTasksAmounts.reduce((a, b) => parseInt(a) + parseInt(b));
      setAcummulatedAmount(amount);
    }
    else {
      setAcummulatedAmount(0);
    }
  }, [paymentSubTasks]);

  const selectSubTask = (subTask) => {
    const paymentSubTasksCopy = [...paymentSubTasks];
    const findIndex = paymentSubTasksCopy.findIndex(subTaskSelected => subTaskSelected.id === subTask.id);
    if (findIndex === -1) {
      paymentSubTasksCopy.push(subTask);
    }
    else {
      paymentSubTasksCopy.splice(findIndex, 1);
    }
    setPaymentSubTasks(paymentSubTasksCopy);
  }

  const handleEdit = () => setEdit(edit => !edit);

  const handleReason = e => setReason(e.target.value);

  const handleEditSubTasks = () => {
    handlePaymentSubTasks(prevSubTasks, paymentSubTasks, paymentsProofForm.amount, reason, paymentsProofForm.id);
  }

  return (
    <>
      <Grid container justify="flex-end">
        <FilterComponent permission={'proofOfPayment.subtasks.update'}>
          {
            edit ?
            <SecondaryButton title={'Cancelar'} onClick={handleEdit} />
            :
            <PrimaryButton title={'Editar'} onClick={handleEdit} />
          }
        </FilterComponent>
      </Grid>
      {
        edit ?
        <TextField
          name="amount"
          label="Monto"
          variant="outlined"
          value={paymentsProofForm.amount}
          onChange={handleChange}
          type="number"
          classStyle={classes.textField}
        />
        :
        <div className={classes.dates}>
          <Typography className={classes.miniTitle}>
            {`Monto del comprobante:`}
          </Typography>
          <Typography >
            {`${paymentsProofForm.amount}`}
          </Typography>
        </div>
      }
      <CapitulationSubTasksTable subTasks={edit ? allSubTasks : prevSubTasks} subTasksSelected={paymentSubTasks} selectSubTask={selectSubTask}
        accumulatedAmount={accumulatedAmount} edit={edit}
      />
      <TextField
        name="reason"
        label="Razón de edición"
        variant="outlined"
        value={reason}
        onChange={handleReason}
        multiline
        rows={3}
        classStyle={classes.reasonField}
      />
      {
        edit &&
        <>
          <Grid container justify="flex-end" style={{marginTop: 20}}>
            <PrimaryButton title={'Guardar cambios'} onClick={handleEditSubTasks} disabled={!reason}/>
          </Grid>
        </>
      }
    </>
  )
}

export default PaymentSubTasks;
