import React, {useState, useEffect, } from 'react';

import {Dialog, DialogContent, DialogActions, DialogTitle, Button, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleInventoryModal, } from '../../../../redux/actions';

import InventoryForm from './InventoryForm';
import {getDateFormat, } from '../../../Utils/Functions/DateFunctions';

const ProjectDetailsInventoryModal = ({project, projectInventoryModal, userData, }) => {

  const dispatch = useDispatch();

  const {show, type, inventoryData} = projectInventoryModal;

  const [inventoryForm, setInventoryForm] = useState({});
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setInventoryForm(inventoryData.inventory);
  }, []);

  const handleClose = () => dispatch(handleInventoryModal());

  const handleChange = (e) => {
    setChanged(true);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setInventoryForm(inventoryForm => ({...inventoryForm, [e.target.name]: value}));
  }

  const handleAddFileInventory = (file) => {
    setInventoryForm(inventoryForm => ({...inventoryForm, file: file}));
  }

  const handleInventory = () => {
    const {handleInventory} = inventoryData;
    handleInventory(inventoryForm);
    dispatch(handleInventoryModal());
  }

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogTitle id="form-dialog-title">
        {
          type === 'edit' ? 'Editar Inventario' : 'Crear Inventario'
        }
      </DialogTitle>
      <DialogContent>
        <InventoryForm inventoryForm={inventoryForm} setInventoryForm={setInventoryForm} handleChange={handleChange} periods={project.periods}
          handleAddFileInventory={handleAddFileInventory}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleInventory}>
          Crear Inventario
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProjectDetailsInventoryModal;
