import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {ListItem, ListItemText, ListItemSecondaryAction, Button, Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handlePaymentsModal, editPayment, editPaymentSubTasks, setLoading, handleNotifications, handleDeletePayment, } from '../../../../../../redux/actions';

import FilterComponent from '../../../../../Utils/FilterComponent/FilterComponent';

const PaymentsProofItem = ({paymentsProof, periodSelect, estado, setEstado, }) => {

  const dispatch = useDispatch();

  const handleEditPayment = async (paymentsProofForm, changedFile) => {
    const edit = async (data, file) => dispatch(editPayment(data, file));
    const paymentsProofData = {
      id: paymentsProofForm.id,
      periodId: periodSelect.id,
      amount: paymentsProofForm.amount,
      folioNumber: paymentsProofForm.folioNumber,
      documentNumber: paymentsProofForm.documentNumber,
      registerNumber: paymentsProofForm.registerNumber,
      reason: paymentsProofForm.reason
    };
    const paymentsProofFile = paymentsProofForm.file;
    await edit(paymentsProofData, changedFile ? paymentsProofFile : null);
    setEstado(estado => !estado);
  }

  const handleEditPaymentSubTasks = async (paymentData, paymentSubTasks, amount, reason, paymentsProofId) => {
    const edit = async (payment, data) => dispatch(editPaymentSubTasks(paymentsProofId, data));
    const prevSubTasks = [...paymentData];
    const currentSubTasks = [...paymentSubTasks];
    const getNewSubTasks = currentSubTasks.filter(subTask => prevSubTasks.findIndex(subTaskPrev => subTaskPrev.id === subTask.id) < 0);
    const deletedSubTasks = prevSubTasks.filter(subTask => currentSubTasks.findIndex(subTaskN => subTaskN.id === subTask.id) < 0);
    // console.log(paymentData);
    // console.log(currentSubTasks);
    // console.log(getNewSubTasks);
    // console.log(deletedSubTasks);
    const data = {
      subTasksId: getNewSubTasks.map(subTask => subTask.id),
      deletedSubTasksId: deletedSubTasks.map(subTask => subTask.id),
      amount: amount,
      reason: reason
    }
    await edit(paymentData, data);
    setEstado(estado => !estado);
  }

  const handleDelete = async (reason) => {
    const deletePayment = async (paymentsProofId) => dispatch(handleDeletePayment(paymentsProofId, reason));
    await deletePayment(paymentsProof.id);
    setEstado(estado => !estado);
  }

  const paymentDetails = () => dispatch(handlePaymentsModal(true, 'edit',
    {
      ...paymentsProof, subTasks: paymentsProof.subTasks || []
    },
    handleEditPayment,
    handleEditPaymentSubTasks,
    handleDelete
  ));

  return (
    <ListItem key={paymentsProof.id} style={{width: '100%'}}>
      <ListItemText style={{width: '80%'}} primary={`Comprobante de pago ${paymentsProof.documentNumber}-${paymentsProof.registerNumber},
        folio ${paymentsProof.folioNumber}`}
      />
      <Grid container justify="flex-end">
        <FilterComponent permission={'proofOfPayment.show'}>
          <Button onClick={paymentDetails}>
            Ver detalles
          </Button>
        </FilterComponent>
      </Grid>
    </ListItem>
  )
}

export default PaymentsProofItem;
