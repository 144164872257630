const initialState = {
  projectPeriods: []
}

const projectPeriodsReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setProjectPeriods':
      return {
        ...state,
        projectPeriods: action.payload.projectPeriods
      }
    default:
      return state;
  }
}

export default projectPeriodsReducer;
