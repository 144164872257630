import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {Table, TableContainer, Paper, } from '@material-ui/core';

import ProjectDetailsSubtasksHeader from './ProjectDetailsSubtasksHeader';
import ProjectDetailsSubtasksBody from './ProjectDetailsSubtasksBody';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ProjectDetailsSubtasksTable = ({subTasks, openSubTaskModal, projectPeriods, }) => {

  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <ProjectDetailsSubtasksHeader />
        <ProjectDetailsSubtasksBody subTasks={subTasks} openSubTaskModal={openSubTaskModal} projectPeriods={projectPeriods} />
      </Table>
    </TableContainer>
  )
}

export default ProjectDetailsSubtasksTable;
