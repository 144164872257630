import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {UserSelector, UsersSelector, UserFormModalSelector, SelectUserModalSelector, } from '../../redux/selectors';

import UsersList from '../../components/UsersList/UsersList';

const UsersListContainer = () => {

  const userFormModal = useSelector(UserFormModalSelector);

  const selectUserModal = useSelector(SelectUserModalSelector);

  const usersData = useSelector(UsersSelector);

  const userData = useSelector(UserSelector);

  return (
    <UsersList userFormModal={userFormModal} usersData={usersData} selectUserModal={selectUserModal} userData={userData} />
  )
}

export default UsersListContainer;
