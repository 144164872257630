import React, {useState, } from 'react';

import {Row, Col, Button, Form, } from 'react-bootstrap';

import ProjectDetailsSectionFields from './ProjectDetailsSectionFields';

const ProjectDetailsSectionDetails = ({sectionFields, setSectionFields, sectionName, }) => {

  const [selectedField, setSelectedField] = useState({
    fieldType: 'textField',
    fieldName: ''
  });

  const handleSelectedField = (e) => {
    setSelectedField({...selectedField, [e.target.id]: e.target.value});
  }

  const addField = () => {
    const newField = {
      ...selectedField,
      data: selectedField.fieldType === 'textField' ? '' : selectedField.fieldType === 'table' ?
      [{
        rowFields: ['']
      }]
      :
      selectedField.fieldType === 'dynamicFields' ?
      [
        {
          firstField: null,
          secondField: [{ value: null }]
        }
      ]
      :
      selectedField.fieldType === 'repository' ? [] : ''
    };
    setSectionFields([...sectionFields, newField]);
  }

  const removeField = (sectionIdx) => {
    const sectionFieldsAux = [...sectionFields]
    sectionFieldsAux.splice(sectionIdx, 1);
    setSectionFields(sectionFieldsAux);
  }

  return (
    <>
      <Row>
        <Col>
          {
            sectionFields.map((field, sectionIdx) =>
              <ProjectDetailsSectionFields field={field} sectionIdx={sectionIdx} sectionFields={sectionFields} setSectionFields={setSectionFields}
                removeField={removeField} />
            )
          }
        </Col>
        <Col>
          <Form>
            <Form.Row>
              <Form.Group controlId="fieldName">
                <Form.Control type="text" placeholder="Nombre" onChange={handleSelectedField}/>
              </Form.Group>
              <Form.Group controlId="fieldType">
                <Form.Control as="select" defaultValue='textField' onChange={handleSelectedField}>
                  <option value='textField'>Campo de Texto</option>
                  <option value='table'>Tabla</option>
                  <option value='dynamicFields'>Campos Dinámicos</option>
                  <option value='repository'>Repositorio</option>
                </Form.Control>
              </Form.Group>
              <Button className="mb-2" onClick={addField}>
                Agregar
              </Button>
            </Form.Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default ProjectDetailsSectionDetails;
