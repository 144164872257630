import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, Typography, Box, Grid, } from '@material-ui/core';

import ProjectGoalDetails from './ProjectGoalDetails';
import GoalFiles from './GoalFiles';
import GoalHistorial from './GoalHistorial';
import HistoryRecordTable from '../../../../Utils/HistoryRecord/HistoryRecordTable/HistoryRecordTable';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterTabsComponent from '../../../../Utils/FilterComponent/FilterTabsComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  bar: {
    backgroundColor: '#002F6C',
    borderRight: `1px solid ${theme.palette.divider}`,
  }
}));

const historyData = {
  history: ['', '']
}

const GoalTabs = ({setItemForm, edit, itemForm, handleItemEdit, handleNewMilestone, handleEditMilestone, handleDeleteMilestone,
  addGoalFiles, deleteFile, userRole, userPermissionsList, }) => {

  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [algo, setAlgo] = useState(false);

  useEffect(() => {
    setAlgo(algo => !algo);
  }, [itemForm]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      permission: 'milestone.show',
      label: 'Datos del Hito',
      component: <ProjectGoalDetails edit={edit} itemForm={itemForm} setItemForm={setItemForm} handleItemEdit={handleItemEdit}
        handleEditMilestone={handleEditMilestone} handleNewMilestone={handleNewMilestone}
        handleDeleteMilestone={handleDeleteMilestone}
        userRole={userRole} userPermissionsList={userPermissionsList}
      />
    },
    {
      permission: 'milestone.show',
      label: 'Medios de Verificación',
      component: <GoalFiles itemForm={itemForm} addGoalFiles={addGoalFiles} deleteFile={deleteFile}
        userRole={userRole} userPermissionsList={userPermissionsList}
      />
    },
    {
      permission: 'milestoneVerificationMeans.history',
      label: 'Historial medios de Verificación',
      component: <GoalHistorial itemForm={itemForm} />
    },
  ]

  return (
    <div className={classes.root}>
      {
        itemForm.creating ?
        <ProjectGoalDetails edit={edit} itemForm={itemForm} setItemForm={setItemForm} handleItemEdit={handleItemEdit}
          handleEditMilestone={handleEditMilestone} handleNewMilestone={handleNewMilestone}
          handleDeleteMilestone={handleDeleteMilestone} userPermissionsList={userPermissionsList}
        />
        :
        <>
          <FilterTabsComponent value={value} handleChangeTab={handleChangeTab} tabs={tabs} />
          {/*<AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChangeTab}
              TabIndicatorProps={{style: {background:'white'}}}
              className={classes.bar}
              aria-label="simple tabs example"
              variant="fullWidth"
            >
              <Tab label="Datos del Hito" {...a11yProps(0)} />
              <Tab label="Medios de Verificación" {...a11yProps(1)} />
              <Tab label="Historial medios de Verificación" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <ProjectGoalDetails edit={edit} itemForm={itemForm} setItemForm={setItemForm} handleItemEdit={handleItemEdit}
              handleEditMilestone={handleEditMilestone} handleNewMilestone={handleNewMilestone}
              handleDeleteMilestone={handleDeleteMilestone}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GoalFiles itemForm={itemForm} addGoalFiles={addGoalFiles} deleteFile={deleteFile}/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <GoalHistorial itemForm={itemForm} />
          </TabPanel>*/}
        </>
      }
    </div>
  );
}

export default GoalTabs;
