import React, {useState, useEffect, } from 'react';

import {Table, } from '@material-ui/core';

import GoodsListHeader from './GoodsListHeader';
import GoodsListBody from './GoodsListBody';

import { useDispatch, } from 'react-redux';
import {handleNotifications, } from '../../../../../../../redux/actions';

import alert from '../../../../../../Utils/Alert/Alert';
import TablePaginator from '../../../../../../Utils/ItemsPaginator/TablePaginator';

const GoodsListTable = ({projectPeriods, goods, }) => {

  const [showResponsible, setShowResponsible] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const dispatch = useDispatch();

  // const handleDeleteFile = (fileIdx) => {
  //   const acceptFunction = () => {
  //     deleteInventory(fileIdx);
  //     dispatch(handleNotifications(true, {
  //       status: 'success',
  //       message: 'Archivo eliminado correctamente'}
  //     ));
  //   };
  //   const alertData = {
  //     title: 'Seguro que desea eliminar el archivo?',
  //     text: 'No se podrá recuperar',
  //     acceptFunction: acceptFunction
  //   }
  //   alert(alertData);
  // }

  const handleShowResponsible = () => setShowResponsible(showResponsible => !showResponsible);

  return (
    <Table>
      <GoodsListHeader showResponsible={showResponsible} handleShowResponsible={handleShowResponsible} />
      <GoodsListBody
        goods={rowsPerPage > 0 ? goods.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : goods}
        showResponsible={showResponsible}
        projectPeriods={projectPeriods}
      />
      <TablePaginator rows={goods} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} colSpan={showResponsible ? 8 : 6}
        rowOptions={[3]}
      />
    </Table>
  )
}

export default GoodsListTable;
