import React from 'react';

import {Card, } from 'react-bootstrap';

const ProjectDetailsSectionTextField = ({field, }) => {
  return (
    <Card.Body>
      <Card.Header>
        {field.fieldName}
      </Card.Header>
      <Card.Body>
        <Card.Text>
          {field.data}
        </Card.Text>
      </Card.Body>
    </Card.Body>
  )
}

export default ProjectDetailsSectionTextField;
