import React, {useState, useEffect, } from 'react';

import {Table, TableContainer, Paper, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleNotifications, } from '../../../../redux/actions';

import HistoryRecordHeader from './HistoryRecordHeader';
import HistoryRecordBody from './HistoryRecordBody';

import alert from '../../Alert/Alert';

const HistoryRecordTable = ({title, historyData, showDownloadButton, }) => {

  const dispatch = useDispatch();

  return (
    <TableContainer component={Paper}>
      <Table>
        <HistoryRecordHeader showDownloadButton={showDownloadButton}/>
        <HistoryRecordBody history={historyData} showDownloadButton={showDownloadButton} />
      </Table>
    </TableContainer>
  )
}

export default HistoryRecordTable;
