import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {TableBody, CardHeader, List, ListItem, ListItemIcon, Checkbox, ListItemText, Divider, } from '@material-ui/core';

import PermissionsTableRow from './PermissionsTableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 300,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  divider: {
    width: 300
  }
}));

const PermissionsTableBody = ({permissions, userPermissions, selectPermission, permissionsSelected, handlePermissions, }) => {

  const classes = useStyles();

  return (
    <>
    <TableBody>
      {
        permissions.map(permission => {
          const isChecked = permissionsSelected?.includes(permission.id);
          return (
            <PermissionsTableRow key={permission.id} permission={permission} selectPermission={selectPermission} isChecked={isChecked}
              handlePermissions={handlePermissions}
            />
          )
        })
      }
    </TableBody>
    <div>
    <CardHeader
      className={classes.cardHeader}
      avatar={
        <Checkbox
          // onClick={handleToggleAll(items)}
          // checked={numberOfChecked(items) === items.length && items.length !== 0}
          // indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
          // disabled={items.length === 0}
          inputProps={{ 'aria-label': 'all items selected' }}
        />
      }
      title={'Seleccionar todos'}
      subheader={`0/0 selected`}
    />
    <Divider className={classes.divider}/>
    <List className={classes.list} dense component="div" role="list">
      {
        permissions.map(permission => {
          const isChecked = permissionsSelected?.includes(permission.id);
          return (
            <ListItem key={permission.id} role="listitem" button onClick={() => handlePermissions(permission.id)}>
              <ListItemIcon>
                <Checkbox
                  checked={isChecked}
                />
              </ListItemIcon>
              <ListItemText primary={`${permission.permission}`} />
            </ListItem>
          )
        })
      }
    </List>
    </div>
    </>
  )
}

export default PermissionsTableBody;
