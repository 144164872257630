import React, {useState, useEffect} from 'react';

import {Modal, Button, } from 'react-bootstrap';

import { useDispatch, } from 'react-redux';
import {handleSectionModal, } from '../../../redux/actions';

import ProjectDetailsSectionDetails from './ProjectDetailsSectionDetails/ProjectDetailsSectionDetails';

const ProjectDetailsSectionModal = ({sectionModalData, projectPhases, setProjectPhases, }) => {

  const dispatch = useDispatch();

  const {sectionData} = sectionModalData;

  const [sectionFields, setSectionFields] = useState([]);

  useEffect(() => {
    setSectionFields(sectionData.data);
  }, [sectionData.data]);

  useEffect(() => {
    console.log(sectionFields);
  }, [sectionFields])

  const handleClose = () => dispatch(handleSectionModal());

  const saveData = () => {
    const projectPhasesCopy = [...projectPhases];
    console.log(projectPhases);
    projectPhasesCopy[sectionData.phaseIndex].categories[sectionData.categoryIdx].sections[sectionData.sectionIdx].data = sectionFields;
    dispatch(handleSectionModal());
    setSectionFields([]);
    setProjectPhases(projectPhasesCopy);
  }

  return (
    <Modal
      show={sectionModalData.show}
      onHide={handleClose}
      dialogClassName="projectEspecificModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{sectionData.sectionName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProjectDetailsSectionDetails sectionFields={sectionFields} setSectionFields={setSectionFields} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={saveData}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ProjectDetailsSectionModal;
