import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {TextField, Grid, FormControl, InputLabel, Select, MenuItem, Typography, Button, Paper, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  buttons: {
    margin: '20px 200px 50px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const SubTaskForm = ({subTask, subTaskIdx, handleChangeSubtask, handleDeleteSubtask, handleAddFileSubtask, }) => {


  return (
    <Paper variant="outlined">
      <Grid container>
        <Grid item xs={6} lg={6}>
          <TextField
            name="registerNumber"
            label="Número de Registro"
            value={subTask.registerNumber}
            onChange={e => handleChangeSubtask(e, subTaskIdx)}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <TextField
            name="period"
            label="Período de la subtares"
            value={subTask.period}
            onChange={e => handleChangeSubtask(e, subTaskIdx)}
            type='date'
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} lg={6}>
          <TextField
            name="description"
            label="Descripción"
            value={subTask.description}
            onChange={e => handleChangeSubtask(e, subTaskIdx)}
            multiline
            row={2}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <Button
            variant="contained"
            component="label"
          >
            Seleccionar archivo
            <input hidden type="file" onChange={e => handleAddFileSubtask(e.target.files[0], subTaskIdx)}/>
          </Button>
          {
            subTask.file &&
            <Typography variant="caption" display="block" gutterBottom>
              {subTask.file.name}
            </Typography>
          }
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SubTaskForm;
