import React, {useState, useEffect} from 'react';

import {Table, TableContainer, Paper, } from '@material-ui/core';

import ProjectsListTableBody from './ProjectsListTableBody';
import ProjectsListTableHeader from './ProjectsListTableHeader';
import ProjectsListTableFilters from './ProjectsListTableFilters';
import TablePaginator from '../../Utils/ItemsPaginator/TablePaginator';

const ProjectsListTable = ({projects, userInfo, }) => {

  const [projectsFiltered, setProjectsFiltered] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nameFilter, setNameFilter] = useState('');

  useEffect(() => {
    setProjectsFiltered(projects);
  }, [projects]);

  useEffect(() => {
    const filterProjects = projects.filter(project => project.name.toLowerCase().includes(nameFilter.toLowerCase()));
    setProjectsFiltered(filterProjects);
  }, [projects, nameFilter]);

  const applyFilters = () => {
  }

  const handleApplyFilters = (e) => {
    if (e.keyCode === 13) {
      applyFilters();
    }
  }

  const handleNameFilter = e => setNameFilter(e.target.value);

  return (
    <div onKeyDown={handleApplyFilters}>
      <ProjectsListTableFilters nameFilter={nameFilter} handleNameFilter={handleNameFilter} />
      <TableContainer component={Paper}>
        <Table>
          <ProjectsListTableHeader userInfo={userInfo}/>
          <ProjectsListTableBody
            projects={rowsPerPage > 0 ? projectsFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : projectsFiltered}
            userInfo={userInfo}
          />
        </Table>
      </TableContainer>
      <TablePaginator rows={projectsFiltered} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} rowOptions={[]}/>
    </div>
  )
}

export default ProjectsListTable;
