import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Table, TableContainer, Paper, Grid, Button, TextField, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {newTeam, editTeam, deleteTeam, } from '../../../../../redux/actions';

import alert from '../../../../Utils/Alert/Alert';

import ProjectDetailsGroupTeamTableHeader from './ProjectDetailsGroupTeamTableHeader';
import ProjectDetailsGroupTeamTableBody from './ProjectDetailsGroupTeamTableBody';

import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 250,
  },
  fields: {
    width: 200
  }
}));

const ProjectDetailsGroupTeamTable = ({project, groupTeam, create, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [groupTeamForm, setGroupTeamForm] = useState({
    teamId: 1,
    description: '',
    teamMembers: [
      {
        name: '',
        rut: '',
        projectPosition: '',
        institutionPosition: '',
        hoursAssigned: 0,
        email: ''
      }
    ]
  });
  const [membersDeleted, setMembersDeleted] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [edit, setEdit] = useState(create);

  useEffect(() => {
    if (groupTeam) {
      setGroupTeamForm(groupTeam);
    }
  }, [groupTeam]);

  useEffect(() => {
    const groupMembers = [...groupTeamForm.teamMembers];
    const checkGroupMembers = groupMembers.every(member => member.name && member.rut && member.email);
    setDisabled(!(groupTeamForm.description && checkGroupMembers));
  }, [groupTeamForm]);

  const handleChange = (e, memberIdx) => {
    const groupTeamFormCopy = {...groupTeamForm};
    groupTeamFormCopy.teamMembers[memberIdx][e.target.name] = e.target.value;
    groupTeamFormCopy.teamMembers[memberIdx].edited = true;
    setGroupTeamForm(groupTeamFormCopy);
  }

  const handleTeamName = (e) => setGroupTeamForm(groupTeamForm => ({...groupTeamForm, description: e.target.value}));

  const newMember = () => {
    const groupTeamFormCopy = {...groupTeamForm};
    groupTeamFormCopy.teamMembers.push({
      name: '',
      rut: '',
      projectPosition: '',
      institutionPosition: '',
      hours: 0,
      email: '',
      new: true
    });
    setGroupTeamForm(groupTeamFormCopy);
  }

  const deleteMember = (memberIdx) => {
    const groupTeamFormCopy = {...groupTeamForm};
    const memberDeleted = groupTeamFormCopy.teamMembers.splice(memberIdx, 1);
    if (!memberDeleted[0].new) {
      setMembersDeleted(membersDeleted => [...membersDeleted, memberDeleted[0]]);
    }
    setGroupTeamForm(groupTeamFormCopy);
  }

  const handleTeam = () => {
    setEdit(true);
  }

  const handleEditTeam = () => {
    const newMembers = groupTeamForm.teamMembers.filter(member => member.new);
    const editedMembers = groupTeamForm.teamMembers.filter(member => !member.new && member.edited);
    const groupTeamData = {
      description: groupTeamForm.description,
      newMembers: newMembers,
      editedMembers: editedMembers,
      membersDeleted: membersDeleted
    }
    dispatch(editTeam(groupTeamForm.id, groupTeamData, project.id));
    setEdit(false);
  }

  const deleteTeamFunction = () => {
    dispatch(deleteTeam(groupTeamForm.id, project.id));
  }

  const handleDeleteTeam = () => {
    const alertData = {
      title: `Seguro que desea eliminar este equipo?`,
      text: 'No se podrá recuperar',
      acceptFunction: deleteTeamFunction
    }
    alert(alertData);
  }

  const handleNewTeam = () => {
    const teamForm = {
      description: groupTeamForm.description,
      teamMembers: groupTeamForm.teamMembers,
      projectId: project.id
    }
    dispatch(newTeam(teamForm, project.id));
  }

  return (
    <Paper>
      <Grid container justify="flex-end">
        <FilterComponent permission={'team.delete'}>
          {
            edit &&
            <SecondaryButton title={'Eliminar'} onClick={handleDeleteTeam} />
          }
        </FilterComponent>
        {
          create ?
          <FilterComponent permission={'team.delete'}>
            <PrimaryButton disabled={disabled} title={'Crear Equipo'}
              onClick={handleNewTeam}
            />
          </FilterComponent>
          :
          edit ?
          <FilterComponent permission={'team.update'}>
            <PrimaryButton disabled={disabled} title={'Guardar'}
              onClick={handleEditTeam}
            />
          </FilterComponent>
          :
          <FilterComponent permission={'team.update'}>
            <PrimaryButton disabled={disabled} title={'Editar'}
              onClick={handleTeam}
            />
          </FilterComponent>
        }
      </Grid>
      {
        edit &&
        <TextField
          name="description"
          label="Nombre del Equipo"
          variant="outlined"
          value={groupTeamForm.description}
          className={classes.fields}
          onChange={handleTeamName}
        />
      }
      <TableContainer component={Paper}>
        <Table style={{overflow: 'visible'}}>
          <ProjectDetailsGroupTeamTableHeader />
          <ProjectDetailsGroupTeamTableBody edit={edit} groupTeamForm={groupTeamForm} handleChange={handleChange} newMember={newMember}
            deleteMember={deleteMember} />
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default ProjectDetailsGroupTeamTable;
