import React from 'react';

import { Route, } from 'react-router-dom';

import NotFound from '../components/NotFound/NotFound';

const AuthRoute = ({Component, path, exact, requiredRoles, }) => {

  const token = localStorage.getItem('token');
  const userData = JSON.parse(atob(token.split('.')[1]));

  return (
    <Route
      exact
      path={path}
      render={(props) => (
        <>
          {
            userData.isAdmin ?
            <Component {...props} />
            :
            <NotFound />
          }
        </>
      )}
    />
  )
}

export default AuthRoute;
