import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

const groupTeams = [
  {
    groupTeamName: 'Equipo Directivo del plan de Fortalecimiento',
    team: [
      {
        name: 'Juan Zolezzi C.',
        rut: '6704920-9',
        position: 'Rector',
        positionPlan: 'Director del Proyecto',
        hoursAssigned: 8,
        phone: 227180069,
        email: 'Juan.Zolezzi@usach.cl'
      },
      {
        name: 'Jorge Torres O.',
        rut: '13055616-7',
        position: 'Prorrector',
        positionPlan: 'Subdirector Proyecto',
        hoursAssigned: 16,
        phone: 227180076,
        email: 'jorge.torres.ortega@usach.cl'
      },
      {
        name: 'Julio Romero F',
        rut: '12404385-9',
        position: 'Vicerrector de Investigación desarrollo  e Innovación',
        positionPlan: 'Coordinador Área Investigación',
        hoursAssigned: 16,
        phone: 227180047,
        email: 'julio.romero@usach.cl '
      },
      {
        name: 'Karina Arias Y.',
        rut: '15379823-0',
        position: 'Vicerrectora de Vinculación con el Medio',
        positionPlan: 'Coordinador Área Vinculación con el Medio',
        hoursAssigned: 16,
        phone: 227183703,
        email: 'Karina.Arias@usach.cl'
      }
    ]
  },
  {
    groupTeamName: 'Equipo Ejecutivo del plan de Fortalecimiento ',
    team: [
      {
        name: 'María José Galotto L.',
        rut: '14649940-6',
        position: 'Directora de Gestión Tecnológica',
        positionPlan: 'Encargada de de Gestión Tecnológica',
        hoursAssigned: 12,
        phone: 227184512,
        email: 'maria.galotto@usach.cl'
      },
      {
        name: 'Dora Albirt D.',
        rut: '6957244-8',
        position: 'Directora de Investigación Científica y Tecnológica',
        positionPlan: 'Encargada de Investigación Científica y Tecnológica',
        hoursAssigned: 12,
        phone: 227180051,
        email: 'dora.altbir@usach.cl'
      },
      {
        name: 'Macarena Carrasco ',
        rut: '16447409-7',
        position: 'Jefa Gabinete Vicerrectoría de Investigación, desarrollo  e Innovación (VRIDEI)',
        positionPlan: 'Coordinadora VRIDEI ',
        hoursAssigned: 12,
        phone: 227180298,
        email: 'Macarena.carrasco@usach.cl'
      },
      {
        name: 'Andrea Hurtado',
        rut: '24103118-7',
        position: 'Directora de la dirección de género, diversidad y equidad',
        positionPlan: 'Encargada de género',
        hoursAssigned: 22,
        phone: 227183720,
        email: 'Andrea.hurtado.q@usach.cl'
      },

    ]
  },
  {
    groupTeamName: 'Responsable Unidad de Coordinación Institucional ',
    team: [
      {
        name: 'Bernardita Labra',
        rut: '15632276-87',
        position: 'Jefa Unidad de Coordinación Institucional',
        positionPlan: 'Coordinadora de Proyectos',
        hoursAssigned: 16,
        phone: 227180016,
        email: 'bernardita.labra@usach.cl'
      }
    ]
  }
];

export const handleProjectGroupTeamModal = (show, projectId) => {
  if (show) {
    return dispatch => dispatch(showGroupTeamModal(projectId));
  }
  else {
    return dispatch => dispatch(closeGroupTeamModal())
  }
}

const showGroupTeamModal = (projectId) => ({
  type: 'showGroupTeamModal',
  payload: {
    projectId: projectId
  }
});

const closeGroupTeamModal = () => ({
  type: 'closeGroupTeamModal',
  payload: {}
});

export const getGroupTeams = (projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/project/${projectId}/teams`);
      console.log(data);
      dispatch(setGroupTeams(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener los equipos de trabajo'}
      ));
    }
  }
}

const setGroupTeams = (groupTeams) => ({
  type: 'setGroupTeams',
  payload: {
    groupTeams: groupTeams
  }
});

export const newTeam = (groupTeamData, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      console.log(groupTeamData);
      console.log(projectId);
      const {data} = await clientAxios().post(`/api/project/team/add`, groupTeamData);
      // dispatch(setGroupTeams(groupTeams));
      console.log(data);
      dispatch(getGroupTeams(projectId));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al crear el equipo de trabajo'}
      ));
    }
  }
}

export const editTeam = (teamId, groupTeamData, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      console.log(teamId);
      console.log(groupTeamData);
      const {data} = await clientAxios().put(`/api/project/team/${teamId}/edit`, groupTeamData);
      // dispatch(setGroupTeams(groupTeams));
      dispatch(getGroupTeams(projectId));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al editar el equipo de trabajo'}
      ));
    }
  }
}

export const deleteTeam = (teamId, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      console.log(teamId);
      console.log(projectId);
      const {data} = await clientAxios().delete(`/api/project/team/${teamId}`);
      // dispatch(setGroupTeams(groupTeams));
      dispatch(getGroupTeams(projectId));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al eliminar el equipo de trabajo'}
      ));
    }
  }
}
