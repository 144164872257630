const initialState={
  show: false,
  type: 'create',
  periodData: {
    periodSelected: {
      startDate: null,
      endDate: null
    },
    periods: [],
    handlePeriod: () => {}
  }
}

const projectPeriodModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showPeriodModal':
      return {
        ...state,
        show: true,
        periodData: {
          periods: action.payload.periodData.periods,
          handlePeriod: action.payload.periodData.handlePeriod,
          periodSelected: action.payload.periodData.periodSelected || state.periodData.periodSelected,
          hasPayments: action.payload.periodData.hasPayments,
          deletePeriod: action.payload.periodData.deletePeriod,
        },
        type: action.payload.type
      }
    case 'closePeriodModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default projectPeriodModalReducer;
