import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {CardHeader, Divider, List, Checkbox, } from '@material-ui/core';

import PermissionItem from './PermissionItem';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 300,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  divider: {
    width: 300
  }
}));

const PermissionsList = ({permissions, permissionsSelected, handlePermissions, handleToggleAll, }) => {

  const classes = useStyles();

  const permissionsSelectedInTab = permissions.filter(permission => permissionsSelected.includes(permission.id));
  const allSelected = permissionsSelectedInTab.length === permissions.length;

  return (
    <>
      {/*<CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={() => handleToggleAll(allSelected)}
            checked={allSelected}
            indeterminate={permissionsSelectedInTab.length > 0 && (permissionsSelectedInTab.length !== permissions.length)}
            disabled={permissions.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={'Seleccionar todos'}
        subheader={`${permissionsSelectedInTab.length}/${permissions.length} seleccionados`}
      />
      <Divider className={classes.divider}/>*/}
      <List className={classes.list} dense component="div" role="list">
        {
          permissions.map(permission => {
            const isChecked = permissionsSelected?.includes(permission.id);
            return (
              <PermissionItem permission={permission} handlePermissions={handlePermissions} isChecked={isChecked} />
            )
          })
        }
      </List>
    </>
  )
}

export default PermissionsList;
