import React from 'react';

import {TableHead, TableRow, TableCell, } from '@material-ui/core';

const CapitulationSubtasksHeader = ({edit, }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Descripción</TableCell>
        <TableCell>Archivo</TableCell>
        <TableCell>Tipo</TableCell>
        <TableCell>Monto</TableCell>
        {
          edit &&
          <TableCell>Rendir</TableCell>
        }
      </TableRow>
    </TableHead>
  )
}

export default CapitulationSubtasksHeader;
