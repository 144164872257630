const initialState={
  projects: [],
  projectSelected: {}
}

const projectsReducer = (state = initialState, action) => {
  switch(action.type){
    case 'addProject':
      return {
        ...state,
        projects: [...state.projects, action.payload.project]
      }
    case 'setProjects':
      return {
        ...state,
        projects: action.payload.projects
      }
    case 'selectProject':
      return {
        ...state,
        projectSelected: action.payload.project
      }
    case 'editProject':
      return {
        ...state,
        projects: action.payload.projects
      }
    default:
      return state;
  }
}

export default projectsReducer;
