import React from 'react';

import { useSelector } from 'react-redux';

import {UserPermissionsSelector, } from '../../../../../redux/selectors';

import {ListItem, ListItemText, } from '@material-ui/core';


import { useDispatch, } from 'react-redux';
import {handleProjectActivitiesModal, } from '../../../../../redux/actions';

const ProjectDetailsMainObjective = ({project, objective, }) => {

  const dispatch = useDispatch();

  const {userPermissionsList} = useSelector(UserPermissionsSelector);

  const token = localStorage.getItem('token');
  const userData = JSON.parse(atob(token.split('.')[1]));
  const userRole = userData.isAdmin;
  // const userRole = false;
  const checkPermissions = userPermissionsList.find(permission => permission.slug === 'generalObjective.show');

  const openActivitiesModal = () => {
    const objectiveData = {
      objective: objective,
      project: project
    }
    dispatch(handleProjectActivitiesModal(true, objectiveData));
  }

  return (
    <ListItem button={(checkPermissions || userRole)} onClick={(checkPermissions || userRole) ? openActivitiesModal : () => {}}>
      <ListItemText primary={objective.name} />
    </ListItem>
  )
}

export default ProjectDetailsMainObjective;
