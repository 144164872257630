import sha1 from 'js-sha1';

import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const manageUser = (user) => {
  if (user) {
    return dispatch => dispatch(loginUser(user));
  }
  else {
    localStorage.removeItem('token');
    console.log('logout...');
    return dispatch => dispatch(logoutUser());
  }
}

export const handleUserLogin = (email, password) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const userForm = {
        username: email,
        password: sha1(password)
      };
      const {data} = await clientAxios().post(`/api/auth/signin/ldap`, userForm);
      localStorage.setItem('token', data.accessToken);
      dispatch(loginUser(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'error',
        message: e.response?.data || 'Credenciales no coinciden'}
      ));
    }
  }
}

const loginUser = (user) => ({
  type: 'loginUser',
  payload: user
})

const logoutUser = () => ({
  type: 'logoutUser',
  payload: {}
})

export const handleSetAdmin = () => {
  return dispatch => dispatch(changeAdmin())
}

const changeAdmin = () => ({
  type: 'changeAdmin',
  payload: {}
})

export const getCurrentUser = (token) => {
  const tokenUser = token ? token : localStorage.getItem('token');  return dispatch => {
    if (tokenUser) {
      //if tokenUser handle check token with back, if it returns user dispatch get user by id
      dispatch(loginUser({
        email: 'leo'
      }));
    }
  }
}

export const getUserById = (id) => {
  return dispatch => {
    //get user in backend by id of user, set in local storage and dispatch loginUser with data provided by back
  }
}
