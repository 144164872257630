import React from 'react';

import { makeStyles } from '@material-ui/core/styles'
import {Grow, TextField, IconButton, } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flex: '1 0 auto',
    },
    searchIcon: {
      color: theme.palette.text.secondary,
      marginTop: '10px',
      marginRight: '8px',
    },
    searchText: {
      flex: '0.8 0',
    },
    clearIcon: {
      '&:hover': {
        color: theme.palette.error.main,
      },
    },
  }),
  { name: 'MUIDataTableSearch' },
);

const TableSearch = ({searchText, handleSearchText, handleShowSearch, resetText, }) => {

  const classes = useStyles();

  const onKeyDown = event => {
    if (event.key === 'Escape') {
      resetText();
      handleShowSearch();
    }
  };

  return (
    <Grow appear in={true} timeout={300}>
      <div className={classes.main}>
        <SearchIcon className={classes.searchIcon} />
        <TextField
          className={classes.searchText}
          // autoFocus={true}
          value={searchText || ''}
          onKeyDown={onKeyDown}
          onChange={handleSearchText}
          fullWidth={true}
        />
        <IconButton className={classes.clearIcon} onClick={handleShowSearch}>
          <ClearIcon />
        </IconButton>
      </div>
    </Grow>
  )
};

export default TableSearch;
