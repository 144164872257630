import { createSelector } from 'reselect';

export const UsersSelector = createSelector(
  state => state.users,
  users => users
);
export const UserSelector = createSelector(
  state => state.user,
  user => user
);

export const UserPermissionsSelector = createSelector(
  state => state.userPermissions,
  userPermissions => userPermissions
);
