import React from 'react';

import {TableBody, } from '@material-ui/core';

import IndicatorGoalsTableRow from './IndicatorGoalsTableRow';

const IndicatorGoalsTableBody = ({indicatorForm, type, handleChange, edit, handleIndicatorGoals, newIndicator, }) => {
  return (
    <TableBody>
      <IndicatorGoalsTableRow indicatorForm={indicatorForm} type={type} handleChange={handleChange} edit={edit} handleIndicatorGoals={handleIndicatorGoals}
        newIndicator={newIndicator}
      />
    </TableBody>
  )
}

export default IndicatorGoalsTableBody;
