import React, {useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import TextField from '../../../Utils/TextFields/TextField';

const useStyles = makeStyles((theme) => ({
  textFields: {
    margin: '10px 15px 10px 15px',
    width: '90%'
  },
  datePicker: {
    margin: '10px 15px 10px 15px',
    width: '80%'
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
}));

const CreateProjectFormDates = ({projectForm, handleChangeProjectForm, startDate, handlePickDate, }) => {

  const classes = useStyles();

  const [validityTime, setValidityTime] = useState(new Date());
  const [implamentationTime, setImplamentationTime] = useState(new Date());

  useEffect(() => {
    const startDate = new Date(projectForm.startDate);
    startDate.setMonth(parseInt(startDate.getMonth())+parseInt(projectForm.validityTime));
    setValidityTime((!isNaN(startDate.getDate()) && startDate) || new Date());
  }, [projectForm.validityTime, projectForm.startDate]);

  useEffect(() => {
    const startDate = new Date(projectForm.startDate);
    startDate.setMonth(parseInt(startDate.getMonth())+parseInt(projectForm.implamentationTime));
    setImplamentationTime((!isNaN(startDate.getDate()) && startDate) || new Date());
  }, [projectForm.implamentationTime, projectForm.startDate]);

  return (
    <Grid container>
      <Grid item lg={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={classes.datePicker}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Fecha de Inicio"
            name="startDate"
            value={startDate}
            onChange={handlePickDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item lg={4}>
        <Grid container>
          <Grid item lg={6}>
            <TextField
              name="implamentationTime"
              label="Duración de la ejecución"
              variant="outlined"
              value={projectForm.implamentationTime}
              onChange={handleChangeProjectForm}
              classStyle={classes.textFields}
              type="number"
            />
          </Grid>
          <Grid item lg={6}>
            <div className={classes.dates}>
              <Typography className={classes.miniTitle}>
                {`Fecha estimada de término de ejecución:`}
              </Typography>
              <Typography >
                {`${implamentationTime.getDate()}/${implamentationTime.getMonth()+1}/${implamentationTime.getFullYear()}`}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4}>
        <Grid container>
          <Grid item lg={6}>
            <TextField
              name="validityTime"
              label="Tiempo de vigencia"
              variant="outlined"
              value={projectForm.validityTime}
              onChange={handleChangeProjectForm}
              classStyle={classes.textFields}
              type="number"
            />
          </Grid>
          <Grid item lg={6}>
            <div className={classes.dates}>
              <Typography className={classes.miniTitle}>
                {`Fecha estimada de término de vigencia:`}
              </Typography>
              <Typography >
                {`${validityTime.getDate()}/${validityTime.getMonth()+1}/${validityTime.getFullYear()}`}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreateProjectFormDates;
