import React from 'react';

import {Form, } from 'react-bootstrap';
import {TableCell, TableRow, FormControlLabel, Checkbox, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleUserFormModal, handleUserActive, } from '../../../redux/actions';

const allUserRoles = {
  admin: 'Administrador',
  supervisor: 'Supervisor de Proyectos',
  gestor: 'Gestor de Proyectos',
  gestorFinanciero: 'Gestor Financiero de Proyectos',
  coordinador: 'Coordinador de Proyectos',
  observador: 'Observador'
}

const UsersListTableRow = ({user, selectUser, usersSelected, }) => {

  const dispatch = useDispatch();

  let isChecked = usersSelected && usersSelected.findIndex(userSelected => userSelected.email === user.email) > -1;

  const openModalUserForm = () => {
    dispatch(handleUserFormModal(true, 'edit', user));
  }

  const changeUserStatus = e => {
    e.stopPropagation();
    dispatch(handleUserActive(user));
  }

  return (
    <TableRow key={user.id} hover onClick={() => selectUser ? selectUser(user) : openModalUserForm()}>
      {
        selectUser &&
        <TableCell>
          <Checkbox
            checked={isChecked}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </TableCell>
      }
      {/*<TableCell>{user.code}</TableCell>*/}
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.lastname}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.admin ? 'Administrador' : 'Usuario UCI'}</TableCell>
      {
        !selectUser &&
        <TableCell>
          <Checkbox
            checked={user.active}
            onClick={changeUserStatus}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </TableCell>
      }
      {/*<TableCell>{user.workGroup}</TableCell>
      <TableCell>{user.position}</TableCell>
      <TableCell>{allUserRoles[user.role]}</TableCell>*/}
    </TableRow>
  )
}

export default UsersListTableRow;
