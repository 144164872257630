import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {InputLabel, MenuItem, FormControl, Select, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const StateSelector = ({states, projectState, handleChange, }) => {

  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">Estado del Proyecto</InputLabel>
      <Select
        value={projectState}
        onChange={handleChange}
        label="Estado del Proyecto"
      >
        {
          states.map(state =>
            <MenuItem value={state.id}>{state.name}</MenuItem>
          )
        }
      </Select>
    </FormControl>
  )
}

export default StateSelector;
