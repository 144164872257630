import React from 'react';

import {Button, } from 'react-bootstrap';

import DynamicTableRow from './DynamicTableRow';

const CreateProjectEditSectionTableBody = ({addTableRow, handleTableChanges, tableRows, }) => {

  const [, ...tableBody] = tableRows;

  return (
    <tbody>
      {
        tableBody.map((row, idx) =>
          <DynamicTableRow row={row} handleTableChanges={handleTableChanges} idx={idx} />
        )
      }
      <tr>
        <Button onClick={addTableRow}>+</Button>
      </tr>
    </tbody>
  )
}

export default CreateProjectEditSectionTableBody;
