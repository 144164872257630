import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Typography, Button, Grid, } from '@material-ui/core';

import FilesManager from '../../../../Utils/FilesManager/FilesManager';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '0.5px solid grey',
    marginBottom: '10px'
  },
  title: {
    fontSize: 18,
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
}));

const PaymentDetails = ({paymentsProofForm, }) => {

  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Número de comprobante de pago:`}
            </Typography>
            <Typography >
              {`${paymentsProofForm.documentNumber}`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Número de folio:`}
            </Typography>
            <Typography >
              {`${paymentsProofForm.folioNumber}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.dates}>
            <Typography className={classes.miniTitle}>
              {`Número de documento:`}
            </Typography>
            <Typography >
              {`${paymentsProofForm.registerNumber}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <FilesManager title={'Comprobante de pago'} edit={false} files={[paymentsProofForm.file]} formAndUnique={true} downloadPermission={'proofOfPayment.download'}
      />
      {/*<CapitulationSubTasksTable subTasks={paymentsProofForm.subTasks || []} type={'capitulation'}/>*/}
    </>
  )
}

export default PaymentDetails;
