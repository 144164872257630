import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Button, ButtonGroup, } from '@material-ui/core';

import NewClosingSelect from './NewClosingSelect';
import NewClosingExtension from './NewClosingExtension';
import NewBudgetCommitment from './NewBudgetCommitment';

const useStyles = makeStyles({
  root: {
    padding: '20px 50px 0px 50px'
  }
});

const NewClosing = ({project, }) => {

  const classes = useStyles();

  const [selectedOption, setSelectedOption] = useState(0);

  return (
    <div className={classes.root}>
      {
        {
          0: <NewClosingSelect setSelectedOption={setSelectedOption} />,
          1: <NewClosingExtension project={project} />,
          2: <NewBudgetCommitment />
        }[selectedOption]
      }
    </div>
  )
}

export default NewClosing;
