import React, {useState, useEffect} from 'react';

import {Table, TableContainer, Paper, } from '@material-ui/core';

import UsersListTableBody from './UsersListTableBody';
import UsersListTableHeader from './UsersListTableHeader';
import UsersListTableFilters from './UsersListTableFilters';
import TablePaginator from '../../Utils/ItemsPaginator/TablePaginator';

const UsersListTable = ({users, usersSelected, selectUser, userInfo, }) => {

  const [usersFiltered, setUsersFiltered] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nameFilter, setNameFilter] = useState('');

  useEffect(() => {
    setUsersFiltered(users);
  }, [users]);

  useEffect(() => {
    const filterProjects = users.filter(user => user.name.toLowerCase().includes(nameFilter.toLowerCase()));
    setUsersFiltered(filterProjects);
  }, [users, nameFilter]);

  const handleNameFilter = e => setNameFilter(e.target.value);

  return (
    <div>
      {/*<UsersListTableFilters nameFilter={nameFilter} handleNameFilter={handleNameFilter} />*/}
      <TableContainer component={Paper}>
        <Table>
          <UsersListTableHeader selectUser={selectUser} />
          <UsersListTableBody
            users={rowsPerPage > 0 ? usersFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : usersFiltered}
            usersSelected={usersSelected}
            selectUser={selectUser}
            userInfo={userInfo}
          />
        </Table>
      </TableContainer>
      <TablePaginator rows={usersFiltered} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} rowOptions={[]}/>
    </div>
  )
}

export default UsersListTable;
