import React, {useState, useEffect, } from 'react';

import {uploadSingleFileAxios, } from '../../../../../config/manageFileAxios';

import {Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {saveGoalFile, setLoading, handleNotifications, handleSubTaskModal, } from '../../../../../redux/actions';

import FilesManager from '../../../../Utils/FilesManager/FilesManager';
import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const SubTaskFile = ({subTaskData, addGoalFiles, deleteFile, }) => {

  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [file, setFile] = useState({});

  useEffect(() => {
    setFile({
      name: subTaskData.subTask?.name,
      fileDownloadUri: subTaskData.subTask?.fileDownloadUri,
      filePath: subTaskData.subTask?.filePath,
      date: subTaskData.subTask?.createdAt,
      userId: subTaskData.subTask?.createdByUserId,
      createdByUserName: subTaskData.subTask?.updatedFileByUserName || subTaskData.subTask?.createdByUserName
    });
  }, [subTaskData.subTask]);

  const addFiles = (newFile, reason) => {
    const subTaskD = {
      taskId: subTaskData.subTask?.taskId,
      reason: reason,
      subtaskId: subTaskData.subTask?.id,
    }
    subTaskData.editSubtaskFile(subTaskD, newFile);
    dispatch(handleSubTaskModal());
    // try {
    //   dispatch(setLoading(true));
    //   const token = localStorage.getItem('token');
    //   const userData = JSON.parse(atob(token.split('.')[1]));
    //   const {data} = await uploadSingleFileAxios(subTaskD, file, `/api/task/subtask/edit`, 'put');
    //   // setChanged(changed => !changed);
    //   dispatch(handleNotifications(true, {
    //     status: 'success',
    //     message: 'Archivo de la subtarea editada correctamente'}
    //   ));
    //   dispatch(setLoading());
    // } catch (e) {
    //   console.log(e);
    //   dispatch(handleNotifications(true, {
    //     status: 'error',
    //     message: 'Hubo un error al agregar el archivo a la subtarea'}
    //   ));
    //   dispatch(setLoading());
    // }
  }

  // const activeFiles = itemForm.milestoneVerification.filter(file => file.available);
  // const filesWithHistory = activeFiles.map(file => {updatedByUserName
  //   const historial = file.milestoneVerificationRecords;
  //   const historialCreate = historial.find(record => record.action === "CREATE");
  //   file.date = historialCreate.createdAt;
  //   file.userId = historialCreate.createdByUserId;
  //   file.createdByUserName = historialCreate.createdByUserName;
  //   return file
  // });
  // console.log(activeFiles);

  return (
    <>
      <FilterComponent permission={subTaskData.subTask?.paymentDocument ? 'subtask.uploadPayed' : 'subtask.upload'}>
        <Grid container justify="flex-end">
          {
            edit ?
            <SecondaryButton title={'Cancelar'} onClick={() => setEdit(edit => !edit)} />
            :
            <PrimaryButton title={'Editar'} onClick={() => setEdit(edit => !edit)} />
          }
        </Grid>
      </FilterComponent>
      <FilesManager title={'Archivo de la subtarea'} deleteFile={deleteFile} files={[file] || []} addFiles={addFiles} edit={edit}
        needReason={true} downloadPermission={'subtask.download'}
      />
    </>
  )
}

export default SubTaskFile;
