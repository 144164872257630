import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const getObjectiveResults = (projectId) => {
  console.log(projectId);
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/project/${projectId}/generalpurposes`);
      dispatch(setObjectivesResults(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el indicador'}
      ));
    }
  }
}

const setObjectivesResults = (objectivesResults) => ({
  type: 'setObjectivesResults',
  payload: {
    objectivesResults: objectivesResults
  }
});
