import React from 'react';

import Swal from 'sweetalert2';

const alert = ({title, text, confirmButtonText, cancelButtonText, acceptFunction, cancelFunction, }) => {
  Swal.fire({
    title: title,
    text: text,
    showCancelButton: true,
    cancelButtonColor: '#d33',
    confirmButtonColor: '#000',
    cancelButtonText: cancelButtonText || 'Cancelar',
    confirmButtonText: confirmButtonText || 'Aceptar'
  })
  .then(result => {
    if (result.value) {
      acceptFunction && acceptFunction();
    }
    else {
      cancelFunction && cancelFunction();
    }
  })
}

export default alert;
