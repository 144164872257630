import React, {} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, FormControl, InputLabel, Select, MenuItem, } from '@material-ui/core';

import TextField from '../../Utils/TextFields/TextField';

const useStyles = makeStyles((theme) => ({
  textFields: {
    margin: theme.spacing(1),
    width: '90%'
  },
  textFieldName: {
    width: '95%'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '95%'
  }
}));

const UserForm = ({userForm, handleChangeUserForm, }) => {

  const classes = useStyles();
  console.log(userForm);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            name="name"
            label="Nombres del usuario"
            variant="outlined"
            value={userForm.name}
            onChange={handleChangeUserForm}
            classStyle={classes.textFields}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            name="lastname"
            label="Apellidos del usuario"
            variant="outlined"
            value={userForm.lastname}
            onChange={handleChangeUserForm}
            classStyle={classes.textFields}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            name="rut"
            label="Rut del usuario"
            variant="outlined"
            value={userForm.rut}
            onChange={handleChangeUserForm}
            classStyle={classes.textFields}
            type='number'
          />
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <TextField
            name="email"
            label="Correo del usuario"
            variant="outlined"
            value={userForm.email}
            onChange={handleChangeUserForm}
            classStyle={classes.textFields}
          />
        </Grid>
      </Grid>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>Rol del usuario</InputLabel>
        <Select
          name="admin"
          label="Rol de usuario"
          value={userForm.admin ? 1:0}
          onChange={handleChangeUserForm}
        >
          <MenuItem value={0}>Usuario UCI</MenuItem>
          <MenuItem value={1}>Administrador</MenuItem>
        </Select>
      </FormControl>

      {/*<Grid container>
        <Grid item lg={6}>
          <TextField
            name="workGroup"
            label="Grupo de trabajo"
            variant="outlined"
            value={userForm.workGroup}
            onChange={handleChangeUserForm}
            classStyle={classes.textFields}
          />
        </Grid>

        <Grid item lg={6}>
          <TextField
            name="position"
            label="Cargo"
            variant="outlined"
            value={userForm.position}
            onChange={handleChangeUserForm}
            classStyle={classes.textFields}
          />
        </Grid>
      </Grid>*/}

    </>
  )
}

export default UserForm;
