import React, {useEffect} from 'react';

import {TableHead, TableRow, TableCell, } from "@material-ui/core";

const ProjectDetailsResultsTableHeader = ({maxIndicatorGoals, }) => {

  const arrayIndicator = new Array(maxIndicatorGoals || 0).fill(0);

  return (
    <TableHead>
      <TableRow>
        <TableCell>Objetivo General</TableCell>
        <TableCell>Objetivo Específico</TableCell>
        <TableCell>Nombre de Indicador</TableCell>
        <TableCell>Fórmula de Cálculo</TableCell>
        <TableCell>Línea Base</TableCell>
        {
          arrayIndicator.map((n, i) =>
            <TableCell>{`Meta ${i+1}`}</TableCell>
          )
        }
      </TableRow>
    </TableHead>
  )
}

export default ProjectDetailsResultsTableHeader;
