import React, {useState, useEffect} from 'react';

import clientAxios from '../../config/axios';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, FormControl, InputLabel, Select, MenuItem, Typography, Button, Paper, Checkbox, FormControlLabel, } from '@material-ui/core';

import TextField from '../Utils/TextFields/TextField';
import PrimaryButton from '../Utils/Buttons/PrimaryButton';

import { useDispatch, } from 'react-redux';
import {setLoading, manageUser, handleUserLogin, } from '../../redux/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  buttons: {
    margin: '20px 200px 50px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  textFields: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
}));

const Login = () => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [userForm, setUserForm] = useState({
    username: '',
    password: ''
  });

  const handleChange = (e) => {
    setUserForm(userForm => ({...userForm, [e.target.name]: e.target.value}));
  }

  useEffect(() => {
    dispatch(setLoading());
  }, []);

  const login = async () => {
    if (userForm.username || userForm.password) {
      // try {
      //   dispatch(setLoading(true));
      //   // const pass = sha1(`${userForm.password}`);
      //   const newForm = {...userForm};
      //   newForm.password = sha1(`${userForm.password}`);
      //   const user = await clientAxios().post(`/api/auth/signin/ldap`, newForm);
      //   const {data} = user;
      //   console.log(data);
      //   localStorage.setItem('token', data.accessToken);
      //   dispatch(manageUser(data));
      // } catch (e) {
      //   console.log(e);
      // }
      try {
        // dispatch(setLoading(true));
        dispatch(handleUserLogin(userForm.username, userForm.password));
        // dispatch(setLoading());
      } catch (e) {

      }
    }
  }

  const handleLogin = (e) => {
    if (e.keyCode === 13) {
      login();
    }
  }

  return (
    <div style={{textAlign: 'center', marginTop: '15%'}} onKeyDown={handleLogin}>
      <h2 style={{marginBottom: 30}}>
        Bienvenido a Repositorio UCI
      </h2>
      <h5 style={{marginBottom: 30}}>
        Login
      </h5>
      <TextField
        name="username"
        label="Email"
        value={userForm.username}
        onChange={handleChange}
        variant="outlined"
        classStyle={classes.textFields}
      />
      <br/>
      <TextField
        name="password"
        label="Contraseña"
        value={userForm.password}
        onChange={handleChange}
        variant="outlined"
        type="password"
        classStyle={classes.textFields}
      />
      <br/>
      <PrimaryButton title={'Ingresar'} onClick={login} />
      {/*<Button style={{marginTop: 10}} onClick={() => login()}>
        Ingresar
      </Button>*/}
    </div>
  )
}

export default Login;
