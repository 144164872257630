import React, {useState, useEffect} from 'react';

import {Grid, Button, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleFilesManagerModal, handleBudgetSummaryModal, handleNotifications, getBudgetFile, firstBudgetFile, saveBudgetFile,
  handleHistoryRecordModal, } from '../../../../../redux/actions';

import FilesManagerTable from '../../../../Utils/FilesManager/FilesManagerTable/FilesManagerTable';
import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import TertiaryButton from '../../../../Utils/Buttons/TertiaryButton';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const BudgetFiles = ({project, budgetFile, active, }) => {

  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (active && project.id) {
      dispatch(getBudgetFile(project.id));
    }
  }, [active, project.id]);

  const newFile = (file) => {
    dispatch(firstBudgetFile(project.id, file));
  }

  const editFile = (file, reason) => {
    dispatch(saveBudgetFile(project.id, file, reason));
  }

  const addFileToBudget = () => {
    const data = {
      title: 'Agregar archivo a presupuesto',
      files: budgetFile ? [budgetFile] : [],
      addFile: budgetFile?.id ? editFile : newFile
    }
    dispatch(handleFilesManagerModal(true, data, 'unique', true));
  }

  const deleteFile = (fileIdx) => {
    const filesCopy = [...files];
    filesCopy.splice(fileIdx, 1);
    setFiles(filesCopy);
  }

  const fileWithHistory = {...budgetFile, date: budgetFile.updatedAt || budgetFile.createdAt,
    createdByUserName: budgetFile.updatedByUserName || budgetFile.createdByUserName};

  const openHistorial = () => {
    dispatch(handleHistoryRecordModal(true, `/api/budget/${project.id}`));
  }

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Grid container justify="flex-start">
            <FilterComponent permission={'budgetTab.budgetFile.upload'}>
              <PrimaryButton title={'Agregar archivo'} onClick={addFileToBudget} />
            </FilterComponent>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="flex-end">
            <FilterComponent permission={'budgetTab.budgetFile.history'}>
              <TertiaryButton title={'Ver historial'} onClick={openHistorial}/>
            </FilterComponent>
          </Grid>
        </Grid>
      </Grid>
      <FilesManagerTable title={'Archivos de presupuesto'} files={[fileWithHistory]} deleteFile={deleteFile} uploadType={'unique'}
        showReasons={true} showFilters={true} activeColumn={false} downloadPermission={'budgetTab.budgetFile.download'}
      />
    </>
  )
}

export default BudgetFiles;
