import React, {useState, useEffect, } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {Paper, Table, Grid, Button, } from "@material-ui/core";

import { useDispatch, } from 'react-redux';
import {handleIndicatorModal, getObjectiveResults, } from '../../../../../redux/actions';
import clientAxios from '../../../../../config/axios';

import ProjectDetailsResultsTableHeader from './ProjectDetailsResultsTableHeader';
import ProjectDetailsResultsTableBody from './ProjectDetailsResultsTableBody';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    overflowX: "auto",
    marginTop: 10
  },
  table: {
    minWidth: 700
  }
}));

const ProjectDetailsResultsTable = ({project, projectObjectivesResults, active2, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [objectivesResults, setObjectivesResults] = useState([
    {
      specificPurposes: []
    }
  ]);
  const [maxIndicatorGoals, setMaxIndicatorGoals] = useState(0);

  useEffect(() => {
    if (project.id && active2) {
      dispatch(getObjectiveResults(project.id));
    }
    else {
      setObjectivesResults([]);
    }
  }, [project, active2]);

  useEffect(() => {
    setObjectivesResults(projectObjectivesResults.objectivesResults);
  }, [projectObjectivesResults]);

  useEffect(() => {
    if (objectivesResults.length > 0) {
      const specificPurposes = [].concat(...objectivesResults.map(objective => objective.specificPurposes));
      const indicators = [].concat(...specificPurposes.map(specificPurpose => specificPurpose.indicators));
      const indicatorGoals = indicators.map(indicator => indicator.indicatorGoals?.length || 0);
      if (indicatorGoals.length > 0) {
        const maxNumberGoals = Math.max(...indicatorGoals);
        setMaxIndicatorGoals(maxNumberGoals);
      }
      else {
        setMaxIndicatorGoals(0);
      }
    }
  }, [objectivesResults]);

  const openNewIndicatorModal = () => {
    const addNewIndicator = (objectiveNumber, indicator) => {
      const objectiveResultsCopy = [...objectivesResults];
      const objectiveIndex = objectiveResultsCopy.indexOf(objective => objective.objective === objectiveNumber);
      objectiveResultsCopy[objectiveIndex].indicators.push(indicator);
      setObjectivesResults(objectiveResultsCopy);
    }
    dispatch(handleIndicatorModal(true, 'create', {
      indicatorForm: {
        indicatorName: '',
        calculationFormula: '',
        baseLine: '',
        indicatorGoal: [''],
        files: []
      },
      handleIndicator: addNewIndicator
    }));
  };

  const openIndicatorModal = (indicator, objective) => {
    const editIndicator = (objectiveNumber, indicator, indicatorIdx) => {
      const objectiveResultsCopy = [...objectivesResults];
      const objectiveIndex = objectiveResultsCopy.indexOf(objective => objective.objective === objectiveNumber);
      objectiveResultsCopy[objectiveIndex].indicators[indicatorIdx] = indicator;
      setObjectivesResults(objectiveResultsCopy);
    }
    dispatch(handleIndicatorModal(true, '', indicator.id));
  }

  return (
    <Paper className={classes.root}>
      <FilterComponent permission={'indicator.create'}>
        <Grid container justify="flex-end">
          <Button variant="contained" color="primary" onClick={openNewIndicatorModal}>
            Nuevo Indicador
          </Button>
        </Grid>
      </FilterComponent>
      <Table className={classes.table}>
        <ProjectDetailsResultsTableHeader maxIndicatorGoals={maxIndicatorGoals} />
        <ProjectDetailsResultsTableBody objectivesResults={objectivesResults} maxIndicatorGoals={maxIndicatorGoals} openIndicatorModal={openIndicatorModal}/>
      </Table>
    </Paper>
  )
}

export default ProjectDetailsResultsTable;
