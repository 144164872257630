import React from 'react';

import ProjectObjectiveDetailsShow from './ProjectObjectiveDetailsShow';
import ProjectObjectiveDetailsEdit from './ProjectObjectiveDetailsEdit';

const ProjectObjectiveDetails = ({edit, itemForm, handleItemEdit, saveItemDetails, handleNewObjective, handleEditObjective, }) => {
  return (
    <>
      {/*
        edit ?
        <ProjectObjectiveDetailsEdit itemForm={itemForm} handleItemEdit={handleItemEdit} handleNewObjective={handleNewObjective}
          handleEditObjective={handleEditObjective}
        />
        :
      */}
      <ProjectObjectiveDetailsShow itemForm={itemForm} />
    </>
  )
}

export default ProjectObjectiveDetails;
