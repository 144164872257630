import React from 'react';

import {TableHead, TableRow, TableCell, } from '@material-ui/core';

const ProjectDetailsSubtasksHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Descripción</TableCell>
        <TableCell>Archivo</TableCell>
        <TableCell>Tipo</TableCell>
        <TableCell>Monto</TableCell>
        <TableCell>Período</TableCell>
        <TableCell>N° Rendición</TableCell>
        <TableCell>Actualizado por</TableCell>

        <TableCell>Rendido</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default ProjectDetailsSubtasksHeader;
