import React from 'react';

import { makeStyles, } from '@material-ui/core/styles';
import {TableCell, TableRow, Collapse, Box, Table, TableHead, TableBody, Grid, Typography, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

import TextField from '../../TextFields/TextField';
import PrimaryButton from '../../Buttons/PrimaryButton';
import SecondaryButton from '../../Buttons/SecondaryButton';

const history = [
  { date: '2020-01-05', customerId: '11091700', amount: 3 },
  { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
];

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 50,
    fontSize: 28,
    fontWeight: 'bold'
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
  textField: {
    width: '90%'
  }
}));

const FilesManagerDeleteRow = ({openDelete, deleteReason, handleDeleteReason, handleOpenDelete, deleteFileWithReason, }) => {

  const classes = useStyles();

  return (
    <TableRow >
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={openDelete} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Typography className={classes.miniTitle}>
              {`Borrar archivo`}
            </Typography>
            <TextField
              classStyle={classes.textField}
              value={deleteReason}
              onChange={handleDeleteReason}
              variant="outlined"
              label="Razón de edición"
              multiline
              rows={2}
            />
            <Grid container justify="flex-end">
              <PrimaryButton title={'Cancelar'} onClick={handleOpenDelete} />
              <SecondaryButton title={'Eliminar archivo'} onClick={deleteFileWithReason} disabled={!deleteReason}/>
            </Grid>
            {/*<Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Total price ($)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((historyRow) => (
                  <TableRow key={historyRow.date}>
                    <TableCell component="th" scope="row">
                      {historyRow.date}
                    </TableCell>
                    <TableCell>{historyRow.customerId}</TableCell>
                    <TableCell align="right">{historyRow.amount}</TableCell>
                    <TableCell align="right">
                      {Math.round(historyRow.amount * 100) / 100}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>*/}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default FilesManagerDeleteRow;
