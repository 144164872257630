import React, {useState, useEffect} from 'react';

import clientAxios from '../../config/axios';

import {Button} from 'react-bootstrap';

import { useDispatch, } from 'react-redux';
import { push } from 'connected-react-router';
import {setLoading, setProjects, handleNotifications, } from '../../redux/actions';

import ProjectsListTable from './ProjectsListTable/ProjectsListTable';

import './ProjectsListCSS/ProjectsList.css'

const ProjectsList = ({userInfo, projects, }) => {

  const dispatch = useDispatch();

  const token = localStorage.getItem('token');
  const userData = JSON.parse(atob(token.split('.')[1]));

  const goCreateProject = () => dispatch(push('/create/project'));

  return (
    <div className='projectsListBody'>
      <h5 className='projectsListHeader'>
        Lista de Proyectos
      </h5>

      {
        userData.isAdmin &&
        <Button onClick={goCreateProject}>
          Crear Proyecto
        </Button>
      }
      <ProjectsListTable projects={projects} userInfo={userInfo} />
    </div>
  )
}

export default ProjectsList;
