import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {Table, TableContainer, Paper, } from '@material-ui/core';

import CapitulationSubTasksHeader from './CapitulationSubTasksHeader';
import CapitulationSubTasksBody from './CapitulationSubTasksBody';
import CapitulationSubTasksFilters from './CapitulationSubTasksFilters';
import TablePaginator from '../../../../Utils/ItemsPaginator/TablePaginator';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const CapitulationSubTasksTable = ({subTasks, edit, subTasksSelected, selectSubTask, accumulatedAmount, }) => {

  const classes = useStyles();

  const [searchText, setSearchText] = useState('');
  const [subTasksFiltered, setSubTasksFiltered] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  useEffect(() => {
    let filterSubtasks = [...subTasks];
    filterSubtasks = filterSubtasks.filter(subTask => subTask.description.toLowerCase().includes(searchText.toLowerCase()));
    setSubTasksFiltered(filterSubtasks);
  }, [subTasks, searchText]);

  const handleSearchText = e => setSearchText(e.target.value);

  const resetText = () => setSearchText('');

  return (
    <div style={{width: '100%'}}>
      <CapitulationSubTasksFilters searchText={searchText} handleSearchText={handleSearchText} resetText={resetText} />
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <CapitulationSubTasksHeader edit={edit}/>
          <CapitulationSubTasksBody
            subTasks={rowsPerPage > 0 ? subTasksFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : subTasksFiltered}
            edit={edit} subTasksSelected={subTasksSelected} selectSubTask={selectSubTask}
            accumulatedAmount={accumulatedAmount}
          />
          <TablePaginator rows={subTasksFiltered} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} rowOptions={[3]}/>
        </Table>
      </TableContainer>
    </div>
  )
}

export default CapitulationSubTasksTable;
