import React from 'react';

import {InputGroup, FormControl, } from 'react-bootstrap';

const DynamicTableRow = ({row, handleTableChanges, idx, }) => {
  return (
    <tr>
      {row.rowFields.map((field, n) =>
        <td>
          <InputGroup>
            <FormControl
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              defaultValue={field}
              onChange={e => handleTableChanges(idx+1, n, e)}
            />
          </InputGroup>
        </td>
      )}
    </tr>
  )
}

export default DynamicTableRow;
