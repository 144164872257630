import React from 'react';

import {Card, Col, Row, ListGroup, } from 'react-bootstrap';

import { useDispatch, } from 'react-redux';
import { push } from 'connected-react-router';

const ProjectData = ({currentProject, projectCode, }) => {

  const dispatch = useDispatch();

  const goEditTeams = () => dispatch(push(`/project/${projectCode}/details/teams`));

  return (
    <>
      <h5>
        Detalles
      </h5>
      <Card style={{margin: '20px'}}>
        <Card.Header>
          Descripción
        </Card.Header>
        <Card.Body>
          <Card.Text>
            {currentProject.description}
          </Card.Text>
        </Card.Body>
      </Card>
      <Card style={{margin: '20px'}}>
        <Card.Header>
          Objetivo Principal
        </Card.Header>
        <Card.Body>
          <Card.Text>
            {currentProject.mainObjective}
          </Card.Text>
        </Card.Body>
      </Card>
      <Card style={{margin: '20px'}}>
        <Card.Header onClick={goEditTeams}>
          Equipos de Trabajo
        </Card.Header>
        <Card.Body>
          <ListGroup>
            {
              currentProject.teams && currentProject.teams.map(team =>
                <ListGroup.Item action>
                  {team.teamName}
                </ListGroup.Item>
              )
            }
          </ListGroup>
        </Card.Body>
      </Card>
    </>
  )
}

export default ProjectData;
