import React, {useState, useEffect} from 'react';

import { useSelector } from 'react-redux';

import {UserPermissionsSelector, } from '../../../../../../redux/selectors';

import { makeStyles } from '@material-ui/core/styles';

import {Card, CardContent, CardActions, Typography, Button, Divider, Grid, Box, Paper, } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { useDispatch, } from 'react-redux';
import {handleNotifications, handleSubTaskModal, handleHistoryRecordModal, } from '../../../../../../redux/actions';

import CapitulationSubTasksTable from '../../CapitulationSubTasksTable/CapitulationSubTasksTable';

import FileUploader from '../../../../../Utils/FileUploader/FileUploader';
import FilesManager from '../../../../../Utils/FilesManager/FilesManager';
import PrimaryButton from '../../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../../Utils/Buttons/SecondaryButton';
import UploadButton from '../../../../../Utils/Buttons/UploadButton';
import TextField from '../../../../../Utils/TextFields/TextField';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: '10px 0px 0px 0px',
    border: '1px solid grey'
  },
  title: {
    fontSize: 20,
  },
  uploadText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '300px'
  },
  textField: {
    marginTop: 20,
    marginBottom: 10,
    width: '90%'
  },
  button: {
    marginTop: 23,
  },
  reasonField: {
    marginTop: 20,
    width: '98%'
  },
  files: {
    marginTop: 10,
    marginBottom: 20,
    padding: '20px 0px 20px 15px'
  },
});

const PaymentsProofForm = ({subTasks, type, paymentsProofForm, handleChange, selectSubTask, accumulatedAmount, reason, handleReason, addFile, edit, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const {userPermissionsList} = useSelector(UserPermissionsSelector);

  const token = localStorage.getItem('token');
  const userData = JSON.parse(atob(token.split('.')[1]));
  const userRole = userData.isAdmin;
  const checkPermissions = userPermissionsList.find(permission => permission.slug === 'proofOfPayment.upload');

  // const selectSubTask = (subTask) => {
  //   const paymentSubTasks = [...paymentsProofForm.subTasks];
  //   const findIndex = paymentSubTasks.findIndex(subTaskSelected => subTaskSelected.id === subTask.id);
  //   if (findIndex === -1) {
  //     paymentSubTasks.push(subTask);
  //   }
  //   else {
  //     paymentSubTasks.splice(findIndex, 1);
  //   }
  //   setPaymentsProofForm(paymentsProofForm => ({...paymentsProofForm, subTasks: paymentSubTasks}))
  // }
  //
  // const handleChange = (e) => {
  //   setPaymentsProofForm(paymentsProofForm => ({...paymentsProofForm, [e.target.name]: e.target.value}));
  // }

  return (
    <>
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              name="documentNumber"
              label="Número de comprobante de pago"
              variant="outlined"
              value={paymentsProofForm.documentNumber}
              onChange={handleChange}
              classStyle={classes.textField}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <TextField
              name="folioNumber"
              label="Número de folio"
              variant="outlined"
              value={paymentsProofForm.folioNumber}
              onChange={handleChange}
              classStyle={classes.textField}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={12} md={6} lg={6}>
            <TextField
              name="registerNumber"
              label="Número de documento"
              variant="outlined"
              value={paymentsProofForm.registerNumber}
              onChange={handleChange}
              classStyle={classes.textField}
            />
          </Grid>
          {
            type === 'create' &&
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                name="amount"
                label="Monto"
                variant="outlined"
                value={paymentsProofForm.amount}
                onChange={handleChange}
                type="number"
                classStyle={classes.textField}
              />
            </Grid>
          }
        </Grid>

        {/*
        <Grid container justify="flex-start">
          <div style={{width: 350, height: 100}}>
            <FileUploader title={'Agregar comprobante de pago'} setFile={handleAddFilePayment}/>
          </div>
        </Grid>
        */}
        <Divider />
        {/*<PrimaryButton title={'Agregar archivo'} onClick={addFile} />*/}
        {
          type === 'create' ?
          <Paper className={classes.files}>
            <Typography variant="h5" style={{marginBottom: 10}}>
              Archivo
            </Typography>
            <UploadButton file={paymentsProofForm.file} handleFile={addFile} buttonName={'Comprobante de pago'}
            />
          </Paper>
          :
          <FilesManager title={'Comprobante de pago'} edit={userRole || checkPermissions} addFiles={addFile} files={[paymentsProofForm.file]} deleteFile={() => {}}
            formAndUnique={true} downloadPermission={'proofOfPayment.download'}
          />
        }

        {
          type === 'create' &&
          <Paper elevation={3} style={{padding: 20, marginTop: 20}}>
            <CapitulationSubTasksTable subTasks={subTasks} edit={type === 'create' || edit} subTasksSelected={paymentsProofForm.subTasks || []}
              selectSubTask={selectSubTask} accumulatedAmount={accumulatedAmount}
            />
          </Paper>
        }
        <Divider />
        {/*<Grid container>
          <Grid item xs={6}>
            <TextField
              name="amount"
              label="Monto"
              variant="outlined"
              value={paymentsProofForm.amount}
              onChange={handleChange}
              type="number"
            />
          </Grid>
        </Grid>*/}
      {
        type !== 'create' &&
        <TextField
          name="reason"
          label="Razón de edición"
          variant="outlined"
          value={reason}
          onChange={handleReason}
          multiline
          rows={3}
          classStyle={classes.reasonField}
        />
      }
      {/*<CardActions>
        <Grid container justify="flex-start">
          <SecondaryButton title={'Eliminar'} onClick={() => cancelButton()} />
        </Grid>
        <Grid container justify="flex-end">
          <SecondaryButton title={'Cancelar'} onClick={() => cancelButton()} />
          <PrimaryButton title={type === 'create' ? 'Crear Rendición' : 'Guardar Cambios'} onClick={() => confirmButton()} />
        </Grid>
      </CardActions>*/}
    </>
  )
}

export default PaymentsProofForm;
