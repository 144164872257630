export const handleUserFormModal = (show, type, userForm) => {
  if (show) {
    return dispatch => dispatch(showUserFormModal(type, userForm));
  }
  else {
    return dispatch => dispatch(closeUserFormModal());
  }
}

const showUserFormModal = (type, userForm) => ({
  type: 'showUserFormModal',
  payload: {
    type: type,
    userForm: userForm
  }
})

const closeUserFormModal = () => ({
  type: 'closeUserFormModal',
  payload: {}
})
