import React, {useState, useEffect} from 'react';

import clientAxios from '../../config/axios';

import { useSelector } from 'react-redux';

import {UserPermissionsSelector, } from '../../redux/selectors';

import {Grid, } from '@material-ui/core';
import {Typography, Paper, } from '@material-ui/core';

import Swal from 'sweetalert2';

import ObjectivesSelect from './ObjectivesSelect';
import ProjectActivitiesMenu from './ProjectActivitiesMenu';
import ProjectActivitiesList from './ProjectActivitiesList/ProjectActivitiesList';
import ProjectActivitiesItems from './ProjectActivitiesItems/ProjectActivitiesItems';
import ProjectActivitiesStepper from './ProjectActivitiesStepper/ProjectActivitiesStepper';

import { useDispatch, } from 'react-redux';
import {setLoading, handleNotifications, getSpecificPurposes, } from '../../redux/actions';

import alert from '../Utils/Alert/Alert';

import FilterComponent from '../Utils/FilterComponent/FilterComponent';

import './ProjectActivitiesCSS/ProjectActivities.css';

import projectDevelopmentData from './ProjectDevelopmentData';

let i = 0;

const ProjectActivities = ({project, show, generalPurposesData, specificPurposes, userData, objectiveData, specificObjectives, setCallApi, activityStatus, }) => {

  const dispatch = useDispatch();

  const {objective, } = objectiveData;

  const {userPermissionsList} = useSelector(UserPermissionsSelector);

  const token = localStorage.getItem('token');
  const userToken = JSON.parse(atob(token.split('.')[1]));
  const userRole = userToken.isAdmin;
  // const userRole = false;

  const [edit, setEdit] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [milestones, setMilestones] = useState([]);
  const [objectiveSelected, setObjectiveSelected] = useState({
    milestones: []
  });
  const [itemSelected, setItemSelected] = useState({});
  const [objectives, setObjectives] = useState([]);
  const [editingField, setEditingField] = useState([]);
  const [itemForm, setItemForm] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (specificPurposes.length > 0) {
      if (i === 0) {
        console.log(specificPurposes[0]);
        setItemSelected({...generalPurposesData.entities?.specificPurposes[specificPurposes[0]], type: 'objective'});
        i = i+1;
      }
      else {
        if (itemSelected.type === "goal") {
          let findItem = {};
          const specificPurposeFind = generalPurposesData.entities?.specificPurposes[activeStep];
          if (itemSelected.creating) {
            findItem = generalPurposesData.entities?.milestones[specificPurposeFind.milestones.sort()[specificPurposeFind.milestones.length - 1]];
          }
          else {
            findItem = generalPurposesData.entities?.milestones[itemSelected.id];
          }
          setItemSelected(findItem ? {...findItem, type: itemSelected.type} : {...generalPurposesData.entities?.milestones[specificPurposeFind.milestones[0]], type: itemSelected.type});
        }
        else if (itemSelected.type === 'activity') {
          const specificPurposeFind = generalPurposesData.entities?.specificPurposes[activeStep];
          const findMilestone = generalPurposesData.entities?.milestones[itemForm.milestoneId];
          let findItem = {};
          if (itemSelected.creating) {
            findItem = findMilestone.activities[findMilestone.activities.length-1];
            findItem = generalPurposesData.entities?.activities[findMilestone.activities.sort()[findMilestone.activities.length - 1]];
          }
          else {
            findItem = generalPurposesData.entities?.activities[itemForm.id];
          }
          setItemSelected(findItem ? {...findItem, type: itemSelected.type, statusId: findItem?.activityStatus?.id || 1} : {...generalPurposesData.entities?.activities[findMilestone.activities[0]], type: itemSelected.type, statusId: findItem?.activityStatus?.id || 1});
        }
      }
      if (!objectiveSelected || !objectiveSelected.id) {
        setActiveStep(specificPurposes[0]);
      }
      // setObjectives([...specificObjectives]);
    }
  }, [specificPurposes, generalPurposesData]);

  useEffect(() => {
    setObjectiveSelected(generalPurposesData.entities?.specificPurposes[activeStep]);
    // if (objectives.length > 0) {
    //   const findObjectiveData = objectives.find(objective => objective.id === activeStep);
    //   if (findObjectiveData) {
    //     setObjectiveSelected(findObjectiveData);
    //   }
    // }
  }, [activeStep, objectives, generalPurposesData]);

  useEffect(() => {
    setItemForm({...itemSelected});
  }, [itemSelected]);

  useEffect(() => {
    handleItemSelected(0, 0, 'objective');
  }, [activeStep]);

  const handleItemSelected = (idx, n, type) => {
    let item = {};
    if (type === "goal") {
      // const findObjectiveData = objectives.find(objective => objective.id === activeStep);
      // item = findObjectiveData.milestones[idx];
      item = generalPurposesData.entities?.milestones[idx];
    }
    else if (type === "objective") {
      if (activeStep === 'new') {
        // item = {
        //   name: '',
        //   milestones: [],
        // generalPurposeId: objective.id,
        //   creating: true
        // }
      }
      else {
        item = generalPurposesData.entities?.specificPurposes[activeStep];
      }
    }
    else {
      // const findObjectiveData = objectives.find(objective => objective.id === activeStep);
      // item = findObjectiveData.milestones[idx].activities[n];
      item = generalPurposesData.entities?.activities[idx];
      item = {...item, statusId: item?.activityStatus?.id || 1};
    }
    setItemSelected({...item, type: type});
  }

  const addActivity = (milestoneId) => {
    // const findObjectiveData = objectives.find(objective => objective.id === activeStep);
    // const goalSelected = findObjectiveData.milestones[goalIdx];
    setItemSelected({
      name: '',
      type: 'activity',
      creating: true,
      statusId: 1,
      milestoneId: milestoneId,
      startDate: 0,
      endDate: 0
    });
  }

  const addMilestone = () => {
    setItemSelected({
      name: '',
      type: 'goal',
      creating: true,
      specificPurposeId: activeStep
    });
  }

  const deleteItem = () => {

    const alertData = {
      title: `Seguro que desea eliminar este objetivo?`,
      text: 'No se podrá recuperar',
      acceptFunction: setAnchorEl(true)
    }

    alert(alertData);
    dispatch(handleNotifications(true, {
      status: 'success',
      message: 'Objetivo eliminado correctamente'}
    ));
  }

  const editItems = () => {
    setEdit(true);
    setAnchorEl(null);
  }

  const saveItems = () => {
    setEdit(false);
    setAnchorEl(null);
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

   return (
    <div className='projectActivities' style={{margin: 20}}>
      <Grid container>
        <Grid item xs={11}>
            <ObjectivesSelect generalPurposesData={generalPurposesData} specificPurposes={specificPurposes} activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
        </Grid>
        <Grid item xs={1}>
          <Grid container justify="flex-end">
            {/*<FilterComponent permission={'milestone.update'}>*/}
              {
                (userRole || (userPermissionsList.find(permission => permission.slug === 'milestone.update' || permission.slug === 'activity.update' ||
                  permission.slug === 'activity.create' || permission.slug === 'milestone.create')))
                &&
                <ProjectActivitiesMenu itemSelected={itemSelected} anchorEl={anchorEl} open={open} handleOpenMenu={handleOpenMenu} handleCloseMenu={handleCloseMenu}
                  edit={edit} saveItems={saveItems} editItems={editItems} deleteItem={deleteItem}
                />
              }
            {/*</FilterComponent>*/}
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        {/*<Grid xs={2}>
          <ProjectActivitiesStepper objectives={objectives} activeStep={activeStep} setActiveStep={setActiveStep}
            handleItemSelected={handleItemSelected} edit={edit}
          />
        </Grid>*/}
        <Grid item xs={6}>
          <div style={{margin: 30}}>
          {
            activeStep === 'new' ?
            <div style={{textAlign: 'center', marginTop: '5%'}}>
              <Typography variant="h5">
                Creando Nuevo Objetivo
              </Typography>
            </div>
            :
            objectiveSelected?.id ?
            <FilterComponent permission={'milestone.show'}>
              <ProjectActivitiesList generalPurposesData={generalPurposesData} objectiveSelected={objectiveSelected} handleItemSelected={handleItemSelected}
                activeStep={activeStep} edit={edit}
                objectives={objectives} setObjectives={setObjectives} editingField={editingField} setEditingField={setEditingField}
                setItemSelected={setItemSelected} userData={userData} setCallApi={setCallApi} addActivity={addActivity}
                addMilestone={addMilestone} userRole={userRole} userPermissionsList={userPermissionsList}
              />
            </FilterComponent>
            :
            <Paper style={{padding: 20, textAlign: 'center'}}>
              <Typography variant="h4">
                Este objetivo general no cuenta con Objetivos Específicos
              </Typography>
            </Paper>
          }
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{margin: 30}}>
            <ProjectActivitiesItems project={project} itemSelected={itemSelected} edit={edit} itemForm={itemForm} setItemForm={setItemForm}
              userData={userData} objective={objective} setEdit={setEdit} activityStatus={activityStatus} userRole={userRole}
              userPermissionsList={userPermissionsList}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default ProjectActivities;
