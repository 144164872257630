const initialState = {
  show: false,
  type: 'create',
  paymentData: {},
  handlePayment: () => {},
  handlePaymentSubTasks: () => {},
  handleDeletePayment: () => {}
}

const projectPaymentsModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showPaymentsModal':
      return {
        ...state,
        show: true,
        type: action.payload.type,
        paymentData: action.payload.paymentData,
        handlePayment: action.payload.handlePayment,
        handlePaymentSubTasks: action.payload.handlePaymentSubTasks,
        handleDeletePayment: action.payload.handleDeletePayment
      }
    case 'closePaymentsModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default projectPaymentsModalReducer;
