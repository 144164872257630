import React from 'react';

import { uuid } from 'uuidv4';

import { makeStyles } from '@material-ui/core/styles';

import DynamicFields from '../../../Utils/DynamicFields/DynamicFields';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2% 15% 2% 15%'
  },
}));

const CreateProjectFormObjectives = ({projectForm, setProjectForm, type, }) => {

  const classes = useStyles();
  console.log(projectForm);

  const handleChange = (i, event) => {
    let projectFormCopy = {...projectForm};
    const values = [...projectForm.generalPurposes];
    values[i].name = event.target.value;
    values[i].edit = true;
    projectFormCopy.generalPurposes = values;
    setProjectForm(projectFormCopy);
  }

  const handleChangeType = (idx, n, event) => {
    let projectFormCopy = {...projectForm};
    const values = [...projectForm.generalPurposes];
    const valueSelected = values[idx];
    valueSelected.specificPurposes[n].name = event.target.value;
    valueSelected.specificPurposes[n].edit = true;
    values[idx] = valueSelected;
    projectFormCopy.generalPurposes = values;
    setProjectForm(projectFormCopy);
  }

  const handleAdd = () => {
    let projectFormCopy = {...projectForm};
    const values = [...projectForm.generalPurposes];
    values.push({
      name: null,
      new: true,
      auxId: uuid(),
      specificPurposes:[{ name: null }]
    });
    projectFormCopy.generalPurposes = values;
    setProjectForm(projectFormCopy);
  }

  const handleAddType = (idx) => {
    let projectFormCopy = {...projectForm};
    const values = [...projectForm.generalPurposes];
    const valueSelected = values[idx];
    valueSelected.specificPurposes.push({ name: null, new: true, auxId: uuid(), generalPurposeId: valueSelected.id });
    // if (type === 'edit' && !valueSelected.new) {
    //   const specificPurposeAdded = valueSelected.specificPurposes[valueSelected.specificPurposes.length-1];
    //   const newSP = projectFormCopy.newSpecificPurposes || [];
    //   newSP.push({...specificPurposeAdded, generalPurposeId: valueSelected.id});
    //   projectFormCopy.newSpecificPurposes = newSP;
    // }
    values[idx] = valueSelected;
    projectFormCopy.generalPurposes = values;
    setProjectForm(projectFormCopy);
  }

  const handleRemove = (i) => {
    let projectFormCopy = {...projectForm};
    const values = [...projectForm.generalPurposes];
    const deletedGeneralPurpose = values.splice(i, 1);
    if (type === 'edit') {
      const previousDeletedGP = projectFormCopy.deletedGeneralPurposes || [];
      previousDeletedGP.push(...deletedGeneralPurpose);
      projectFormCopy.deletedGeneralPurposes = previousDeletedGP.filter(generalPurpose => !generalPurpose.new);
    }
    projectFormCopy.generalPurposes = values;
    setProjectForm(projectFormCopy);
  }

  const handleRemoveField = (idx, n) => {
    let projectFormCopy = {...projectForm};
    const values = [...projectForm.generalPurposes];
    const valueSelected = values[idx];
    const deletedSpecificPurpose = valueSelected.specificPurposes.splice(n, 1);
    if (type === 'edit') {
      if (valueSelected.new) {
        // const newSP = projectFormCopy.newSpecificPurposes || [];
        // const newSPIndex = newSP.findIndex(specificPurpose => specificPurpose.auxId === deletedSpecificPurpose.auxId);
        // newSP.slice(newSPIndex, 1);
        // projectFormCopy.newSpecificPurposes = newSP;
      }
      else {
        const previousDeletedSP = projectFormCopy.deletedSpecificPurposes || [];
        previousDeletedSP.push(...deletedSpecificPurpose);
        projectFormCopy.deletedSpecificPurposes = previousDeletedSP.filter(specificPurpose => !specificPurpose.new);
      }
    }
    values[idx] = valueSelected
    projectFormCopy.generalPurposes = values;
    setProjectForm(projectFormCopy);
  }

  return (
    <div className={classes.root}>
      <DynamicFields generalPurposes={projectForm.generalPurposes} handleChange={handleChange} handleChangeType={handleChangeType} handleAdd={handleAdd}
        handleAddType={handleAddType} handleRemove={handleRemove} handleRemoveField={handleRemoveField} type={'edit'}/>
      {/*<ListGroup>
        {
          projectForm.specificObjectives.map((objective, objectiveIdx) =>
            <ListGroup.Item>
              <InputGroup className="mb-3">
                <FormControl
                  defaultValue={objective}
                  onChange={(e) => handleSpecificObjectives(e, objectiveIdx)}
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </ListGroup.Item>
          )
        }
        <ListGroup.Item onClick={addSpecificObjective}>
          Agregar Objetivo
        </ListGroup.Item>
      </ListGroup>*/}
    </div>
  )
}

export default CreateProjectFormObjectives;
