const initialState={
  show: false,
  type: '',
  indicator: {
    indicatorVerication: [],
    indicatorGoal: [],
  },
  indicatorData: {
    indicatorForm: {
      indicatorName: '',
      calculationFormula: '',
      baseLine: '',
      indicatorGoal: [],
      files: []
    },
    objective: {},
    implamentationTime: 24,
    handleIndicator: () => {}
  },
  indicatorId: -1
}

const projectIndicatorModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showIndicatorModal':
      return {
        ...state,
        show: true,
        type: action.payload.type,
        indicatorId: action.payload.indicatorId
      }
    case 'setIndicator':
      return {
        ...state,
        indicator: action.payload.indicator
      }
      break;
    case 'closeIndicatorModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default projectIndicatorModalReducer;
