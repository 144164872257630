const projectStates = {
  0: 'Formulación y adjudicación de iniciativas',
  1: 'Ejecución',
  2: 'Ejecución con prórroga',
  3: 'Ejecución con compromiso presupuestario',
  4: 'Ejecución con contraparte',
  5: 'Cerrado'
}

export const getProjectState = (state) => projectStates[state];

const projectFinancingSouces = {
  '0': 'Fondos de Desarrollo Institucional',
  '1': 'Aporte Institucional Universidades Estatales',
  '2': 'Programa de Acompañamiento y Acceso Efectivo',
  '3': 'Plan de Fortalecimiento de Universidades Estatales',
  '4': 'Convenio Marco',
  '5': 'Beca de Nivelación Académica',
  '6': 'Aporte para Actividades de Interés Nacional'
}

export const getProjectFinancingSource = (financingSource) => projectFinancingSouces[financingSource];

const projectFinancingLines = {
  '0': 'Concursable',
  '1': 'Directo'
}

export const getProjectFinancingLine = (financingLine) => projectFinancingLines[financingLine];

const itemTypes = {
  goal: 'Hito',
  activity: 'Actividad',
  objective: 'Objetivo'
}

export const getItemType = (type) => itemTypes[type];
