import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Button, Grid, Typography, Card, CardActions, CardContent, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleNotifications, } from '../../../../redux/actions';

import ProjectDetailsProlongations from './ProjectDetailsProlongations/ProjectDetailsProlongations';
import NewClosing from './NewClosing/NewClosing';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: '1%'
  },
});

const ProjectDetailsClosing = ({project, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [prolongations, setProlongations] = useState([
    {
      type: 'extension',
      files: [
        {
          file: {
            name: 'archivo 1'
          }
        }
      ]
    },
    {
      type: 'commitment',
      files: [
        {
          file: {
            name: 'archivo 2'
          }
        }
      ]
    }
  ]);
  const [state, setState] = useState(0);

  return (
    <div className={classes.root}>
      <Typography variant="h5">
        Cierre del Proyecto
      </Typography>
      <Grid container justify="flex-end">
        {
          state === 0 ?
          <Button onClick={() => setState(1)}>
            Nuevo Evento de Cierre
          </Button>
          :
          <Button onClick={() => setState(0)}>
            Cancelar
          </Button>
        }
      </Grid>
      {
        {
          0: <ProjectDetailsProlongations project={project} prolongations={prolongations} />,
          1: <NewClosing project={project}/>
        }[state]
      }
    </div>
  )
}

export default ProjectDetailsClosing;
