import React, {useState, useEffect, } from 'react';

function useForm(form, validators = {}, ){

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(true);
  }, []);

  useEffect(() => {
    const fields = Object.keys(validators);
    const formValidate = fields.some(field => {
      const validator = validators[field];
      const value = form[field];
      let invalid = true;
      if (validator.required && validator.validator !== null) {
        if (value && validator.validator.test(value)) {
          invalid = false;
        }
      }
      return invalid;
    });
    setDisabled(formValidate)
  }, [form, validators]);

  return {disabled};
}

export default useForm;
