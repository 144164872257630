import React, {useState, useEffect} from 'react';

import clientAxios from '../../../../config/axios';

import { uuid } from 'uuidv4';

import { makeStyles } from '@material-ui/core/styles';
import {Button, Typography, Card, CardActions, CardContent, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {setLoading, handleNotifications, } from '../../../../redux/actions';

import ProjectDetailsActivity from './ProjectDetailsActivity';
import ProjectDetailsActivitiesFilter from './ProjectDetailsActivitiesFilter';
import ItemsPaginator from '../../../Utils/ItemsPaginator/ItemsPaginator';

import FilterComponent from '../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: '2% 3% 1% 3%'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  pos: {
    marginBottom: 12,
  },
});

const ProjectDetailsActivities = ({project, generalPurposesData, projectObjectives, setProjectObjectives, projectActivities, setProjectActivities,
  allSpecificPurposes, allMilestones, active, projectPeriods, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [activities, setActivities] = useState([]);
  const [activitiesFiltered, setActivitiesFiltered] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [objectiveSelected, setObjectiveSelected] = useState(-1);
  const [milestoneSelected, setMilestoneSelected] = useState(-1);
  const [activitiesFilter, setActivitiesFilter] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    const activitiesIds = generalPurposesData.entities?.activities ? Object.keys(generalPurposesData.entities?.activities) : [];
    setActivities(activitiesIds);
  }, [active, project, generalPurposesData]);

  useEffect(() => {
    // let filterActivities = [...activities];
    let filterActivities = activities.filter(activityId => generalPurposesData.entities?.activities[activityId].name.toLowerCase()
      .includes(activitiesFilter.toLowerCase()));
    if (!(objectiveSelected === -1)) {
      const findSpecificPurpose = generalPurposesData.entities?.specificPurposes[objectiveSelected];
      const milestonesIds = findSpecificPurpose.milestones;
      filterActivities = filterActivities.filter(activity => milestonesIds.includes(generalPurposesData?.entities?.activities[activity].milestoneId));
      if (milestoneSelected !== -1) {
        filterActivities = filterActivities.filter(activity => generalPurposesData?.entities?.activities[activity].milestoneId == milestoneSelected);
      }
    }
    console.log(filterActivities);
    setActivitiesFiltered(filterActivities);
  }, [activities, projectActivities, activitiesFilter, objectiveSelected, milestoneSelected]);

  useEffect(() => {
    let milestonesAux = [...allMilestones];
    if (objectiveSelected !== -1) {
      milestonesAux = allMilestones.filter(milestone => !milestone.specificPurposeId.localeCompare(objectiveSelected));
    }
    setMilestoneSelected(-1);
    setMilestones(milestonesAux);
  }, [objectiveSelected]);

  const addTaskToActivity = (activityIdx, newTask)  => {
    const activitiesCopy = [...projectActivities];
    const activityCopy = {...activitiesCopy[activityIdx]};
    activityCopy.tasks.push({...newTask, subTasks: []});
    activitiesCopy[activityIdx] = activityCopy;
    setProjectActivities(activitiesCopy);
  }

  const addFilesToTask = (activityIdx, taskIdx, file, option) => {
    const activitiesCopy = [...projectActivities];
    const activityCopy = {...activitiesCopy[activityIdx]};
    const taskCopy = {...activityCopy.tasks[taskIdx]};
    taskCopy.files.push({file: file, type: option});
    activityCopy.tasks[taskIdx] = taskCopy;
    activitiesCopy[activityIdx] = activityCopy;
    setProjectActivities(activitiesCopy);
  }

  const deleteFileOfTask = (activityIdx, taskIdx, fileIdx) => {
    const activitiesCopy = [...projectActivities];
    const activityCopy = {...activitiesCopy[activityIdx]};
    const taskCopy = {...activityCopy.tasks[taskIdx]};
    taskCopy.files.splice(fileIdx, 1);
    activityCopy.tasks[taskIdx] = taskCopy;
    activitiesCopy[activityIdx] = activityCopy;
    setProjectActivities(activitiesCopy);
  }

  const addSubtaskToTask = (activityIdx, taskIdx, newSubtask) => {
    const activitiesCopy = [...projectActivities];
    const taskCopy = {...activitiesCopy[activityIdx].tasks[taskIdx]};
    taskCopy.subTasks.push({...newSubtask, id: uuid()});
    activitiesCopy[activityIdx].tasks[taskIdx] = taskCopy;
    setProjectActivities(activitiesCopy);
  }

  const handleSubtaskEdit = (activityIdx, taskIdx, subTask) => {
    const activitiesCopy = [...projectActivities];
    activitiesCopy[activityIdx].tasks[taskIdx] = subTask;
    setProjectActivities(activitiesCopy);
  }

  const handleDeleteSubtask = (activityIdx, taskIdx) => {
    const activitiesCopy = [...projectActivities];
    activitiesCopy[activityIdx].tasks.splice(taskIdx, 1);
    setProjectActivities(activitiesCopy);
  }

  const handleObjectiveSelected = e => setObjectiveSelected(e.target.value);

  const handleMilestoneSelected = e => setMilestoneSelected(e.target.value);

  return (
    <>
      <FilterComponent permission={'activity.index'}>
        <h5>
          {'Actividades'}
        </h5>
        <ProjectDetailsActivitiesFilter generalPurposesData={generalPurposesData} activitiesFilter={activitiesFilter} setActivitiesFilter={setActivitiesFilter}
          objectiveSelected={objectiveSelected} handleObjectiveSelected={handleObjectiveSelected} allSpecificPurposes={allSpecificPurposes} milestones={milestones}
          milestoneSelected={milestoneSelected} handleMilestoneSelected={handleMilestoneSelected}
        />
        {
          activitiesFiltered.slice((page-1)*5, (page-1)*5 + 5).map((activityId, activityIdx) =>
            <ProjectDetailsActivity key={activityId} activityId={activityId} generalPurposesData={generalPurposesData}
              activity={generalPurposesData.entities?.activities[activityId]} activityIdx={activityIdx} addTaskToActivity={addTaskToActivity}
              addFilesToTask={addFilesToTask} deleteFileOfTask={deleteFileOfTask} addSubtaskToTask={addSubtaskToTask} handleSubtaskEdit={handleSubtaskEdit}
              handleDeleteSubtask={handleDeleteSubtask} projectPeriods={projectPeriods}
            />
          )
        }
        <ItemsPaginator itemsFiltered={activitiesFiltered} setItemsFiltered={setActivitiesFiltered} page={page} setPage={setPage}/>
      </FilterComponent>
    </>
  )
}

export default ProjectDetailsActivities;
