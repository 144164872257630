import {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, ClickAwayListener, Tooltip, Popper, Paper, IconButton, } from '@material-ui/core';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';

import SubTaskForm from './SubTaskForm';
import SubTaskShow from './SubTaskShow';

import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 10000,
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.5)",
    zIndex: 1301,
    maxHeight: 200,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  }
}));

const SubTask = ({subTaskData, periods, subTaskForm, setSubTaskForm, handleChange, handleAddFileSubtask, taskType, handlePeriod, addFileToSubTask, deleteFile,
  type, subTaskTypes, disabled, handleDeleteSubTask, projectPeriods, }) => {

  const classes = useStyles();

  const [edit, setEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleEdit = () => setEdit(edit => !edit);

  const editInfo = () => {
    const {handleSubTask} = subTaskData;
    const newForm = {
      description: subTaskForm.description,
      amount: subTaskForm.amount,
      inventoryable: subTaskForm.inventoryable,
      reason: subTaskForm.reason,
      subtaskId: subTaskForm.id,
      taskId: subTaskForm.taskId,
      subtaskTypeId: subTaskForm.subtaskTypeId,
      periodId: subTaskForm.period?.id,
      model: subTaskForm.model,
      brand: subTaskForm.brand,
      supplier: subTaskForm.supplier
    }
    handleSubTask(newForm);
    setEdit(false);
  }

  const handleOpenInfo = (event) => {
    setAnchorEl(anchorEl => anchorEl ? null : event.currentTarget);
  };

  const handleCloseInfo = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper1' : undefined;

  return (
    <>
      <Grid container justify="flex-end">
        <FilterComponent permission={'subtask.update'}>
          {
            type === 'create' ||
            (
              edit ?
              <SecondaryButton title={'Cancelar'} onClick={handleEdit}/>
              :
              <>
                <PrimaryButton title={'Editar'} onClick={handleEdit} disabled={!!subTaskForm.paymentDocument?.id}/>
                {
                  subTaskForm.paymentDocument?.id &&
                  <ClickAwayListener onClickAway={handleCloseInfo}>
                    <div>
                      <Tooltip aria-describedby={id} title={'Qué ocurre?'} disableFocusListener onClick={handleOpenInfo}>
                        <IconButton>
                          <NotListedLocationIcon/>
                        </IconButton>
                      </Tooltip>
                      <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        className={classes.popper}
                      >
                        <Paper className={classes.paper}>
                          {`Esta subtarea tiene un comprobante de pago asociado: ${subTaskForm.paymentDocument?.documentNumber}-${subTaskForm.paymentDocument?.registerNumber}`}
                        </Paper>
                      </Popper>
                    </div>
                  </ClickAwayListener>
                }
              </>
            )
          }
        </FilterComponent>
      </Grid>
      {
        (type === 'create' || edit) ?
        <SubTaskForm periods={periods} subTaskForm={subTaskForm} handleChange={handleChange} taskType={taskType} handlePeriod={handlePeriod} type={type}
          setSubTaskForm={setSubTaskForm} addFileToSubTask={addFileToSubTask} subTaskTypes={subTaskTypes} projectPeriods={projectPeriods}
        />
        :
        <>
          <SubTaskShow subTaskForm={subTaskForm} />
        </>
      }
      {
        edit &&
        <div style={{display: 'flex', marginTop: 20}}>
          <FilterComponent permission={'subtask.delete'}>
            <Grid container justify="flex-start">
              <SecondaryButton title={'Eliminar Subtarea'} onClick={handleDeleteSubTask} disabled={disabled || !subTaskForm.reason}/>
            </Grid>
          </FilterComponent>
          <FilterComponent permission={'subtask.update'}>
            <Grid container justify="flex-end">
              <PrimaryButton title={'Guardar'} onClick={editInfo} disabled={disabled || !subTaskForm.reason}/>
            </Grid>
          </FilterComponent>
        </div>
      }
    </>
  )
}

export default SubTask;
