import React from 'react';

import { makeStyles, } from '@material-ui/core/styles';
import {TableCell, TableRow, Collapse, Box, Typography, Table, TableHead, TableBody, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

const history = [
  { date: '2020-01-05', customerId: '11091700', amount: 3 },
  { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
];

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 50,
    fontSize: 28,
    fontWeight: 'bold'
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
}));

const FilesManagerCollapseRow = ({file, open, }) => {

  const classes = useStyles();

  return (
    <TableRow style={file.active ? {opacity: 1}: {opacity: 0.5}}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <div className={classes.dates}>
              <Typography className={classes.miniTitle}>
                {`Fecha de edición:`}
              </Typography>
              <Typography>
                {`15-03-2021`}
              </Typography>
            </div>
            <div className={classes.dates}>
              <Typography className={classes.miniTitle}>
                {`Editado por:`}
              </Typography>
              <Typography>
                {`Juanito Perez`}
              </Typography>
            </div>
            <div className={classes.dates}>
              <Typography className={classes.miniTitle}>
                {`Razón de Edición:`}
              </Typography>
              <Typography >
                {`Acá va la razón de edición`}
              </Typography>
            </div>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default FilesManagerCollapseRow;
