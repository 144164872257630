import React, {useState, useEffect, } from 'react';

import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {List, ListItem, ListItemText, } from '@material-ui/core';
import { green, purple } from '@material-ui/core/colors';

import ProjectActivitiesListGoals from './ProjectActivitiesListGoals';

import { useDispatch, } from 'react-redux';
import {setLoading, handleNotifications, } from '../../../redux/actions';

import {getItemType, } from '../../Utils/Functions/ProjectHelperFunctions';
import alert from '../../Utils/Alert/Alert';

import FilterComponent from '../../Utils/FilterComponent/FilterComponent';

const ColorListItemAdd = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[300]),
    backgroundColor: green[300],
    '&:hover': {
      backgroundColor: green[400],
    },
    border: '1px solid grey'
  },
}))(ListItem);

const ProjectActivitiesList = ({objectiveSelected, generalPurposesData, handleItemSelected, edit, objectives, setObjectives, activeStep, editingField,
  setEditingField, setItemSelected, setCallApi, addActivity, addMilestone, userRole, userPermissionsList, }) => {

  const dispatch = useDispatch();

  const [itemEdit, setItemEdit] = useState('');
  const [changed, setChanged] = useState('');

  useEffect(() => {
    if (editingField.length === 1) {
      // if (objectiveSelected.milestones.length > editingField[0]) {
        setItemEdit(objectiveSelected.milestones[editingField[0]].name);
      // }
    }
    else if (editingField.length === 2) {
      // console.log(editingField[0]);
      // console.log(objectiveSelected.milestones);
      // console.log(objectiveSelected.milestones[parseInt([editingField][0])]);
      if (objectiveSelected.milestones.length > editingField[0] && objectiveSelected.milestones[parseInt([editingField][0])].activities.length > editingField[1]) {
        setItemEdit(objectiveSelected.milestones[parseInt(editingField[0])].activities[parseInt(editingField[1])].name);
      }
    }
    else {
      setItemEdit('');
    }
  }, [editingField, objectiveSelected]);

  const addGoal = async() => {
    const objectivesCopy = [...objectives];
    const objectiveValues = {...objectiveSelected};
    objectiveValues.milestones.push({
      name: '',
      activities: [],
      creating: true
    });
    const objectiveIndex = objectives.findIndex(objective => objective.specificObjectiveNumber === objectiveSelected.specificObjectiveNumber);
    objectivesCopy[objectiveIndex] = objectiveValues;
    setChanged(true);
    setObjectives(objectivesCopy);
    setEditingField([objectiveValues.milestones.length-1]);
  }

  // const addActivity = async(goalIdx) => {
  //   const objectivesCopy = [...objectives];
  //   const objectiveValues = {...objectiveSelected};
  //   objectiveValues.milestones[goalIdx].activities.push({
  //     name: '',
  //     startDateActivity: 0,
  //     endDateActivity: 0,
  //     creating: true
  //   });
  //   const objectiveIndex = objectives.findIndex(objective => objective.id === objectiveSelected.id);
  //   objectivesCopy[objectiveIndex] = objectiveValues;
  //   setChanged(true);
  //   await setObjectives(objectivesCopy);
  //   await setEditingField([goalIdx, objectiveValues.milestones[goalIdx].activities.length-1]);
  // }

  const handleItemEdit = (e) => {
    setChanged(true);
    setItemEdit(e.target.value);
  }

  const handleEditObjectives = () => {
    const objectivesCopy = [...objectives];
    const objectiveCopy = {...objectiveSelected};
    if (editingField.length === 1) {
      objectiveCopy.milestones[editingField[0]].name = itemEdit;
      setItemSelected({...objectiveCopy.milestones[editingField[0]], type: 'goal'});
    }
    else {
      objectiveCopy.milestones[editingField[0]].activities[editingField[1]].name = itemEdit;
      setItemSelected({...objectiveCopy.milestones[editingField[0]].activities[editingField[1]], type: 'activity'});
    }
    const objectiveIndex = objectives.findIndex(objective => objective.specificObjectiveNumber === objectiveSelected.specificObjectiveNumber);
    objectivesCopy[objectiveIndex] = objectiveCopy;
    setObjectives(objectivesCopy);
    console.log('aca va apiiii');
  }

  const handleEditingField = (...indexes) => {
    setEditingField(indexes);
  };

  const handleSaveItem = (e) => {
    if (e.keyCode === 13) {
      handleEditObjectives();
      setEditingField([]);
    }
  }

  const handleDeleteItem = (e, type, ...indexes) => {
    e.stopPropagation();
    const deleteItem = () => {
      dispatch(setLoading(true));
      const objectivesCopy = [...objectives];
      const objectiveCopy = {...objectiveSelected};
      if (indexes.length === 1) {
        objectiveCopy.milestones.splice(indexes[0], 1);
      }
      else {
        objectiveCopy.milestones[indexes[0]].activities.splice(indexes[1], 1);
      }
      objectiveCopy[activeStep] = objectiveCopy;
      setObjectives(objectivesCopy);
      dispatch(handleNotifications(true, {
        status: 'success',
        message: `${type === 'goal' ? 'Hito eliminado' : 'Actividad eliminada'} correctamente`}
      ));
      dispatch(setLoading(false));
    }
    const alertData = {
      title: `Seguro que desea eliminar ${type === 'goal' ? 'este hito' : 'esta actividad'}?`,
      text: 'No se podrá recuperar',
      acceptFunction: deleteItem
    }
    alert(alertData);
  }

  return (
    <>
      <List component="nav" onKeyDown={handleSaveItem}>
        <FilterComponent permission={'milestone.show'}>
          {
            objectiveSelected.milestones.map((goal, goalIdx) =>
              <ProjectActivitiesListGoals key={goal} generalPurposesData={generalPurposesData} goal={generalPurposesData.entities?.milestones[goal]}
                goalIdx={goalIdx} handleItemSelected={handleItemSelected}
                handleItemEdit={handleItemEdit}
                handleEditingField={handleEditingField} editingField={editingField} edit={edit} handleEditObjectives={handleEditObjectives} itemEdit={itemEdit}
                addActivity={addActivity} handleDeleteItem={handleDeleteItem} changed={changed} setChanged={setChanged} setCallApi={setCallApi}
                setEditingField={setEditingField} addActivity={addActivity} addMilestone={addMilestone}
              />
            )
          }
        </FilterComponent>
        <FilterComponent permission={'milestone.create'}>
          {
            edit &&
            <ColorListItemAdd
              key={'addGoal'}
              button
              onClick={addMilestone}
            >
              Agregar Hito
            </ColorListItemAdd>
          }
        </FilterComponent>
      </List>
    </>
  )
}

export default ProjectActivitiesList;
