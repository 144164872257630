import React from 'react';

import {Button, InputGroup, FormControl} from 'react-bootstrap';

const CreateProjectEditSectionTableHeader = ({tableRows, addTableHeader, removeTableHeader, handleTableChanges, }) => {

  return (
    <thead>
      <tr>
        {
          tableRows[0].rowFields.map((tableHeader, i) =>
            <th>
              <InputGroup>
                <FormControl
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  defaultValue={tableHeader}
                  onChange={e => handleTableChanges(0, i, e)}
                />
                <InputGroup.Append>
                  <Button variant="outline-secondary" onClick={() => removeTableHeader(i)}>x</Button>
                </InputGroup.Append>
              </InputGroup>
            </th>
          )
        }
        <th><Button onClick={addTableHeader}>+</Button></th>
      </tr>
    </thead>
  )
}

export default CreateProjectEditSectionTableHeader;
