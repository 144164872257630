import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Typography, Grid, Card, CardContent, Button, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  buttons: {
    margin: '20px 200px 50px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  subTaskTitle: {
    fontSize: 24,
    fontWeight: 'bold'
  },
  info: {
    fontSize: 20,
    marginTop: 10,
    fontWeight: 'bold'
  },
  cards: {
    margin: 5
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
  section: {
    margin: 20
  },
  name: {
    fontSize: 20
  }
}));

const TaskShow = ({taskForm, }) => {

  const classes = useStyles();

  return (
    <>
      <Card>
        <CardContent>
          <Grid container className={classes.section}>
            <Grid item xs={8}>
              <Typography className={classes.subTaskTitle}>
                {`Nombre de la tarea`}
              </Typography>
              <Typography className={classes.name}>
                {`${taskForm.taskName}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.section}>
            <Grid item xs={6} lg={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Código Memo:`}
                </Typography>
                <Typography >
                  {`${taskForm.memoCode}`}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} lg={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Fecha`}
                </Typography>
                <Typography >
                  {`${taskForm.date}`}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.section}>
            <Grid item xs={6} lg={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Código DDI:`}
                </Typography>
                <Typography >
                  {`${taskForm.ddiCode}`}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} lg={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Unidad Académica:`}
                </Typography>
                <Typography >
                  {`${taskForm.academicTeam}`}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.section}>
            <Grid item xs={6} lg={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Monto Máximo:`}
                </Typography>
                <Typography >
                  {`${taskForm.maxAmount || 0}`}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} lg={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Nombre de Solicitante:`}
                </Typography>
                <Typography >
                  {`${taskForm.applicantName}`}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.section}>
            <Grid item xs={6} lg={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Tipo de Gasto:`}
                </Typography>
                <Typography >
                  {`${taskForm.taskType}`}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.section}>
            <Grid item xs={6} lg={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Unidad Académica:`}
                </Typography>
                <Typography >
                  {`${taskForm.academicTeam}`}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default TaskShow;
