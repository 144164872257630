import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {FormControl, InputLabel, Select, MenuItem, } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '10px 20px 10px 30px',
    minWidth: 120,
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ObjectivesSelect = ({generalPurposesData, specificPurposes, activeStep, setActiveStep, }) => {

  const classes = useStyles();

  const handleChange = (e) => setActiveStep(e.target.value);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">Objetivos</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={activeStep}
        onChange={handleChange}
        label="Objetivo"
      >
        {
          specificPurposes?.map(specificPurpose =>
            <MenuItem value={specificPurpose}>{generalPurposesData.entities?.specificPurposes[specificPurpose]?.name}</MenuItem>
          )
        }
      </Select>
    </FormControl>
  )
}

export default ObjectivesSelect;
