import React, {useState, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, Typography, Box, Grid, } from '@material-ui/core';

import alert from '../../../../Utils/Alert/Alert';

import PaymentDetails from './PaymentDetails';
import PaymentSubTasks from './PaymentSubTasks';
import PaymentHistorial from './/PaymentHistorial';
import PaymentsProofForm from '../../../ProjectDetailsTabs/ProjectDetailsCapitulation/PaymentsProofs/PaymentsProofForms/PaymentsProofForm';
import CapitulationSubTasksTable from '../../../ProjectDetailsTabs/ProjectDetailsCapitulation/CapitulationSubTasksTable/CapitulationSubTasksTable';
import HistoryRecordTable from '../../../../Utils/HistoryRecord/HistoryRecordTable/HistoryRecordTable';
// import IndicatorFiles from './IndicatorFiles';
import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';
import FilterTabsComponent from '../../../../Utils/FilterComponent/FilterTabsComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  bar: {
    backgroundColor: '#002F6C',
    borderRight: `1px solid ${theme.palette.divider}`,
  }
}));

const historyData = {
  history: ['', '']
}

const PaymentsTabs = ({project, subTasks, type, paymentsProofForm, setPaymentsProofForm, reason, setReason, handleReason, addFile, handlePaymentForm,
  accumulatedAmount, handleChange, selectSubTask, paymentData, handlePaymentSubTasks, handleDeletePayment, }) => {

  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [edit, setEdit] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setReason('');
    setValue(newValue);
  };

  const handleEdit = () => setEdit(edit => !edit);

  const deletePayment = () => {
    const handleDelete = () => {
      handleDeletePayment(reason);
      setReason('');
    };
    const alertData = {
      title: `Seguro que desea eliminar el comprobante de pago?`,
      text: 'Se desvincularan las subtareas asociadas y no se podrá recuperar',
      acceptFunction: handleDelete
    }
    alert(alertData);
  }

  const tabs = [
    {
      permission: 'proofOfPayment.show',
      label: 'Comprobante de pago',
      component: <>
        <Grid container justify="flex-end">
          <FilterComponent permission={'proofOfPayment.update'}>
            {
              edit ?
              <SecondaryButton title={'Cancelar'} onClick={handleEdit} />
              :
              <PrimaryButton title={'Editar'} onClick={handleEdit} />
            }
          </FilterComponent>
        </Grid>
        {
          edit ?
          <FilterComponent permission={'proofOfPayment.update'}>
            <PaymentsProofForm subTasks={subTasks} type={type} paymentsProofForm={paymentsProofForm} setPaymentsProofForm={setPaymentsProofForm}
              reason={reason} handleReason={handleReason} handleChange={handleChange} addFile={addFile}
            />
          </FilterComponent>
          :
          <PaymentDetails paymentsProofForm={paymentsProofForm}/>
        }
        {
          edit &&
          <Grid container style={{marginTop: 20}}>
            <Grid item xs={6}>
              <Grid container justify="flex-start">
                <FilterComponent permission={'proofOfPayment.delete'}>
                  <PrimaryButton title={'Eliminar comprobante'} onClick={deletePayment} disabled={!reason} />
                </FilterComponent>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justify="flex-end">
                <PrimaryButton title={'Guardar cambios'} onClick={handlePaymentForm} disabled={!reason} />
              </Grid>
            </Grid>
          </Grid>
        }
      </>
    },
    {
      permission: 'proofOfPayment.show',
      label: 'Subtareas',
      component: <PaymentSubTasks paymentsProofForm={paymentsProofForm} subTasks={subTasks} type={'new'} subTasksSelected={paymentsProofForm.subTasks || []}
        selectSubTask={selectSubTask} handleChange={handleChange} accumulatedAmount={accumulatedAmount} paymentData={paymentData}
        handlePaymentSubTasks={handlePaymentSubTasks} setPaymentsProofForm={setPaymentsProofForm}
      />
    },
    {
      permission: 'proofOfPayment.hisotory',
      label: 'Historial',
      component: <PaymentHistorial project={project} paymentsProofForm={paymentsProofForm} />
    },
  ]

  return (
    <div className={classes.root}>
      <FilterTabsComponent value={value} handleChangeTab={handleChangeTab} tabs={tabs} />
      {/*<AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChangeTab}
          TabIndicatorProps={{style: {background:'white'}}}
          className={classes.bar}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab label="Comprobante de pago" {...a11yProps(0)} />
          <Tab label="Subtareas" {...a11yProps(1)} />
          <Tab label="Historial" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container justify="flex-end">
        {
          edit ?
          <SecondaryButton title={'Cancelar'} onClick={handleEdit} />
          :
          <PrimaryButton title={'Editar'} onClick={handleEdit} />
        }
        </Grid>
        {
          edit ?
          <PaymentsProofForm subTasks={subTasks} type={type} paymentsProofForm={paymentsProofForm} setPaymentsProofForm={setPaymentsProofForm}
            reason={reason} handleReason={handleReason} handleChange={handleChange} addFile={addFile}
          />
          :
          <PaymentDetails paymentsProofForm={paymentsProofForm}/>
        }
        {
          edit &&
          <Grid container style={{marginTop: 20}}>
            <Grid item xs={6}>
              <Grid container justify="flex-start">
                <PrimaryButton title={'Eliminar comprobante'} onClick={deletePayment} disabled={!reason} />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justify="flex-end">
                <PrimaryButton title={'Guardar cambios'} onClick={handlePaymentForm} disabled={!reason} />
              </Grid>
            </Grid>
          </Grid>
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PaymentSubTasks paymentsProofForm={paymentsProofForm} subTasks={subTasks} type={'new'} subTasksSelected={paymentsProofForm.subTasks || []}
          selectSubTask={selectSubTask} handleChange={handleChange} accumulatedAmount={accumulatedAmount} paymentData={paymentData}
          handlePaymentSubTasks={handlePaymentSubTasks} setPaymentsProofForm={setPaymentsProofForm} active={value === 1}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PaymentHistorial project={project} paymentsProofForm={paymentsProofForm} active={value === 2} />
      </TabPanel>*/}
    </div>
  );
}

export default PaymentsTabs;
