import React, {} from 'react';

import ProjectDetailsSectionTextField from './ProjectDetailsSectionFieldsTypes/ProjectDetailsSectionTextField';
import ProjectDetailsSectionDynamicFields from './ProjectDetailsSectionFieldsTypes/ProjectDetailsSectionDynamicFields';
import ProjectDetailsSectionDynamicTable from './ProjectDetailsSectionFieldsTypes/ProjectDetailsSectionDynamicTable';
import ProjectDetailsSectionRepository from './ProjectDetailsSectionFieldsTypes/ProjectDetailsSectionRepository';

const ProjectDetailsSectionFields = ({field, sectionIdx, projectData, setProjectData, sectionFields, setSectionFields, removeField, }) => {



  if (field.fieldType === 'textField') {
    return (
      <ProjectDetailsSectionTextField field={field} sectionIdx={sectionIdx} sectionFields={sectionFields} setSectionFields={setSectionFields}
        removeField={removeField} />
    )
  }
  else if (field.fieldType === 'table') {
    return (
      <ProjectDetailsSectionDynamicTable field={field} sectionIdx={sectionIdx} sectionFields={sectionFields} setSectionFields={setSectionFields}
        removeField={removeField} />
    )
  }
  else if (field.fieldType === 'dynamicFields') {
    return (
      <ProjectDetailsSectionDynamicFields field={field} sectionIdx={sectionIdx} sectionFields={sectionFields} setSectionFields={setSectionFields}
        removeField={removeField} />
    )
  }
  else if (field.fieldType === 'repository') {
    return (
      <ProjectDetailsSectionRepository field={field} sectionIdx={sectionIdx} sectionFields={sectionFields} setSectionFields={setSectionFields}
        removeField={removeField} />
    )
  }
  else {
    return(
      <>
      </>
    )
  }
}

export default ProjectDetailsSectionFields;
