export const setLoading = (loading) => {
  if (loading) {
    return (dispatch) => dispatch(startLoading());
  }
  else {
    return (dispatch) => dispatch(finishLoading());
  }
}

const startLoading = () => ({
  type: 'startLoading',
  payload: {}
})

const finishLoading = () => ({
  type: 'finishLoading',
  payload: {}
})
