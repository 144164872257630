import clientAxios from '../../config/axios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

export const getProjectPeriods = (projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/project/${projectId}/periods/list`);
      dispatch(setProjectPeriods(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener los períodos del proyecto'}
      ));
    }
  }
}

const setProjectPeriods = (projectPeriods) => ({
  type: 'setProjectPeriods',
  payload: {
    projectPeriods: projectPeriods
  }
});

export const setProjectPeriod = (projectId, period) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const periodData = {...period, projectId: projectId};
      const {data} = await clientAxios().post(`/api/project/periods/add`, periodData);
      dispatch(getProjectPeriods(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Período creado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al crear el período'}
      ));
    }
  }
}

export const deleteProjectPeriod = (projectId, periodId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().delete(`/api/project/period/${periodId}`);
      dispatch(getProjectPeriods(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Período eliminado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al crear el período'}
      ));
    }
  }
}

// const setProjectPeriods = (projectPeriods) => ({
//   type: 'setPeriods',
//   payload: {
//     projectPeriods: projectPeriods
//   }
// });
