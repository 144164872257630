import React from 'react';

const ProjectDetailsSectionTableHeader = ({headerRows, }) => {

  return (
    <thead>
        {
          headerRows.rowFields.map(header =>
            <th>{header}</th>
          )
        }
    </thead>
  )
}

export default ProjectDetailsSectionTableHeader;
