import React, {useState, useEffect, } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {Typography, Grid, FormControl, InputLabel, Select, MenuItem, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {newIndicator, editIndicator, deleteIndicator, } from '../../../../../redux/actions';

import alert from '../../../../Utils/Alert/Alert';

import IndicatorGoalsTable from './IndicatorGoalsTable/IndicatorGoalsTable';
import IndicatorPurposeSelect from './IndicatorPurposeSelect';

import useForm from '../../../../FormValidator/formValidator';

import TextField from '../../../../Utils/TextFields/TextField';
import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles(theme => ({
  fields: {
    width: '98%',
    marginBottom: 20
  },
  details: {
    width: '95%'
  },
  selects: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '95%',
    marginBottom: 10
  }
}));

const validationStateSchema = {
  name: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  baseLine: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
};

const Indicator = ({project, generalPurposesData, type, indicator, files, show, handleClose, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [indicatorForm, setIndicatorForm] = useState({
    name: '',
    calculationFormula: '',
    baseLine: '',
    indicatorGoal: [],
    files: []
  });
  const [specificPurposeSelected, setSpecificPurposeSelected] = useState(-1);
  const [edit, setEdit] = useState(type === 'create');
  const [checkGoals, setCheckGoals] = useState(false);

  useEffect(() => {
    setEdit(type === 'create');
  }, [type, show]);

  useEffect(() => {
    if (!(type === 'create')) {
      setIndicatorForm(indicator);
    }
  }, [indicator]);

  useEffect(() => {
    const indicatorGoal = [...indicatorForm.indicatorGoal];
    const namesIG = indicatorGoal.every(goal => goal.goal);
    setCheckGoals(namesIG);
  }, [indicatorForm]);

  const handleChange = (e) => {
    setIndicatorForm(indicatorForm => ({...indicatorForm, [e.target.name]: e.target.value}));
  }

  const handleSpecificPurpose = (e) => setSpecificPurposeSelected(e.target.value);

  const handleEditIndicator = () => {
    const indicatorGoals = indicatorForm.indicatorGoal.map((goal, idx) => ({goal: goal.goal, year: idx+1}));
    const newIndicatorForm = {
      ...indicatorForm,
      indicatorGoals: indicatorGoals,
    }
    dispatch(editIndicator(newIndicatorForm, project.id));
  }

  const handleNewIndicator = () => {
    const indicatorGoals = indicatorForm.indicatorGoal.map((goal, idx) => ({goal: goal.goal, year: idx+1}));
    const newIndicatorForm = {
      ...indicatorForm,
      specificPurposeId: specificPurposeSelected,
      indicatorGoals: indicatorGoals,
    }
    dispatch(newIndicator(newIndicatorForm, project.id));
  }

  const handleDeleteIndicator = () => {
    dispatch(deleteIndicator(indicatorForm.id, project.id));
  }

  const confirmDeleteIndicator = () => {
    const alertData = {
      title: `Seguro que desea eliminar este indicador?`,
      text: 'No se podrá recuperar',
      acceptFunction: handleDeleteIndicator
    }
    alert(alertData);
  }

  const {disabled} = useForm(indicatorForm, validationStateSchema);

  return (
    <>
      {
        type === 'create' &&
        <IndicatorPurposeSelect project={project} generalPurposesData={generalPurposesData} specificPurposeSelected={specificPurposeSelected}
          setSpecificPurposeSelected={setSpecificPurposeSelected} handleSpecificPurpose={handleSpecificPurpose}
        />
      }
      <Grid container>
        <Grid item xs={type === 'create' ? 12:10} md={type === 'create' ? 12:10} lg={type === 'create' ? 12:10} >
          {
            edit ?
            <>
              <TextField
                classStyle={classes.fields}
                name="name"
                label="Nombre del Indicador"
                multiline
                rows={2}
                value={indicatorForm.name}
                onChange={handleChange}
                variant="outlined"
              />
              <TextField
                classStyle={classes.fields}
                name="calculationFormula"
                label="Fórmula de Cálculo"
                value={indicatorForm.calculationFormula}
                onChange={handleChange}
                multiline
                rows={2}
                variant="outlined"
              />
            </>
            :
            <>
              <Typography>
                {`Indicador: ${indicatorForm.name}`}
              </Typography>
              <Typography>
                {`Fórmula de cálculo: ${indicatorForm.calculationFormula}`}
              </Typography>
            </>
          }
        </Grid>
        {
          type === 'create' ||
          <Grid item xs={2} md={2} lg={2}>
            <FilterComponent permission={'indicator.update'}>
              <Grid container justify="flex-end">
              {
                edit ?
                <SecondaryButton title={'Cancelar'} onClick={() => setEdit(edit => !edit)} />
                :
                <PrimaryButton title={'Editar'} onClick={() => setEdit(edit => !edit)} />
              }
              </Grid>
            </FilterComponent>
          </Grid>
        }
      </Grid>
      <div style={{marginTop: 20, marginBottom: 50}}>
        <IndicatorGoalsTable indicatorForm={indicatorForm} setIndicatorForm={setIndicatorForm} handleChange={handleChange} type={type} edit={edit} />
      </div>
      <div style={{display: 'flex'}}>
        <FilterComponent permission={'indicator.delete'}>
          {
            type !== 'create' && edit &&
            <Grid container justify="flex-start">
              <SecondaryButton title={'Eliminar indicador'} onClick={confirmDeleteIndicator}/>
            </Grid>
          }
        </FilterComponent>
        <Grid container justify="flex-end">
          <SecondaryButton title={'Cerrar'} variant="secondary" onClick={handleClose} />
          {
            type === 'create' ?
            <FilterComponent permission={'indicator.create'}>
              <PrimaryButton title={'Crear Indicador'} onClick={handleNewIndicator} disabled={disabled || specificPurposeSelected < 0}/>
            </FilterComponent>
            :
            edit &&
            <FilterComponent permission={'indicator.update'}>
              <PrimaryButton title={'Guardar cambios'} onClick={handleEditIndicator} disabled={disabled || !checkGoals}/>
            </FilterComponent>
          }
        </Grid>
      </div>
    </>
  )
}

export default Indicator;
