import React, {} from 'react';

import {Card, Row, Col, Button, } from 'react-bootstrap';

import DynamicFields from '../../../../Utils/DynamicFields/DynamicFields';

const ProjectDetailsSectionDynamicFields = ({field, sectionIdx, sectionFields, setSectionFields, removeField, }) => {

  const handleChange = (i, event) => {
    let sectionFieldsCopy = [...sectionFields];
    const values = [...sectionFieldsCopy[sectionIdx].data];
    values[i].firstField = event.target.value;
    sectionFieldsCopy[sectionIdx].data = values;
    setSectionFields(sectionFieldsCopy);
  }

  const handleChangeType = (idx, n, event) => {
    let sectionFieldsCopy = [...sectionFields];
    const values = [...sectionFieldsCopy[sectionIdx].data];
    const valueSelected = values[idx];
    valueSelected.secondField[n].value = event.target.value;
    values[idx] = valueSelected;
    sectionFieldsCopy[sectionIdx].data = values;
    setSectionFields(sectionFieldsCopy);
  }

  const handleAdd = () => {
    let sectionFieldsCopy = [...sectionFields];
    const values = [...sectionFieldsCopy[sectionIdx].data];
    values.push({
      firstField: null,
      secondField:[{ value: null }]
    });
    sectionFieldsCopy[sectionIdx].data = values;
    setSectionFields(sectionFieldsCopy);
  }

  const handleAddType = (idx) => {
    let sectionFieldsCopy = [...sectionFields];
    const values = [...sectionFieldsCopy[sectionIdx].data];
    const valueSelected = values[idx];
    valueSelected.secondField.push({ value: null })
    values[idx] = valueSelected;
    sectionFieldsCopy[sectionIdx].data = values;
    setSectionFields(sectionFieldsCopy);
  }

  const handleRemove = (i) => {
    let sectionFieldsCopy = [...sectionFields];
    const values = [...sectionFieldsCopy[sectionIdx].data];
    values.splice(i, 1);
    sectionFieldsCopy[sectionIdx].data = values;
    setSectionFields(sectionFieldsCopy);
  }

  const handleRemoveField = (idx, n) => {
    let sectionFieldsCopy = [...sectionFields];
    const values = [...sectionFieldsCopy[sectionIdx].data];
    const valueSelected = values[idx];
    valueSelected.secondField.splice(n, 1)
    values[idx] = valueSelected
    sectionFieldsCopy[sectionIdx].data = values;
    setSectionFields(sectionFieldsCopy);
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col xs={10}>
            <Card.Title>
              {field.fieldName}
            </Card.Title>
          </Col>
          <Col xs={2}>
            <Button onClick={() => removeField(sectionIdx)}>
              X
            </Button>
          </Col>
        </Row>
        <Card.Text>
          <DynamicFields goals={sectionFields[sectionIdx].data} handleChange={handleChange} handleChangeType={handleChangeType} handleAdd={handleAdd}
            handleAddType={handleAddType} handleRemove={handleRemove} handleRemoveField={handleRemoveField} defaultValue={field.data}/>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default ProjectDetailsSectionDynamicFields;
