const initialState={
  show: false,
  type: '',
  subTaskData: {
    taskIdx: 0,
    taskType: '',
    subTask: {
      description: '',
      period: {
        id: -1,
        startDate: null,
        endDate: null
      },
      file: {},
      inventoryable: false,
      subTaskType: 'purchaseOrder',
    },
    handleSubTask: () => {}
  }
}

const projectSubTaskModalReducer = (state = initialState, action) => {
  switch(action.type){
    case 'showSubTaskModal':
      return {
        ...state,
        show: true,
        type: action.payload.type,
        subTaskData: action.payload.subTaskData
      }
    case 'closeSubTaskModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default projectSubTaskModalReducer;
