const initialState={
  goodsFile: {},
  goodsList: []
}

const projectGoodsReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setGoodsFile':
      return {
        ...state,
        goodsFile: action.payload.goodsFile
      }
    case 'setGoodsList':
      return {
        ...state,
        goodsList: action.payload.goodsList
      }
      break;
    default:
      return state;
  }
}

export default projectGoodsReducer;
