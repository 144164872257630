import React, {useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, Typography, Box, Grid, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
// import {editProject, editProjectUsers, editProjectObjectives, } from '../../../redux/actions';

// import CreateProjectFormHeader from '../CreateProjectForm/CreateProjectFormHeader/CreateProjectFormHeader';
// import CreateProjectAssignUsers from '../CreateProjectForm/CreateProjectAssignUsers/CreateProjectAssignUsers';
// import CreateProjectFormObjectives from '../CreateProjectForm/CreateProjectFormHeader/CreateProjectFormObjectives';
import GoodForm from './GoodForm';
import GoodsFile from './GoodsFile';
import GoodDetails from './GoodDetails';

import PrimaryButton from '../../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../../Utils/Buttons/SecondaryButton';

import FilterComponent from '../../../../Utils/FilterComponent/FilterComponent';
import FilterTabsComponent from '../../../../Utils/FilterComponent/FilterTabsComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const validationStateSchema = {
  name: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  projectCode: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  financingSourceId: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
};

const GoodsTabs = ({project, goodForm, goodFile, handleChange, handleFile, handleEditGood, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);
  const [edit, setEdit] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = () => setEdit(edit => !edit);

  const tabs = [
    {
      permission: 'inventoryProperty.show',
      label: 'Datos del bien',
      component: <>
        <FilterComponent permission={'inventoryProperty.update'}>
          <Grid container justify="flex-end">
            {
              edit ?
              <SecondaryButton title={'Cancelar'} onClick={handleEdit} />
              :
              <PrimaryButton title={'Editar'} onClick={handleEdit} />
            }
          </Grid>
        </FilterComponent>
        {
          edit ?
          <GoodForm goodForm={goodForm} handleChange={handleChange} handleEditGood={handleEditGood} />
          :
          <GoodDetails goodForm={goodForm} />
        }
      </>
    },
    {
      permission: 'inventoryProperty.download',
      label: 'Archivo del bien',
      component: <GoodsFile project={project} goodForm={goodForm} goodFile={goodFile} handleFile={handleFile} />
    },
  ]

  return (
    <div className={classes.root}>
      <FilterTabsComponent value={value} handleChangeTab={handleChangeTab} tabs={tabs} />
      {/*<AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChangeTab}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          <Tab label="Datos del bien" {...a11yProps(0)} />
          <Tab label="Archivo del bien" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} >
        <GoodForm goodForm={goodForm} handleChange={handleChange} handleEditGood={handleEditGood} />
      </TabPanel>
      <TabPanel value={value} index={1} >
        <GoodsFile project={project} goodForm={goodForm} goodFile={goodFile} handleFile={handleFile} />
      </TabPanel>*/}
    </div>
  );
}

export default GoodsTabs;
