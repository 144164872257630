import React, {useState, useEffect, useCallback, } from 'react';

import { makeStyles } from "@material-ui/core/styles";
import {Grid, Collapse, Accordion, AccordionDetails, AccordionSummary, Typography, List, ListItem, ListItemText, IconButton, Divider, Paper, } from '@material-ui/core';
import RootRef from '@material-ui/core/RootRef'

import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import {useDropzone} from 'react-dropzone';

import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

const useStyles = makeStyles(theme => ({
  filesSelector: {
    height: 70
  },
  details: {
    width: '95%'
  },
  selects: {
    width: 120,
    marginBottom: 10
  },
  listItem: {
    display: 'flex',
    width: '60%',
  },
  uploadText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '350px'
  },
  buttonFile: {
    backgroundColor: 'transparent',
    borderColor: '#777 !important',
    color: '#777',
    textAlign: 'left',
    height: 50,
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const FilesSelector = ({handleAddFiles, modal, }) => {

  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const [showAddFiles, setShowAddFiles] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    setFiles(files => {
      files.push(...acceptedFiles);
      let filesUnique = [...new Set(files)];
      return filesUnique;
    });
  }, []);

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps();

  const handleShowAddFiles = () => setShowAddFiles(showAddFiles => !showAddFiles);

  const removeFile = (fileIdx) => {
    const filesCopy = [...files];
    filesCopy.splice(fileIdx, 1);
    setFiles(filesCopy);
  }

  const handleSelect = () => {
    handleAddFiles(files);
    setFiles([]);
  }

  return (
    <>
    {
      modal ?
      <>
        <AccordionDetails style={{display: 'block'}}>
          <div>
            <Grid container>
              <Grid item xs={8}>
                <RootRef rootRef={ref}>
                  <Paper {...rootProps} className={classes.filesSelector}>
                    <input {...getInputProps()} />
                    <p>Arraste aquí su(s) archivos</p>
                  </Paper>
                </RootRef>
                {/*<div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <p>Arraste aquí su(s) archivos</p>
                </div>*/}
              </Grid>
              <Grid item xs={4}>
                <Grid container justify="flex-end">
                  <PrimaryButton title={'Guardar archivos'} onClick={handleSelect} disabled={files.length === 0}/>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
        <AccordionDetails style={{display: 'block'}}>
          {
            files.length > 0 &&
            <Divider style={{width: '50%'}} />
          }
          {
            files.map((file, fileIdx) =>
              <>
              <List className={classes.listItem}>
                <ListItemText className={classes.uploadText}>
                  <InsertDriveFileIcon />
                  {file.name}
                </ListItemText>
                <IconButton className={classes.clearIcon} onClick={() => removeFile(fileIdx)}>
                  <ClearIcon />
                </IconButton>
              </List>
              <Divider style={{width: '50%'}}/>
              </>
            )
          }
        </AccordionDetails>
      </>
      :
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Typography>
            Agregar Archivos
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{display: 'block'}}>
          <div>
            <Grid container>
              <Grid item xs={8}>
                <RootRef rootRef={ref}>
                  <Paper {...rootProps} className={classes.filesSelector}>
                    <input {...getInputProps()} />
                    <p>Arraste aquí su(s) archivos</p>
                  </Paper>
                </RootRef>
                {/*<div className={classes.filesSelector}>
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <p>Arraste aquí su(s) archivos</p>
                </div>
                </div>*/}
              </Grid>
              <Grid item xs={4}>
                <Grid container justify="flex-end">
                  <PrimaryButton title={'Guardar archivos'} disabled={files.length === 0} onClick={handleSelect}/>
                </Grid>
              </Grid>
            </Grid>
            </div>
          </AccordionDetails>
          <AccordionDetails style={{display: 'block'}}>
            {
              files.length > 0 &&
              <Divider style={{width: '50%'}} />
            }
            {
              files.map((file, fileIdx) =>
                <>
                <List className={classes.listItem}>
                  <ListItemText className={classes.uploadText}>
                    <InsertDriveFileIcon />
                    {file.name}
                  </ListItemText>
                  <IconButton className={classes.clearIcon} onClick={() => removeFile(fileIdx)}>
                    <ClearIcon />
                  </IconButton>
                </List>
                <Divider style={{width: '50%'}}/>
                </>
              )
            }
          </AccordionDetails>
      </Accordion>
    }
    </>
  )
}

export default FilesSelector;
