import clientAxios from '../../config/axios';
import {uploadFileAxios} from '../../config/manageFileAxios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';
import {getGeneralPurposes, } from './projectActions';

export const handleProjectActivitiesModal = (show, objectiveData) => {
  if (show) {
    return dispatch => dispatch(showActivitiesModal(objectiveData));
  }
  else {
    return dispatch => dispatch(closeActivitiesModal());
  }
}

const showActivitiesModal = (objectiveData) => ({
  type: 'showActivitiesModal',
  payload: {
    objectiveData: objectiveData
  }
})

const closeActivitiesModal = () => ({
  type: 'closeActivitiesModal',
  payload: {}
})

export const getSpecificPurposes = (projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/generalpurpose/${projectId}`);
      const {specificPurposes} = data;
      dispatch(setEspecificPurposes(specificPurposes));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(failedGetSpecificPurposes());
    }
  }
}

const setEspecificPurposes = (specificPurposes) => ({
  type: 'setEspecificPurposes',
  payload: {
    specificPurposes: specificPurposes
  }
});

const failedGetSpecificPurposes = (specificPurposes) => ({
  type: 'failedGetSpecificPurposes',
  payload: {}
});

export const createSpecificPurpose = (specificPurpose) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      console.log(specificPurpose);
      const {data} = await clientAxios().post(`/api/specificpurpose/add`, specificPurpose);
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
    }
  }
}

export const createMilestone = (milestone, projectId) => {
  return async dispatch => {
    try {
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      console.log({...milestone, userId: userData.userId});
      dispatch(setLoading(true));
      // const {data} = await clientAxios().post(`/api/milestone/add2`, milestone);
      await uploadFileAxios(milestone, [], `/api/milestone/add2`, 'post');
      dispatch(getGeneralPurposes(projectId));
      dispatch(setLoading());
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Actividad añadida correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al agregar el hito'}
      ));
    }
  }
}

export const createActivity = (activity, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const activityData = {
        ...activity,
        userId: userData.userId
      }
      const {data} = await clientAxios().post(`/api/activity/add`, activityData);
      // const {activityData} = data;
      dispatch(getGeneralPurposes(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Actividad añadida correctamente'}
      ));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al agregar la actividad'}
      ));
    }
  }
}

export const editActivity = (activity, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const dataRequest = {
        ...activity,
        userId: userData.userId
      }
      const {data} = await clientAxios().put(`/api/activity/edit/${activity.id}`, dataRequest);
      // const {activityData} = data;
      dispatch(getGeneralPurposes(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Actividad editada correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al editar la actividad'}
      ));
    }
  }
}

export const saveGoalFile = (milestoneId, files, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      console.log(userData);
      const objectData = {
        userid: userData.userId,
        milestoneId: milestoneId
      }
      console.log(objectData);
      console.log(files);
      await uploadFileAxios(objectData, files, `/api/milestone/uploadFiles/${milestoneId}`, 'post');
      dispatch(getGeneralPurposes(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivos agregados correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al guardar el archivo de hito'}
      ));
    }
  }
}

export const deleteGoalFile = (fileId, reason, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const dataRequest = {
        reason: reason,
        userId: userData.userId
      }
      // const {data} = await axios.delete(`http://158.170.66.206:8085/api/indicator/deleteFile/${fileId}`, {data: dataRequest});
      const {data} = await clientAxios().delete(`/api/milestone/deleteFile/${fileId}`, {data: dataRequest});
      dispatch(getGeneralPurposes(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivos eliminado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al eliminar el archivo'}
      ));
    }
  }
}


export const editMilestone = (milestone, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      console.log(milestone);
      const {data} = await clientAxios().put(`/api/milestone/edit/${milestone.id}`, milestone);
      // const {activityData} = data;
      // await setTimeout(() => {console.log('holis');}, 3000);
      dispatch(getGeneralPurposes(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Hito editado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al editar el hito'}
      ));
    }
  }
}

export const deleteActivity = (activity, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      // const {data} = await clientAxios().post(`/api/activity/add`, activity);
      const {data} = await clientAxios().delete(`/api/activity/delete/${activity.id}`);
      // const {activityData} = data;
      dispatch(getGeneralPurposes(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Actividad eliminada correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al eliminar la actividad'}
      ));
    }
  }
}

export const deleteMilestone = (milestone, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().delete(`/api/milestone/delete/${milestone.id}`);
      // const {activityData} = data;
      // await setTimeout(() => {console.log('holis');}, 3000);
      dispatch(getGeneralPurposes(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Hito eliminado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al eliminar el hito'}
      ));
    }
  }
}
