import React, {useState, useEffect, useRef, } from 'react';

import { createMuiTheme, withStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {ListItem, ListItemText, TextField, Grid, } from '@material-ui/core';
import { green, purple, grey } from '@material-ui/core/colors';


import FeatherIcon from 'feather-icons-react';

import ProjectActivitiesListActivity from './ProjectActivitiesListActivity';

import alert from '../../Utils/Alert/Alert';

import FilterComponent from '../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
  }
}));

const ColorListItemAdd = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[300]),
    backgroundColor: grey[300],
    '&:hover': {
      backgroundColor: grey[300],
    },
    border: '1px solid grey'
  },
}))(ListItem);

const ColorListItem = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[800],
    },
    border: '1px solid grey'
  },
}))(ListItem);

function useOutsideAlerter(ref, handleEditingField, changed, setChanged) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    var i = 1;
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (changed) {
          if (i === 1) {
            const alertData = {
              title: `Seguro que desea dejar de editar?`,
              text: 'No se mantendrán los cambios',
            }
            alert(alertData);
            i = i+1;
          }
          // handleEditingField();
        }
        else {
          handleEditingField();
          setChanged(false);
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handleEditingField]);
}

const ProjectActivitiesListGoals = ({goal, generalPurposesData, goalIdx, handleItemSelected, handleEditingField, editingField, edit, handleEditObjectives, itemEdit,
  handleItemEdit, addActivity, handleDeleteItem, changed, setChanged, setCallApi, setEditingField, addMilestone, }) => {

  const classes = useStyles();
  const textRef = useRef(null);
  const wrapperref = useRef(null);

  useOutsideAlerter(wrapperref, handleEditingField, changed, setChanged);

  const goalSelected = editingField.length === 1 && editingField[0] === goalIdx;

  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    if (goalSelected) {
      textRef.current.focus();
    }
  }, [goalSelected]);

  const handleShowButtons = (state) => edit && !goalSelected && setShowButtons(state);

  const startEditField = (...indexes) => {
    setShowButtons(false);
    handleEditingField(...indexes);
  }

  return (
    <div>
      <ColorListItem button onClick={() => handleItemSelected(goal.id, 0, "goal")}
        // onDoubleClick={() => edit ? startEditField(goalIdx) : {}}
        onMouseEnter={() => handleShowButtons(true)} onMouseLeave={() => handleShowButtons(false)}>
        {
          goalSelected ?
          <div ref={wrapperref}>
            <TextField
              className={classes.textField}
              inputRef={textRef}
              value={itemEdit}
              onChange={handleItemEdit}
              placeholder="Hito"
              variant="outlined"
            />
          </div>
          :
          <Grid container>
            <Grid item xs={11}>
              <ListItemText>
                {`Hito ${goalIdx+1}: ${goal?.name}`}
              </ListItemText>
            </Grid>
            <Grid item xs={1}>
            {/*
              showButtons &&
              <FeatherIcon icon="x" className="mr-1 iconButton" onClick={e => handleDeleteItem(e, 'goal', goalIdx)}/>
            */}
            </Grid>
          </Grid>
        }
      </ColorListItem>
      <FilterComponent permission={'activity.show'}>
        {
          goal?.activities.map((activity, activityIdx) =>
            <ProjectActivitiesListActivity key={activity} activity={generalPurposesData.entities?.activities[activity]} activityIdx={activityIdx} goalIdx={goalIdx} handleItemEdit={handleItemEdit}
              handleItemSelected={handleItemSelected} handleEditingField={handleEditingField} editingField={editingField} edit={edit} itemEdit={itemEdit}
              useOutsideAlerter={useOutsideAlerter} handleDeleteItem={handleDeleteItem} changed={changed} setChanged={setChanged} setCallApi={setCallApi}
            />
          )
        }
      </FilterComponent>

      <FilterComponent permission={'activity.create'}>
        {
          edit &&
          <ColorListItemAdd
            key={'addActivity'}
            button
            onClick={() => addActivity(goal?.id)}
          >
            Agregar Actividad
          </ColorListItemAdd>
        }
      </FilterComponent>
    </div>
  )
}

export default ProjectActivitiesListGoals;
