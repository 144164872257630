import clientAxios from './axios';
import FileSaver from 'file-saver';

export const uploadFileAxios = (objectData, files, url, requestType) => {
  var formData = new FormData();
  // formData.append("userId", userId);
  // formData.append("id", id);
  const keys = Object.keys(objectData);
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], objectData[keys[i]]);
  }

  for (var i = 0; i < files.length; i++) {
    formData.append("documents", files[i]);
  }

  if (requestType === 'post') {
    return clientAxios().post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }
  else {
    return clientAxios().put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }
}

export const uploadSingleFileAxios = (objectData, file, url, requestType, fileNull) => {
  var formData = new FormData();
  // formData.append("userId", userId);
  // formData.append("id", id);
  const keys = Object.keys(objectData);
  for (var i = 0; i < keys.length; i++) {
    formData.append(keys[i], objectData[keys[i]]);
  }
  console.log(file);
  console.log(typeof file);

  file && formData.append("file", file);
  if (fileNull) {
    formData.append("file", file);
  }

  // for (var i = 0; i < files.length; i++) {
  //   formData.append("documents", files[i]);
  // }

  if (requestType === 'post') {
    return clientAxios().post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }
  else {
    return clientAxios().put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
  }
}

export const downloadFileAxios = async (url, fileName) => {
  const {data} =  await clientAxios().get(url, {
    responseType: 'blob',
    timeout: 30000,
  });
  await FileSaver.saveAs(data, fileName);
};
