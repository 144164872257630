import { createSelector } from 'reselect';

export const ProjectSelector = createSelector(
  state => state.project,
  project => project
);

export const ProjectPeriodsSelector = createSelector(
  state => state.projectPeriods,
  projectPeriods => projectPeriods.projectPeriods
);
