import React, {useState, useEffect} from 'react';

import clientAxios from '../../../../config/axios';

import {Button, Dialog, ListItemText, ListItem, List, Divider, AppBar, Toolbar, IconButton, Typography, Slide, Breadcrumbs, Link, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { useDispatch, } from 'react-redux';
import {handleProjectActivitiesModal, getSpecificPurposes, } from '../../../../redux/actions';

import ProjectActivities from '../../../ProjectActivities/ProjectActivities';

import FilterComponent from '../../../Utils/FilterComponent/FilterComponent';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: 'white'
  },
  text: {
    color: 'white',
    "&:hover": {
      color: 'white'
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectActivitiesModal = ({project, projectActivitiesModal, generalPurposesData, userData, activityStatus, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const {show, objectiveData, specificPurposesData} = projectActivitiesModal;

  const [specificObjectives, setEspecificObjectives] = useState([]);
  const [objectivesApi, setObjectivesApi] = useState([]);
  const [callApi, setCallApi] = useState(false);

  const {specificPurposes} = objectiveData.objective;

  useEffect(() => {
    if (show) {
      // const {id} = objectiveData.objective;
      // dispatch(getSpecificPurposes(id));
    }
  }, [show, objectiveData]);

  const handleClose = () => {
    dispatch(handleProjectActivitiesModal());
  }

  return (
    <Dialog fullScreen open={show} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.text}>
              <Typography color="inherit" >
                {objectiveData && objectiveData.project.name.toUpperCase()}
              </Typography>
              <Typography className={classes.text}>
                {objectiveData && objectiveData.objective.name.toUpperCase()}
              </Typography>
            </Breadcrumbs>
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClose}>
            Cerrar
          </Button>
        </Toolbar>
      </AppBar>
      <FilterComponent permission={'generalObjective.show'}>
        <ProjectActivities project={project} show={show} generalPurposesData={generalPurposesData} specificPurposes={specificPurposes} userData={userData}
          objectiveData={objectiveData}
          specificObjectives={specificPurposesData.specificPurposes} callApi={callApi} setCallApi={setCallApi} objectivesApi={objectivesApi}
          activityStatus={activityStatus}
        />
      </FilterComponent>
    </Dialog>
  );
}

export default ProjectActivitiesModal;
