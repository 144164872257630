import React from 'react';

import {TableRow, TableCell, } from "@material-ui/core";

import ProjectDetailsResultsTableRow from './ProjectDetailsResultsTableRow';

const ProjectDetailsResultsTableRowSpecific = ({specificPurpose, maxIndicatorGoals, openIndicatorModal, objectiveResults, }) => {

  return (
    <>
      <TableRow>
        <TableCell rowSpan={specificPurpose.indicators.length + 1}>{specificPurpose.name}</TableCell>
      </TableRow>
      {
        specificPurpose.indicators.map(indicator =>
          <ProjectDetailsResultsTableRow objectiveResults={objectiveResults} indicator={indicator} maxIndicatorGoals={maxIndicatorGoals}
            openIndicatorModal={openIndicatorModal}
          />
        )
      }
    </>
  )
}

export default ProjectDetailsResultsTableRowSpecific;
