const initialState={
  phases: [
    {
      phaseName: '',
      sections: [
        {
          sectionName: '',
          sectionData: {},
        }
      ]
    }
  ]
}

const createProjectReducer = (state = initialState, action) => {
  switch(action.type){
    default:
      return state;
  }
}

export default createProjectReducer;
