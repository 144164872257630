import React, {useState, useEffect, } from 'react';

import HistoryRecordTable from '../../../../Utils/HistoryRecord/HistoryRecordTable/HistoryRecordTable';

const GoalHistorial = ({itemForm, project, }) => {

  const [historial, setHistorial] = useState([]);

  useEffect(() => {
    const allHistorial = itemForm.milestoneVerification.map(file => file.milestoneVerificationRecords);
    const getAllHistorial = [].concat(...allHistorial);
    setHistorial(getAllHistorial);
  }, [itemForm]);

  return (
    <>
      <HistoryRecordTable title={'Historial'} historyData={historial} />
    </>
  )
}

export default GoalHistorial;
