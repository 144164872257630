import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Typography, Button, Grid, Card, CardContent, } from '@material-ui/core';

import InventoryDetails from './InventoryDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '0.5px solid grey',
    marginBottom: '10px'
  },
  title: {
    fontSize: 18,
  },
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
  blocks: {
    marginBottom: 20
  },
  title: {
    marginBottom: 10
  }
}));

const GoodDetails = ({goodForm, }) => {

  const classes = useStyles();

  return (
    <>
      <Card className={classes.blocks} >
        <CardContent>
          <Typography variant="h5" className={classes.title} >
            Datos del Bien
          </Typography>
          <InventoryDetails form={goodForm} />
          <Grid container>
            <Grid item xs={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Código:`}
                </Typography>
                <Typography >
                  {`${goodForm.codeNumber}`}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Número de serie:`}
                </Typography>
                <Typography >
                  {`${goodForm.serialNumber}`}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Ubicación:`}
                </Typography>
                <Typography >
                  {`${goodForm.location}`}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.blocks} >
        <CardContent>
          <Typography variant="h5" className={classes.title} >
            Datos del Responsable
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Responsable:`}
                </Typography>
                <Typography >
                  {`${goodForm.responsable}`}
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Correo del Responsable:`}
                </Typography>
                <Typography >
                  {`${goodForm.responsableEmail}`}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Número del responsable:`}
                </Typography>
                <Typography >
                  {`${goodForm.responsableNumber}`}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/*<CapitulationSubTasksTable subTasks={paymentsProofForm.subTasks || []} type={'capitulation'}/>*/}
    </>
  )
}

export default GoodDetails;
