import React from 'react';

import { useSelector } from 'react-redux';

import {UserPermissionsSelector, } from '../../../redux/selectors';

const FilterComponent = (props) => {

  const {userPermissionsList} = useSelector(UserPermissionsSelector);

  const token = localStorage.getItem('token');
  const userData = JSON.parse(atob(token.split('.')[1]));
  const userRole = userData.isAdmin;
  // const userRole = false;
  const checkPermissions = userPermissionsList.find(permission => permission.slug === props.permission);
  // const checkPermissions = userPermissionsList.includes(props.permission);

  return (
    <>
      {
        (userRole || checkPermissions) && props.children
      }
    </>
  )
}

export default FilterComponent;
