import React from 'react';

import { makeStyles, } from '@material-ui/core/styles';
import {CardContent, CardActions, Grid, InputAdornment, OutlinedInput, Button, FormControl, InputLabel, Select, MenuItem, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

import useForm from '../../../FormValidator/formValidator';

import TextField from '../../../Utils/TextFields/TextField';
import PrimaryButton from '../../../Utils/Buttons/PrimaryButton';
import SecondaryButton from '../../../Utils/Buttons/SecondaryButton';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '90%',
    margin: 10
  },
  formControl: {
    margin: 10,
    width: '90%'
  },
  buttonLeft: {
    marginLeft: 20
  },
  buttonRight: {
    marginRight: 20
  }
}));

const validationStateSchema = {
  name: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  statusId: {
    required: true,
    validator: /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*/
  },
  // tel_T: {
  //   validator: {
  //     regEx: /^[0-9]$/,
  //     error: 'Invalid last name format.',
  //   },
  // },
};

const ProjectActivityDetailsEdit = ({itemForm, setItemForm, handleItemEdit, saveItemDetails, handleNewActivity, handleEditActivity, handleDeleteActivity,
  activityStatus, }) => {

  const classes = useStyles();

  const {disabled} = useForm(itemForm, validationStateSchema);

  return (
    <>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              name="name"
              value={itemForm.name}
              onChange={handleItemEdit}
              label="Nombre de la actividad"
              variant="outlined"
              classStyle={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Estado</InputLabel>
              <Select
                name="statusId"
                value={itemForm.statusId}
                onChange={handleItemEdit}
                label="Estado"
              >
                {
                  activityStatus.map(status =>
                    <MenuItem value={status.id}>{status.name}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              name="startDate"
              value={itemForm.startDate}
              onChange={handleItemEdit}
              label="Fecha de inicio de la actividad"
              variant="outlined"
              classStyle={classes.textField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">{`Mes   `}</InputAdornment>
                ),
              }}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="endDate"
              value={itemForm.endDate}
              onChange={handleItemEdit}
              label="Fecha de término de la actividad"
              variant="outlined"
              classStyle={classes.textField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">{`Mes   `}</InputAdornment>
                ),
              }}
              type="number"
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container justify="start" className={classes.buttonLeft}>
        {
          itemForm.creating ||
          <SecondaryButton title={'Eliminar actividad'} onClick={handleDeleteActivity} />
        }
        </Grid>
        <Grid container justify="flex-end" className={classes.buttonRight}>
        {
          itemForm.creating ?
          <PrimaryButton title={'Crear Actividad'} onClick={handleNewActivity} disabled={disabled}/>
          :
          <PrimaryButton title={'Guardar'} onClick={handleEditActivity} disabled={disabled}/>
        }
        </Grid>
      </CardActions>
    </>
  )
}

export default ProjectActivityDetailsEdit;
