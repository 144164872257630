const initialState={
  show: false,
  projectData: {

  }
}

const projectSelectActivityModal = (state = initialState, action) => {
  switch(action.type){
    case 'showSelectActivityModal':
      return {
        ...state,
        show: true,
        projectData: action.payload.projectData,
        selectActivityFunction: action.payload.selectActivityFunction,
        type: action.payload.type
      }
    case 'closeSelectActivityModal':
      return {
        ...state,
        ...initialState
      }
    default:
      return state;
  }
}

export default projectSelectActivityModal;
