import React from 'react';
import { addDays, subDays, } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DatePicker from 'react-datepicker';

const useStyles = makeStyles({
  miniTitle: {
    fontWeight: 'bold',
    marginRight: 5
  },
  dates: {
    display: 'flex'
  },
});

const PeriodForm = ({type, startDate, endDate, onChange, excludeDates, highlightDates, handlePickDate, }) => {

  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={6} >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {
              type === 'create' ?
              <KeyboardDatePicker
                style={{marginRight: 10}}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Fecha de Inicio"
                name="startDate"
                value={startDate}
                onChange={date => handlePickDate(date, 'startDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              :
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Fecha de inicio:`}
                </Typography>
                <Typography >
                  {`${startDate.getDate()}/${startDate.getMonth()+1}/${startDate.getFullYear()}`}
                </Typography>
              </div>
            }
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {
              type === 'create' ?
              <KeyboardDatePicker
                style={{marginRight: 10}}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                label="Fecha de Término"
                name="endDate"
                value={endDate}
                onChange={date => handlePickDate(date, 'endDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              :
              <div className={classes.dates}>
                <Typography className={classes.miniTitle}>
                  {`Fecha de término:`}
                </Typography>
                <Typography >
                  {`${endDate.getDate()}/${endDate.getMonth()+1}/${endDate.getFullYear()}`}
                </Typography>
              </div>
            }
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} lg={6} >
          {
            type === 'create' ?
            <DatePicker
              selected={startDate}
              onChange={type === 'create' ? onChange : () => {}}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              excludeDates={excludeDates}
              highlightDates={highlightDates}
            />
            :
            <DatePicker
              selected={startDate}
              onChange={type === 'create' ? onChange : () => {}}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              includeDates={[]}
              excludeDates={excludeDates}
              highlightDates={highlightDates}
            />
          }
        </Grid>
      </Grid>
    </>
  )
}

export default PeriodForm;
