import React from 'react';

import {TableBody, } from '@material-ui/core';

import GoodsListRow from './GoodsListRow';

const GoodsListBody = ({projectPeriods, goods, showResponsible, }) => {

  return (
    <TableBody>
      {goods.map((good, goodIdx) => (
        <GoodsListRow key={good.id} projectPeriods={projectPeriods} good={good}
          goodIdx={goodIdx} showResponsible={showResponsible}
        />
      ))}
    </TableBody>
  )
}

export default GoodsListBody;
