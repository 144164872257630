const initialState = {
  projectStatusList: []
}

const projectStatusListReducer = (state = initialState, action) => {
  switch(action.type){
    case 'setProjectStatusList':
      return {
        ...state,
        projectStatusList: action.payload.projectStatusList
      }
    default:
      return state;
  }
}

export default projectStatusListReducer;
