export const handleSelectUserModal = (show, selectUserData) => {
  if (show) {
    return dispatch => dispatch(showSelectUserModal(selectUserData));
  }
  else {
    return dispatch => dispatch(closeSelectUserModal());
  }
}

const showSelectUserModal = (selectUserData) => ({
  type: 'showSelectUserModal',
  payload: {
    selectUserData: selectUserData
  }
})

const closeSelectUserModal = () => ({
  type: 'closeSelectUserModal',
  payload: {}
})
