import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme =>({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: '#FFF',
    width: 200,
    '& .MuiInputBase-root': {
      color: 'white',
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      color: '#FFF',
      borderColor: "#FFF"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFF"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      color: '#FFF',
      borderColor: "#FFF"
    }
  },
  formLabel: {
    color: '#FFF',
    '&.Mui-focused': {
      color: '#FFF'
    }
  }
}));

const TextFieldColoredSecondary = (props) => {

  const classes = useStyles();

  return (
    <TextField
      className={classes.textField}
      InputLabelProps={{
        className: classes.formLabel
      }}
      {...props}
    />
  )
}

export default TextFieldColoredSecondary;
