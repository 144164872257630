import clientAxios from '../../config/axios';
import {uploadFileAxios} from '../../config/manageFileAxios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';
import {getObjectiveResults, } from './projectObjectivesResultsActions';

export const handleIndicatorModal = (show, type, indicatorId) => {
  if (show) {
    return dispatch => {
      dispatch(showIndicatorModal(type, indicatorId));
    }
  }
  else {
    return dispatch => dispatch(closeIndicatorModal());
  }
}

const showIndicatorModal = (type, indicatorId) => ({
  type: 'showIndicatorModal',
  payload: {
    type: type,
    indicatorId: indicatorId
  }
})

const closeIndicatorModal = () => ({
  type: 'closeIndicatorModal',
  payload: {}
});

export const getIndicatorData = (indicatorId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/indicator/${indicatorId}`);
      dispatch(setIndicator({...data, indicatorGoals: []}));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el indicador'}
      ));
    }
  }
}

const setIndicator = (indicator) => ({
  type: 'setIndicator',
  payload: {
    indicator: indicator
  }
});

export const newIndicator = (indicatorForm, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      console.log(indicatorForm);
      console.log(projectId);
      const {data} = await clientAxios().post(`/api/indicator/add`, indicatorForm);
      dispatch(getObjectiveResults(projectId));
      dispatch(closeIndicatorModal());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el indicador'}
      ));
    }
  }
}

export const editIndicator = (indicatorForm, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().put(`/api/indicator/edit/${indicatorForm.id}`, indicatorForm);
      dispatch(getObjectiveResults(projectId));
      dispatch(closeIndicatorModal());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el indicador'}
      ));
    }
  }
}

export const deleteIndicator = (indicatorId, projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().delete(`/api/indicator/delete/${indicatorId}`);
      dispatch(getObjectiveResults(projectId));
      dispatch(closeIndicatorModal());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el indicador'}
      ));
    }
  }
}

export const saveIndicatorFile = (userId, indicatorId, files) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const objectData = {
        userid: userData.userId,
        id: indicatorId
      }
      console.log(objectData);
      await uploadFileAxios(objectData, files, `/api/indicator/uploadFiles/${indicatorId}`, 'post');
      dispatch(getIndicatorData(indicatorId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivos agregados correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el indicador'}
      ));
    }
  }
}

export const deleteIndicatorFile = (fileId, reason, userId, indicatorId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const dataRequest = {
        reason: reason,
        userId: userData.userId
      }
      // const {data} = await axios.delete(`http://158.170.66.206:8085/api/indicator/deleteFile/${fileId}`, {data: dataRequest});
      const {data} = await clientAxios().delete(`/api/indicator/deleteFile/${fileId}`, {data: dataRequest});
      dispatch(getIndicatorData(indicatorId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivos eliminado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al eliminar el archivo'}
      ));
    }
  }
}
