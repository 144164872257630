import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Accordion, AccordionSummary, AccordionDetails, Typography, } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import HistoryRecordTable from './HistoryRecordTable/HistoryRecordTable';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const historyData = {
  history: ['', '']
}

const HistoryRecordAccordion = () => {

  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>Historial</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <HistoryRecordTable title={''} historyData={historyData} />
      </AccordionDetails>
    </Accordion>
  )
}

export default HistoryRecordAccordion;
