import React, {useState, useEffect, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {Typography, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {handleSelectUserModal, } from '../../../../redux/actions';

import CreateProjectAssignUsersTable from './CreateProjectAssignUsersTable/CreateProjectAssignUsersTable';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: '2% 15% 2% 15%'
  }
}));

const CreateProjectAssignUsers = ({projectForm, setProjectForm, roles, }) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const assignUsers = (users) => {
    console.log(users);
    const setUserRole = users.map(user => {
      const findUser = projectForm.currentAssignedUsers.find(cUser => cUser.id === user.id);
      if (findUser) {
        return {...user, role: findUser.role};
      }
      else {
        return {...user, role: 1};
      }
    });
    console.log(setUserRole);
    setProjectForm(projectForm => ({...projectForm, currentAssignedUsers: setUserRole}));
    dispatch(handleSelectUserModal());
  };

  const handleChange = (e, userIdx) => {
    const projectFormAux = {...projectForm};
    projectFormAux.currentAssignedUsers[userIdx][e.target.name] = e.target.value;
    projectFormAux.currentAssignedUsers[userIdx].edited = true;
    setProjectForm(projectFormAux);
  }

  const removeUser = (userIdx) => {
    const currentAssignedUsersCopy = [...projectForm.currentAssignedUsers];
    currentAssignedUsersCopy.splice(userIdx, 1);
    setProjectForm(projectForm => ({...projectForm, currentAssignedUsers: currentAssignedUsersCopy}));
  }

  return (
    <div className={classes.form}>
      <CreateProjectAssignUsersTable currentAssignedUsers={projectForm.currentAssignedUsers} handleChange={handleChange} removeUser={removeUser}
        assignUsers={assignUsers} roles={roles}
      />
    </div>
  )
}

export default CreateProjectAssignUsers;
