import React from 'react';

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {UsersSelector, SelectUserModalSelector, } from '../../../redux/selectors';

import SelectUsersModal from '../../../components/GeneralModals/SelectUsersModal/SelectUsersModal';

const SelectUsersModalContainer = (userData) => {

  const selectUserModal = useSelector(SelectUserModalSelector);

  const usersData = useSelector(UsersSelector);

  return (
    <SelectUsersModal selectUserModal={selectUserModal} usersData={usersData} userData={userData} />
  )

}

export default SelectUsersModalContainer;
