import React from 'react';

import {TableBody, } from '@material-ui/core';

// import UsersListTableFilters from './UsersListTableFilters';
import UsersListTableRow from './UsersListTableRow';

const UsersListTableBody = ({users, usersSelected, selectUser, userInfo, }) => {

  return (
    <TableBody>
      {
        users.map(user =>
          <UsersListTableRow user={user} usersSelected={usersSelected} selectUser={selectUser} userInfo={userInfo} />
        )
      }
    </TableBody>
  )
}

export default UsersListTableBody;
