import clientAxios from '../../config/axios';
import {uploadFileAxios, uploadSingleFileAxios, } from '../../config/manageFileAxios';

import {setLoading, } from './loaderActions';
import {handleNotifications, } from './notificationActions';

const fakeFile = {
  fileName: 'Archivo 123',
  available: true
};

const budgetSummaryData = [
  {
    name: 'Gasto Adquirible',
    items: [
      {
        name: 'Bienes',
        value: 33000000
      },
      {
        name: 'Servicios de consultoría',
        value: 0
      },
      {
        name: 'Obras',
        value: 0
      }
    ]
  },
  {
    name: 'Recursos humanos',
    items: [
      {
        name: 'Recursos humanos',
        value: 293116147
      },
    ]
  },
  {
    name: 'Gastos académicos',
    items: [
      {
        name: 'Gastos académicos',
        value: 5750000
      },
    ]
  },
  {
    name: 'Gastos de administración',
    items: [
      {
        name: 'Gastos de administración',
        value: 27390000
      },
    ]
  }
];

export const getBudgetFile = (projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/budget/${projectId}`);
      dispatch(setBudgetFile(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el archivo de presupuesto'}
      ));
      dispatch(setLoading());
    }
  }
}

const setBudgetFile = (budgetFile) => ({
  type: 'setBudgetFile',
  payload: {
    budgetFile: budgetFile
  }
});

export const getBudgetResume = (projectId) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().get(`/api/budget/greatAccounts/${projectId}`);
      console.log(data);
      dispatch(setBudgetResume(data));
      dispatch(setLoading());
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el resumen del presupuesto'}
      ));
    }
  }
}

const setBudgetResume = (budgetResume) => ({
  type: 'setBudgetResume',
  payload: {
    budgetResume: budgetResume
  }
});

export const firstBudgetFile = (projectId, file) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const objectData = {
        userId: userData.userId,
        projectId: projectId
      }
      const {data} = await uploadSingleFileAxios(objectData, file, `/api/budget/uploadFiles`, 'post');
      dispatch(getBudgetFile(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivo agregado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el indicador'}
      ));
    }
  }
}

export const saveBudgetFile = (projectId, file, reason) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const token = localStorage.getItem('token');
      const userData = JSON.parse(atob(token.split('.')[1]));
      const objectData = {
        userId: userData.userId,
        projectId: projectId,
        reason: reason
      }
      const {data} = await uploadSingleFileAxios(objectData, file, `/api/budget/edit`, 'put');
      dispatch(getBudgetFile(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Archivo agregado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el indicador'}
      ));
    }
  }
}

export const saveBudgetResume = (projectId, budgetSummary) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true));
      const {data} = await clientAxios().post(`/api/budget/greatAccount/edit`, budgetSummary);
      dispatch(getBudgetResume(projectId));
      dispatch(handleNotifications(true, {
        status: 'success',
        message: 'Resumen de presupuesto modificado correctamente'}
      ));
    } catch (e) {
      console.log(e);
      dispatch(handleNotifications(true, {
        status: 'error',
        message: 'Hubo un error al obtener el indicador'}
      ));
    }
  }
}
