export const setUserPermissions = (userPermissionsList) => ({
  type: 'setUserPermissions',
  payload: {
    userPermissionsList: userPermissionsList,
  }
})

export const clearUserPermissions = () => ({
  type: 'clearUserPermissions',
  payload: {}
})
