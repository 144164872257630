import React, {useState, useEffect, } from 'react';

import {TableContainer, Paper, } from '@material-ui/core';

import { useDispatch, } from 'react-redux';
import {getGoodsList, } from '../../../../../../redux/actions';

import GoodsListTable from './GoodsListTable/GoodsListTable';
import GoodsFilter from './GoodsFilter';

const goodsData = [
  {
    description: 'Item 1',
    amount: 0,
    brand: 'marca 1',
    model: 'modelo 1',
    provider: 'proveedor 1',
    stockNumber: 111,
    serialNumber: 111,
    location: 'algun lugar 1',
    responsible: 'alguien 1',
    responsibleNumber: 123312,
    responsibleEmail: 'alguien@algo.com',
    period: 1,
    unit: 2,
    file: {}
  },
  {
    description: 'Item 2',
    amount: 0,
    brand: 'marca 2',
    model: 'modelo 2',
    provider: 'proveedor 2',
    stockNumber: 132,
    serialNumber: 132,
    location: 'algun lugar 2',
    responsible: 'alguien 2',
    responsibleNumber: 123312,
    responsibleEmail: 'alguien2@algo.com',
    period: 0,
    unit: 1,
    file: {}
  },
  {
    description: 'Item 3',
    amount: 0,
    brand: 'marca 1',
    model: 'modelo 2',
    provider: 'proveedor 1',
    stockNumber: 1123,
    serialNumber: 123,
    location: 'algun lugar 3',
    responsible: 'alguien 1',
    responsibleNumber: 123312,
    responsibleEmail: 'alguien@algo.com',
    period: 1,
    unit: 1,
    file: {}
  },
];

const GoodsList = ({project, academicUnits, projectPeriods, goodsList, active, }) => {

  const dispatch = useDispatch();

  const [goods, setGoods] = useState([]);
  const [goodsFiltered, setGoodsFiltered] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({
    unitSelected: -1,
    periodSelected: -1
  });

  useEffect(() => {
    if (active && project.id) {
      dispatch(getGoodsList(project.id));
    }
  }, [active, project.id]);

  useEffect(() => {
    setGoods(goodsList);
  }, [goodsList]);

  useEffect(() => {
    let goodsFilteredAux = [...goods];
    if (filters.unitSelected !== -1) {
      goodsFilteredAux = goodsFilteredAux.filter(good => good.academicUnit?.id === filters.unitSelected);
    }
    if (filters.periodSelected !== -1) {
      goodsFilteredAux = goodsFilteredAux.filter(good => good.periodId === filters.periodSelected);
    }
    goodsFilteredAux = goodsFilteredAux.filter(good => good.description.toLowerCase().includes(searchText.toLowerCase()));
    setGoodsFiltered(goodsFilteredAux);
  }, [filters, goods, searchText]);

  const handleSearchText = e => setSearchText(e.target.value);

  const resetText = () => setSearchText('');

  const handleChangeFilters = (e) => {
    setFilters(filters => ({...filters, [e.target.name]: e.target.value}));
  }

  return (
    <>
      <GoodsFilter searchText={searchText} handleSearchText={handleSearchText} resetText={resetText} filters={filters}
        handleChangeFilters={handleChangeFilters} projectPeriods={projectPeriods} academicUnits={academicUnits}
      />
      <TableContainer component={Paper}>
        <GoodsListTable goods={goodsFiltered} projectPeriods={projectPeriods} />
      </TableContainer>
    </>
  )
}

export default GoodsList;
