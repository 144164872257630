import React from 'react';

import {TableHead, TableRow, TableCell, } from '@material-ui/core';

const ProjectsListTableHeader = ({userInfo, }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Código Mineduc</TableCell>
        <TableCell>Nombre de Convenio</TableCell>
        <TableCell>Fuente de Financiamiento</TableCell>
        {
          userInfo.role === 'admin' &&
          <TableCell>Número de Resolución interna</TableCell>
        }
        <TableCell>Fecha de Inicio</TableCell>
        <TableCell>Fecha de Término Ejecución</TableCell>
        <TableCell>Duración en Meses</TableCell>
        <TableCell>Tiempo Restante</TableCell>
        {
          userInfo.role === 'admin' &&
          <>
            <TableCell>Fecha de Término Vigencia</TableCell>
            <TableCell>Código interno USACH</TableCell>
          </>
        }
        <TableCell>Estado</TableCell>
      </TableRow>
    </TableHead>
  )
}

export default ProjectsListTableHeader;
