import React, {useState, } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Tabs, Tab, Typography, Box, } from '@material-ui/core';

import ProjectDetailsPlanification from './ProjectDetailsPlanification/ProjectDetailsPlanification';
import ProjectDetailsBudget from './ProjectDetailsBudget/ProjectDetailsBudget';
import ProjectDetailsActivities from './ProjectDetailsActivities/ProjectDetailsActivities';
import ProjectDetailsInventory from './ProjectDetailsInventory/ProjectDetailsInventory';
import ProjectDetailsDocumentation from './ProjectDetailsDocumentation/ProjectDetailsDocumentation';
import ProjectDetailsNormativeContext from './ProjectDetailsNormativeContext/ProjectDetailsNormativeContext';
import ProjectDetailsCapitulation from './ProjectDetailsCapitulation/ProjectDetailsCapitulation';
import ProjectDetailsClosing from './ProjectDetailsClosing/ProjectDetailsClosing';

import FilterComponent from '../../Utils/FilterComponent/FilterComponent';
import FilterTabsComponent from '../../Utils/FilterComponent/FilterTabsComponent';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  bar: {
    backgroundColor: '#002F6C',
    borderRight: `1px solid ${theme.palette.divider}`,
  }
}));



const ProjectDetailsTabs = ({project, generalPurposesData, setProject, projectObjectives, setProjectObjectives, projectActivities, setProjectActivities, userData,
  allSpecificPurposes, allMilestones, projectObjectivesResults, projectBudget, projectPeriods, projectGoods, academicUnits, }) => {

  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      permission: 'planningTab.show',
      label: 'Planificación',
      component: <ProjectDetailsPlanification project={project} generalPurposesData={generalPurposesData} projectObjectivesResults={projectObjectivesResults}
      />
    },
    {
      permission: 'budgetTab.show',
      label: 'Presupuesto de Gastos',
      component: <ProjectDetailsBudget project={project} userData={userData} projectBudget={projectBudget} />
    },
    {
      permission: 'activitiesTab.show',
      label: 'Actividades',
      component: <ProjectDetailsActivities project={project} generalPurposesData={generalPurposesData} projectObjectives={projectObjectives}
        setProjectObjectives={setProjectObjectives} projectActivities={projectActivities} setProjectActivities={setProjectActivities}
        userData={userData} allSpecificPurposes={allSpecificPurposes} allMilestones={allMilestones} active={value === 2} projectPeriods={projectPeriods}
      />
    },
    {
      permission: 'renditionsTab.show',
      label: 'Rendiciones',
      component: <ProjectDetailsCapitulation project={project} setProject={setProject} projectActivities={projectActivities} userData={userData}
        projectPeriods={projectPeriods}
      />
    },
    {
      permission: 'inventoryTab.show',
      label: 'Inventarios',
      component: <ProjectDetailsInventory project={project} projectGoods={projectGoods} projectActivities={projectActivities}
        setProjectActivities={setProjectActivities} userData={userData} projectPeriods={projectPeriods}
        academicUnits={academicUnits}
      />
    },
    {
      permission: 'documentsAndBackgroundTab.show',
      label: 'Antecedentes y Documentación',
      component: <ProjectDetailsDocumentation project={project} userData={userData} />
    },
    {
      permission: 'normatContextTab.show',
      label: 'Contexto Normativo',
      component: <ProjectDetailsNormativeContext project={project} userData={userData} />
    },
  ]

  return (
    <div className={classes.root}>
      <FilterTabsComponent value={value} handleChangeTab={handleChange} tabs={tabs} />
      {/*<AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{style: {background:'white'}}}
          className={classes.bar}
          scrollButtons="on"
          variant="scrollable"
          aria-label="simple tabs example"
        >
          <Tab label="Planificación" {...a11yProps(0)} />
          <Tab label="Presupuesto de Gastos" {...a11yProps(1)} />
          <Tab label="Actividades" {...a11yProps(2)} />
          <Tab label="Rendiciones" {...a11yProps(3)} />
          <Tab label="Inventarios" {...a11yProps(4)} />
          <Tab label="Antecedentes y Documentación" {...a11yProps(5)} />
          <Tab label="Contexto Normativo" {...a11yProps(6)} />
          {/*<Tab label="Cierre del Proyecto" {...a11yProps(7)} />*/}
        {/*</Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <FilterComponent permission={'planningTab.show'}>
          <ProjectDetailsPlanification project={project} generalPurposesData={generalPurposesData} projectObjectivesResults={projectObjectivesResults}
            active={value === 0}
          />
        </FilterComponent>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <FilterComponent permission={'budgetTab.show'}>
          <ProjectDetailsBudget project={project} userData={userData} projectBudget={projectBudget} active={value === 1} />
        </FilterComponent>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <FilterComponent permission={'activitiesTab.show'}>
          <ProjectDetailsActivities project={project} generalPurposesData={generalPurposesData} projectObjectives={projectObjectives}
            setProjectObjectives={setProjectObjectives} projectActivities={projectActivities} setProjectActivities={setProjectActivities}
            userData={userData} allSpecificPurposes={allSpecificPurposes} allMilestones={allMilestones} active={value === 2} projectPeriods={projectPeriods}
          />
        </FilterComponent>
      </TabPanel>

      <TabPanel value={value} index={3}>
        <FilterComponent permission={'renditionsTab.show'}>
          <ProjectDetailsCapitulation project={project} setProject={setProject} projectActivities={projectActivities} userData={userData}
            active={value === 3} projectPeriods={projectPeriods}
          />
        </FilterComponent>
      </TabPanel>

      <TabPanel value={value} index={4}>
        <FilterComponent permission={'inventoryTab.show'}>
          <ProjectDetailsInventory project={project} projectGoods={projectGoods} projectActivities={projectActivities}
            setProjectActivities={setProjectActivities} userData={userData} projectPeriods={projectPeriods}
            academicUnits={academicUnits}
            active={value === 4}
          />
        </FilterComponent>
      </TabPanel>

      <TabPanel value={value} index={5}>
        <FilterComponent permission={'documentsAndBackgroundTab.show'}>
          <ProjectDetailsDocumentation project={project} userData={userData} active={value === 5} />
        </FilterComponent>
      </TabPanel>

      <TabPanel value={value} index={6}>
        <FilterComponent permission={'normatContextTab.show'}>
          <ProjectDetailsNormativeContext project={project} userData={userData} active={value === 6} />
        </FilterComponent>
      </TabPanel>}

      {/*

        <TabPanel value={value} index={7}>
        <ProjectDetailsClosing project={project} active={value === 7} />

        </TabPanel>
      */}
    </div>
  );
}

export default ProjectDetailsTabs;
