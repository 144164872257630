import React from 'react';

import {TableRow, TableCell, } from "@material-ui/core";

const ProjectDetailsResultsTableRow = ({objectiveResults, indicator, maxIndicatorGoals, openIndicatorModal, }) => {

  const arrayIndicator = new Array(maxIndicatorGoals).fill(0);

  return (
    <TableRow hover onClick={() => openIndicatorModal(indicator, objectiveResults)}>
      <TableCell>{indicator.name}</TableCell>
      <TableCell>{indicator.calculationFormula}</TableCell>
      <TableCell>{indicator.baseLine}</TableCell>
      {
        arrayIndicator.map((n, i) =>
          <TableCell>
            {indicator.indicatorGoals && (indicator.indicatorGoals[i] || '-')}
          </TableCell>
        )
      }
    </TableRow>
  )
}

export default ProjectDetailsResultsTableRow;
