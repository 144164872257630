import React, {} from 'react';

import {Table, } from 'react-bootstrap';

const Repository = ({files, addFile, removeFile, }) => {

  return (
    <>
      <Table>
        <thead>
          <th>Nombre Archivo</th>
          <th>Opciones</th>
        </thead>
        <tbody>
          {
            files.map(file =>
              <tr>
                <td>{file.name}</td>
                <td>Aca van opciones</td>
              </tr>
            )
          }
        </tbody>
      </Table>
      <div className="file-uploader">
       <input type="file" onChange={addFile} />
       {/*<button onClick={e => fileInput.current && fileInput.current.click()} className="btn btn-primary" />*/}
      </div>
    </>
  )
}

export default Repository;
