import React from 'react'

import { makeStyles } from '@material-ui/core/styles';

import DynamicFieldsFirstField from './DynamicFieldsFirstField';

import PrimaryButton from '../Buttons/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const DynamicFields = ({generalPurposes, handleChange, handleChangeType, handleAdd, handleAddType, handleRemove, handleRemoveField, defaultValue, type, }) => {

  const classes = useStyles();

  return (
    <>
      <div className={classes.button}>
        <PrimaryButton title={'Agregar objetivo principal'} onClick={() => handleAdd()} />
      </div>

      {generalPurposes && generalPurposes.map((generalPurpose, generalPurposeIdx) =>
        <DynamicFieldsFirstField generalPurpose={generalPurpose} generalPurposeIdx={generalPurposeIdx} handleChange={handleChange} handleChangeType={handleChangeType}
          handleAddType={handleAddType} handleRemove={handleRemove} handleRemoveField={handleRemoveField} defaultValue={defaultValue} type={type}
        />
      )}
    </>
  );
}

export default DynamicFields;
