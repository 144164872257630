import React from 'react';

import { useSelector } from 'react-redux';

import {UserPermissionsSelector, } from '../../../../../../../redux/selectors';

import {TableCell, TableRow, FormControlLabel, Checkbox, Collapse, } from '@material-ui/core';

import FeatherIcon from 'feather-icons-react';

import { useDispatch, } from 'react-redux';
import {handleGoodsModal, editGood, } from '../../../../../../../redux/actions';

import {getSelectFormat, } from '../../../../../../Utils/Functions/DateFunctions';

const GoodsListRow = ({projectPeriods, good, goodIdx, showResponsible, }) => {

  const dispatch = useDispatch();

  const {userPermissionsList} = useSelector(UserPermissionsSelector);

  const token = localStorage.getItem('token');
  const userData = JSON.parse(atob(token.split('.')[1]));
  const userRole = userData.isAdmin;
  const checkPermissions = userPermissionsList.find(permission => permission.slug === 'inventoryProperty.show');

  const periodFind = projectPeriods.find(period => period.id === good.periodId);

  const openGoodsModal = () => {
    dispatch(handleGoodsModal(true, {
      good: good
    }));
  }

  return (
    <TableRow hover={userRole || checkPermissions} onClick={(userRole || checkPermissions) ? openGoodsModal : () => {}}>
      <TableCell component="th" scope="row">
        {good.description}
      </TableCell>
      <TableCell>
        {good.amount}
      </TableCell>
      <TableCell>
        {good.intentoryItems[0]?.brand}
      </TableCell>
      <TableCell>
        {good.intentoryItems[0]?.model}
      </TableCell>
      <TableCell>
        {good.intentoryItems[0]?.supplier}
      </TableCell>
      <TableCell>
        {good.intentoryItems[0]?.codeNumber}
      </TableCell>
      <TableCell>
        {good.intentoryItems[0]?.serialNumber}
      </TableCell>
      <TableCell>
        {good.intentoryItems[0]?.location}
      </TableCell>
      <TableCell>
        {good.academicUnit?.name}
      </TableCell>
      <TableCell>
        {
          periodFind ? (periodFind.startDate &&
          getSelectFormat(periodFind.startDate, periodFind.endDate)) : '-'
        }
      </TableCell>
      <TableCell>
        {good.intentoryItems[0]?.responsable}
      </TableCell>
      {
        showResponsible &&
        <>
          <TableCell>
            {good.intentoryItems[0]?.responsableEmail}
          </TableCell>
          <TableCell>
            {good.intentoryItems[0]?.responsableNumber}
          </TableCell>
        </>
      }
    </TableRow>
  )
}

export default GoodsListRow;
